<template>
  <div class="scroll-bar" ref="scrollBar">
    <div
      class="scroll-percent"
      :style="{ marginLeft: maxLeft * percent + 'px' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ScrollBar",
  props: {
    percent: Number,
  },
  data: function () {
    return {
      maxLeft: 100,
    };
  },
  mounted: function () {
    this.checkMaxLeft();
    window.addEventListener("resize", this.checkMaxLeft);
  },
  methods: {
    checkMaxLeft: function () {
      this.maxLeft = this.$refs.scrollBar.offsetWidth - 70;
    },
  },
};
</script>

<style scoped>
.scroll-bar {
  height: 5px;
  background: #ebeef1;
  border-radius: 100px;
}
.dark .scroll-bar {
  background: #333333;
}
.scroll-percent {
  width: 70px;
  height: 100%;
  background: #000000;
  border-radius: 100px;
}
.dark .scroll-percent {
  background: #d9d9d9;
}
</style>
