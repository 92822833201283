<template>
  <div class="modal" @click.self="close">
    <div class="modal-window" :class="{ max: max, wide: wide }">
      <p class="close" @click="close">
        <mdicon name="close" size="24" />
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    max: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}
.dark .modal {
  background: rgba(0, 0, 0, 0.8);
}
.modal-window {
  position: relative;
  background: #fff;
  max-width: 600px;
  max-height: 90%;
  border: 1px solid #eef0f3;
  box-shadow: 0 15px 30px rgba(0,0,0,.08);
  border-radius: 10px;
  text-align: left;
  padding: 10px;
  padding-top: 40px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.dark .modal-window {
  background: black;
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
  border: 1px solid #333333;
}
.modal-window.max {
  height: 90%;
}
.modal-window.wide {
  width: 90%;
  max-width: 90%;
}
p {
  font-family: "Roboto";
}
.close {
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #909194;
}
</style>