<template>
  <div class="flow-search">
    <input type="text" v-model="search" placeholder="Ticker" />
    <img v-if="!loading" src="../assets/search-normal.png" />
    <img v-if="loading" src="../assets/loading.gif" />
  </div>
</template>

<script>
export default {
  name: "FlowSearch",
  props: {
    pageUpdated: Boolean,
  },
  data: function () {
    return {
      search: "",
      results: [],
      timer: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    openTicker: function () {},
  },
  watch: {
    search: function (val) {
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.$emit("search", val), 1500);
    },
    pageUpdated() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.flow-search {
  position: relative;
  width: 200px;
}

input {
  position: relative;
  height: 40px;
  border-radius: 100px;
  border: 1px solid #eef0f3;
  box-shadow: 0px 10px 50px RGBA(0, 0, 0, 0.16);
  padding: 12px 20px;
  padding-right: 40px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  z-index: 10;
  width: 200px;
}

.dark input {
  background: #000000;
  color: #d9d9d9;
  box-shadow: none;
  border: 1px solid #333333;
}

input:focus {
  outline: #10bc74 solid 2px;
}

img {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
  width: 16px;
  height: 16px;
}
.search-results {
  position: absolute;
  top: 45px;
  left: 20px;
  right: 20px;
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 10px 50px RGBA(0, 0, 0, 0.16);
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 375px;
  overflow-y: scroll;
}
.dark .search-results {
  background: #191919;
  box-shadow: 0px 0px 100px RGBA(0, 0, 0, 1);
}
.result {
  margin-top: 20px;
  cursor: pointer;
}
.result p {
  font-family: "Roboto";
  margin: 0;
  text-align: left;
  font-size: 16px;
}

.result .ticker {
  font-weight: 500;
}

.error p {
  margin: 0;
  font-family: "Roboto";
  font-size: 14px;
  color: #ce0606;
  margin-top: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 550px) {
}
</style>
