<template>
  <div class="double-range-slider">
    <label class="filter-name">{{ this.filterProps.displayName }}</label>
    <div class="multi-range-slider">
      <input
        type="range"
        id="input-left"
        :min="inputLeftMin"
        :max="inputLeftMax"
        v-model="inputLeftValue"
        @input="setLeftValue()"
        @mouseover="addClass('Left', 'hover')"
        @mouseout="removeClass('Left', 'hover')"
        @mousedown="addClass('Left', 'active')"
        @mouseup="removeClass('Left', 'active')"
      />
      <input
        type="range"
        id="input-right"
        :min="inputRightMin"
        :max="inputRightMax"
        v-model="inputRightValue"
        @input="setRightValue()"
        @mouseover="addClass('Right', 'hover')"
        @mouseout="removeClass('Right', 'hover')"
        @mousedown="addClass('Right', 'active')"
        @mouseup="removeClass('Right', 'active')"
      />

      <div class="slider">
        <div class="start-end">
          <div ref="startPos" class="start-value">
            {{ displayFilterMinValue }}
          </div>
          <div ref="endPos" class="end-value">
            {{ displayFilterMaxValue }}&#43;
          </div>
        </div>
        <div class="track"></div>
        <div class="range" :style="rangeStyle"></div>
        <div ref="thumbLeft" class="thumb left" :style="thumbLeftStyle"></div>
        <div
          ref="thumbRight"
          class="thumb right"
          :style="thumbRightStyle"
        ></div>

        <div class="min-max">
          <div
            ref="minPos"
            v-if="displayFilterMinValue != displayLeftValue"
            class="min-value"
            :style="minValuePosition"
          >
            {{ displayLeftValue }}
          </div>
          <div
            ref="maxPos"
            v-if="displayFilterMaxValue != displayRightValue"
            class="max-value"
            :style="maxValuePosition"
          >
            {{ displayRightValue }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";

export default {
  name: "DoubleRangeSlider",
  mixins: [Mixins],
  props: {
    filterProps: Object,
  },
  data() {
    return {
      inputLeftMin: 0,
      inputLeftMax: 100,
      inputLeftValue: this.filterProps.leftValue,
      displayLeftValue: 0,
      inputRightMin: 0,
      inputRightMax: 100,
      inputRightValue: this.filterProps.rightValue,
      displayRightValue: 0,
      rangeStyle: {},
      thumbLeftStyle: {},
      thumbRightStyle: {},
      minValuePosition: {},
      maxValuePosition: {},
      minMaxValuePosition: {},
      timer: 0,
      //below for easier access
      filterName: this.filterProps.name,
    };
  },
  mounted() {
    this.setLeftValue();
    this.setRightValue();
  },
  computed: {
    displayFilterMinValue() {
      const convString = this.shortDollar(this.filterProps.minValue, true);
      return this.filterProps.prependString + convString;
    },
    displayFilterMaxValue() {
      const convString = this.shortDollar(this.filterProps.maxValue, true);
      return this.filterProps.prependString + convString;
    },
  },
  methods: {
    setLeftValue() {
      this.inputLeftValue = this.shortDollar(
        Math.min(
          parseInt(this.inputLeftValue),
          parseInt(this.inputRightValue) - 1
        ),
        true
      );
      // input element range is between 0 and 100. multiply with decimal for large range
      const convString = this.shortDollar(
        this.inputLeftValue * (this.filterProps.maxValue / 100),
        true
      );
      this.displayLeftValue = this.filterProps.prependString + convString;

      let percent =
        ((this.inputLeftValue - this.inputLeftMin) /
          (this.inputLeftMax - this.inputLeftMin)) *
        100;

      this.thumbLeftStyle.left = percent + "%";
      this.rangeStyle.left = percent + "%";

      this.minValuePosition.left = this.optimizeLeftDisplayPosition(percent);
      this.checkForOverlap();
      this.updateFilterData();
    },
    setRightValue() {
      this.inputRightValue = this.shortDollar(
        Math.max(
          parseInt(this.inputRightValue),
          parseInt(this.inputLeftValue) + 1
        ),
        true
      );
      // input element range is between 0 and 100. multiply with decimal for large range
      const convString = this.shortDollar(
        this.inputRightValue * (this.filterProps.maxValue / 100),
        true
      );
      this.displayRightValue = this.filterProps.prependString + convString;

      let percent =
        ((this.inputRightValue - this.inputRightMin) /
          (this.inputRightMax - this.inputRightMin)) *
        100;

      this.thumbRightStyle.right = 100 - percent + "%";
      this.rangeStyle.right = 100 - percent + "%";

      this.maxValuePosition.right = this.optimizeRightDisplayPosition(percent);
      this.checkForOverlap();
      this.updateFilterData();
    },
    addClass(ref, className) {
      this.$refs[`thumb${ref}`].classList.add(className);
    },
    removeClass(ref, className) {
      this.$refs[`thumb${ref}`].classList.remove(className);
    },
    optimizeLeftDisplayPosition(percent) {
      const startPosSizePercent =
        ((this.$refs["startPos"].getBoundingClientRect()?.right -
          this.$refs["startPos"].getBoundingClientRect()?.left) /
          window.innerWidth) *
        100;
      return startPosSizePercent > percent
        ? startPosSizePercent + "%"
        : percent + "%";
    },
    optimizeRightDisplayPosition(percent) {
      const endPosSizePercent =
        ((this.$refs["endPos"].getBoundingClientRect()?.right -
          this.$refs["endPos"].getBoundingClientRect()?.left) /
          window.innerWidth) *
        100;
      return 100 - endPosSizePercent > percent
        ? 100 - percent + "%"
        : endPosSizePercent + "%";
    },

    checkForOverlap() {
      const rangeSize =
        this.$refs["thumbRight"]?.getBoundingClientRect().left -
        this.$refs["thumbLeft"]?.getBoundingClientRect().right;
      const minSize =
        this.$refs["minPos"]?.getBoundingClientRect().right -
        this.$refs["minPos"]?.getBoundingClientRect().left;
      const maxSize =
        this.$refs["maxPos"]?.getBoundingClientRect().right -
        this.$refs["maxPos"]?.getBoundingClientRect().left;
      const minMaxSize = minSize + maxSize;

      // values are based on UI look and feel
      if (rangeSize < minMaxSize) {
        this.minValuePosition.left = (rangeSize / 2 / window.innerWidth) * 100;
        this.maxValuePosition.right = (rangeSize / 2 / window.innerWidth) * 100;
      }
    },
    updateFilterData() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let filterPayload = {
          filterName: this.filterName,
          from: Math.floor(
            this.inputLeftValue * (this.filterProps.maxValue / 100)
          ),
          to: Math.floor(
            this.inputRightValue * (this.filterProps.maxValue / 100)
          ),
          sliderFrom: this.inputLeftValue,
          sliderTo: this.inputRightValue,
        };
        this.$emit("filterUpdated", filterPayload);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.double-range-slider {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 92px;
  padding-bottom: 22px;
}

.multi-range-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 54px;
}

.filter-name {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #151c26;
  margin-bottom: 12px;
}
.dark .filter-name {
  color: #d9d9d9;
}

.slider {
  position: relative;
  z-index: 1;
  height: 10px;
  margin: 0 15px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 6px solid #f0f3f5;
  background: #f0f3f5;
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border: 6px solid #0baa5e;
  background: #0baa5e;
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 8%);
  background: #ffffff;
  border: 2px solid #0baa5e;
  top: 4px;
}

.start-end {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: -25px;
}

.start-value,
.end-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #151c26;
}

.dark .start-value,
.dark .end-value {
  color: #d9d9d9;
}

.min-max {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 20px;
}

.min-value,
.max-value,
.min-max-value {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #97999a;
}

.slider > .thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
}

.slider > .thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
}

.slider > .thumb.hover {
  box-shadow: 0 0 0 10px rgba(11, 170, 94, 0.2);
}

.slider > .thumb.active {
  box-shadow: 0 0 0 20px rgba(11, 170, 94, 0.2);
}

input[type="range"] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 10px;
  width: 100%;
  opacity: 0;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  -webkit-appearance: none;
}
</style>
