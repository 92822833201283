<template>
  <div class="share-card">
    <div v-if="loading" class="load-house">
      <img class="loading" src="../assets/loading.gif" />
      Loading shareable...
    </div>
    <div v-if="!loading && !error">
      <img class="shareable" :src="shareable" />

      <div class="buttons">
        <div class="link-house">
          <p>Aries Verified URL:</p>
          <textarea readonly v-model="shareable"></textarea>
        </div>
        <a
          :href="shareable"
          @click="imageSaved()"
          class="save"
          target="_blank"
          download
          >Save Image</a
        >
      </div>
    </div>
    <div v-if="error">
      <p class="error red">Error: {{ error }}</p>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";

export default {
  name: "OrderCard",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  props: {
    order: Object,
    position: Object,
  },
  components: {},
  data() {
    return {
      loading: true,
      error: null,
      shareable: "",
    };
  },
  mounted() {
    var _this = this;
    var getShareableUrl =
      process.env.VUE_APP_JOURNAL +
      "/profit/profitshare?session=" +
      encodeURIComponent(localStorage.session) +
      "&token=" +
      encodeURIComponent(localStorage.sessionKey);
    if (this.order != null) {
      getShareableUrl += "&order=" + encodeURIComponent(this.order.OrderID);
    } else {
      getShareableUrl +=
        "&position=" + encodeURIComponent(JSON.stringify(this.position));
    }
    this.http
      .get(getShareableUrl)
      .then(function (res) {
        if (res.data.shareable != null) {
          _this.shareable = res.data.shareable;
        } else {
          _this.error = "Failed to get shareable.";
        }

        _this.loading = false;
      })
      .catch(function (err) {
        console.log(err);
        _this.loading = false;
        _this.error = "Failed to get shareable.";
      });
  },
  methods: {
    imageSaved() {
      this.$gtag.event("share_save_image");
    },
  },
  computed: {
    downloadLink() {
      var canvas = document.getElementById("c");
      return canvas.toDataURL("image/jpeg");
    },
  },
};
</script>

<style scoped>
.share-card {
  padding: 10px;
  min-width: 280px;
}
.load-house {
  text-align: center;
  margin-bottom: 24px;
}
.loading {
  width: 50px;
  display: block;
  margin: auto;
  margin-bottom: 24px;
}
.shareable {
  max-width: 100%;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
}
p {
  margin: 0;
}
textarea {
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  word-break: break-all;
  text-overflow: ellipsis;
}
.link-house {
  width: 100%;
  padding-right: 24px;
}
a {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  min-width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
</style>
