/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/**
 * @module Make sure that you include Promise polyfill in your bundle to support old browsers
 * @see {@link https://caniuse.com/#search=Promise | Browsers with native Promise support}
 * @see {@link https://www.npmjs.com/package/promise-polyfill | Polyfill}
 */
import { mdiConsoleLine } from "@mdi/js";
import {
  accountSummaryColumns,
  ordersPageColumns,
  positionsPageColumns,
} from "./columns";
import {
  subscribeRealTimeStream,
  unsubscribeRealTimeStream,
} from "../../datafeeds/udf/lib/streaming";
import https from "https";
const activeOrderStatuses = [
  1, 2, 4, 5, 3 /* OrderStatus.Inactive */, 6 /* OrderStatus.Working */,
];

const codesString =
  "RSN, CSN, UCN, OPN, ASS, BRC, BRF, BRO, CHG, CND, COR, DIS, DOA, ECN, EXE, FPR, LAT, OSO, OTHER, REC, RJC, STP, STT, SUS";

// Convert the string to an array
const codesArray = codesString.split(", ");

// Function to check if a value exists in the array of codes
function isCodePresent(value) {
  return codesArray.includes(value);
}

function getFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

export class BrokerSample {
  constructor(host, quotesProvider, account, orderData, posData, eventEmmiter) {
    let balanceNumber = parseFloat(account[5]).toFixed(2);
    let equityNumber = parseFloat(account[7]).toFixed(2);
    balanceNumber = parseFloat(balanceNumber);
    equityNumber = parseFloat(equityNumber);
    this._account = account;
    this._accountManagerData = {
      title: "Aries",
      balance: balanceNumber,
      equity: equityNumber,
      pl: parseFloat(account[9]).toFixed(2),
    };
    this._ordersData = Array.isArray(orderData) ? orderData : [];
    this._positionById = {};
    this._positions = Array.isArray(posData) ? posData : [];
    this._orderById = {};
    this._executions = [];
    this.streams = {
      position: {
        connection: null,
        leftOver: null,
        data: null,
        timeout: null,
      },
    };
    this._idsCounter = 1;
    this._handleEquityUpdate = (value) => {
      this._host.equityUpdate(value);
    };
    this._handleDOMUpdate = (symbol, data) => {
      this._host.domUpdate(symbol, data);
    };
    this._quotesProvider = quotesProvider;
    this._host = host;
    this._amChangeDelegate = this._host.factory.createDelegate();
    this._balanceValue = this._host.factory.createWatchedValue(
      this._accountManagerData.balance
    );
    this._equityValue = this._host.factory.createWatchedValue(
      this._accountManagerData.equity
    );
    this._amChangeDelegate.subscribe(null, (values) => {
      this._balanceValue.setValue(values.balance);
      this._equityValue.setValue(values.equity);
    });
    this.realtimeUpdate = (symbol, data) => {
      this._host.realtimeUpdate(symbol, data);
    };

    eventEmmiter.on("orders", async (orders) => {

      this._ordersData = orders.map((response) =>
        convertApiResponseToSampleOrder(response)
      );
      if (this._host && typeof this._host.orderUpdate === "function") {
        this._ordersData.forEach((order) => {
          this._host.orderUpdate(order);
        });
      } else {
        console.error(
          "this._host is not initialized or orderUpdate is not a function"
        );
      }
    });

    eventEmmiter.on("positions", (positions) => {
      this._positions = positions.map((response) =>
        convertPositionToOrder(response)
      );
      if (this._host && typeof this._host.positionUpdate === "function") {
        this._positions.forEach((pos) => {
          this._host.positionUpdate(pos, true);
        });
      } else {
        console.error(
          "this._host is not initialized or positionUpdate is not a function"
        );
      }
    });

    this.subscribePl();
    this.getPositions();
  }

  // Method to start the interval for fetching and updating orders
  startOrdersUpdateInterval() {
    // Clear any existing interval to prevent multiple intervals running
    if (this._ordersUpdateIntervalId !== null) {
      clearInterval(this._ordersUpdateIntervalId);
    }
    // Set up the interval to callUpdateOrders every 2 seconds
    this._ordersUpdateIntervalId = setInterval(() => {
      this.fetchAndUpdateOrders();
    }, 5000); // 2000 milliseconds = 2 seconds
  }

  async subscribePl() {
    try {
      const url = `${
        process.env.VUE_APP_TS_SIM
      }brokerage/stream/accounts/${localStorage.getItem(
        "lastAccount"
      )}/positions`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const whileCondition = true;
      while (whileCondition) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunkText = decoder.decode(value, { stream: true });
        const res = chunkText.split("\n");
        const data = JSON.parse(res[0]);
        this._host.plUpdate(
          data.PositionID,
          parseFloat(data.UnrealizedProfitLoss)
        );
      }
    } catch (e) {
      console.error("Fetch error:", e);
    }
  }

  async getPositions() {
    // Ensure previous connections and data are cleaned up properly to avoid memory leaks or duplicate data
    if (this.streams.position.connection) {
      this.streams.position.connection?.destroy();
      this.streams.position.connection = null;
    }
    this.streams.position.data = []; // Reset data to an empty array instead of null for consistency
    this.streams.position.leftOver = null;

    // Clear any existing timeout to avoid unnecessary resets
    if (this.streams.position.timeout) {
      clearTimeout(this.streams.position.timeout);
    }

    this.streams.position.timeout = setTimeout(
      () => this.restartPositionStream(),
      10000
    );

    const selectedAccount = localStorage.getItem("lastAccount");
    const accessToken = localStorage.getItem("accessToken");

    const positionUrl = `/v3/brokerage/stream/accounts/${selectedAccount}/positions`;
    const options = {
      hostname:
        localStorage.sim == "true"
          ? "sim-api.tradestation.com"
          : "api.tradestation.com",
      path: positionUrl,
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const startListening = () => {
      https
        .get(options, async (tsRes) => {
          this.streams.position.connection = tsRes;
          tsRes.setEncoding("binary");
          tsRes.on("data", (chunk) => {
            clearTimeout(this.streams.position.timeout);
            this.streams.position.timeout = setTimeout(
              () => this.restartPositionStream(),
              10000
            );
            if (!this.streams.position || !this.streams.position.connection) {
              tsRes?.destroy();
              return;
            }
            if (this.streams.position.leftOver) {
              chunk = this.streams.position.leftOver + chunk;
              this.streams.position.leftOver = null;
            }
            chunk = chunk.replace(/END/g, "").replace(/\r/g, "");
            const positionsData = chunk.split("\n");
            positionsData.forEach((position) => {
              if (position.trim().endsWith("}")) {
                try {
                  const snapShot = JSON.parse(position);
                  if (
                    snapShot["Heartbeat"] != null ||
                    snapShot["StreamStatus"] != null ||
                    snapShot["Error"] != null
                  ) {
                    return;
                  }
                  const index = this.streams.position.data.findIndex(
                    (data) => data.PositionID === snapShot.PositionID
                  );
                  if (snapShot["Deleted"]) {
                    if (index !== -1) {
                      this.streams.position.data.splice(index, 1);
                    } else {
                      return;
                    }
                  } else if (index !== -1) {
                    this.streams.position.data[index] = snapShot;
                  } else {
                    this.streams.position.data.push(snapShot);
                  }
                } catch (e) {
                  this.streams.position.leftOver = null;
                  console.log(e);
                }
              } else {
                this.streams.position.leftOver = position;
              }
            });
            this._positionById = this.streams.position.data?.map((item) =>
              convertPositionToOrder(item)
            );
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    };

    startListening();
  }

  restartPositionStream() {
    this.streams.position.connection?.destroy();
    this.streams.position.connection = null;
    this.streams.position.data = [];
    this.streams.position.leftOver = null;
    clearTimeout(this.streams.position.timeout);
    this.streams.position.timeout = null;
    this.getPositions();
  }

  // Method to stop the interval for fetching and updating orders
  stopOrdersUpdateInterval() {
    if (this._ordersUpdateIntervalId !== null) {
      clearInterval(this._ordersUpdateIntervalId);
      this._ordersUpdateIntervalId = null;
    }
  }

  subscribeEquity() {
    this._equityValue.subscribe(this._handleEquityUpdate, {
      callWithLast: true,
    });
  }
  unsubscribeEquity() {
    this._equityValue.unsubscribe(this._handleEquityUpdate);
  }

  subscribeRealtime(symbol) {
    subscribeRealTimeStream(symbol, this.realtimeUpdate);
  }

  unsubscribeRealtime(symbol) {
    unsubscribeRealTimeStream(symbol);
  }

  subscribeDOM(symbol) {
    const callback = this._handleDOMUpdate;
    const requestSymbol = symbol.includes(":") ? symbol.split(":")[1] : symbol;
    const url = `${process.env.VUE_APP_TS_SIM}marketdata/stream/marketdepth/aggregates/${requestSymbol}`;
    const headers = { Authorization: `Bearer ${localStorage.accessToken}` };
    fetch(url, { headers })
      .then((response) => {
        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }
        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();

        function processStream() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                console.log("Stream complete");
                return;
              }
              const text = textDecoder.decode(value, { stream: true });
              const data = JSON.parse(text);
              let postData = {};
              postData.asks = data.Asks.map((item) => ({
                price: parseFloat(item.Price),
                volume: parseInt(item.TotalSize),
              }));
              postData.bids = data.Bids.map((item) => ({
                price: parseFloat(item.Price),
                volume: parseInt(item.TotalSize),
              }));
              postData.snapshot = true;
              callback(symbol, postData);
              processStream();
            })
            .catch((err) => {
              console.error("Stream reading error:", err);
              processStream();
            });
        }
        processStream();
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }
  unsubscribeDOM() {
    console.log("unsubscribed");
  }
  connectionStatus() {
    return 1 /* ConnectionStatus.Connected */;
  }
  chartContextMenuActions(context, options) {
    return this._host.defaultContextMenuActions(context);
  }
  isTradable(symbol) {
    return Promise.resolve(true);
  }
  async placeOrder(preOrder) {
    console.log("[Pre Order]", preOrder);
    const havePosition = this._positionById.find((item) =>
      item.symbol.includes(preOrder.symbol)
    );
    console.log("[Have Position]", havePosition);
    const url =
      (localStorage.sim ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS) +
      "orderexecution/orders";
    let data = {};
    data.AccountID = this._account[0];
    data.Symbol = preOrder.symbol.includes(":")
      ? preOrder.symbol.split(":")[1]
      : preOrder.symbol;
    data.Quantity = preOrder.qty + "";
    data.OrderType = "Limit";
    data.TimeInForce = {
      Duration: preOrder?.duration?.type || "GTC",
    };
    switch (preOrder.type) {
      case 2:
        data.OrderType = "Market";
        break;
      case 1:
        data.OrderType = "Limit";
        data.LimitPrice = `${preOrder.limitPrice.toFixed(1)}`;
        break;
      case 3:
        data.OrderType = "StopMarket";
        data.StopPrice = `${preOrder?.stopPrice.toFixed(1)}`;
        break;
      case 4:
        data.OrderType = "StopLimit";
        data.StopPrice = `${preOrder?.stopPrice.toFixed(1)}`;
        data.LimitPrice = `${preOrder?.limitPrice.toFixed(1)}`;
    }
    data.TradeAction = preOrder.side == -1 ? "SELL" : "BUY";
    data.Route = "Intelligent";
    if (preOrder.symbol.includes(" ")) {
      let legs = {};
      legs.Quantity = data.Quantity;
      legs.Symbol = data.Symbol;
      if (havePosition) {
        if (havePosition.side == 1)
          legs.TradeAction = preOrder.side == -1 ? "SELLTOCLOSE" : "BUYTOOPEN";
        else
          legs.TradeAction = preOrder.side == -1 ? "SELLTOOPEN" : "BUYTOCLOSE";
      } else {
        data.TradeAction = preOrder.side == -1 ? "SELLSHORT" : "BUY";
        legs.TradeAction = "SELLTOOPEN";
      }
      data.Legs = [legs];
    }
    let response = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      body: JSON.stringify(data),
    });
    response = await response.text();
    response = JSON.parse(response);

    if (response.Error) {
      this._host.showNotification("Error", response.Message);
      return {};
    } else if (
      response.Orders &&
      response.Orders.length > 0 &&
      response.Orders[0].Error
    ) {
      this._host.showNotification("Error", response.Orders[0].Message);
      return {};
    }
    if (
      response.Orders &&
      response.Orders.length > 0 &&
      response.Orders[0].Message
    ) {
      this._host.showNotification("Success", response.Orders[0].Message);
    }
    const orderId = response.Orders[0].OrderID;
    preOrder.id = orderId;
    // this._host.activateBottomWidget();
    if (
      (preOrder.type === 2 /* OrderType.Market */ ||
        preOrder.type === undefined) &&
      this._getBrackets(preOrder.id).length > 0
    ) {
      this._updateOrder(this._createOrder(preOrder));
      return {};
    }
    const orders = this._createOrderWithBrackets(preOrder);
    // orders.forEach((order) => {
    //   this._updateOrder(order);
    // });
    /*
      remove double updated function
    */
    this.startOrdersUpdateInterval();
    return orders;
  }
  async modifyOrder(order) {
    const index = this._orderById.findIndex((item) => (item.id = order.id));
    const originalOrder = this._orderById[index];
    if (originalOrder === undefined) {
      return;
    }
    const data = {
      LimitPrice: `${order.limitPrice}`,
      Quantity: `${order.qty}`,
    };
    const url =
      (localStorage.sim ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS) +
      `orderexecution/orders/${originalOrder.id}`;
    await fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      body: JSON.stringify(data),
    });
    this._updateOrder(order);
    if (order.parentId !== undefined) {
      return;
    }
    const takeProfitBracket = this._getTakeProfitBracket(order);
    const stopLossBracket = this._getStopLossBracket(order);
    this._updateOrdersBracket({
      parent: order,
      bracket: takeProfitBracket,
      newPrice: order.takeProfit,
      bracketType: 1 /* BracketType.TakeProfit */,
    });
    this._updateOrdersBracket({
      parent: order,
      bracket: stopLossBracket,
      newPrice: order.stopLoss,
      bracketType: 0 /* BracketType.StopLoss */,
    });
  }
  async editPositionBrackets(positionId, modifiedBrackets) {
    var _a, _b;
    const position = this._positionById.find((position) => {
      if (position.id === positionId) {
        return position;
      }
    });
    const positionBrackets = this._getBrackets(positionId);
    const modifiedPosition = { ...position };
    // (_a = modifiedPosition.takeProfit) !== null && _a !== void 0
    //   ? _a
    //   : (modifiedPosition.takeProfit = modifiedBrackets.takeProfit);
    // (_b = modifiedPosition.stopLoss) !== null && _b !== void 0
    //   ? _b
    //   : (modifiedPosition.stopLoss = modifiedBrackets.stopLoss);
    modifiedPosition.takeProfit = modifiedBrackets.takeProfit;
    modifiedPosition.stopLoss = modifiedBrackets.stopLoss;
    this._updatePosition(modifiedPosition);
    const takeProfitBracket = positionBrackets.find(
      (bracket) => bracket.limitPrice !== undefined
    );
    const stopLossBracket = positionBrackets.find(
      (bracket) => bracket.stopPrice !== undefined
    );
    this._updatePositionsBracket({
      parent: modifiedPosition,
      bracket: takeProfitBracket,
      bracketType: 1 /* BracketType.TakeProfit */,
      newPrice: modifiedBrackets.takeProfit,
    });
    this._updatePositionsBracket({
      parent: modifiedPosition,
      bracket: stopLossBracket,
      bracketType: 0 /* BracketType.StopLoss */,
      newPrice: modifiedBrackets.stopLoss,
    });
  }
  async closePosition(positionId) {
    const index = this._positionById.findIndex((position) => {
      if (position.id === positionId) {
        return position;
      }
    });
    const position = this._positionById[index];
    let postData = {};
    postData.AccountID = this._account[0];
    postData.Route = "Intelligent";
    postData.TradeAction = position.qty>0?"SELL":"BUY";
    postData.TimeInForce = {
      Duration: "GTC",
    };
    postData.Symbol = position.symbol;
    if (position.symbol.includes(":") > -1) {
      postData.Symbol = position.symbol.includes(":")
        ? position.symbol.split(":")[1]
        : position.symbol;
    }
    if (position.side == 1) {
      postData.Quantity = position.qty + "";
      postData.OrderType = "Market";
    } else {
      postData.OrderType = "Market";
      postData.Legs = [{
        Quantity: Math.abs(position.qty) + "",
        Symbol: position.symbol,
        TradeAction: "BUYTOCLOSE",
      }];
    }
    const url =
      (localStorage.sim ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS) +
      "orderexecution/orders";
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.text())
      .then((res) => {
        const data = JSON.parse(res);
        if (!data.Orders[0].Error) {
          position.qty = 0;
          this._updatePosition(position);
        } else {
          this._host.showNotification(
            "Failed to close position",
            data.Orders[0].Message
          );
        }
      })
      .catch((err) => console.log(err));
  }
  async orders() {
    return this._orders();
  }
  positions() {
    this._positionById = this._positions;
    return Promise.resolve(this._positionById.slice());
  }
  executions(symbol) {
    return Promise.resolve(
      this._executions.filter((data) => {
        return data.symbol === symbol;
      })
    );
  }
  async reversePosition(positionId) {
    const position = this._positionById.find((position) => {
      if (position.id === positionId) {
        return position;
      }
    });
    const handler = () => {
      return this.placeOrder({
        symbol: position.symbol,
        side:
          position.side === -1 /* Side.Sell */
            ? 1 /* Side.Buy */
            : -1 /* Side.Sell */,
        type: 2 /* OrderType.Market */,
        qty: position.qty * 2,
        limitPrice: position.last,
      });
    };
    await handler();
  }
  async cancelOrder(orderId) {
    const url =
      (localStorage.sim ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS) +
      "orderexecution/orders/" +
      orderId;
    await fetch(url, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    });
    const order = this._orderById.find((item) => item.id == orderId);
    const handler = () => {
      order.status = 1 /* OrderStatus.Canceled */;
      this._updateOrder(order);
      this._getBrackets(order.id).forEach((bracket) =>
        this.cancelOrder(bracket.id)
      );
      return Promise.resolve();
    };
    return handler();
  }

  cancelOrders(symbol, side, ordersIds) {
    const closeHandler = () => {
      return Promise.all(
        ordersIds.map((orderId) => {
          return this.cancelOrder(orderId);
        })
      ).then(() => {}); // tslint:disable-line:no-empty
    };
    return closeHandler();
  }
  accountManagerInfo() {
    const summaryProps = [
      {
        text: "Balance",
        wValue: this._balanceValue,
        formatter: "fixed" /* StandardFormatterName.Fixed */, // default value
        isDefault: true,
      },
      {
        text: "Equity",
        wValue: this._equityValue,
        formatter: "fixed" /* StandardFormatterName.Fixed */, // default value
        isDefault: true,
      },
    ];
    return {
      accountTitle: "Trading Sample",
      summary: summaryProps,
      orderColumns: ordersPageColumns,
      positionColumns: positionsPageColumns,
      pages: [
        {
          id: "accountsummary",
          title: "Account Summary",
          tables: [
            {
              id: "accountsummary",
              columns: accountSummaryColumns,
              getData: () => {
                return Promise.resolve([this._accountManagerData]);
              },
              initialSorting: {
                property: "balance",
                asc: false,
              },
              changeDelegate: this._amChangeDelegate,
            },
          ],
        },
      ],
      contextMenuActions: (contextMenuEvent, activePageActions) => {
        return Promise.resolve(this._bottomContextMenuItems(activePageActions));
      },
    };
  }
  async symbolInfo(symbol) {
    // const mintick = await this._host.getSymbolMinTick(symbol);
    const mintick = 0.01;
    const pipSize = mintick; // pip size can differ from minTick
    const accountCurrencyRate = 1; // account currency rate
    const pointValue = 1; // USD value of 1 point of price
    return {
      qty: {
        min: 1,
        max: 1e8,
        step: 1,
      },
      pipValue: pipSize * pointValue * accountCurrencyRate || 1,
      pipSize: pipSize,
      minTick: mintick,
      description: "",
    };
  }
  currentAccount() {
    return "1";
  }
  async accountsMetainfo() {
    return [
      {
        id: "1",
        name: this._account[0],
      },
    ];
  }
  _bottomContextMenuItems(activePageActions) {
    const separator = { separator: true };
    const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility();
    if (activePageActions.length) {
      activePageActions.push(separator);
    }
    return activePageActions.concat([
      {
        text: "Show Buy/Sell Buttons",
        action: () => {
          if (sellBuyButtonsVisibility) {
            sellBuyButtonsVisibility.setValue(
              !sellBuyButtonsVisibility.value()
            );
          }
        },
        checkable: true,
        checked:
          sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value(),
      },
      {
        text: "Trading Settings...",
        action: () => {
          this._host.showTradingProperties();
        },
      },
    ]);
  }
  _createPositionForOrder(order) {
    const positionId = order.symbol;
    let position = this._positionById[positionId];
    const orderSide = order.side;
    const orderQty = order.qty;
    const isPositionClosedByBracket = order.parentId !== undefined;
    order.avgPrice = order.price;
    if (position) {
      const sign = order.side === position.side ? 1 : -1;
      if (sign > 0) {
        position.avgPrice =
          (position.qty * position.avgPrice + order.qty * order.price) /
          (position.qty + order.qty);
      } else {
        position.avgPrice = position.avgPrice;
        const amountToClose = Math.min(orderQty, position.qty);
        this._accountManagerData.balance +=
          (order.price - position.avgPrice) *
          amountToClose *
          (position.side === -1 /* Side.Sell */ ? -1 : 1);
      }
      position.qty = position.qty + order.qty * sign;
      const brackets = this._getBrackets(position.id);
      if (position.qty <= 0) {
        brackets.forEach((bracket) => {
          if (isPositionClosedByBracket) {
            this._setFilledStatusAndUpdate(bracket);
            return;
          }
          this._setCanceledStatusAndUpdate(bracket);
        });
        position.side = changeSide(position.side);
        position.qty *= -1;
      } else {
        brackets.forEach((bracket) => {
          bracket.side = changeSide(position.side);
          bracket.qty = position.qty;
          this._updateOrder(bracket);
        });
      }
    } else {
      position = {
        ...order,
        id: positionId,
        avgPrice: order.price,
      };
    }
    const execution = {
      id: `${this._idsCounter++}`,
      brokerSymbol: order.brokerSymbol,
      price: order.price,
      qty: orderQty,
      side: orderSide,
      symbol: order.symbol,
      time: Date.now(),
    };
    this._executions.push(execution);
    this._host.executionUpdate(execution);
    this._updatePosition(position);
    this._recalculateAMData();
    this._host.plUpdate(position.symbol, position.profit);
    this._host.positionPartialUpdate(position.id, position);
    this._recalculateAMData();
    return position;
  }
  _updateOrderLast(order) {
    this._host.orderPartialUpdate(order.id, { last: order.last });
  }
  _orders() {
    // const sampleOrders = this._ordersData.map((response) =>
    //   convertApiResponseToSampleOrder(response)
    // );
    this._orderById = this._ordersData;
    return Object.values(this._orderById);
  }
  _updateOrder(order) {
    const executionChecks = {
      [-1 /* Side.Sell */]: {
        [2 /* OrderType.Market */]: () => !!order.price,
        [1 /* OrderType.Limit */]: () =>
          order.limitPrice !== undefined && order.last >= order.limitPrice,
        [3 /* OrderType.Stop */]: () =>
          order.stopPrice !== undefined && order.last <= order.stopPrice,
        [4 /* OrderType.StopLimit */]: () => false,
      },
      [1 /* Side.Buy */]: {
        [2 /* OrderType.Market */]: () => !!order.price,
        [1 /* OrderType.Limit */]: () =>
          order.limitPrice !== undefined && order.last <= order.limitPrice,
        [3 /* OrderType.Stop */]: () =>
          order.stopPrice !== undefined && order.last >= order.stopPrice,
        [4 /* OrderType.StopLimit */]: () => false,
      },
    };
    const index = this._orderById.findIndex((item) => item.id == order.id);

    const hasOrderAlready = Boolean(this._orderById[index]);
    this._orderById[index] = order;

    Object.assign(this._orderById[index], order);
    if (!hasOrderAlready) {
      this._subscribeData(order.symbol, order.id, (last) => {
        if (order.last === last) {
          return;
        }
        order.last = last;
        if (order.price == null) {
          order.price = order.last;
        }
        if (
          order.status === 6 /* OrderStatus.Working */ &&
          executionChecks[order.side][order.type]()
        ) {
          const positionData = { ...order };
          order.price = order.last;
          order.avgPrice = order.last;
          const position = this._createPositionForOrder(positionData);
          order.status = 2 /* OrderStatus.Filled */;
          this._updateOrder(order);
          this._getBrackets(order.id).forEach((bracket) => {
            bracket.status = 6 /* OrderStatus.Working */;
            bracket.parentId = position.id;
            bracket.parentType = 2 /* ParentType.Position */;
            this._updateOrder(bracket);
          });
        }
        this._updateOrderLast(order);
      });
    }
    this._host.orderUpdate(order);
    if (order.parentId !== undefined) {
      const entity =
        order.parentType === 2 /* ParentType.Position */
          ? this._positionById[order.parentId]
          : this._orderById[order.parentId];
      if (entity === undefined) {
        return;
      }
      if (order.limitPrice !== undefined) {
        entity.takeProfit =
          order.status !== 1 /* OrderStatus.Canceled */
            ? order.limitPrice
            : undefined;
      }
      if (order.stopPrice !== undefined) {
        entity.stopLoss =
          order.status !== 1 /* OrderStatus.Canceled */
            ? order.stopPrice
            : undefined;
      }
      if (order.parentType === 2 /* ParentType.Position */) {
        return this._updatePosition(entity);
      }
      this._updateOrder(entity);
    }
  }
  _updatePosition(position) {
    const index = this._positionById.findIndex(
      (item) => position.id === item.id
    );
    const hasPositionAlready = Boolean(this._positionById[index]);
    if (hasPositionAlready && !position.qty) {
      this._unsubscribeData(position.id);
      const index = this._positions.indexOf(position);
      if (index !== -1) {
        this._positions.splice(index, 1);
      }
      delete this._positionById[index];
      this._host.positionUpdate(position);
      return;
    }
    if (!hasPositionAlready) {
      this._positions.push(position);
      this._subscribeData(position.symbol, position.id, (last) => {
        if (position.last === last) {
          return;
        }
        position.last = last;
        position.profit =
          (position.last - position.price) *
          position.qty *
          (position.side === -1 /* Side.Sell */ ? -1 : 1);
        this._host.plUpdate(position.symbol, position.profit);
        this._host.positionPartialUpdate(position.id, position);
        this._recalculateAMData();
      });
    }
    this._positionById[index] = position;
    this._host.positionUpdate(position);
  }
  _subscribeData(symbol, id, updateFunction) {
    this._quotesProvider.subscribeQuotes(
      [],
      [symbol],
      (symbols) => {
        const deltaData = symbols[0];
        if (deltaData.s !== "ok") {
          return;
        }
        if (typeof deltaData.v.lp === "number") {
          updateFunction(deltaData.v.lp);
        }
      },
      getDatafeedSubscriptionId(id)
    );
  }
  _unsubscribeData(id) {
    this._quotesProvider.unsubscribeQuotes(getDatafeedSubscriptionId(id));
  }
  _recalculateAMData() {
    let pl = 0;
    this._positions.forEach((position) => {
      pl += position.profit || 0;
    });
    // this._accountManagerData.pl = pl;
    this._accountManagerData.equity = this._accountManagerData.balance + pl;
    this._amChangeDelegate.fire(this._accountManagerData);
  }
  _createOrderWithBrackets(preOrder) {
    const orders = [];
    const order = this._createOrder(preOrder);
    orders.push(order);
    if (order.takeProfit !== undefined) {
      const takeProfit = this._createTakeProfitBracket(order);
      orders.push(takeProfit);
    }
    if (order.stopLoss !== undefined) {
      const stopLoss = this._createStopLossBracket(order);
      orders.push(stopLoss);
    }
    return orders;
  }
  _getBrackets(parentId) {
    return this._orders().filter(
      (order) =>
        order.parentId === parentId &&
        activeOrderStatuses.includes(order.status)
    );
  }
  _createOrder(preOrder) {
    return {
      id: `${preOrder.id || this._idsCounter++}`,
      duration: preOrder.duration, // duration is not used in this sample
      limitPrice: preOrder.limitPrice,
      profit: 0,
      qty: preOrder.qty,
      side: preOrder.side || 1 /* Side.Buy */,
      status: 6 /* OrderStatus.Working */,
      stopPrice: preOrder.stopPrice,
      symbol: preOrder.symbol,
      type: preOrder.type || 2 /* OrderType.Market */,
      takeProfit: preOrder.takeProfit,
      stopLoss: preOrder.stopLoss,
    };
  }
  _createTakeProfitBracket(entity) {
    return {
      symbol: entity.symbol,
      qty: entity.qty,
      id: `${this._idsCounter++}`,
      parentId: entity.id,
      parentType: 1 /* ParentType.Order */,
      limitPrice: entity.takeProfit,
      side: changeSide(entity.side),
      status: 3 /* OrderStatus.Inactive */,
      type: 1 /* OrderType.Limit */,
    };
  }
  _createStopLossBracket(entity) {
    return {
      symbol: entity.symbol,
      qty: entity.qty,
      id: `${this._idsCounter++}`,
      parentId: entity.id,
      parentType: 1 /* ParentType.Order */,
      stopPrice: entity.stopLoss,
      price: entity.stopPrice,
      side: changeSide(entity.side),
      status: 3 /* OrderStatus.Inactive */,
      type: 3 /* OrderType.Stop */,
    };
  }
  _getTakeProfitBracket(entity) {
    return this._getBrackets(entity.id).find(
      (bracket) => bracket.limitPrice !== undefined
    );
  }
  _getStopLossBracket(entity) {
    return this._getBrackets(entity.id).find(
      (bracket) => bracket.stopPrice !== undefined
    );
  }
  _updateOrdersBracket(params) {
    const { parent, bracket, bracketType, newPrice } = params;
    const shouldCancelBracket = bracket !== undefined && newPrice === undefined;
    if (shouldCancelBracket) {
      this._setCanceledStatusAndUpdate(bracket);
      return;
    }
    if (newPrice === undefined) {
      return;
    }
    const shouldCreateNewBracket = bracket === undefined;
    if (bracketType === 1 /* BracketType.TakeProfit */) {
      const takeProfitBracket = shouldCreateNewBracket
        ? this._createTakeProfitBracket(parent)
        : { ...bracket, limitPrice: newPrice };
      this._updateOrder(takeProfitBracket);
      return;
    }
    if (bracketType === 0 /* BracketType.StopLoss */) {
      const stopLossBracket = shouldCreateNewBracket
        ? this._createStopLossBracket(parent)
        : { ...bracket, stopPrice: newPrice };
      this._updateOrder(stopLossBracket);
      return;
    }
  }
  _updatePositionsBracket(params) {
    const { parent, bracket, bracketType, newPrice } = params;
    const shouldCancelBracket = bracket !== undefined && newPrice === undefined;
    if (shouldCancelBracket) {
      this._setCanceledStatusAndUpdate(bracket);
      return;
    }
    if (newPrice === undefined) {
      return;
    }
    const shouldCreateNewBracket = bracket === undefined;
    if (bracketType === 1 /* BracketType.TakeProfit */) {
      if (shouldCreateNewBracket) {
        const takeProfitBracket = this._createTakeProfitBracket(parent);
        takeProfitBracket.status = 6 /* OrderStatus.Working */;
        takeProfitBracket.parentType = 2 /* ParentType.Position */;
        this._updateOrder(takeProfitBracket);
        return;
      }
      bracket.limitPrice = newPrice;
      bracket.takeProfit = newPrice;
      this._updateOrder(bracket);
      return;
    }
    if (bracketType === 0 /* BracketType.StopLoss */) {
      if (shouldCreateNewBracket) {
        const stopLossBracket = this._createStopLossBracket(parent);
        stopLossBracket.status = 6 /* OrderStatus.Working */;
        stopLossBracket.parentType = 2 /* ParentType.Position */;
        this._updateOrder(stopLossBracket);
        return;
      }
      bracket.stopPrice = newPrice;
      bracket.stopLoss = newPrice;
      this._updateOrder(bracket);
      return;
    }
  }
  _setCanceledStatusAndUpdate(order) {
    order.status = 1 /* OrderStatus.Canceled */;
    this._updateOrder(order);
  }
  _setFilledStatusAndUpdate(order) {
    order.status = 2 /* OrderStatus.Filled */;
    this._updateOrder(order);
  }

  async fetchAndUpdateOrders() {
    let posList = getFromLocalStorage("positionsData");
    let oList = getFromLocalStorage("ordersData");
    if (oList != undefined) {
      this._ordersData = oList.map((response) =>
        convertApiResponseToSampleOrder(response)
      );
      if (this._host && typeof this._host.orderUpdate === "function") {
        this._ordersData.forEach((order) => {
          this._host.orderUpdate(order);
        });
      } else {
        console.error(
          "this._host is not initialized or orderUpdate is not a function"
        );
      }
    }
    if (posList != undefined) {
      this._positions = posList.map((response) =>
        convertPositionToOrder(response)
      );
      if (this._host && typeof this._host.positionUpdate === "function") {
        this._positions.forEach((pos) => {
          this._host.positionUpdate(pos);
        });
      } else {
        console.error(
          "this._host is not initialized or positionUpdate is not a function"
        );
      }
    }
  }
}

function convertApiResponseToSampleOrder(apiResponse) {
  const leg = apiResponse.Legs[0]; // Assuming we are only interested in the first leg
  const sampleOrder = {
    id: `${apiResponse.OrderID}`,
    symbol: `${leg.Symbol}`, // Prefix the symbol with the exchange
    type: mapOrderType(apiResponse.OrderType), // Map to the correct order type
    side: leg.BuyOrSell === "Buy" ? 1 : -1, // Map to 1 for Buy, -1 for Sell
    qty: parseInt(leg.QuantityOrdered, "0"), // Convert string to number
    price: parseFloat(apiResponse.FilledPrice || "0"), // Convert string to float
    limitPrice: parseFloat(apiResponse.LimitPrice || "0"), // Convert string to float
    status: mapOrderStatus(apiResponse.Status), // Map to the correct order status
    stopPrice: parseFloat(apiResponse.StopPrice || "0"), // Default to 0 if StopPrice is not provided\
  };
  return sampleOrder;
}

function mapOrderType(orderType) {
  const orderTypeMapping = {
    Market: 2,
    Limit: 1,
    Stop: 3,
    StopLimit: 4,
  };

  return orderTypeMapping[orderType] || null; // Default to null if type is not recognized
}

function mapOrderStatus(status) {
  // Validate input type
  if (typeof status !== "string") {
    console.error(`Invalid input: ${status}. Expected a string.`);
    return null;
  }

  // Normalize the status
  const normalizedStatus = status.trim().toUpperCase();

  // Map the status based on the provided logic
  if (normalizedStatus === "FLL" || normalizedStatus === "FLP") {
    return 2; // Filled
  } else if (normalizedStatus === "REJ") {
    return 5; // Rejected
  } else if (
    ["CAN", "EXP", "OUT", "RJR", "SCN", "TSC", "UCH"].includes(normalizedStatus)
  ) {
    return 1; // Canceled
  } else if (isCodePresent(normalizedStatus)) {
    return 6; // Specific codes
  } else if (normalizedStatus === "DON") {
    return 3; // Done
  } else if (["ACK", "RPD", "PLA"].includes(normalizedStatus)) {
    return 1; // Acknowledged/Placing
  } else {
    console.warn(`Unrecognized status: ${status}`);
    return null; // Default to null if status is not recognized
  }
}

function convertPositionToOrder(position) {
  const side = position.LongShort === "Long" ? "1" : "-1";
  // Create the order object with the required fields
  const convertingPos = {
    id: position.PositionID,
    qty: `${parseInt(position.Quantity || "0", 0)}`,
    side: side,
    avgPrice: position.AveragePrice,
    last: position.Last,
    bid: position.Bid,
    ask: position.Ask,
    symbol: `${position.Symbol?.replace("!", "")}`, // Remove any unwanted characters like '!'
    takeProfit: position.TodaysProfitLoss, // Assuming takeProfit is based on AveragePrice
    stopLoss: position.UnrealizedProfitLoss, // Assuming stopLoss is based on AveragePrice
    pl: position.UnrealizedProfitLossPercent,
  };
  return convertingPos;
}

function changeSide(side) {
  return side === 1 /* Side.Buy */ ? -1 /* Side.Sell */ : 1 /* Side.Buy */;
}
function getDatafeedSubscriptionId(id) {
  return `SampleBroker-${id}`;
}
