<template>
  <div class="scrollable-container">
    <div class="scrollable-column left-column" ref="leftColumn" @scroll="handleScroll('left', $event)">
      <table class="column-content">
        <thead>
          <tr>
            <th>Vega</th>
            <th>Rho</th>
            <th>Gamma</th>
            <th>Theta</th>
            <th>Delta</th>
            <th>IV</th>
            <th>OI</th>
            <th>Vol.</th>
            <th>Mid</th>
            <th>Bid</th>
            <th>Ask</th>
          </tr>
          <tr>
            <td colspan="10">
              &nbsp;
            </td>
            <td class="light">
              Calls
            </td>
          </tr>
        </thead>
        <tr v-for="(option, index) in callList" :key="'l-opt-' + index" :class="{
          itm: lastItm == option.StrikePrice, buy: selectedCart.filter(
            (obj) =>
              obj.Symbol == option.Symbol && obj.Buy == true
          ).length != 0, sell: selectedCart.filter(
            (obj) =>
              obj.Symbol == option.Symbol && obj.Buy == false
          ).length != 0
        }" @click.self="selectOption(option)">
          <td @click="selectOption(option)">{{ option.Vega }}</td>
          <td @click="selectOption(option)">{{ option.Rho }}</td>
          <td @click="selectOption(option)">{{ option.Gamma }}</td>
          <td @click="selectOption(option)">{{ option.Theta }}</td>
          <td @click="selectOption(option)">{{ option.Delta }}</td>
          <td @click="selectOption(option)">{{ option.ImpliedVolatility }}</td>
          <td @click="selectOption(option)">{{ option.DailyOpenInterest }}</td>
          <td @click="selectOption(option)">{{ option.Volume }}</td>
          <td @click="selectOption(option)">{{ option.Mid }}</td>
          <td @click="selectOption(option, false)" class="sell-box">{{ option.Bid }}</td>
          <td @click="selectOption(option)" class="buy-box">{{ option.Ask }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>
    <div class="fixed-column" ref="centerColumn" @scroll="handleScroll('center', $event)">
      <table>
        <thead>
          <tr>
            <th>Strike</th>
          </tr>
          <tr @click="expirySelection = !expirySelection">
            <td class="expiry-label">{{ daysUntilTargetDate(selectedDate.Date) }}D</td>
          </tr>
        </thead>
        <tr v-for="(option, index) in callList" :key="'c-opt-' + index" :class="{ itm: lastItm == option.StrikePrice }" >
          <td style="position: relative;">
             <div
                @click="showChart('left',option)"
                @contextmenu="showChartNewTab('left',option)"
                @mouseover="showChartButton('left', option, $event)"
                @mouseleave="hideChartButton($event)"
                class="chart-button left"
                :class="{ hide: shownOptionPosition !== 'left' || shownOption !== option }"
              >
                <span style="font-size: 20px;">📈</span>
              </div>
            <span>{{ option.StrikePrice }}</span>
            <div @click="showChart('right', option)"
                @contextmenu="showChartNewTab('right',option)"
                @mouseover="showChartButton('right', option, $event)"
                @mouseleave="hideChartButton($event)"
                class="chart-button right"
                :class="{ hide: shownOptionPosition !== 'right' || shownOption !== option }">
                <span style="font-size: 20px;">📈</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>
    <div class="scrollable-column right-column" ref="rightColumn" @scroll="handleScroll('right', $event)">
      <table class="column-content">
        <thead>
          <tr>
            <th>Bid</th>
            <th>Ask</th>
            <th>Mid</th>
            <th>Vol.</th>
            <th>OI</th>
            <th>IV</th>
            <th>Delta</th>
            <th>Theta</th>
            <th>Gamma</th>
            <th>Rho</th>
            <th>Vega</th>
          </tr>
          <tr>
            <td class="light">
              Puts
            </td>
            <td colspan="10">
              &nbsp;
            </td>
          </tr>
        </thead>
        <tr v-for="(option, index) in putList" :key="'r-opt-' + index" :class="{
          itm: lastItm == option.StrikePrice, buy: selectedCart.filter(
            (obj) =>
              obj.Symbol == option.Symbol && obj.Buy == true
          ).length != 0, sell: selectedCart.filter(
            (obj) =>
              obj.Symbol == option.Symbol && obj.Buy == false
          ).length != 0
        }" @click.self="selectOption(option)">
          <td @click="selectOption(option, false)" class="sell-box">{{ option.Bid }}</td>
          <td @click="selectOption(option)" class="buy-box">{{ option.Ask }}</td>
          <td @click="selectOption(option)">{{ option.Mid }}</td>
          <td @click="selectOption(option)">{{ option.Volume }}</td>
          <td @click="selectOption(option)">{{ option.DailyOpenInterest }}</td>
          <td @click="selectOption(option)">{{ option.ImpliedVolatility }}</td>
          <td @click="selectOption(option)">{{ option.Delta }}</td>
          <td @click="selectOption(option)">{{ option.Theta }}</td>
          <td @click="selectOption(option)">{{ option.Gamma }}</td>
          <td @click="selectOption(option)">{{ option.Rho }}</td>
          <td @click="selectOption(option)">{{ option.Vega }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>
    <div class="cart" v-if="selectedCart.length > 0">
      <div class="order-list">
        <p v-for="(option, index) in selectedCart" :key="'cart' + index" @click="selectOption(option)"
          :class="{ green: option.Buy, red: !option.Buy }">
          {{ option.Buy ? "Buy" : "Sell" }} {{ option.StrikePrice }}
          {{
            option.Symbol.split(" ")[1].indexOf("C") != -1 ? "Calls" : "Puts"
          }}
        </p>
      </div>
      <button class="active" @click="startOrder">Order</button>
    </div>
    <div @click="loadSelection = !loadSelection" class="change-load green">
      {{ loadLevel }} strikes
    </div>
    <div @click="expirySelection = !expirySelection" class="current-expiry green">
      {{ selectedDate.Date.substring(5, 7) }}.{{ selectedDate.Date.substring(8, 10) }}.{{ selectedDate.Date.substring(0,
        4) }}
    </div>
    <div class="expiry-dates" v-if="expirySelection">
      <div class="expiry-heading">
        <p>Choose Exp</p>
        <p>Days</p>
      </div>
      <div @click="changeExpiry(date)" class="expiry-date-options" v-for="(date, index) in dates" :key="'exp' + index"
        :class="{ active: date.Date == selectedDate.Date }">
        <p>{{ months[date.Date.substring(5, 7)] }} {{ date.Date.substring(8, 10) }}, {{ date.Date.substring(0, 4) }}</p>
        <p class="green">{{ daysUntilTargetDate(date.Date) }}</p>
      </div>
    </div>
    <div class="load-levels" v-if="loadSelection">
      <div class="expiry-heading">
        <p>Choose Load</p>
        <p>&nbsp;</p>
      </div>
      <div @click="changeLoad(load)" class="load-options" v-for="(load, index) in [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]"
        :key="'exp' + index" :class="{ active: load == loadLevel }">
        <p>{{ load }}</p>
        <p>Strikes</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionsChain",
  props: {
    symbol: String,
    underlying: Number,
    dates: Array,
    options: Array,
    selectedDate: Object,
    selectedCart: Array,
    loadedDate: Object,
    loadLevel: Number,
    lastLoad: Number,
  },
  data() {
    return {
      shownOption:null,
      shownOptionPosition:null,
      difference: 0,
      expirySelection: false,
      loadSelection: false,
      waitingSecondClick: null,
      months: {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      }
    };
  },
  mounted() {
    this.resetScroll();
    window.addEventListener("resize", this.resetScroll);
    //this hides the fixed element chart buttons so they don't scroll with the page.
    window.addEventListener("scroll", this.resetChartButton);
  },
  beforeDestroy() {
    // To prevent memory leaks, remove the event listener when the component is destroyed.
    window.removeEventListener("resize", this.resetScroll);
    window.removeEventListener("scroll", this.resetChartButton);
  },
  methods: {
    resetChartButton() {
      this.shownOptionPosition = null;
      this.shownOption = null;
    },
    resetScroll() {
      // Scroll the left column all the way to the right initially
      this.$refs.leftColumn.scrollLeft = this.$refs.leftColumn.scrollWidth;
      this.$refs.leftColumn.scrollTop = 38.5 * (this.loadLevel - 4);
      this.difference = this.$refs.leftColumn.scrollLeft;
    },
    handleScroll(direction, event) {
      var newScroll = 0;
      if (direction == 'left') {
        newScroll = this.difference - event.target.scrollLeft;
        this.$refs.rightColumn.scrollLeft = newScroll;
        this.$refs.rightColumn.scrollTop = event.target.scrollTop;
        this.$refs.centerColumn.scrollTop = event.target.scrollTop;
      } else if (direction == 'right') {
        newScroll = this.difference - event.target.scrollLeft;
        this.$refs.leftColumn.scrollLeft = newScroll;
        this.$refs.leftColumn.scrollTop = event.target.scrollTop;
        this.$refs.centerColumn.scrollTop = event.target.scrollTop;
      } else {
        this.$refs.leftColumn.scrollTop = event.target.scrollTop;
        this.$refs.rightColumn.scrollTop = event.target.scrollTop;
      }
    },
    showChart(position,option) {
      //left is calls side
      if (position=='left') {
        window.location.href = `${window.location.origin}/trade/${option.Symbol}`;
      }
      else {
        const putSymbol = this.swapCallToPut(option);
        window.location.href = `${window.location.origin}/trade/${putSymbol}`;
      }
    },
    showChartNewTab(position,option) {
      let url;
      if (position=='left') {
        url = `${window.location.origin}/trade/${option.Symbol}`;
      }
      else {
        const putSymbol = this.swapCallToPut(option);
        url = `${window.location.origin}/trade/${putSymbol}`;
      }
      //create element to make a new blank page.
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click(); 
    },
    showChartButton(position,option,e) {
      const buttonPositionData = e.currentTarget.getBoundingClientRect();
      e.currentTarget.style.position = 'fixed';
      e.currentTarget.style.left = `${buttonPositionData.left}px`;
      e.currentTarget.style.top = `${buttonPositionData.top}px`;

      this.shownOptionPosition = position;
      this.shownOption = option;
    },
    hideChartButton(e){
      e.currentTarget.style.position = 'absolute';
      if (e.currentTarget.classList.contains('left')) {
        e.currentTarget.style.left = `-18px`;
        e.currentTarget.style.top = 0;
      }
      else if (e.currentTarget.classList.contains('right')) {
        e.currentTarget.style.left = 'unset';
        e.currentTarget.style.right = `-18px`;
        e.currentTarget.style.top = 0;
      }
      
      this.shownOptionPosition = null;
      this.shownOption = null;
    },
    selectOption(option, buy = true) {
      if (this.waitingSecondClick == option) {
        this.waitingSecondClick = null;
        this.$emit('activeOption', option);
      } else {
        this.waitingSecondClick = option;
        setTimeout(() => {
          this.waitingSecondClick = null;
        }, 500);
      }

      var selectedOption = JSON.parse(JSON.stringify(option));
      if (selectedOption.Buy == undefined) {
        selectedOption.Buy = buy;
      }
      const indexToRemove = this.selectedCart.findIndex(item => item.Symbol === selectedOption.Symbol);
      if (indexToRemove !== -1) {
        this.selectedCart.splice(indexToRemove, 1);
      } else {
        this.selectedCart.push(selectedOption);
      }
      this.startOrder();
    },
    startOrder(e) {
      //pass the event to check if order button was hit.
      this.$emit("orderOptions", { cart: this.selectedCart, event: e });
    },
    daysUntilTargetDate(targetDateString) {
      // Parse the target date string into a Date object
      const targetDate = new Date(targetDateString);

      // Calculate the time difference in milliseconds between the target date and today
      const timeDifference = targetDate - new Date();

      // Calculate the number of days (rounded up) by dividing and using Math.ceil
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference;
    },
    changeExpiry(date) {
      this.expirySelection = false;
      this.$emit("changeExpiry", date);
    },
    changeLoad(loadTarget) {
      this.loadSelection = false;
      this.$emit("changeLoad", loadTarget);
    },
    swapCallToPut(option) {
      let strings = option.Symbol.split(' ');
      let indexOfLetter = strings[1].indexOf('C')
      let charArr = strings[1].split('');
      charArr[indexOfLetter] = 'P';
      return `${strings[0]} ${charArr.join('')}`;
    }
  },
  computed: {
    putList() {
      if (this.options == null) {
        return [];
      }
      try {
        return this.options.filter(option => option.Symbol.split(" ")[1].indexOf("P") != -1);
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    callList() {
      if (this.options == null) {
        return [];
      }
      try {
        return this.options.filter(option => option.Symbol.split(" ")[1].indexOf("C") != -1);
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    lastItm() {
      if (this.options == null) {
        return 0;
      }
      try {
        return this.options.filter(option => option.StrikePrice < this.underlying).pop().StrikePrice;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
  }
};
</script>

<style scoped>
.scrollable-container {
  display: flex;
  overflow: hidden;
  position: relative;
}

.scrollable-column {
  position: relative;
  overflow: auto;
  max-width: 100%;
  height: 533px;
  flex: 1;
}

.fixed-column {
  height: 533px;
  width: 90px;
  max-width: 100%;
  overflow-y: scroll;
}

.column-content {
  min-width: 400px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

.fixed-column table {
  table-layout: fixed;
}

th,
td {
  min-width: 87px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #F0F3F5;
}

.dark th {
  border: 1px solid #6A6C6C;
}

td {
  font-weight: 400;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

td.light {
  color: #6A6C6C;
}

.fixed-column td {
  background-color: #F3EFFC;
  background-image: url("../assets/chain-scroll.png");
  background-repeat: repeat-y;
  background-position-x: right;
  background-size: auto 100%;
}

.dark .fixed-column td {
  background-color: hsl(258, 68%, 15%);
}

thead {
  position: sticky;
  top: 0;
  /* Make the header row sticky at the top */
  background-color: #ffffff;
  /* Add a background color to the header for contrast */
  z-index: 2;
  /* Ensure the header appears above the tbody */
}

.dark thead {
  background-color: #1F2324;
}

thead td {
  font-size: 16px;
}

.fixed-column thead td {
  background-image: none;
}

tr.itm td {
  border-bottom: 1px solid #3E70F1;
}

tr.buy {
  box-shadow: inset 0 0 0 2px #0BAA5E;
  border-radius: 4px;
  position: relative;
}

tr.buy .buy-box:after,
tr.sell .sell-box:after {
  content: "Buy";
  display: flex;
  position: absolute;
  top: 0;
  width: 90px;
  right: 0;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  background-color: #0BAA5E;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;
}

tr.buy .buy-box,
tr.sell .sell-box {
  position: relative;
}

tr.sell .sell-box:after {
  content: "Sell";
  background-color: #D20B3B;
}


tr.sell {
  box-shadow: inset 0 0 0 2px #D20B3B;
  border-radius: 4px;
  position: relative;
}


thead th {
  border: 1px solid #F0F3F5;
}

.cart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6px;
  background-color: #ffffff;
  font-size: 14px;
}

.dark .cart {
  background-color: #1F2324;
}

.cart .order-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-right: 20px;
}

.cart .order-list p {
  /* display: inline-block; */
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}

button {
  font-family: "Roboto";
  font-size: 14 px;
  font-weight: 500;
  width: 80px;
  padding: 5px 0;
  border-radius: 100px;
  border: none;
  color: #1F2324;
  background: #eef0f3;
  cursor: pointer;
}

.dark button {
  color: #d9d9d9;
  background: #333333;
}

button.active {
  background: #10bc74;
  color: #ffffff;
}

.current-expiry {
  position: absolute;
  top: 50px;
  left: 32px;
  font-size: 14px;
  font-weight: 400;
  z-index: 5;
  padding: 6px;
  cursor: pointer;
}

.change-load {
  position: absolute;
  top: 50px;
  right: 32px;
  font-size: 14px;
  font-weight: 400;
  z-index: 5;
  padding: 6px;
  cursor: pointer;
}

.expiry-dates,
.load-levels {
  position: absolute;
  top: 80px;
  width: 214px;
  height: 300px;
  overflow-y: scroll;
  background-color: #ffffff;
  z-index: 5;
  box-shadow: 0px 4px 16px 0px #1F23244d;
  border-radius: 8px;
}

.dark .expiry-dates,
.dark .load-levels {
  background-color: #1F2324;
  box-shadow: 0px 4px 16px 0px #ffffff74;
}

.expiry-dates {
  left: 32px;
}

.load-levels {
  right: 32px;
}

.expiry-dates p,
.load-levels p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.expiry-heading,
.expiry-date-options,
.load-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #F0F3F5;
  box-sizing: border-box;
  cursor: pointer;
}

.dark .expiry-heading,
.dark .expiry-date-options,
.dark .load-options {
  border-bottom: 1px solid #6A6C6C;
}

.expiry-heading p {
  font-weight: 500;
}


.expiry-heading {
  background-color: #F9FAFC;
}

.dark .expiry-heading {
  background-color: #1d1d1d;
}

.expiry-date-options.active,
.load-options.active {
  background-color: #0BAA5E;
}

.expiry-date-options.active p,
.load-options.active p {
  color: #ffffff;
}

.chart-button {
  z-index: 999;
  position: absolute;
  top: 0;
  padding: 5px 6px;
  background-color: #fff;
  display:flex; flex-direction: column; justify-content: center; align-items: center;
  border-radius: 16px;
  box-shadow: 0px 2px 10px 0px #151C261A;
  width: 28px;
}

.chart-button.left {
  left: -18px;
}

.chart-button.right {
  right: -18px;
}
.hide {
  opacity:0.0;
}
.mdi-finance {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
