<template>
  <div class="parent">
    <p class="price">
      Commission free orders must be split into quantities of 50 or less. To
      automatically split your orders click the Continue button.
    </p>
    <div class="buttons">
      <div class="row">
        <button class="cancel" @click="close()">close</button>
        <button class="confirm" @click="confirm()">Continue</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmationOrder",
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style scoped>
.price {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
}
.parent {
  padding: 1rem 1rem;
  max-width: 367px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}
.buttons {
  margin-top: 2rem;
}
.confirm {
  background-color: #10bc74;
  color: #ffffff;
  margin-right: 15px;
}
.cancel {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}
</style>