<template>
  <div class="top-cubes">
    <!-- <div class="top-cubes" @mousedown="mouseDown" @mousemove="mouseMove" @mouseup="mouseUp"> -->
    <div class="price-cubes" @scroll="scrollCubes" ref="priceCubes">
      <PriceCube
        v-for="(cube, index) in cubes"
        @openTicker="openTicker"
        :key="'cube' + index"
        :symbol="cube.ticker?cube.ticker:cube.symbol"
        :name="cube.description? cube.description : cube.companyName"
        :price="cube.lastPrice?String(cube.lastPrice):String(cube.latestPrice)"
        :change="parseFloat(cube.changePercent * 100).toFixed(2)"
      />
      <p v-if="cubes.length == 0">Nothing to show</p>
    </div>
    <div class="cube-controls" v-if="cubesHaveScroll">
      <ScrollBar class="scroll-bar" :percent="percentCubeScroll" />
      <img
        v-if="!dark"
        src="../assets/scroll-left.png"
        class="scroll-left"
        alt="Scroll Left"
        @click="clickScroll('left')"
      />
      <img
        v-if="dark"
        src="../assets/scroll-left-dark.png"
        class="scroll-left"
        alt="Scroll Left"
        @click="clickScroll('left')"
      />
      <img
        v-if="!dark"
        src="../assets/scroll-right.png"
        class="scroll-right"
        alt="Scroll Left"
        @click="clickScroll('right')"
      />
      <img
        v-if="dark"
        src="../assets/scroll-right-dark.png"
        class="scroll-right"
        alt="Scroll Left"
        @click="clickScroll('right')"
      />
    </div>
  </div>
</template>

<script>
import PriceCube from "./PriceCube.vue";
import ScrollBar from "./ScrollBar.vue";

export default {
  name: "TopCubes",
  inject: {
    stream: { from: "stream" },
  },
  props: {
    cubes: Array,
    dark: Boolean,
    tab: String,
  },
  components: {
    PriceCube,
    ScrollBar,
  },
  data: function () {
    return {
      cubesHaveScroll: false,
      maxCubeScroll: 10,
      percentCubeScroll: 0,
      dragging: false,
      priceStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
    };
  },
  mounted: function () {
    this.checkCubeScrolling();
    window.addEventListener("resize", this.checkCubeScrolling);
    this.streamPrices();
  },
  beforeUnmount: function () {
    this.priceStream.connection.end();
    this.priceStream.connection.destroy();
  },
  methods: {
    streamPrices() {
      var _this = this;
      if (this.priceStream.connection) {
        this.priceStream.connection.end();
        this.priceStream.connection.destroy();
      }
      this.priceStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var tickerString = "";
      for (var i = 0; i < this.cubes.length; i++) {
        tickerString += (this.cubes[i].ticker??this.cubes[i].symbol) + ",";
      }
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${tickerString}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.priceStream.connection = this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.priceStream || !this.priceStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.priceStream.leftOver != null) {
                chunk = _this.priceStream.leftOver + chunk;
                _this.priceStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (
                    snapShot.Last &&
                    _this.cubes.find((obj) => (obj.ticker??obj.symbol) == snapShot.Symbol)
                  ) {
                    _this.cubes.find(
                      (obj) => (obj.ticker??obj.symbol) == snapShot.Symbol
                    ).latestPrice = snapShot.Last;
                  }
                  if (
                    snapShot.NetChangePct &&
                    _this.cubes.find((obj) => (obj.ticker??obj.symbol) == snapShot.Symbol)
                  ) {
                    _this.cubes.find(
                      (obj) => (obj.ticker??obj.symbol) == snapShot.Symbol
                    ).changePercent = snapShot.NetChangePct;
                  }
                } else {
                  _this.priceStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    scrollCubes: function (event) {
      this.maxCubeScroll = event.target.scrollWidth - event.target.offsetWidth;
      this.percentCubeScroll = event.target.scrollLeft / this.maxCubeScroll;
    },
    checkCubeScrolling: function () {
      if (
        this.$refs.priceCubes.scrollWidth > this.$refs.priceCubes.offsetWidth
      ) {
        this.cubesHaveScroll = true;
        this.maxCubeScroll =
          this.$refs.priceCubes.scrollWidth - this.$refs.priceCubes.offsetWidth;
      }
    },
    clickScroll: function (direction) {
      if (direction == "right") {
        this.$refs.priceCubes.scrollLeft += 206;
      } else {
        this.$refs.priceCubes.scrollLeft -= 206;
      }
    },
    openTicker: function (ticker) {
      this.$gtag.event("tap_on_list", {
        location: this.tab == "watch" ? "watchlist" : "active",
        ticker: ticker,
      });
      if (!this.dragging) {
        this.$emit("open-ticker", ticker);
      }
    },
    // mouseDown: function(){
    //   this.dragging = true;
    // },
    // mouseMove: function(event){
    //   if(this.dragging){
    //     this.$refs.priceCubes.scrollLeft -= event.movementX;
    //   }
    // },
    // mouseUp: function(){
    //   this.dragging = false;
    // }
  },
  watch: {
    cubes() {
      if (this.priceStream.connection) {
        this.priceStream.connection.end();
        this.priceStream.connection.destroy();
      }
      this.streamPrices();
      this.checkCubeScrolling();
    },
  },
};
</script>

<style scoped>
.price-cubes {
  margin-top: 46px;
  overflow-x: scroll; 
  white-space: nowrap;
  padding-bottom: 35px;
  padding-left: 70px;
  margin-right: -70px;
  margin-left: -70px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.price-cubes::-webkit-scrollbar {
  display: none;
}
.price-cubes > * {
  display: inline-flex;
  margin-right: 16px;
}

.cube-controls {
  position: relative;
  text-align: right;
  bottom: 14px;
  margin-bottom: -14px;
}
.cube-controls > .scroll-bar {
  position: absolute;
  right: 82px;
  left: 0;
  top: 14px;
}
.cube-controls .scroll-left {
  margin-right: 4px;
}

@media only screen and (max-width: 768px) {
  .price-cubes {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
  }
}
</style>
