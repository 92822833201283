<template>
  <table class="pair-table">
    <tr>
      <th>Symbol</th>
      <th>Last</th>
      <th class="bid">Bid</th>
      <th class="mid">Mid</th>
      <th class="ask">Ask</th>
      <th></th>
    </tr>
    <tr v-for="(pair, index) in pairs" :key="`pair${index}`">
      <td class="symbol">{{ pair.Symbol }}</td>
      <td>{{ cryptoFormat(pair.Last) }}</td>
      <td class="bid">{{ cryptoFormat(pair.Bid) }}</td>
      <td class="mid">
        {{ cryptoFormat((parseFloat(pair.Bid) + parseFloat(pair.Ask)) / 2) }}
      </td>
      <td class="ask">{{ cryptoFormat(pair.Ask) }}</td>
      <td class="trade">
        <button @click="buyPair(pair.Symbol)" class="buy">Buy</button>
        <button @click="sellPair(pair.Symbol)" class="sell">Sell</button>
      </td>
    </tr>
  </table>
</template>

<script>
import Mixins from "../Mixins";

export default {
  name: "Coin",
  props: {
    pairs: Array,
  },
  mixins: [Mixins],
  data() {
    return {};
  },
  methods: {
    getImgUrl(tag) {
      try {
        return require("../assets/crypto/" + tag + ".png");
      } catch (e) {
        return null;
      }
    },
    openCrypto() {
      this.$emit("openCrypto", this.coin);
    },
    buyPair(pairSymbol) {
      this.$emit("buyPair", pairSymbol);
    },
    sellPair(pairSymbol) {
      this.$emit("sellPair", pairSymbol);
    },
  },
};
</script>

<style scoped>
.pair-table {
  width: 100%;
  position: relative;
  text-align: center;
}
tr {
  height: 3em;
  border-bottom: 1px solid black;
}

td,
th {
  width: 16%;
}
table .symbol {
  font-weight: 500;
}
table .trade {
  min-width: 140px;
}

button {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background: #10bc74;
  color: #ffffff;
}
.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}
button.buy {
  margin-right: 10px;
}
button.sell {
  background: #ce0606;
}
.dark button.sell {
  background: #000000;
  border: 1px solid #ce0606;
  color: #ce0606;
}

@media only screen and (max-width: 1250px) {
  .mid {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .bid,
  .ask {
    display: none;
  }
}
</style>
