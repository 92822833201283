<template>
  <div class="position-card">
    <p class="symbol" :class="{
      red: position.OpenProfitLoss < 0,
      green: position.OpenProfitLoss >= 0,
    }">
      {{ displaySymbol(position, true) }}
    </p>
    <p v-if="!byMark && position.PositionType != 'group'" class="price">
      Last: ${{ dollarWithCommas(position.Last) }}
    </p>
    <p v-else-if="position.PositionType != 'group'" class="price">
      Mark: ${{ dollarWithCommas(markPosition(position)) }}
    </p>

    <KeyValue :shareable="!mini && position.PositionType != 'group'" @share="triggerShare()" v-if="!byMark" :class="{
      red: position.UnrealizedProfitLoss < 0,
      green: position.UnrealizedProfitLoss >= 0,
    }" key-string="Profit / Loss" :value="dollarWithCommas(position.UnrealizedProfitLoss)"
      :sub="position.PositionType != 'group' ? `(${position.UnrealizedProfitLossPercent}%)` : null" />
    <KeyValue v-else :shareable="!mini && position.PositionType != 'group'" @share="triggerShareMark()" :class="{
      red: markProfitLossAll(position) < 0,
      green: markProfitLossAll(position) >= 0,
    }" key-string="Profit / Loss" :value="dollarWithCommas(markProfitLossAll(position))"
      :sub="position.PositionType != 'group' ? `(${markProfitLossPercent(position).toFixed(2)}%)` : null" />

    <KeyValue v-if="position.PositionType != 'group'" key-string="Position" :value="position.LongShort +
      ' ' +
      numberWithCommas(position.Quantity.replace('-', ''))
      " />
    <KeyValue v-if="position.PositionType != 'group'" key-string="Bid / Ask" :value="dollarWithCommas(position.Bid) + ' / ' + dollarWithCommas(position.Ask)
      " />

    <KeyValue key-string="Market Value" :value="dollarWithCommas(position.MarketValue)" />

    <KeyValue v-if="position.PositionType == 'group'" key-string="Total Cost"
      :value="dollarWithCommas(position.TotalCost)" />

    <KeyValue last key-string="Average Cost" :value="dollarWithCommas(position.AveragePrice)" />
    <p class="error red" v-if="error">{{ error }}</p>
    <div class="buttons">
      <div class="row" v-if="position.PositionType != 'group'">
        <button class="full" @click="chart()" v-if="!loadingChart">
          Chart
        </button>
        <button class="full blank" v-if="loadingChart">
          <img class="loading" src="../assets/loading.gif" />
        </button>
      </div>
      <div class="row">
        <button @click="buy()" v-if="!loadingBuy">{{ getMoreText }}</button>
        <button class="blank" v-if="loadingBuy">
          <img class="loading" src="../assets/loading.gif" />
        </button>
        <button @click="sell()" v-if="!loadingSell">{{ closeText }}</button>
        <button class="blank" v-if="loadingSell">
          <img class="loading" src="../assets/loading.gif" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import KeyValue from "./KeyValue.vue";

export default {
  name: "PositionCard",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  props: {
    position: Object,
    byMark: Boolean,
    mini: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    KeyValue,
  },
  data() {
    return {
      loadingChart: false,
      loadingBuy: false,
      loadingSell: false,
      error: null,
    };
  },
  computed: {
    getMoreText() {
      if (this.position.PositionType == "group") {
        return "Add More"
      } else if (this.position.LongShort == "Long") {
        return "Buy More"
      } else {
        return "Sell More"
      }
    },
    closeText() {
      if (this.position.PositionType == "group") {
        return "Close Off"
      } else if (this.position.LongShort == "Long") {
        return "Sell / Close"
      } else {
        return "Buy / Cover"
      }
    }
  },
  methods: {
    chart() {
      this.$emit("chart", this.position.Symbol);
    },
    triggerShare() {
      this.$emit("share");
    },
    triggerShareMark() {
      this.$emit("shareMark");
    },
    buy() {
      if (this.position.Symbol.indexOf(" ") != -1) {
        this.$emit("buyOptions", [
          {
            Symbol: this.position.Symbol,
            Buy: this.position.LongShort == "Long",
            Bid: this.position.Bid,
            Ask: this.position.Ask,
            AssetType: this.position.AssetType,
          },
        ]);
      } else if (this.position.PositionType == 'group') {
        var childrenList = [];
        //iterate through position.children
        for (var i = 0; i < this.position.Children.length; i++) {
          var child = this.position.Children[i];
          childrenList.push({
            Symbol: child.Symbol,
            Buy: child.LongShort == "Long",
            Bid: child.Bid,
            Ask: child.Ask,
            AssetType: child.AssetType,
          });
        }
        this.$emit("buyOptions", childrenList);
      } else {
        this.$emit("buy", this.position.Symbol);
      }
    },
    sell() {
      if (this.position.Symbol.indexOf(" ") != -1) {
        this.$emit("sellOptions", [
          {
            Symbol: this.position.Symbol,
            Bid: this.position.Bid,
            Ask: this.position.Ask,
            AssetType: this.position.AssetType,
            AveragePrice: this.position.AveragePrice,
            Quantity: this.position.Quantity,
          },
        ]);
      } else if (this.position.PositionType == 'group') {
        var childrenList = [];
        //iterate through position.children
        for (var i = 0; i < this.position.Children.length; i++) {
          var child = this.position.Children[i];
          childrenList.push({
            Symbol: child.Symbol,
            Buy: child.LongShort == "Short",
            Bid: child.Bid,
            Ask: child.Ask,
            AssetType: child.AssetType,
            AveragePrice: child.AveragePrice,
            Quantity: "1"
          });
        }
        this.$emit("sellOptions", childrenList);
      } else {
        this.$emit("sell", this.position.Symbol);
      }
    },
  },
};
</script>

<style scoped>
.position-card {
  padding: 10px;
  min-width: 280px;
}

p {
  margin: 0;
}

.symbol {
  text-align: center;
  font-weight: 500;
  font-size: 21px;
}

.price {
  text-align: center;
  font-size: 18px;
}

.error {
  text-align: center;
  margin-bottom: 10px;
}

.loading {
  width: 18px;
  height: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}

.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

button.full {
  width: 100%;
}

button.blank {
  background: #ffffff;
  color: #000000;
}

@media only screen and (max-width: 425px) {}
</style>
