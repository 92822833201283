<template>
  <div class="ticker-details">
    <p v-if="errors.global" class="error red">{{ errors.global }}</p>
    <div v-if="ticker && ticker.Error">
      <p class="ticker">{{ ticker.Symbol.toUpperCase() }}</p>
      <p class="error large">{{ ticker.Error }}</p>
      <p class="green large" v-if="watched" @click="setWatchlist(false)" style="cursor: pointer;">Remove from Watchlist
      </p>
      <HeadLink title="Return Home" @on-click="returnCv" /><br /><br />
    </div>
    <div v-if="ticker && !ticker.Error">
      <div class="top-row">
        <div class="graph-window">
          <div class="controls">
            <div class="controls-links">
              <mdicon class="home-link" name="home" size="24" @click="returnCv" aria-label="Return Home Button" />
              <HeadLink :active="tab == 'main'" title="Chart" @on-click="setTab('main')" />
              <HeadLink v-if="filteredPositions.length > 0" :active="tab == 'positions'" title="Positions"
                @on-click="setTab('positions')" />
              <HeadLink v-if="optionsGlance" :active="tab == 'activity'" title="Activity"
                @on-click="setTab('activity')" />
              <HeadLink v-if="epsRatings && epsRatings.length > 1" :active="tab == 'earnings'" title="Earnings"
                @on-click="setTab('earnings')" />

              <HeadLink :active="tab == 'options'" title="Options" @on-click="setTab('options')" v-if="missingOptions"
                @click="viewMissingOptions" />
              <HeadLink v-if="!missingOptions && expiryDates != null" :active="tab == 'options'" title="Options"
                @on-click="setTab('options')" />
            </div>
            <div class="search-house">
              
              <div ref="popTabRef" class="pop-tab" v-if="ticker" :class="{popTabRed: ticker.NetChangePct < 0}" >
                <svg @click="setWatchlist(!watched)" style=" margin-right:.25rem;" width="20" height="18" viewBox="0 0 20 18" 
                :fill="watched && ticker.NetChangePct < 0  ? '#D20B3B' : watched && ticker.NetChangePct > 0 ? '#0BAA5E': 'none'" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.3651 2.84172C16.9395 2.41589 16.4342 2.0781 15.8779 1.84763C15.3217 1.61716 14.7255 1.49854 14.1235 1.49854C13.5214 1.49854 12.9252 1.61716 12.369 1.84763C11.8128 2.0781 11.3074 2.41589 10.8818 2.84172L9.99847 3.72506L9.11514 2.84172C8.25539 1.98198 7.08933 1.49898 5.87347 1.49898C4.65761 1.49898 3.49155 1.98198 2.6318 2.84172C1.77206 3.70147 1.28906 4.86753 1.28906 6.08339C1.28906 7.29925 1.77206 8.46531 2.6318 9.32506L3.51514 10.2084L9.99847 16.6917L16.4818 10.2084L17.3651 9.32506C17.791 8.89943 18.1288 8.39407 18.3592 7.83785C18.5897 7.28164 18.7083 6.68546 18.7083 6.08339C18.7083 5.48132 18.5897 4.88514 18.3592 4.32893C18.1288 3.77271 17.791 3.26735 17.3651 2.84172Z" :stroke="ticker.NetChangePct < 0 ? '#D20B3B' :'#0BAA5E'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                {{ticker.Symbol}} | {{ticker.Last}}
              </div>
              
              
              <SearchBox :ticker="ticker" :watched="watched" :watchlist="watchlist" :noShadow="true" :onTickerPage="true"
                @open-ticker="chartTicker" @update-ticker-details="setWatchlist" />
            </div>
          </div>
          <!-- <div class="basic-house">
            <div class="basic-info">
              <div class="ticker-watch">
                <p class="ticker">
                  {{
      ticker.Symbol.indexOf(" ") != -1
        ? displaySymbol(ticker)
        : ticker.Description
    }}
                </p>
                <img @click="setWatchlist(true)" v-if="ticker.Symbol.indexOf(' ') == -1 && !this.watched"
                  src="../assets/watch.png" alt="Add to watchlist" />
                <img @click="setWatchlist(false)" v-if="ticker.Symbol.indexOf(' ') == -1 && this.watched"
                  src="../assets/watched.png" alt="Remove from watchlist" />
              </div>
              <div class="price-info">
                <p class="price">{{ dollarWithCommas(ticker.Last) }}</p>
                <div class="bid-ask">
                  <p>
                    Bid: ${{ dollarWithCommas(ticker.Bid) }} ({{
      numberWithCommas(ticker.BidSize)
    }})
                  </p>
                  <p>
                    Ask: ${{ dollarWithCommas(ticker.Ask) }} ({{
      numberWithCommas(ticker.AskSize)
    }})
                  </p>
                </div>
              </div>
              <p class="change" :style="{
        color: ticker.NetChange >= 0 ? '#10BC74' : '#CE0606',
      }">
                {{
      (ticker.NetChange > 0 ? "+" : "") +
      parseFloat(ticker.NetChange).toFixed(2)
    }}
                ({{
        (parseFloat(ticker.NetChangePct) * 100)
          .toFixed(2)
          .replace("-", "")
      }}%)
              </p>
            </div>
            <div class="button-house">
              <button class="options" v-if="!optionsRes">
                <img style="width: 20px; height: 20px" src="../assets/loading.gif" />
              </button>
              <button class="trade" @click="scrollToBottom">Trade</button>
            </div>
          </div> -->
          <div class="chart-house" id="TradingTerminal" :class="{ visible: tab == 'main' }">
            <!-- <iframe :src="chartUrl" seamless /> -->
            <TradingTerminalVue :dark="dark" :chartUrl="chartUrl" :watchlistData="watchlist" :positionsData="positions"
              @positions-data="receiveEmitPositionsData" :ordersData="ordersData" :account="account" :eventEmitter="eventEmitter" />
          </div>

          <div v-if="tab != 'main'" class="open-house" :class="{ chain: tab == 'options' }">

            <Positions :byMark="byMark" :positions="filteredPositions"
              :globalPositionsQuotesPrice="globalPositionsQuotesPrice" @openPosition="openPosition"
              v-if="tab == 'positions'" :mini="true" @rollOptions="rollTickerOptions" @sellOptions="sellTickerOptions"
              @sell="sellTicker" :dark="dark" />
            <OptionsActivityChart v-if="tab == 'activity'" :options="optionsGlance" :date="optionsExpiry"
              :dark="dark" />
            <EarningsChart :dark="dark" v-if="tab == 'earnings'" :eps="epsRatings" />

            <OptionsChain class="desktop" v-if="tab == 'options'" :symbol="ticker.Symbol"
              :underlying="parseFloat(ticker.Last)" :dates="expiryDates" :options="optionsData" :loadLevel="loadLevel"
              :lastLoad="lastLoad" :selectedDate="selectedOptionDate" :loadedDate="loadedOptionDate"
              :selectedCart="optionsSelectedCart" @changeExpiry="newOptionDate" @orderOptions="orderOptions"
              @activeOption="setActiveOption" @changeLoad="changeLoad" @saveCart="rememberCart" />

            <OptionsChainMobile class="mobile" v-if="tab == 'options'" :symbol="ticker.Symbol"
              :underlying="parseFloat(ticker.Last)" :dates="expiryDates" :options="optionsData" :loadLevel="loadLevel"
              :lastLoad="lastLoad" :selectedDate="selectedOptionDate" :loadedDate="loadedOptionDate"
              :selectedCart="optionsSelectedCart" @changeExpiry="newOptionDate" @orderOptions="orderOptions"
              @activeOption="setActiveOption" @changeLoad="changeLoad" @saveCart="rememberCart" />

          </div>
        </div>
        <hr class="custom-hr">
          <div class="news-house">
            <HeadLink v-if="!hideNewsTab" :active="subTab == 'NEWS'" title="News" @click="setSubTab('NEWS')" />
            <!-- <HeadLink v-if="showLevelTwo" :active="subTab == 'LEVEL_TWO'" title="Level 2"
              @click="setSubTab('LEVEL_TWO')" /> -->
          <HeadLink v-if="showDepth" :active="subTab == 'DEPTH'" title="Active Trader" @click="setSubTab('DEPTH')" />
          <HeadLink :active="subTab == 'WATCHLIST'" title="Watchlist" @click="setSubTab('WATCHLIST')" />
          <!-- <img class="traderSettingsIcon" @click="activeTraderSettings = true"
            v-if="subTab == 'DEPTH' && !activeTraderSettings" src="../assets/settings.png" />
          <img class="traderSettingsIcon" @click="activeTraderSettings = false"
            v-if="subTab == 'DEPTH' && activeTraderSettings" src="../assets/settings-active.png" /> -->
          <div v-if="subTab == 'NEWS'" class="news-list" ref="news">
            <!-- <perfect-scrollbar> -->
                <NewsArticle v-for="(newsItem, index) in news" :url="newsItem.article_url" :key="'news' + index"
                  :date="getTimeDifference(new Date(newsItem.published_utc))" :author="newsItem.publisher.name" :source="newsItem.source" :title="newsItem.title"
                  :image="newsItem.image_url" :tickers="newsItem.tickers" :showTickers="false" :dark="dark" />
            <!-- </perfect-scrollbar> -->
          </div>
          <div v-if="subTab == 'WATCHLIST'" class="watchlist" ref="watch">
            <div class="loading-main" v-if="!ticker.Symbol">
              <img src="../assets/loading.gif" />
              <p>Loading...</p>
            </div>
            <div v-else>

              <Watchlist :watchListData="watchlist" :ticker="ticker" @chart="chartTicker" :dark="dark" />
            </div>
            <!-- <div v-for="(watched, index) in  watchlistData " class="watchlist-item-container" :key="'watched' + index"
              @click="chartTicker(watched.symbol)">
              <div class="watchlist-item">
                <p class="watchlist-symbol">{{ watched.symbol }}</p>
                <div class="price-column">
                  <p class="watchlist-price">{{ String(watched.latestPrice) }}</p>
                  <p class="watchlist-change" :class="{
      red: watched.changePercent != null && watched.changePercent != 0 && watched.changePercent.indexOf('-') != -1, green:
        watched.changePercent != null && watched.changePercent != 0 && watched.changePercent.indexOf('-') == -1
    }">{{
      (watched.changePercent * 100).toFixed(2) }}%</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div :class="subTab == 'DEPTH' && showLevelTwo ? 'show-L2' : 'hide-L2'
      ">
              <LevelTwoOrders :class="hideNewsTab ? 'level-two' : ''" :symbol="ticker.Symbol" :order="order"
                :dark="dark" :orderLadder="orderLadder" />
            </div> -->
            <div :class="subTab == 'DEPTH' && showDepth ? 'show-depth' : 'hide-depth'
      ">
            <Depth :class="hideNewsTab ? 'depth' : ''" :orderLadder="orderLadder" :largestVolume="orderLadderLargest"
              :account="account"
              :orders="orders.filter(elem => elem.Legs[0].Symbol == activeTraderOption ? activeTraderOption.Symbol : ticker.Symbol)"
              :activeTraderOption="activeTraderOption" :traderSettings="activeTraderSettings" :ticker="ticker"
              :sound="sound" @buyOptions="buyTickerOptions" @closeSettings="activeTraderSettings = false"
              @tapBid="activeTraderTapBid" @tapAsk="activeTraderTapAsk" :tickersData="viewBuyOptions"
              @tapMarket="activeTraderTapMarket" :tabOpened="subTab == 'DEPTH'" :dark="dark" :positions="positions"
              @selectedSymbol="selectedSymbol" :tickerTabSymbol="tickerTabSymbol" />
          </div>
          </div>
          <!-- <div :class="subTab == 'DEPTH' && showLevelTwo ? 'show-L2' : 'hide-L2'
      ">
            <LevelTwoOrders :class="hideNewsTab ? 'level-two' : ''" :symbol="ticker.Symbol" :order="order" :dark="dark"
              :orderLadder="orderLadder" />
          </div> -->

            
        </div>
      <div class="bottom-row">
        <!-- <div v-if="!openOrderAccordian" class="ticker-info">
          <HeadLink :active="false" title="Details" :nohover="true" />
          <div class="detail-split">
            <div>
              <KeyValue key-string="Open" :value="'$' + dollarWithCommas(ticker.Open)" />
              <KeyValue key-string="Close" :value="'$' + dollarWithCommas(ticker.Close)" />
              <KeyValue key-string="Volume" :value="numberWithCommas(ticker.Volume)" />
            </div>
            <div>
              <KeyValue key-string="1D High" :value="'$' + dollarWithCommas(ticker.High)" />
              <KeyValue key-string="1D Low" :value="'$' + dollarWithCommas(ticker.Low)" />
              <KeyValue key-string="1Y High/Low" :value="'$' +
      dollarWithCommas(ticker.High52Week) +
      ' / $' +
      dollarWithCommas(ticker.Low52Week)
      " />
            </div>
          </div>
        </div> -->
        <!-- <div class="analyst-ratings" v-if="ratings && !openOrderAccordian">
          <HeadLink :active="false" title="Analyst Rating" :nohover="true" />
          <p class="rating-subhead">
            Pulled from the world's top analysts.
            <a href="#" class="green" @click="showRatingsList">Tap here</a>
            for break-down.
          </p>
          <AnalystRatings :dark="dark" :ratings="ratings" />
        </div> -->
        <OrderAccordian :byMark="byMark" :sound="sound" :account="account" :positions="positions"  :globalPositionsQuotesPrice="globalPositionsQuotesPrice"
          :orderExecRoutes="orderExecRoutes" :position="selectedPosition" :ticker="ticker" :tickers="viewBuyOptions"
          :orderHistory="orderHistory" :activeTaps="activeTaps" :activeTraderPrice="activeTraderPrice"
          :activeTraderDirection="activeTraderDirection" :activeTraderOption="activeTraderOption"
          :activeTraderIntent="activeTraderIntent" :orders="orders" :openOrderAccordian="openOrderAccordian"
          :currentTab="this.tab"
          @openBuy="toggleAccordian" @chart="chartTicker" :dark="dark" @closeOptions="
      tab = 'main';
    viewBuyOptions = [];
    " @update-cv="updateAccount" />
      </div>
      <TsFooter />

      <Modal max v-if="showRatingDetails" @close="showRatingDetails = false">
        <AnalystDetails :ratings="ratingDetails" />
      </Modal>
      <Modal v-if="buy" @close="buy = false">
        <BuyPosition :sound="sound" :account="account" :ticker="ticker.Symbol" :orderExecRoutes="orderExecRoutes"
          @close="buy = false" @home="returnCv" @chart="chartTicker" @update-cv="updateAccount" />
      </Modal>

      <Modal v-if="optionsInfo" @close="optionsInfo = false">
        <div class="options-info">
          <p>Your account is missing options data entitlement.</p>
          <p>
            If you have not funded your live account yet, please keep in mind
            that options data requires a deposit of any amount.
          </p>
          <a class="green" href="https://www.tradestation.com/" target="_blank">Add Funds</a>
          <p>
            If your funds have already cleared but you're still seeing this
            message please reach out to support.
          </p>
          <a class="green" href="https://www.tradestation.com/contact-us/">Contact Support</a>
        </div>
      </Modal>
      <Arrow />
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import HeadLink from "../components/HeadLink.vue";
import NewsArticle from "../components/NewsArticle.vue";
// import KeyValue from "../components/KeyValue.vue";
// import AnalystRatings from "../components/AnalystRatings.vue";
// import PositionsTable from "../components/PositionsTable.vue";
import OptionsActivityChart from "../components/OptionsActivityChart.vue";
import EarningsChart from "../components/EarningsChart.vue";
import Modal from "../components/Modal.vue";
import OptionsChain from "../components/OptionsChain.vue";
import OptionsChainMobile from "../components/OptionsChainMobile.vue";
import BuyPosition from "../components/BuyPosition.vue";

import AnalystDetails from "../components/AnalystDetails.vue";
import SearchBox from "../components/SearchBox.vue";
// import LevelTwoOrders from "../components/LevelTwoOrders.vue";
import Depth from "../components/Depth.vue";
import OrderAccordian from "../components/orderAccordian/OrderAccordian.vue";
import Arrow from "../components/Arrow.vue";
import Toast from "../components/Toasts.vue";
import TsFooter from "../components/TsFooter.vue";
import Positions from "../components/orderAccordian/Positions.vue";
import TradingTerminalVue from "../components/TradingView/TradingTerminal.vue";
import { streamWatchlist } from "../../public/datafeeds/udf/lib/streaming.js";
import Watchlist from "../components/Watchlist.vue";

const EventEmitter = require("events");

export default {
  name: "TickerDetails",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  props: {
    byMark: Boolean,
    orders: Array,
    orderHistory: Array,
    positions: Array,
    globalPositionsQuotesPrice: Object,
    account: Object,
    watchlist: Array,
    dark: Boolean,
    eventEmitter: EventEmitter,
    sound: Boolean,
    orderExecRoutes: Array,
  },
  mixins: [Mixins],
  components: {
    HeadLink,
    NewsArticle,
    // KeyValue,
    // AnalystRatings,
    // PositionsTable,
    Positions,
    OptionsActivityChart,
    EarningsChart,
    Modal,
    OptionsChain,
    OptionsChainMobile,
    BuyPosition,
    AnalystDetails,
    SearchBox,
    // LevelTwoOrders,
    Depth,
    OrderAccordian,
    Arrow,
    TsFooter,
    Watchlist,
    TradingTerminalVue
  },
  data: function () {
    return {
      tab: "main",
      ticker: null,
      options: false,
      optionsSelectedCart: [],
      chartUrl: `${process.env.VUE_APP_OLD_CHART}/?u=${encodeURIComponent(
        localStorage.u
      )}&access=${encodeURIComponent(
        localStorage.accessToken
      )}&_id=${encodeURIComponent(
        localStorage.session
      )}&token=${encodeURIComponent(
        localStorage.sessionKey
      )}&symbol=${encodeURIComponent(
        this.$route.params.ticker.toUpperCase()
      )}&t=${Date.now()}${this.dark ? "&dark=true" : ""}`,
      buy: false,
      wiim: null,
      news: null,
      ratings: null,
      epsRatings: null,
      openOrderAccordian: false,
      optionsGlance: null,
      glanceRefresh: true,
      optionsExpiry: "",
      optionsData: null,
      selectedOptionDate: null,
      loadedOptionDate: null,
      loadLevel: 10,
      lastLoad: 0,
      expiryDates: null,
      optionsRes: false,
      selectedPosition: null,
      viewBuy: null,
      viewBuyOptions: [],
      viewSell: null,
      viewSellOptions: [],
      rollOptions: null,
      sellQuantity: null,
      mounted: false,
      missingOptions: false,
      optionsInfo: false,
      refreshTimer: null,
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      optionStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      orderStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      order: {},
      ratingDetails: [],
      showRatingDetails: false,
      watched: this.watchlist.find(
        (obj) => obj.symbol == this.$route.params.ticker.toUpperCase()
      )
        ? true
        : false,
      openSymbol: '',
      errors: {},
      subTab: "NEWS",
      hideNewsTab: false,
      showLevelTwo: false,
      showDepth: true,
      showMore: false,
      orderLadder: new Map(),
      orderLadderLargest: 0,
      activeTraderIntent: null,
      activeTraderDirection: null,
      activeTraderPrice: null,
      activeTaps: 0,
      activeTraderSettings: false,
      activeTraderOption: null,
      watchlistData: JSON.parse(localStorage.getItem('watchList')),
      ordersData: this.orders,
      positionsData: this.positions,
      tickerTabSymbol: new Map(),
      newSymbol: "",
      lastPrice:0
    };
  },
  created() {
    //create a temporary watcher to make sure watchlist is populated
    this.watchlistWatcher = this.$watch('watchlist', (newList) => {
      let data = JSON.parse(JSON.stringify(newList));
      const symbolArr = data.filter((e) => e.symbol == this.openSymbol);
      if (symbolArr.length != 0 && symbolArr[0].symbol == this.openSymbol) {
        this.watched = true;
      } else this.watched = false;

      this.watchlistWatcher();
    })
  },
  computed: {
    filteredPositions() {
      return this.positions.filter(
        (position) => position.Symbol.split(" ")[0] == this.ticker.Symbol || position.Symbol == this.ticker.Symbol + "\nSpread"
      );
    },
    lessTitle() {
      return this.wiim.title.length > 130
        ? this.wiim.title.slice(0, 130)
        : this.wiim.title;
    },
    moreTitle() {
      return this.wiim.title.length > 130 ? this.wiim.title.slice(130) : "";
    },
  },
  watch: {
    watchlist(newVal) {
      this.watchlistData = newVal;
      this.streamData();
    },
    subTab(newVal){
      if(newVal=="DEPTH"){
        this.loadOrders();
      }else{
        this.cleanupOrderStream();
      }
    }
  },
  mounted: function () {
    this.openSymbol = this.$route.params.ticker.toUpperCase();
    this.loadQuote();
    // this.loadOrders();
    this.mounted = true;
    this.$gtag.pageview({
      page_title: `Ticker_${this.openSymbol}`,
      page_location: location.href,
      page_path: this.$route.path,
    });
    document.addEventListener('fullscreenchange', this.onFullScreenChange);
    setTimeout(() => {
      this.streamData();
    }, 500);
  },
  beforeUnmount: function () {
    this.mounted = false;
    if (this.quoteStream.connection) {
      this.quoteStream.connection.end();
      this.quoteStream.connection.destroy();
    }
    if (this.optionStream.connection) {
      this.optionStream.connection.end();
      this.optionStream.connection.destroy();
    }
    if (this.orderStream.connection) {
      this.orderStream.connection.end();
      this.orderStream.connection.destroy();
    }
    document.addEventListener('fullscreenchange', this.onFullScreenChange);
  },
  methods: {
    selectedSymbol(newVal) {
      this.openSymbol = newVal;
      this.orderLadder = new Map();
      this.orderLadderLargest = 0;
      this.newSymbol = newVal;
      this.activeTraderOption = null;
      this.loadQuote();
      this.loadOrders();
    },

    receiveEmitPositionsData(posList) {
      this.positions = posList;
    },

    
    orderLadderByKeyValue(arr, symbol) {
      console.log("dataaa", arr, symbol);
      // Additional processing here
    },
    
    loadOrders() {
      const symbols = this.symbol;
      var _this = this;
      if (this.orderStream.connection) {
        this.orderStream.connection.end();
        this.orderStream.connection.destroy();
        this.orderStream.connection = null;
      }
      if (this.orderStream.data) {
        this.orderStream.data = null;
      }
      if (this.orderStream.leftOver) {
        this.orderStream.leftOver = null;
      }
      this.orderStream = {
        symbols: symbols,
        connection: null,
        leftOver: null,
        data: null,
      };

      var options = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v2/stream/matrix/changes/${this.activeTraderOption ? this.activeTraderOption.Symbol : this.openSymbol}?enableVolume=true&interval=1500&limit=100&access_token=${localStorage.accessToken}`,
      };
      this.orderStream.connection = this.stream
        .get(options, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (
                !_this.orderStream ||
                !this.orderStream.connection ||
                !_this.mounted
              ) {
                tsRes.destroy();
                return;
              }
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.orderStream.leftOver != null) {
                chunk = _this.orderStream.leftOver + chunk;
                _this.orderStream.leftOver = null;
              }
              var quotes = chunk.split("\n");

             
            for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Price != null) {
                    if (_this.showDepth == false || _this.showLevelTwo == false) {
                      _this.showDepth = true;
                      _this.showLevelTwo = true;
                    }
                    if (snapShot.SessionVolume && snapShot.SessionVolume > _this.orderLadderLargest) {
                      _this.orderLadderLargest = snapShot.SessionVolume;
                    }
                  
                    if (snapShot.IsLast) {
                       _this.lastPrice = snapShot.Price;
                       _this.orderLadder.set(snapShot.Price, snapShot);
                    } else if(snapShot.Price <= _this.lastPrice+1 || snapShot.Price >= _this.lastPrice-1){
                      _this.orderLadder.set(snapShot.Price, snapShot);
                    }
                  }
                } else {
                  _this.orderStream.leftOver = quotes[a];
                }
              }
          
              setInterval(() => {
                if(_this.orderLadder.size > 1500) {
                  _this.orderLadder.forEach((value, key) => {
                    if (key > _this.lastPrice + 1 || key < _this.lastPrice - 1) {
                      _this.orderLadder.delete(key);
                    }
                  });
                }
              }, 1000);
            } catch (e) {
              _this.orderStream = {
                symbols: "",
                connection: null,
                leftOver: null,
                data: null,
              };
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          _this.orderStream = {
            symbols: "",
            connection: null,
            leftOver: null,
            data: null,
          };
          console.log(err);
        });
    },

  cleanupOrderLadder() {
    const keys = this.orderLadder.keys();
    while (this.orderLadder.size > 1000) { 
      this.orderLadder.delete(keys.next().value);
    }
  },

  cleanupOrderStream() {
    this.orderStream = {
      symbols: "",
      connection: null,
      leftOver: null,
      data: null,
    };
  },

    getTimeDifference(publishedDate) {
      const publishedUTC = new Date(publishedDate);
      const currentDate = new Date();
      const diff = currentDate - publishedUTC;
      const diffMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
      const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(diff / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let formattedDiff = '';
      if (diffMonths > 0) {
        formattedDiff += `${diffMonths}M `;
      }
      if (diffMonths === 0 && diffDays > 0) {
        formattedDiff += `${diffDays}D `;
      }
      if (diffMonths === 0 && diffDays === 0 && diffHours > 0) {
        formattedDiff += `${diffHours}H `;
      }
      if (diffMonths === 0 && diffDays === 0 && diffHours == 0 && (diffMinutes > 0 || diffHours === 0)) {
        if (diffMinutes > 0) {
          formattedDiff += `${diffMinutes.toString().padStart(2, '0')}m`;
        } else {
          formattedDiff += `<1m`;
        }
      }
      return formattedDiff.trim();
    },
    loadQuote: function () {
      var _this = this;
      var openTickerUrl =
        (localStorage.sim == "true"
          ? process.env.VUE_APP_TS_SIM
          : process.env.VUE_APP_TS) +
        `data/quote/${this.openSymbol}?access_token=${encodeURIComponent(
          localStorage.accessToken
        )}`;
      this.http
        .get(openTickerUrl)
        .then(function (res) {
          if(res.data) {
          _this.ticker = res.data[0];
          if (_this.ticker.Symbol.indexOf(" ") == -1) {
            // Get the current recentTickers array from localStorage or initialize it as an empty array
            let recentTickers = JSON.parse(localStorage.getItem('recentTickers')) || [];

            // Check if the new entry already exists in the array based on the Symbol property
            const existingIndex = recentTickers.findIndex(entry => entry.Symbol === _this.ticker.Symbol);

            // If the entry exists, remove it from its current position
            if (existingIndex !== -1) {
              recentTickers.splice(existingIndex, 1);
            }

            // Add the new entry to the beginning of the array
            recentTickers.unshift({
              Symbol: _this.ticker.Symbol,
              Description: _this.ticker.Symbol.indexOf(" ") != -1
                ? "Options"
                : _this.ticker.Description
            });

            // Ensure that the array does not exceed a maximum length of 5
            if (recentTickers.length > 5) {
              recentTickers = recentTickers.slice(0, 5);
            }

            // Save the updated array back to localStorage
            localStorage.setItem('recentTickers', JSON.stringify(recentTickers));

          } else {
            _this.ticker.Buy = true;
            _this.viewBuyOptions = [_this.ticker];
          }
          if (_this.newSymbol) {
            return;
          }

          _this.getData();
          }
        })
        .catch(function (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
          }
        });
    },
    setTab: function (newValue) {
      this.tab = newValue;
      if (newValue == "main") {
        this.$gtag.event(`view_tab_chart`, {
          ticker: this.openSymbol,
        });
      } else if (newValue == "options") {
        this.$gtag.event(`options_view`, {
          ticker: this.openSymbol,
        });
      } else {
        this.$gtag.event(`view_tab_${newValue}`, {
          ticker: this.openSymbol,
        });
      }
    },
    viewMissingOptions() {
      this.$gtag.event("options_view_missing", { ticker: this.openSymbol });
      this.optionsInfo = true;
    },
    showRatingsList() {
      this.showRatingDetails = true;
      this.$gtag.event("view_analyst_ratings_list", {
        ticker: this.openSymbol,
      });
    },
    returnCv: function () {
      this.$emit("return-cv");
    },
    updateAccount: function () {
      this.$emit("update-cv");
    },
    onFullScreenChange: function () {
      var element = document.querySelector('#TradingTerminal');
      var iframe = document.querySelector("#TradingTerminal iframe");
      if (document.fullscreenElement) {
        element.classList.add('Trading-fullscreen');
        iframe.style.height = '100vh';
      } else {
        element.classList.remove('Trading-fullscreen');
        iframe.style.height = '';
      }
    },
    makeFullScreen: async function() {
      var element = document.querySelector('#TradingTerminal');
      if (!document.fullscreenElement) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    scrollToBottom() {
      this.openOrderAccordian = true;
      this.$nextTick(() => {
        document.querySelector("html").scrollTop =
          document.querySelector("html").scrollHeight;
      });
    },
    buyTickerOptions: function (orderArray) {
      console.log(orderArray);
      this.buyOptions = orderArray;
    },
    orderOptions: function (order) {
      this.$router.replace(`/trade/${this.ticker.Symbol}`);
      this.options = false;
      this.viewBuyOptions = JSON.parse(JSON.stringify(order.cart));
      //if the there is a pointer event this means it was the order button clicked
      if (order.event) {
        this.scrollToBottom();
      }
    },
    setActiveOption(option) {
      this.activeTraderOption = option;
      this.orderLadder = new Map();
      this.orderLadderLargest = 0;
      this.loadOrders();
      this.subTab = 'DEPTH';
    },
    rememberCart: function (cart) {
      this.optionsSelectedCart = cart;
    },
    chartTicker: function (ticker) {
      var symbol = ticker.toUpperCase();
      if (symbol == this.ticker.Symbol) {
        return;
      }
      this.tab = "main";
      this.optionsSelectedCart = [];
      this.ticker = null;
      this.options = false;
      this.buy = false;
      this.wiim = null;
      this.news = null;
      this.ratings = null;
      this.epsRatings = null;
      this.openOrderAccordian = false;
      this.optionsGlance = null;
      this.glanceRefresh = true;
      this.optionsExpiry = "";
      this.optionsData = null;
      this.selectedOptionDate = null;
      this.loadedOptionDate = null;
      this.loadLevel = 10;
      this.lastLoad = 0;
      this.expiryDates = null;
      this.optionsRes = false;
      this.selectedPosition = null;
      this.viewBuy = null;
      this.viewBuyOptions = [];
      this.viewSell = null;
      this.viewSellOptions = [];
      this.rollOptions = [];
      this.sellQuantity = null;
      this.optionsInfo = false;
      this.refreshTimer = null;
      this.ratingDetails = [];
      this.showRatingDetails = false;
      this.errors = {};
      this.subTab = "NEWS";
      this.hideNewsTab = false;
      this.showLevelTwo = false;
      this.showMore = false;

      this.$router.replace(`/trade/${symbol}`);
      this.$gtag.pageview({
        page_title: `Ticker_${symbol}`,
        page_location: location.href,
        page_path: this.$route.path,
      });
      this.ticker = null;
      this.chartUrl = `${process.env.VUE_APP_OLD_CHART}/?u=${encodeURIComponent(
        localStorage.u
      )}&access=${encodeURIComponent(
        localStorage.accessToken
      )}&_id=${encodeURIComponent(
        localStorage.session
      )}&token=${encodeURIComponent(
        localStorage.sessionKey
      )}&symbol=${encodeURIComponent(symbol)}&t=${Date.now()}`;
      this.watched = this.watchlist.find((obj) => obj.symbol == symbol)
        ? true
        : false;
      this.openSymbol = symbol;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      if (this.optionStream.connection) {
        this.optionStream.connection.end();
        this.optionStream.connection.destroy();
      }
      if (this.orderStream.connection) {
        this.orderStream.connection.end();
        this.orderStream.connection.destroy();
      }
      this.orderStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      this.orderLadder = new Map();
      this.orderLadderLargest = 0;
      this.activeTraderIntent = null;
      this.activeTraderDirection = null;
      this.activeTraderPrice = null;
      this.activeTaps = 0;
      this.activeTraderSettings = false;
      this.activeTraderOption = null;
      this.loadQuote();
      this.loadOrders();
    },
    buyTicker: function (ticker) {
      this.selectedPosition = null;
      this.viewBuy = ticker;
    },
    sellTicker: function (ticker) {
      this.sellQuantity = this.selectedPosition.Quantity;
      this.selectedPosition = null;
      this.viewSell = ticker;
    },
    sellTickerOptions: function (orderArray) {
      this.sellQuantity = this.selectedPosition.Quantity;
      this.selectedPosition = null;
      this.viewSellOptions = orderArray;
      this.rollOptions = null;
      // this.scrollToBottom();
    },
    rollTickerOptions: function (orderArray) {
      this.selectedPosition = null;
      this.viewSellOptions = orderArray;
      this.rollOptions = orderArray;
      this.scrollToBottom();
    },
    toggleAccordian: function (isOpen) {
      this.openOrderAccordian = isOpen;
    },
    openPosition: function (position) {
      this.selectedPosition = position;
      this.scrollToBottom();
    },
    openOptions() {
      this.options = true;
      this.$router.replace(`/trade/${this.ticker.Symbol}/options`);
    },
    closeOptions() {
      this.options = false;
      this.$router.replace(`/trade/${this.ticker.Symbol}`);
    },
    newOptionDate: function (date) {
      this.loadLevel = 10;
      this.selectedOptionDate = date;
      this.$router.replace(
        `/trade/${this.ticker.Symbol}/options?d=${date.Date.split("T")[0]}`
      );
      this.streamOptions(date, this.loadLevel);
      this.$gtag.event("options_change_expiry", {
        ticker: this.openSymbol,
        date: date.Date,
      });
      // this.streamData();
    },
    changeLoad: function (load) {
      this.loadLevel = load;
      this.streamOptions(this.selectedOptionDate, load);
    },
    getData() {
      var wiimUrl =
        process.env.VUE_APP_BENZINGA +
        `news?tickers=${this.ticker.Symbol}&channels=WIIM&pageSize=1&token=` +
        process.env.VUE_APP_BENZINGA_TOKEN;
      var _this = this;

      this.http
        .get(wiimUrl)
        .then(function (res) {
          if (res.data.length > 0) {
            res.data[0].updated = res.data[0].updated.substring(0, 16);
            _this.wiim = res.data[0];
          }

          _this.showMore = false;
        })
        .catch(function (err) {
          _this.wiim = null;
          console.log(err);
        });
      var newsUrl =`${process.env.VUE_APP_POLYGON_URL}reference/news?ticker=${this.ticker.Symbol}&limit=10&apiKey=${process.env.VUE_APP_POLYGON_API_KEY}`;
      this.http
        .get(newsUrl)
        .then(function (res) {
          _this.news = res.data.results;
          if (_this.news.length < 1) {
            _this.setSubTab("WATCHLIST", true);
            _this.hideNewsTab = true;
            _this.news = null;
          } else {
            _this.setSubTab("NEWS", true);
            _this.hideNewsTab = false;
          }
        })
        .catch(function (err) {
          console.log(err);
          _this.setSubTab("WATCHLIST", true);
          _this.hideNewsTab = true;
          _this.news = null;
        });

      var ratingsUrl =
        process.env.VUE_APP_BENZINGA +
        `calendar/ratings?parameters%5Btickers%5D=${this.ticker.Symbol}&token=` +
        process.env.VUE_APP_BENZINGA_TOKEN;
      this.http
        .get(ratingsUrl)
        .then(function (res) {
          var ratings = res.data.ratings;
          _this.ratingDetails = res.data.ratings;
          var buy = 0;
          var hold = 0;
          var sell = 0;
          var total = 0;
          var alreadyFound = [];
          for (var a = 0; a < ratings.length; a++) {
            if (alreadyFound.indexOf(ratings[a]["analyst"]) == -1) {
              alreadyFound.push(ratings[a]["analyst"]);
              if (
                ratings[a]["rating_current"].indexOf("Buy") != -1 ||
                ratings[a]["rating_current"].indexOf("Accumulate") != -1 ||
                ratings[a]["rating_current"].indexOf("Outperform") != -1 ||
                ratings[a]["rating_current"].indexOf("Overweight") != -1
              ) {
                buy++;
                total++;
              } else if (
                ratings[a]["rating_current"].indexOf("Sell") != -1 ||
                ratings[a]["rating_current"].indexOf("Underperform") != -1 ||
                ratings[a]["rating_current"].indexOf("Underweight") != -1 ||
                ratings[a]["rating_current"].indexOf("Reduce") != -1
              ) {
                sell++;
                total++;
              } else if (
                ratings[a]["rating_current"].indexOf("Hold") != -1 ||
                ratings[a]["rating_current"].indexOf("Neutral") != -1 ||
                ratings[a]["rating_current"].indexOf("Market Perform") != -1 ||
                ratings[a]["rating_current"].indexOf("Sector Perform") != -1 ||
                ratings[a]["rating_current"].indexOf("Monitor") != -1 ||
                ratings[a]["rating_current"].indexOf("Equal") != -1 ||
                ratings[a]["rating_current"].indexOf("In-Line") != -1
              ) {
                hold++;
                total++;
              }
            }
          }
          _this.ratings = {
            buy: buy,
            hold: hold,
            sell: sell,
            total: total,
          };
        })
        .catch(function () { });

      var epsUrl =
        process.env.VUE_APP_BENZINGA +
        `calendar/earnings?parameters%5Btickers%5D=${this.ticker.Symbol}&token=` +
        process.env.VUE_APP_BENZINGA_TOKEN;
      this.http
        .get(epsUrl)
        .then(function (res) {
          var epsRatings = [];
          var epsData = res.data.earnings;
          for (var b = 0; b < epsData.length; b++) {
            if (epsData[b]["date_confirmed"] == 1 && epsRatings.length <= 5) {
              epsRatings.unshift([
                epsData[b]["period"] + " " + String(epsData[b]["period_year"]),
                parseFloat(epsData[b]["eps_est"]),
                "point {stroke-color:#ffffff;stroke-width:2;}",
                parseFloat(epsData[b]["eps"]),
                "point {stroke-color:#ffffff;stroke-width:2;}",
              ]);
            }
          }
          epsRatings.unshift([
            "Quarter",
            "Expected",
            { type: "string", role: "style" },
            "Actual",
            { type: "string", role: "style" },
          ]);
          _this.epsRatings = epsRatings;
        })
        .catch(function () { });

      this.streamData();
      this.getExpiryDates();
    },
    async streamData() {
      var _this = this;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      this.quoteStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      if(_this.ticker){
      var tickerString = await this.ticker?.Symbol;
      }
      for (var i = 0; i < this.watchlist.length; i++) {
        tickerString += "," + this.watchlist[i].symbol;
      }
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${tickerString}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.quoteStream.connection = await this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (
                !_this.quoteStream ||
                !_this.quoteStream.connection ||
                !_this.mounted
              ) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.quoteStream.leftOver != null) {
                chunk = _this.quoteStream.leftOver + chunk;
                _this.quoteStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  streamWatchlist(snapShot);
                  if (snapShot["Symbol"] == _this.ticker.Symbol) {
                    for (const [key, value] of Object.entries(snapShot)) {
                      _this.ticker[key] = value;
                    }
                    //set title tag here
                    document.title = `
                      ${_this.ticker.Symbol} 
                      ${_this.numberWithCommas(_this.ticker.Last)} 
                      ${(_this.ticker.NetChangePct < 0) ? '▼':'▲'}
                      ${(parseFloat(_this.ticker.NetChangePct) * 100).toFixed(2)}%
                    `;
                  }
                  _this.watchlist.forEach((item) => {
                    if (item.symbol == snapShot["Symbol"]) {
                      if (snapShot["NetChangePct"] != null) {
                        item.changePercent = snapShot["NetChangePct"];
                      }
                      if (snapShot["Last"] != null) {
                        item.latestPrice = snapShot["Last"];
                      }
                      if (snapShot["Low"] != null) {
                        item.low = snapShot["Low"];
                      }
                      if (snapShot["High"] != null) {
                        item.high = snapShot["High"];
                      }
                      if (snapShot["NetChange"] != null) {
                        item.netChange = snapShot["NetChange"];
                        item.change = snapShot["NetChange"];
                      }
                    }
                  });
                  localStorage.setItem('watchList', JSON.stringify(_this.watchlistData));
                } else {
                  _this.quoteStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    
    getExpiryDates() {
      var _this = this;
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `marketdata/options/expirations/${this.ticker.Symbol}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          _this.expiryDates = res.data.Expirations;
          if (_this.expiryDates.length == 0) {
            _this.optionsRes = true;
            _this.expiryDates = null;
            return;
          }
          _this.selectedOptionDate = res.data.Expirations[0];
          if (
            _this.$route.path.indexOf("/options") != -1 &&
            _this.$route.query.d
          ) {
            _this.selectedOptionDate = {
              Date: _this.$route.query.d + "T00:00:00Z",
              Type: "Weekly",
            };
          }
          _this.streamOptions(_this.selectedOptionDate, _this.loadLevel);
        })
        .catch(function (error) {
          console.log(error);
          _this.optionsRes = true;
        });
    },
    streamOptions(getDate, getLoad) {
      var dateString = `${getDate.Date.substring(
        5,
        7
      )}-${getDate.Date.substring(8, 10)}-${getDate.Date.substring(0, 4)}`;
      var _this = this;
      if (this.optionStream.connection) {
        this.optionStream.connection.end();
        this.optionStream.connection.destroy();
      }
      if (getLoad > 10) {
        this.optionStream = {
          connection: null,
          leftOver: null,
          data: null,
        };
      } else {
        this.optionsData = null;
        this.optionStream.connection = null;
        this.optionStream.leftOver = null;
      }

      var optionOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/options/chains/${this.ticker.Symbol}?expiration=${dateString}&strikeProximity=${getLoad}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      _this.optionStream.connection = this.stream
        .get(optionOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (
                !_this.optionStream ||
                !this.optionStream.connection ||
                !this.mounted
              ) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.optionStream.leftOver != null) {
                chunk = _this.optionStream.leftOver + chunk;
                _this.optionStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              var newData = _this.optionsData;
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Error) {
                    _this.optionStream.connection.end();
                    _this.optionStream.connection.destroy();
                    if (snapShot.Error == "NotEntitled") {
                      _this.missingOptions = true;
                      _this.optionsRes = true;
                    }
                    return;
                  }
                  if (!snapShot.Legs) {
                    return;
                  }
                  if (!newData) {
                    newData = [
                      {
                        Symbol: snapShot.Legs[0].Symbol,
                        StrikePrice: parseFloat(snapShot.Legs[0].StrikePrice),
                        Ask: parseFloat(snapShot.Ask),
                        Bid: parseFloat(snapShot.Bid),
                        Mid: parseFloat(snapShot.Mid),
                        Last: parseFloat(snapShot.Last),
                        NetChangePct: parseFloat(snapShot.NetChangePct) * 100,
                        Delta: snapShot.Delta,
                        Theta: snapShot.Theta,
                        Gamma: snapShot.Gamma,
                        Rho: snapShot.Rho,
                        Vega: snapShot.Vega,
                        ImpliedVolatility: snapShot.ImpliedVolatility,
                        DailyOpenInterest: snapShot.DailyOpenInterest,
                        Volume: snapShot.Volume,
                        ExpirationDate: snapShot.Legs[0].Expiration,
                        AssetType: snapShot.Legs[0].AssetType,
                      },
                    ];
                  } else if (
                    newData.find((obj) => obj.Symbol == snapShot.Legs[0].Symbol)
                  ) {
                    if (snapShot.Ask) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Ask = parseFloat(snapShot.Ask);
                    }
                    if (snapShot.Bid) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Bid = parseFloat(snapShot.Bid);
                    }
                    if (snapShot.Mid) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Mid = parseFloat(snapShot.Mid);
                    }
                    if (snapShot.Last) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Last = parseFloat(snapShot.Last);
                    }
                    if (snapShot.NetChangePct) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).NetChangePct = parseFloat(snapShot.NetChangePct) * 100;
                    }
                    if (snapShot.Delta) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Delta = snapShot.Delta;
                    }
                    if (snapShot.Theta) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Theta = snapShot.Theta;
                    }
                    if (snapShot.Gamma) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Gamma = snapShot.Gamma;
                    }
                    if (snapShot.Rho) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Rho = snapShot.Rho;
                    }
                    if (snapShot.Vega) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Vega = snapShot.Vega;
                    }
                    if (snapShot.ImpliedVolatility) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).ImpliedVolatility = snapShot.ImpliedVolatility;
                    }
                    if (snapShot.DailyOpenInterest) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).DailyOpenInterest = snapShot.DailyOpenInterest;
                    }
                    if (snapShot.Volume) {
                      newData.find(
                        (obj) => obj.Symbol == snapShot.Legs[0].Symbol
                      ).Volume = snapShot.Volume;
                    }
                  } else {
                    newData.push({
                      Symbol: snapShot.Legs[0].Symbol,
                      StrikePrice: parseFloat(snapShot.Legs[0].StrikePrice),
                      Ask: parseFloat(snapShot.Ask),
                      Bid: parseFloat(snapShot.Bid),
                      Mid: parseFloat(snapShot.Mid),
                      Last: parseFloat(snapShot.Last),
                      NetChangePct: parseFloat(snapShot.NetChangePct) * 100,
                      Delta: snapShot.Delta,
                      Theta: snapShot.Theta,
                      Gamma: snapShot.Gamma,
                      Rho: snapShot.Rho,
                      Vega: snapShot.Vega,
                      ImpliedVolatility: snapShot.ImpliedVolatility,
                      DailyOpenInterest: snapShot.DailyOpenInterest,
                      Volume: snapShot.Volume,
                      ExpirationDate: snapShot.Legs[0].Expiration,
                      AssetType: snapShot.Legs[0].AssetType,
                    });
                  }
                } else {
                  _this.optionStream.leftOver = quotes[a];
                }
              }
              if (newData.length > 0) {
                _this.loadedOptionDate = getDate;
                newData.sort((a, b) =>
                  a.StrikePrice > b.StrikePrice
                    ? 1
                    : b.StrikePrice > a.StrikePrice
                      ? -1
                      : 0
                );
                _this.optionsData = newData;
                _this.optionsRes = true;
                _this.lastLoad = getLoad;
                if (_this.$route.path.indexOf("/options") != -1) {
                  _this.options = true;
                }
              }
              if (
                _this.optionsData.length > 20 &&
                _this.glanceRefresh == true
              ) {
                _this.glanceRefresh = false;
                _this.updateGlance(_this.optionsData);
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    setWatchlist(watch) {
      var _this = this;
      this.watched = watch;
      this.http
        .get(
          process.env.VUE_APP_SERVER +
          `/web/setwatchlist?_id=${localStorage.session}&token=${localStorage.sessionKey}&ticker=${this.ticker.Symbol.toUpperCase()}&add=${watch}`
        )
        .then(function () {
          _this.$gtag.event("watchlist_" + (watch ? "add" : "remove"), {
            ticker: _this.openSymbol,
          });
          _this.$emit('update-watchlist');
        })
        .catch(function (error) {
          console.log(error);
          _this.watched = !watch;
        });

    },
    updateGlance(options) {
      if (options.length < 20) {
        return;
      }
      var highestVolume = 0;
      var highestIndex = 0;
      var optionsGlance = [];
      var expDate = "";

      for (var c = 0; c < options.length; c++) {
        if (options[c].Volume > highestVolume && c > 5) {
          highestVolume = options[c].Volume;
          highestIndex = c;
        }
      }
      if (highestIndex % 2 == 1) {
        highestIndex = highestIndex - 1;
      }
      if (options[highestIndex].Symbol.split(" ")[1].indexOf("C") == -1) {
        highestIndex = highestIndex + 1;
      }
      if (highestIndex + 11 >= options.length) {
        if (highestIndex % 2 == 1) {
          if (options.length - (1 % 2) == 0) {
            highestIndex = options.length - 12;
          } else {
            highestIndex = options.length - 13;
          }
        } else {
          if (options.length - (1 % 2) == 1) {
            highestIndex = options.length - 12;
          } else {
            highestIndex = options.length - 13;
          }
        }
      } else if (highestIndex - 8 <= 0) {
        if (highestIndex % 2 == 1) {
          highestIndex = 9;
        } else {
          highestIndex = 8;
        }
      }
      var getDay = options[highestIndex].ExpirationDate.substring(8, 10);
      var getMonth =
        parseInt(options[highestIndex].ExpirationDate.substring(5, 7)) - 1;
      if (getDay.indexOf("0") == 0) {
        getDay = getDay.substring(1, 2);
      }
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      expDate = months[getMonth] + " " + getDay;
      optionsGlance = [
        ["Strike", "Calls", "Puts"],
        [
          options[highestIndex - 8].StrikePrice.toString(),
          options[highestIndex - 8].Volume,
          options[highestIndex - 7].Volume,
        ],
        [
          options[highestIndex - 6].StrikePrice.toString(),
          options[highestIndex - 6].Volume,
          options[highestIndex - 5].Volume,
        ],
        [
          options[highestIndex - 4].StrikePrice.toString(),
          options[highestIndex - 4].Volume,
          options[highestIndex - 3].Volume,
        ],
        [
          options[highestIndex - 2].StrikePrice.toString(),
          options[highestIndex - 2].Volume,
          options[highestIndex - 1].Volume,
        ],
        [
          options[highestIndex].StrikePrice.toString(),
          options[highestIndex].Volume,
          options[highestIndex + 1].Volume,
        ],
        [
          options[highestIndex + 2].StrikePrice.toString(),
          options[highestIndex + 2].Volume,
          options[highestIndex + 3].Volume,
        ],
        [
          options[highestIndex + 4].StrikePrice.toString(),
          options[highestIndex + 4].Volume,
          options[highestIndex + 5].Volume,
        ],
        [
          options[highestIndex + 6].StrikePrice.toString(),
          options[highestIndex + 6].Volume,
          options[highestIndex + 7].Volume,
        ],
        [
          options[highestIndex + 8].StrikePrice.toString(),
          options[highestIndex + 8].Volume,
          options[highestIndex + 9].Volume,
        ],
        [
          options[highestIndex + 10].StrikePrice.toString(),
          options[highestIndex + 10].Volume,
          options[highestIndex + 11].Volume,
        ],
      ];
      this.optionsGlance = optionsGlance;
      this.optionsExpiry = expDate;
    },
    setSubTab(tab, auto) {
      this.subTab = tab;
      if (auto == true) {
        return;
      }
      if (tab == "LEVEL_TWO") {
        this.$gtag.event("view_level_two", {
          ticker: this.openSymbol,
        });
      } else if (tab == "NEWS") {
        this.$gtag.event("view_news", {
          ticker: this.openSymbol,
        });
      }
    },
    updateLevelTwo(value) {
      this.showLevelTwo = value;
    },
    toggleCollapse() {
      this.showMore = !this.showMore;
      this.$gtag.event(`wiim_${this.showMore ? "show" : "hide"}`, {
        ticker: this.openSymbol,
      });
    },

    activeTraderTapBid(bid) {
      this.activeTraderIntent = "sell";
      this.activeTraderPrice = bid;
      this.activeTraderDirection = "open";

      var findTicker = this.activeTraderOption ? this.activeTraderOption.Symbol : this.ticker.Symbol;
      var havePosition = this.positions.find(elem => elem['Symbol'] == findTicker);

      if (havePosition != undefined && havePosition.LongShort == "Long") {
        this.activeTraderDirection = "close";
      }

      if (localStorage.activeBehaviour == "Send order on double tap") {
        this.executeActive();
      } else {
        this.activeTaps++;
        this.scrollToBottom();
      }
    },
    activeTraderTapAsk(ask) {
      this.activeTraderIntent = "buy";
      this.activeTraderPrice = ask;
      this.activeTraderDirection = "open";

      var findTicker = this.activeTraderOption ? this.activeTraderOption.Symbol : this.ticker.Symbol;
      var havePosition = this.positions.find(elem => elem['Symbol'] == findTicker);

      if (havePosition != undefined && havePosition.LongShort == "Short") {
        this.activeTraderDirection = "close";
      }

      if (localStorage.activeBehaviour == "Send order on double tap") {
        this.executeActive();
      } else {
        this.activeTaps++;
        this.scrollToBottom();
      }
    },
    activeTraderTapMarket(buyOrSell) {
      this.activeTraderIntent = buyOrSell;
      this.activeTraderPrice = "market";
      this.activeTraderDirection = "open";

      var findTicker = this.activeTraderOption ? this.activeTraderOption.Symbol : this.ticker.Symbol;
      var havePosition = this.positions.find(elem => elem['Symbol'] == findTicker);

      if (havePosition != undefined && ((havePosition.LongShort == "Short" && buyOrSell == "buy") || (havePosition.LongShort == "Long" && buyOrSell == "sell"))) {
        this.activeTraderDirection = "close";
      }

      if (localStorage.activeBehaviour == "Send order on double tap") {
        this.executeActive();
      } else {
        this.activeTaps++;
        this.scrollToBottom();
      }
    },
    executeActive() {
      var tradeAction = "BUY";
      if (this.activeTraderIntent == "sell") {
        if (this.activeTraderDirection == "open") {
          tradeAction = "SELLSHORT";
        } else {
          tradeAction = "SELL";
        }
      } else if (this.activeTraderDirection == "close") {
        tradeAction = "BUYTOCOVER";
      }
      var sendQuantity = "1";
      if (localStorage.activeQuantity == "Set Size") {
        var setSize = "1";
        if (localStorage.activeSize) {
          setSize = localStorage.activeSize;
        }
        sendQuantity = setSize;
      } else if (localStorage.activeQuantity == "Set Lot" && localStorage.activeLot) {
        var lotSize = 1;
        try {
          var parseTotal = parseFloat(localStorage.activeLot);
          var parsePrice = parseFloat(this.activeTraderPrice);
          if (this.activeTraderOption) {
            parsePrice = parsePrice * 100;
          }
          var quantityCalc = Math.floor(parseTotal / parsePrice);
          lotSize = quantityCalc;
          if (lotSize == 0) {
            lotSize = 1;
            Toast.error(`Lot size of $${parseTotal.toFixed(2)} is too small for price of $${parsePrice.toString()}`);
            return;
          }
        } catch (e) {
          console.log(e);
          Toast.error(`Could not send active trade order, please check settings and try again.`);
        }
        sendQuantity = String(lotSize);
      }
      var sendTicker = this.ticker.Symbol;
      if (this.activeTraderOption) {
        sendTicker = this.activeTraderOption.Symbol;
        tradeAction = this.activeTraderIntent.toUpperCase() + "TO" + this.activeTraderDirection.toUpperCase();
      }

      if (this.activeTraderDirection == "close") {
        var checkExisting = this.positions.find(elem => elem.Symbol == sendTicker);
        if (checkExisting != null) {
          if (parseFloat(sendQuantity) < parseFloat(checkExisting.Quantity) && this.activeTraderPrice != "market") {
            sendQuantity = checkExisting.Quantity;
          } else if (this.activeTraderPrice == "market" && parseFloat(sendQuantity) > parseFloat(checkExisting.Quantity.replaceAll("-", ""))) {
            sendQuantity = checkExisting.Quantity.replaceAll("-", "");
          }
        }
      }
      if (this.activeTraderOption && parseFloat(sendQuantity) > 50) {
        sendQuantity = "50";
      } else if (this.ticker.AssetType == "STOCK" && parseFloat(sendQuantity) > 10000) {
        sendQuantity = "10000";
      }
      if (this.activeTraderPrice == "market") {
        Toast.info(`Sending Market: ${this.activeTraderIntent} to ${this.activeTraderDirection} ${sendQuantity} ${sendTicker}`);
      } else {
        Toast.info(`Sending Limit: ${this.activeTraderIntent} to ${this.activeTraderDirection} ${sendQuantity} ${sendTicker} @ $${this.activeTraderPrice}`);
      }
      var _this = this;
      this.postOrder({
        final: true,
        symbol: sendTicker,
        quantity: sendQuantity,
        limitPrice: this.activeTraderPrice == "market" ? "" : this.activeTraderPrice,
        orderType: this.activeTraderPrice == "market" ? "Market" : "Limit",
        orderDuration: "Until Cancel",
        legs: null,
        close: this.activeTraderDirection == "close" ? true : false,
        tradeAction: tradeAction,
        assetType: this.activeTraderOption ? this.activeTraderOption.AssetType : this.ticker.AssetType,
        accountID: this.account.AccountID,
      })
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            Toast.error(res.data.Errors[0].Message);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: `active_order_${_this.activeTraderDirection}`,
              res_data: JSON.stringify(res.data),
            });
          } else {
            var submitted = res.data.Orders[0];
            res.data.Orders.forEach((order) => {
              if (order["Error"] == null) {
                _this.$gtag.event(`active_order_${_this.activeTraderDirection}`, { legs: _this.ticker });
              } else {
                _this.$gtag.event("web_error", {
                  action: `active_order_${_this.activeTraderDirection}`,
                  res_data: order["Error"],
                });
              }
            });
            if (submitted.Error) {
              Toast.error(submitted.Message);
              submitted = false;
              _this.playSound(
                "../../order-rejected-cancelled.mp3",
                _this.sound
              );
            } else {
              Toast.success(submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.activeTraderDirection == "close") {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: _this.averageCost,
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }

            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: _this.isClosing ? "order_closing" : "order_new",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: _this.isClosing ? "order_closing" : "order_new",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
  },
};
</script>

<style scoped>
  *{
  box-sizing: border-box;
}
.ticker-details {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 55px;
  max-width: 1440px;
  margin: auto;
}

p {
  font-family: "Roboto";
}

.error {
  margin-top: -10px;
}

.traderSettingsIcon {
  height: 18px;
  width: 18px;
  margin-left: -18px;
  cursor: pointer;
  float: right;
}

.error.large {
  font-family: "Roboto";
  font-size: 21px;
  color: #ce0606;
  margin-top: 20px;
  font-weight: 300;
}

.top-row {
  display: flex;
  flex-direction: row;
  border: 1px solid #F0F3F5;
  box-shadow: 0px 2px 4px 0px #00000014;
  padding: 25px;
  border-radius: 12px;
  height: 100%;
  gap: 24px;
  padding-bottom: 0;
  background: #ffffff;
}

.dark .top-row {
  border: 1px solid #FFFFFF0D;
  background: #1F2324;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.top-row .controls {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eef0f3;
}

.dark .top-row .controls {
  border-bottom: 1px solid #FFFFFF0D;
}


.controls-links {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  text-align: left;
}

.controls-links .home-link {
  margin-right: 15px;
  padding-bottom: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link {
  margin-right: 24px;
}

.controls-links .home-link:hover {
  color: #10bc74;
}

.controls>div {
  margin-bottom: 20px;
}

.top-row .graph-window {
  /* flex-grow: 1;
  margin-top: -30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-bottom: -30px; */
  overflow: hidden;
  width: 100%;
  /* height: 550px; */
  top: 176px;
  left: 102px;
  /* gap: 24px; */
  /* opacity: 0; */
}

.top-row .graph-window .basic-house {
  margin-top: 0;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-row .graph-window .basic-house p {
  font-family: "Roboto";
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.dark .top-row .graph-window .basic-house p {
  color: #ffffff;
}

.top-row .graph-window .basic-house .ticker-watch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-row .graph-window .basic-house .ticker-watch img {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;
}

.top-row .graph-window .basic-house .ticker {
  font-size: 21px;
}

.top-row .graph-window .basic-house .price {
  font-size: 35px;
}

.top-row .graph-window .basic-house .change span {
  font-weight: normal;
  color: #000000;
}

.top-row .graph-window .basic-house .button-house button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #000000;
  background: #f5f7fa;
  cursor: pointer;
}

.dark .top-row .graph-window .basic-house .button-house button {
  background: #000000;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

.top-row .graph-window .basic-house .button-house button.trade {
  margin-left: 16px;
  background: #10bc74;
  color: #ffffff;
}

.dark .top-row .graph-window .basic-house .button-house button.trade {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

.top-row .graph-window .basic-house .button-house button.options-entitlement {
  font-size: 14px;
  margin: 0;
  width: 220px;
  height: 43px;
}

.basic-house .price-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.basic-house .price-info .bid-ask {
  margin-left: 10px;
}

.basic-house .price-info .bid-ask p {
  margin: 0;
  font-size: 14px;
  color: #616164;
}

.chart-house,
.open-house {

  border: 1px solid #eef0f3;
  /* box-shadow: 15px 15px 30px RGBA(0, 0, 0, 0.08); */
  border-radius: 12px;
  overflow: hidden;
  margin-top: 24px;
  position: relative;
}

.open-house {
  padding: 12px;
  box-sizing: border-box;
}

.open-house.chain {
  padding: 0;
}

.chart-house {
  display: none;
}

.dark .chart-house,
.dark .open-house {
  border: 1px solid #333333;
  /* box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2); */
}

.chart-house.visible {
  display: block;
  height: 510px;
  margin-bottom: 24px;
}

.chart-house iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.open-house {
  overflow-y: scroll;
}

.search-house {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.top-row .news-window {
  width: 370px;
  min-width: 370px;
  margin-left: 48px;
  text-align: left;
}

.wiim {
  padding-bottom: 24px;
  border-bottom: 1px solid #eef0f3;
}

.search-box {
  margin-right: 5px;
}
.search-box input {
  max-width: 5rem;
}

.dark .wiim {
  border-bottom: 1px solid #333333;
}

.wiim .wiim-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-family: "Roboto";
  font-size: 21px;
  font-weight: 500;
}

.wiim .wiim-title p {
  margin: 0;
  margin-left: 5px;
}

.wiim .wiim-info {
  font-family: "Roboto";
  margin: 0;
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
}

.wiim .wiim-info a,
.btn-collapse {
  color: #10bc74;
  cursor: pointer;
}

.wiim .wiim-date {
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
  color: #909194;
}

.news-house {
  width: 100%;
  /* height: 576px; */
  top: 176px;
  left: 982px;
  gap: 0px;
  /* overflow-y: scroll; */
  text-align: left;
  /* border-radius: 12px 0px 0px 0px; */
  /* opacity: 0px; */
  /* border-left: 2px solid #eef0f3; */

}

.news-house .news-list {
  margin-top: 26px;
  overflow-y: scroll;
  max-height: 580px;
}

.news-list>.ps {
  height: 500px;
}
.news-article:last-child{
border-bottom: none !important;
}
.news-house .news-list .news-article {
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #F0F3F5;
  column-gap: 20px;
  justify-content: space-between;
}

.news-house .watchlist {
  margin-top: 16px;
  overflow-y: scroll;
  max-height: 590px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.news-house .watchlist-item-container {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 12px;
  cursor: pointer;
}

.news-house .watchlist-item-container:nth-child(odd) {
  padding-right: 6px;
}

.news-house .watchlist-item-container:nth-child(even) {
  padding-left: 6px;
}

.news-house .watchlist-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #eef0f3;
  padding: 8px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 6px RGBA(0, 0, 0, 0.08); */
  background: rgba(255, 255, 255, 0);
}

.news-house .watchlist-item p {
  margin: 0;
}

.news-house .price-column p {
  text-align: right;
}

.news-house .watchlist-symbol {
  font-size: 21px;
  font-weight: 500;
}

.news-house .watchlist-price {
  font-size: 14px;
  font-weight: 500;
}

.news-house .watchlist-change {
  font-size: 12px;
  font-weight: 300;
}

.news-house .fade {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 1));
}

.dark .news-house .fade {
  background: linear-gradient(rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 1));
}

.bottom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 53px;
  margin-bottom: 60px;
}


.bottom-row .ticker-info {
  flex-grow: 1;
  margin-right: 63px;
  text-align: left;
}

.bottom-row .ticker-info .detail-split {
  display: flex;
  flex-direction: row;
}

.bottom-row .ticker-info .detail-split>div {
  flex-grow: 1;
}

.bottom-row .ticker-info .detail-split>div:first-of-type {
  margin-right: 16px;
}

.bottom-row .ticker-info .detail-split>div:last-of-type {
  margin-left: 16px;
}

.bottom-row .analyst-ratings {
  text-align: left;
}

.analyst-ratings p {
  margin: 0;
  font-family: "Roboto";
}

.analyst-ratings .rating-subhead {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #616164;
  font-size: 16px;
  font-weight: 300;
}

.dark .analyst-ratings .rating-subhead {
  color: rgb(150, 150, 150);
}

.options-info {
  max-width: 300px;
}

.options-info p:first-of-type {
  margin-top: 0;
  font-size: 21px;
  font-weight: 500;
}

.options-info a {
  display: block;
  text-decoration: none;
}

.options-info a:last-of-type {
  margin-bottom: 10px;
}


.show-L2 {
  display: flex;
}

.hide-L2,
.hide-depth {
  display: none;
}

@media only screen and (min-width: 1240px) {
  .news-house {
    width: 30%;
  }

  .top-row .graph-window {
    width: 65%;
  }
}

@media only screen and (max-width: 1240px) {

  .bottom-row,
  .top-row {
    flex-direction: column !important;
  }

  .bottom-row .ticker-info {
    margin-bottom: 50px;
    margin-right: 0;
  }

  .top-row .news-window {
    width: auto;
    margin-left: 0;
    margin-top: 25px;
  }

  .top-row .graph-window {
    /* padding-bottom: 30px; */
    overflow: visible;
  }

  .options-chain {
    max-height: 600px;
    max-height: 80vh;
  }

  .news-house .news-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .news-house .news-list .news-article {
    width: 100%;
  }

  .open-house {
    height: auto;
  }
}

.mobile {
  display: none !important;
}
.pop-tab {
  top: 7px;
  left: 10px;
  background: #0BAA5E1A;
  color: #0BAA5E;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  z-index: 32;
  margin-right:1.5rem;
  width: 55%;
}
.popTabRed {
  background: #D20B3B1A;
  color: #D20B3B;
}

@media only screen and (max-width: 768px) {

  .top-row .graph-window {
    margin-top: 0;
  }

  .controls-links {
    bottom: 0;
  }

  .news-house .news-list .news-article {
    width: 100%;
  }

  .bottom-row .ticker-info .detail-split {
    flex-direction: column;
  }

  .bottom-row .ticker-info .detail-split>div {
    margin: 0 !important;
  }

  .ticker-details {
    padding: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .news-house .news-list {
    height: 530px;
  }
  .news-house .watchlist {
    height: 530px;
    display:block
  }
}

@media only screen and (max-width: 825px) {

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }

  .controls .head-link {
    font-size: 16px;
    margin-right:12px;
  }

  .top-row .graph-window .basic-house {
    flex-direction: column;
    text-align: center;
  }

  .top-row .graph-window .basic-house .button-house {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .open-house {
    overflow-x: scroll;
  }
}
@media only screen and (max-width:1345px) and (max-width:1240px) {
  .home-link > svg {
    width: 20px;
    height: 20px;
    margin-right: .5rem;
  }
  .head-link {
    font-size: 20px;
    margin-right: .5rem;
  }
}
@media only screen and (max-width:934px) {

  .head-link {
    font-size: 20px;
    margin-right: .5rem;
  }
}
@media only screen and (max-width: 868px) {
  .head-link {
    font-size: 18px;
    margin-right: .5rem;
  }
}
@media only screen and (max-width: 660px) {
  .controls {
    text-align: start;

    /* justify-content: center !important; */
  }

  .controls-links {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 489px) {
  .controls {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
@media only screen and (max-width: 568px) { 
  .controls {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 425px) {

  .top-row .controls .controls-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: center;
     */
    text-align: start;
  }
  .top-row .controls .controls-links .head-link {
    margin-bottom: 15px;
    text-align: start;
  }
}

.custom-hr {
  border: 1px solid #eef0f3;
}

.dark .custom-hr {
  border: 1px solid #FFFFFF0D
}

.Trading-fullscreen {
  height: 100vh !important;
}

.fullscreen-button {
  position: absolute;
  background: white;
  border: 0;
  border-radius: 5px;
  padding: 3px;
  right: 39px;
  top: 2px;
  line-height: 0;
  cursor: pointer;
}

.fullscreen-button:hover {
  background-color: #f0f3fa;
  transition: 0.2s;
}

.dark-button {
  background-color: #131722;
  color: white;
}

.dark-button:hover {
  background-color: #2a2e39;
}
</style>
