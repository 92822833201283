<template>
    <div>
        <div style="overflow-x: auto; max-width: 900px; max-height: 590px">
            <table style="position: relative">
                <!-- Table headers -->
                <thead
                    style=" position: sticky;top: 0; z-index: 40; background-color: white;height: 45px;transform: translateY(-2px); ">
                    <tr class="clickable positions-header"
                        style="background: white;color: #6a6c6c;border-bottom: 1px solid #f0f3f5;margin-bottom: 10px;padding-bottom: 10px;text-align: left;position: sticky;margin-top: 10px;z-index: 100;">
                        <th v-for="(header, index) in headers" :key="index" v-show="header.isShow"
                            @click="toggleSort(header.text)">
                            {{ header.text }}
                            <!-- Sorting icons -->
                            <span v-if="currentSortColumn === header.text &&
                            currentSortColumn !== 'Spark Chart'
                            ">
                                <svg v-if="currentSortAscending" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M7.646 4.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1-.708.708L8 5.707 4.354 9.354a.5.5 0 1 1-.708-.708l4-4z" />
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                    fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                            <!-- Settings icon -->
                            <span v-if="header.showSettings">
                                <img style="margin-right: 12px" class="traderSettingsIcon" src="../assets/settings.png"
                                    @click="handleSettings" />
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <template v-for="(watchList, symbol, index) in watchList">
                        <!-- Table rows -->
                        <tr class="main-symbol clickable" v-if="true" :key="index" @click="chart(watchList.symbol)">
                            <td v-for="(header, headerIndex) in headers" :key="'header-' + headerIndex"
                                v-show="header.isShow">
                                <div v-if="header.text === 'Symbol'" class="child-description">
                                    <span class="main-ticker-name">{{ watchList.symbol }} </span>
                                    <span class="symbol-description" v-for="descriptionObject in descriptionData"
                                        :key="descriptionObject">
                                        {{ descriptionObject[watchList.symbol] }}
                                        <span v-if="descriptionObject[watchList.symbol] === watchList.symbol">
                                            {{ descriptionObject[watchList.symbol] }}
                                        </span>
                                    </span>
                                </div>
                                <div v-else-if="header.text === 'Spark Chart'">
                                    <SparkChart :symbolName="watchList.symbol" :dark="dark" />
                                </div>
                                <div v-else-if="header.text === 'Last'">
                                    {{ parseFloat(watchList.latestPrice).toFixed(2) }}
                                    <br />
                                    <span :class="{
                            red: parseFloat(watchList.netChange).toFixed(2) < 0,
                            green: parseFloat(watchList.netChange).toFixed(2) >= 0,
                        }">
                                        {{ isNaN(parseFloat(watchList.netChange).toFixed(2)) ? '-' :
                        parseFloat(watchList.netChange).toFixed(2) }}
                                    </span>
                                </div>
                                <div v-else-if="header.text === '%Change'" :class="{
                            red:
                                parseFloat(parseFloat(watchList.changePercent) * 100).toFixed(2) < 0,
                            green:
                                parseFloat(parseFloat(watchList.changePercent) * 100).toFixed(2) >= 0,
                        }">
                                    {{ parseFloat(parseFloat(watchList.changePercent) * 100).toFixed(2) }}%
                                </div>
                                <div v-else-if="header.text === 'Change'" :class="{
                            red: parseFloat(watchList.netChange).toFixed(2) < 0,
                            green: parseFloat(watchList.netChange).toFixed(2) >= 0,
                        }">
                                    {{
                            isNaN(parseFloat(watchList.netChange).toFixed(2)) ? '-' :
                            parseFloat(watchList.netChange).toFixed(2)
                        }}
                                </div>
                                <div v-else-if="header.text === 'High'">
                                    {{ isNaN(parseFloat(watchList.high).toFixed(2)) ? '-' :
                        parseFloat(watchList.high).toFixed(2) }}
                                </div>
                                <div v-else-if="header.text === 'Low'">
                                    {{ isNaN(parseFloat(watchList.low).toFixed(2)) ? '-' :
                        parseFloat(watchList.low).toFixed(2) }}
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <Modal style="z-index: 1000" v-if="titleData" @close="readExpiryWarn">
                <SettingsPopup @close="readExpiryWarn" :headerKey="key" :headerTitle="headers"
                    @update-header="updateHeaders" :dark="dark" />
            </Modal>
        </div>
    </div>
</template>

<script>
import SettingsPopup from "./SettingsPopup.vue";
import Modal from "./Modal.vue";
import SparkChart from "./SparkChart.vue"

export default {
    name: "Watchlist",
    inject: {
        http: { from: "http" },
        stream: { from: "stream" },
    },
    components: {
        SettingsPopup,
        Modal,
        SparkChart,
    },

    props: {
        watchListData: Array,
        dark: Boolean,
        ticker: Object,
        chartData: Object,
    },

    data() {
        return {
            headers: [
                { text: "Symbol", showSettings: true, isShow: true },
                { text: "Spark Chart", showSettings: false, isShow: true },
                { text: "Change", showSettings: false, isShow: true },
                { text: "%Change", showSettings: false, isShow: true },
                { text: "Last", showSettings: false, isShow: true },
                { text: "High", showSettings: false, isShow: true },
                { text: "Low", showSettings: false, isShow: true },
            ],
            currentSortColumn: localStorage.getItem('currentSortColumn') || "Symbol",
            currentSortAscending: localStorage.getItem('currentSortAscending') === 'true',
            titleData: false,
            key: "watchlistHeader",
            descriptionData: [],
            tickers: [],
            updatedTicker: { Symbol: this.ticker },
            priceIncrement: {
                style: null,
                increment: null,
                schedule: [],
            },
            quoteStream: {
                connection: null,
                leftOver: null,
                data: null,
            },
            watchList: this.watchListData,
        };
    },

    async mounted() {
        const storedHeaders = localStorage.getItem("watchlistHeader");
        if (storedHeaders) {
            this.headers = JSON.parse(storedHeaders);
        }
        if (this.currentSortColumn !== null) {
            this.sortPositions(this.currentSortColumn, this.lastSortAsc);
        }

        let isBool = false;
        for (let key of this.watchListData) {
            if (this.tickers.indexOf(key.symbol) === -1) {
                this.tickers.push(key.symbol);
                isBool = true;
            } else {
                isBool = false;
            }
        }
        if (isBool) {
            await this.fetchSymbolDescription();
        }
    },
    watch: {
        watchListData(newVal) {
            if (newVal) {
                this.watchList = newVal;
                this.sortPositions(this.currentSortColumn, this.currentSortAscending);
            }
        },
    },
    methods: {
        chart(symbol) {
            this.loading = true;
            this.$emit("chart", symbol);
        },
        async fetchSymbolDescription() {
            if (!this.watchList.length) {
                return;
            }
            let descriptionList = [];
            try {
                const response = await this.http.get(
                    (localStorage.sim == "true"
                        ? process.env.VUE_APP_TS_SIM
                        : process.env.VUE_APP_TS) + `marketdata/symbols/${this.tickers.join(",")}`,
                    { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
                );

                descriptionList = response.data.Symbols.map((symbolInfo) => ({
                    [symbolInfo.Symbol]: symbolInfo.Description,
                }));
                this.descriptionData = descriptionList;

                if (response.data.Symbols.length > 0) {
                    this.updatedTicker.AssetType = response.data.Symbols[0].AssetType;
                    if (
                        response.data.Symbols[0].PriceFormat &&
                        response.data.Symbols[0].PriceFormat.IncrementStyle
                    ) {
                        this.priceIncrement.style =
                            response.data.Symbols[0].PriceFormat.IncrementStyle;
                        if (this.priceIncrement.style == "Simple") {
                            this.priceIncrement.increment =
                                response.data.Symbols[0].PriceFormat.Increment;
                        } else if (this.priceIncrement.style == "Schedule") {
                            this.priceIncrement.schedule =
                                response.data.Symbols[0].PriceFormat.IncrementSchedule;
                        }
                    }
                }
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        toggleSort(header) {
            if (this.currentSortColumn === header) {
                this.currentSortAscending = !this.currentSortAscending;
            } else {
                this.currentSortColumn = header;
                this.currentSortAscending = true;
            }
            this.sortPositions(header, this.currentSortAscending);
            localStorage.setItem('currentSortColumn', this.currentSortColumn);
            localStorage.setItem('currentSortAscending', this.currentSortAscending);
        },

        handleSettings() {
            this.titleData = true;
        },

        readExpiryWarn() {
            this.titleData = false;
        },

        updateHeaders(updatedHeaders) {
            this.headers = updatedHeaders;
        },

        sortBySymbol(list, sort) {
            list.sort((a, b) => {
                return sort ? a.symbol.localeCompare(b.symbol) : b.symbol.localeCompare(a.symbol);
            })

            this.watchList = list;
        },
        sortByLast(list, sort) {
            list.sort((a, b) => {
                return sort ? a.latestPrice - b.latestPrice : b.latestPrice - a.latestPrice;
            })

            this.watchList = list;
        },
        sortByChange(list, sort) {
            list.sort((a, b) => {
                return sort ? a.netChange - b.netChange : b.netChange - a.netChange;
            })

            this.watchList = list;
        },
        sortByChangePct(list, sort) {
            list.sort((a, b) => {
                return sort ? a.changePercent - b.changePercent : b.changePercent - a.changePercent;
            })

            this.watchList = list;
        },
        sortByHigh(list, sort) {
            list.sort((a, b) => {
                return sort ? a.high - b.high : b.high - a.high;
            })

            this.watchList = list;
        },
        sortByLow(list, sort) {
            list.sort((a, b) => {
                return sort ? a.low - b.low : b.low - a.low;
            })

            this.watchList = list;
        },
        sortPositions(header, sortAscending) {
            let sortedEntries = this.watchList.slice();

            switch (header) {
                case "Symbol":
                    this.sortBySymbol(sortedEntries, sortAscending);
                    break;
                case "Change":
                    this.sortByChange(sortedEntries, sortAscending);
                    break;
                case "%Change":
                    this.sortByChangePct(sortedEntries, sortAscending);
                    break;
                case "Last":
                    this.sortByLast(sortedEntries, sortAscending);
                    break;
                case "High":
                    this.sortByHigh(sortedEntries, sortAscending);
                    break;
                case "Low":
                    this.sortByLow(sortedEntries, sortAscending);
                    break;

                default:
                    console.log("Header not recognized for sorting.");
                    return;
            }
            this.watchList = sortedEntries;
        },
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
    width: 100%;
}

tbody tr {
    cursor: pointer;
}

thead th {
    font-weight: 400;
    background: white;
    color: #6a6c6c;
    border-bottom: 1px solid #f0f3f5;
    border-top: 1px solid #f0f3f5;
    margin: 10px 0px 10px;
    padding: 10px 0px 10px;
    text-align: left;
    min-width: 106px;
}

tbody tr td {
    min-width: 80px;
    font-size: 15px;
    /* padding-top: 5px; */
    /* padding-bottom: 0px; */
    white-space: break-spaces;
    vertical-align: middle;
    color: #151c26;
}

thead th.spark-chart-header {
    min-width: 100px;
}

.traderSettingsIcon {
    display: flex;
    height: 18px;
    width: 18px;
    cursor: pointer;
    float: right;
}

.table-body {
    overflow-y: scroll;
}

tr.group {
    font-weight: bold;
}

tr.group td {
    padding-bottom: 5px;
}

.expand-arrow svg {
    border: 1px solid #cccccc;
    border-radius: 50%;
    cursor: pointer;
}

.expand-arrow.expanded svg {
    transform: rotate(90deg);
}

.expand-arrow.expanded+.position-row {
    border-left: 1px solid #d8dbde;
}

.symbol-data {
    display: flex;
    align-items: center;
    gap: 14px;
}

.symbol-cell {
    display: flex;
    align-items: center;
}

.symbol-content {
    display: flex;
    align-items: flex-start;
    transform: translateX(12px);
}

.checkbox {
    display: flex;
    justify-content: space-evenly;
    margin-right: 8px;
}

table-body td {
    min-width: 80px;
}

.main-symbol {
    text-align: left;
}

tr.child {
    opacity: 0.8;
    text-align: left;
}

.button-position {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #f0f3f5;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    background: #f0f3f5;
}

.dark .button-position {
    border-top: 1px solid #333333;
    background: #ffffff0d;
}

.dark .text-position {
    color: #ffffff;
}

button {
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;
    width: 130px;
    padding: 5px 0;
    border-radius: 100px;
    border: none;
    color: #ffffff;
    background: #0baa5e;
    cursor: pointer;
    height: 40px;
}

.button-position button {
    margin: 0 10px;
    margin-top: 2px;
}

.close-selected {
    background-color: transparent;
    border: #0baa5e solid 2px;
    color: #0baa5e;
}

.text-position {
    color: #616164;
    text-align: left;
    font-family: "Roboto";
    font-size: 18px;
    margin-top: 8px;
    margin-left: 10px;
}

.option-div {
    display: flex;
    background: rgb(238, 236, 236);
    align-content: center;
    padding: 2px;
}

.close-icon svg {
    border: 1px solid #cccccc;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px;
    margin-top: 0.5px;
}

.long {
    font-weight: normal;
    color: #10bc74;
    font-size: 12px;
    text-align: left;
}

.short {
    color: #d20b3b;
    font-size: 12px;
    text-align: left;
}

.symbol-description-main {
    display: flex;
    flex-direction: column;
    margin-right: -66px;
    text-align: left;
}

.symbol-description {
    font-weight: normal;
    color: #0baa5e;
    font-size: 12px;
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.child-description {
    display: flex;
    flex-direction: column;
}

.red {
    color: #d20b3b;
}

.green {
    color: #0baa5e;
}

.dark .red {
    color: #d20b3b;
}

.dark .green {
    color: #0baa5e;
}

.bi-chevron-up {
    transform: translateY(-2px);
}

.bi-chevron-downP {
    transform: translateY(5px);
}

input[type="checkbox"]:checked {
    background-color: #0baa5e;
}

.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox {
    border-color: #cccccc;
}

.custom-checkbox:checked::before {
    content: "\2713";
    font-size: 10px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-main {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.dark .loading-main {
    color: #ffffff;
}

.footer-div {
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 2;
    /* Higher z-index to keep it on top */
}

.dark .footer-div {
    background-color: #1f2324;
}

.dark thead th {
    background-color: #1f2324;
    color: #ffffffcc;
    border-bottom: 1px solid #333333;
    border-top: 1px solid #333333;
}

.dark tbody tr td {
    color: #ffffff;
}
.main-ticker-name{
    white-space: nowrap;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
