import { logMessage } from "./helpers";

export class Requester {
  constructor(headers) {
    if (headers) {
      this._headers = headers;
    }
  }

  sendRequest(datafeedUrl, urlPath, params) {
    let baseUrl = `${datafeedUrl}?access=${encodeURIComponent(this._headers.accessToken)}&_id=${encodeURIComponent(this._headers._id)}&token=${encodeURIComponent(this._headers.token)}`;

    // Ensure that the actionParam includes a "/" before the urlPath
    let actionParam = `action=/${encodeURIComponent(urlPath)}`;

    if (params !== undefined) {
      // Extract the ticker part if the symbol is in "EXCHANGE:TICKER" format
      if (params.symbol && params.symbol.includes(":")) {
        params.symbol = params.symbol.split(":")[1];
      }

      const paramKeys = Object.keys(params);
      if (paramKeys.length !== 0) {
        actionParam += "?";
      }
      actionParam += paramKeys.map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key].toString())}`;
      }).join("&");
    }

    // Append the action parameter to the base URL
    baseUrl += `&${actionParam}`;

    logMessage("New request: " + baseUrl);

    const options = { credentials: "same-origin" };
    if (this._headers !== undefined) {
      options.headers = this._headers;
    }

    return fetch(baseUrl)
      .then((response) => response.text())
      .then((responseText) => JSON.parse(responseText));
  }
}
