<template>
  <div class="price-cube" @click="emitClick">
    <div v-if="!error">
      <p class="symbol">{{ symbol }}</p>
      <p class="name">{{ name }}</p>
    </div>
    <div v-if="!error">
      <p class="price">{{ dollarWithCommas(parseFloat(price)) }}</p>
      <p class="change" :class="{
        red: change.indexOf('-') != -1,
        green: change.indexOf('-') == -1,
      }">
        {{ (change.indexOf("-") == -1 ? "+" : "") + change }}%
      </p>
    </div>
    <div class="loading" v-if="loading">
      <img src="../assets/loading.gif" />
    </div>
    <p class="error red" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import Mixins from "../Mixins";

export default {
  name: "PriceCube",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  props: {
    symbol: String,
    name: String,
    price: String,
    change: String,
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  methods: {
    emitClick: function () {
      this.$emit("openTicker", this.symbol);
    },
  },
};
</script>

<style scoped>
.price-cube {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 190px;
  height: 190px;
  border: 1px solid #eceff2;
  border-radius: 8px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000014;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
  overflow: hidden;
}

.dark .price-cube {
  border: 1px solid #FFFFFF0D;
  background: #1F2324;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.price-cube div {
  text-align: left;
}

p {
  margin: 0;
  font-family: "Roboto";
}

p.symbol {
  font-size: 18px;
  font-weight: 700;
}

p.name {
  font-size: 16px;
  font-weight: 400;
  color: #909194;
  white-space: normal;
}

p.price {
  font-size: 27px;
  font-weight: 500;
}

p.change {
  font-size: 20px;
  font-weight: 500;
}

p.change.red {
  color: #ce0606;
}

p.change.green {
  color: #10bc74;
}

.error {
  white-space: normal;
  margin-top: 20px;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
}

.loading img {
  width: 40px;
  height: 40px;
}
</style>
