<template>
  <div>
    <PageCounter
      v-if="tab == 'HISTORIC_FLOW' && showPages"
      class="pagination top"
      :currentPage="currentPage"
      :nextPage="nextPage"
      @loadPage="loadPage"
    ></PageCounter>
    <slot></slot>
    <PageCounter
      v-if="tab == 'HISTORIC_FLOW' && showPages"
      class="pagination bottom"
      :currentPage="currentPage"
      :nextPage="nextPage"
      @loadPage="loadPage"
    ></PageCounter>
  </div>
</template>

<script>
import PageCounter from "./PageCounter.vue";
export default {
  name: "PageCounterWrapper",
  props: {
    nextPage: String,
    tab: String,
    reset: Number,
    showPages: Boolean,
  },
  components: {
    PageCounter,
  },
  data: function () {
    return {
      currentPage: 0,
      pages: [],
      pageUpdated: true,
      updatedPageNumber: 0,
    };
  },
  watch: {
    nextPage() {
      // to avoid page mismatch from continues click
      this.pageUpdated = true;
      this.currentPage = this.updatedPageNumber;
    },
    reset() {
      this.currentPage = 0;
      this.updatedPageNumber = 0;
      this.pages = [];
    },
  },
  computed: {},
  methods: {
    loadPage(direction) {
      if (this.pageUpdated) {
        const sanitizedPageID = this.nextPage.split("$latest")[0];
        if (direction == "next") {
          const pageFound = this.pages.find(
            (pageId) => pageId == sanitizedPageID
          );
          if (!pageFound) {
            this.pages.push(sanitizedPageID);
          }
          this.$emit("loadPage", sanitizedPageID);
          this.updatedPageNumber = this.currentPage + 1;
        } else if (direction == "previous") {
          this.$emit("loadPage", this.pages[this.currentPage - 1]);
          this.updatedPageNumber = this.currentPage - 1;
        }
        this.pageUpdated = false;
      }
    },
  },
};
</script>

<style scoped></style>
