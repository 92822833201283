<template>
  <div class="icon">
    <mdicon name="arrowUpCircle" size="64" />
    <mdicon name="arrowDownCircle" size="64" />
  </div>
</template>

<script>
export default {
  props: {},

  components: {},
  data: function () {
    return {
      position: document.body.scrollTop,
    };
  },
  methods: {},
  updated() {},
  mounted() {},
};
</script>

<style scoped>
.icon {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
