// const socketURL = "wss://chart.tradearies.com/ws";
const socketURL = process.env.VUE_APP_STREAMING_URL;
let socket;
let reconnectTimer = null;
let subscribedData = [];
let currentUniqueID = "";
let watchListCallback;

const RECONNECT_INTERVAL = 5000;

function pickId(listenerGuid) {
  let str;
  if (listenerGuid.includes("regular")) {
    str = listenerGuid.split("_#_regular_#_");
  } else if (listenerGuid.includes("extended")) {
    str = listenerGuid.split("_#_extended_#_");
  } else {
    str = listenerGuid.split("_#_");
  }
  return {
    symbol: str[0],
    resolution: str[1],
    uniqueId: str[0] + "_#_" + str[1],
  };
}

function connectWebSocket() {
  socket = new WebSocket(socketURL);
  socket.onopen = () => {
    console.log("[socket] Connected");
  };

  socket.onclose = () => {
    console.log("[socket] Disconnected:");
    reconnect();
  };

  socket.onerror = (error) => {
    console.log("[socket] Error:", error);
  };

  socket.onmessage = (event) => {
    let data = JSON.parse(event.data);
    if (data.error !== "") {
      reSubscribeOnStream();
      return;
    }
    if (data.type == "stream-bar") {
      if (data.bars.s == "ok") {
        const uniqueId = `${data.symbol}_#_${data.resolution}`;
        const index = findSubscribeIndex(uniqueId);
        if (index < 0) return;
        let bars = {
          close: data.bars.c[0],
          high: data.bars.h[0],
          low: data.bars.l[0],
          open: data.bars.o[0],
          time: data.bars.t[0] * 1000,
          volume: data.bars.v[0],
        };
        subscribedData[index].callback(bars);
        if (currentUniqueID == uniqueId) {
          var iframe = document.querySelector("iframe");
          var iframeDocument =
            iframe?.contentDocument || iframe.contentWindow.document;
          var lastPriceElement =
            iframeDocument.querySelector(".price-qWcO4bp9");
          if (lastPriceElement)
            lastPriceElement.innerHTML = parseFloat(bars.close).toFixed(2);
        }
      }
    } else if (data.type == "stream-quotes") {
      // const index = findSubscribeIndex(data.listenerGuid);
      let sendData = data.quotes.d[0].v;
      console.log(sendData);
      // if (sendData.ask && sendData.bid && sendData.lp) {
      // const postdata = {
      //   ask: sendData.ask,
      //   bid: sendData.bid,
      //   sendData:
      // };
      // }
      // subscribedData[index].callback(subscribedData[index].symbols, sendData);
    }
  };
}

function reconnect() {
  if (reconnectTimer) {
    return; // Reconnection already scheduled
  }
  reconnectTimer = setTimeout(() => {
    console.log("[socket] Attempting to reconnect...");
    connectWebSocket(); // Attempt to reconnect
    reSubscribeOnStream();
    reconnectTimer = null;
  }, RECONNECT_INTERVAL);
}

function reSubscribeOnStream() {
  subscribedData.forEach((item) => {
    if (item.type == "stream-bar")
      sendSocketData("stream-bar", item.symbol, item.resolution);
    else if (item.type == "stream-quotes")
      sendSocketDataForQuotes("stream-quotes", item.symbols, item.uniqueId);
  });
}

function sendSocketData(type, symbol, resolution) {
  if (socket.readyState === WebSocket.OPEN) {
    let data = {
      type,
      access: localStorage.getItem("accessToken"),
      symbol,
      resolution,
      listenerGuid: "",
    };
    socket.send(JSON.stringify(data));
  } else {
    console.error("WebSocket is not connected.");
  }
}

function sendSocketDataForQuotes(type, symbols, listenerGuid) {
  if (socket.readyState === WebSocket.OPEN) {
    const data = {
      type,
      symbol: symbols,
      resolution: "",
      listenerGuid,
      access: localStorage.getItem("accessToken"),
    };
    // currentQuoteData = { uniqueId: listenerGuid, symbols };
    console.log("[Sent]", data);
    socket.send(JSON.stringify(data));
  } else {
    console.error("WebSocket is not connected.");
  }
}

function findSubscribeIndex(uniequeId) {
  return subscribedData.findIndex((item) => item.uniqueId === uniequeId);
}

// function processSymbols(symbolsArray) {
//   return symbolsArray.map((symbol) => {
//     if (symbol.includes(":")) {
//       return symbol.split(":")[1];
//     }
//     return symbol;
//   });
// }

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onTick,
  listenerGuid
) {
  const data = pickId(listenerGuid);
  currentUniqueID = data.uniqueId;
  let index = findSubscribeIndex(data.uniqueId);
  if (index > -1) {
    subscribedData[index] = {
      type: "stream-bar",
      uniqueId: data.uniqueId,
      callback: onTick,
      symbol: symbolInfo.ticker,
      resolution: resolution,
    };
  } else {
    subscribedData = [
      ...subscribedData,
      {
        type: "stream-bar",
        uniqueId: data.uniqueId,
        callback: onTick,
        symbol: symbolInfo.ticker,
        resolution: resolution,
      },
    ];
  }
  sendSocketData("stream-bar", symbolInfo.ticker, resolution);
}

export function unsubscribeFromStream(listenerGuid) {
  let data = pickId(listenerGuid);
  const index = findSubscribeIndex(data.uniqueId);
  subscribedData.slice(index, 1);
  const sendData = pickId(listenerGuid);
  sendSocketData("stream-bar-stop", sendData.symbol, sendData.resolution);
  const currentData = pickId(currentUniqueID);
  sendSocketData("stream-bar", currentData.symbol, currentData.resolution);
}

export function streamWatchlist() {
  if (!watchListCallback) return;
  let data = JSON.parse(localStorage.getItem("watchList"));
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    watchListCallback([
      {
        s: "ok",
        n: item.symbol,
        v: {
          chp: parseFloat(item.changePercent),
          lp: parseFloat(item.latestPrice),
          ch: parseFloat(item.change),
          short_name: item.symbol,
        },
      },
    ]);
  }
}

function removeDuplicatesByProperty(objects, propertyName) {
  const seen = new Set();
  return objects?.filter((obj) => {
    const value = obj[propertyName];
    if (seen.has(value)) {
      return false; // Duplicate found, filter out this object
    } else {
      seen.add(value);
      return true; // Not a duplicate, keep this object
    }
  });
}

let currentSymbol = "";
export async function subscribeWatchListStream(symbols, onRealtimeCallback) {
  let data = JSON.parse(localStorage.getItem("watchList")) ?? [];
  let filteredData = removeDuplicatesByProperty(data, "symbol");
  if (filteredData.length == symbols.length) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      onRealtimeCallback([
        {
          s: "ok",
          n: item.symbol,
          v: {
            chp: parseFloat(item.changePercent),
            lp: parseFloat(item.latestPrice),
            ch: parseFloat(item.change),
            volume: parseFloat(item.volume),
            short_name: item.symbol,
          },
        },
      ]);
    }
    watchListCallback = onRealtimeCallback;
  }
}

// let currentStreamController = null;
let selectedSymbolforRealTimeStream = "";
let realtimeStreamData = {};

export async function subscribeRealTimeStream(symbol, onRealtimeCallback) {
  console.log("subscribe", symbol);
  console.log(realtimeStreamData);
  realtimeStreamData[symbol] = { callback: onRealtimeCallback };
  // if (currentStreamController && !currentStreamController.signal) {
  //   currentStreamController.abort();
  //   console.log("Previous stream aborted due to new subscription request.");
  // }
  // Create a new controller for the new stream
  // currentStreamController = new AbortController();
  // const { signal } = currentStreamController;
  var sendData = {};
  selectedSymbolforRealTimeStream = symbol;
  // try {
  if (symbol.includes(":")) {
    currentSymbol = symbol;
    selectedSymbolforRealTimeStream = symbol.split(":")[1];
  }
  const url = `${process.env.VUE_APP_TS_SIM}marketdata/stream/quotes/${selectedSymbolforRealTimeStream}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
    // signal: signal,
  });
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const whileCondition = true;
  while (whileCondition) {
    const { done, value } = await reader.read();
    if (done) break;
    const chunkText = decoder.decode(value, { stream: true });
    const res = chunkText.split("\n");
    const response = JSON.parse(res[0]);
    if (response?.Symbol != selectedSymbolforRealTimeStream) {
      console.log(response?.Symbol, selectedSymbolforRealTimeStream);
      return;
    }
    let data = {};
    var iframe = document.querySelector("iframe");
    var iframeDocument =
      iframe?.contentDocument || iframe.contentWindow.document;
    var bidElement = iframeDocument.querySelector(".bid-bxBC50sK");
    var askElement = iframeDocument.querySelector(".ask-bxBC50sK");
    var changeElement = iframeDocument.querySelector(
      ".change-SNvPvlJ3:first-child"
    );
    var changePercentElement = iframeDocument.querySelector(
      ".change-SNvPvlJ3:last-child"
    );
    if (askElement && bidElement && changeElement) {
      if (response.Bid) {
        bidElement.innerHTML = parseFloat(response.Bid).toFixed(2);
      }
      if (response.Ask) {
        askElement.innerHTML = parseFloat(response.Ask).toFixed(2);
      }
      if (response.NetChange)
        changeElement.innerHTML = parseFloat(response.NetChange).toFixed(2);
      if (response.NetChangePct)
        changePercentElement.innerHTML = `(${parseFloat(
          response.NetChangePct
        ).toFixed(2)}%)`;
    }
    // if (lastPriceElement) {
    //   if (response.Last) {
    //     lastPriceElement.innerHTML = parseFloat(response.Last).toFixed(2);
    //   }
    // }
    if (response.Ask && response.Bid && response.AskSize && response.BidSize) {
      data.ask = parseFloat(response.Ask);
      data.bid = parseFloat(response.Bid);
      data.ask_size = parseFloat(response.AskSize);
      data.bid_size = parseFloat(response.BidSize);
      // data.trade = parseFloat(response.Close);
      sendData = data;
    }
    if (response.Bid) {
      sendData = { ...sendData, bid: parseFloat(response.Bid) };
    }
    if (response.Ask) {
      sendData = { ...sendData, ask: parseFloat(response.Ask) };
    }
    // if (response.Close) {
    //   sendData = { ...sendData, trade: parseFloat(response.Close) };
    // }
    if (response.AskSize) {
      sendData = { ...sendData, ask_size: parseFloat(response.AskSize) };
    }
    if (response.bid_size) {
      sendData = { ...sendData, bid_size: parseFloat(response.BidSize) };
    }
    realtimeStreamData[symbol].callback(currentSymbol, sendData);
  }
  // } catch (e) {
  //   if (e.name === "AbortError") {
  //     console.log("Fetch aborted due to new subscription or unsubscription");
  //   } else {
  //     console.error("Fetch error:", e);
  //   }
  // }
  // console.log(currentStreamController, signal);
  // if (currentStreamController === signal.controller) {
  //   currentStreamController = null;
  // }
  // let symbolname = symbols;
  // if (symbolname.includes(":")) {
  //   symbolname = symbolname.split(":")[1];
  // }
  // const uniqueId = "realtime-" + symbolname;
  // const index = subscribedData.findIndex((item) => item.uniqueId === uniqueId);
  // if (index > -1) {
  //   subscribedData[index] = {
  //     type: "stream-quotes",
  //     uniqueId,
  //     callback,
  //     symbols: symbolname,
  //   };
  // } else {
  //   subscribedData.push({
  //     type: "stream-quotes",
  //     uniqueId,
  //     callback,
  //     symbols: symbolname,
  //   });
  // }
  // sendSocketDataForQuotes("stream-quotes", symbolname, uniqueId);
}

export function unsubscribeRealTimeStream() {
  // console.log("unsubscribe", symbol);
  // if (!currentStreamController.signal) {
  //   currentStreamController.abort();
  //   currentStreamController = null;
  //   console.log("Stream unsubscribed successfully.");
  // }
}

connectWebSocket();

export { socket, connectWebSocket };
