import { createRouter, createWebHistory } from "vue-router";
import CV from "../pages/CV.vue";
import TickerDetails from "../pages/TickerDetails.vue";
import Crypto from "../pages/Crypto.vue";
import OptionsFlow from "../pages/OptionsFlow.vue";
import OptionsChain from "../components/OptionsChain.vue";

const routes = [
  {
    path: "/",
    name: "CV",
    component: CV,
  },
  {
    path: "/flow",
    name: "OptionsFlow",
    component: OptionsFlow,
  },
  {
    path: "/crypto",
    name: "Crypto",
    component: Crypto,
  },
  {
    path: "/trade/:ticker",
    name: "TickerDetails",
    component: TickerDetails,
    props: true,
    children: [
      {
        path: "options",
        component: OptionsChain,
      },
    ],
  },
  {
    path: "/order/:ticker",
    name: "OrderFromCV",
    component: CV,
    props: true,
  },
  {
    path: "/close/:ticker",
    name: "CloseFromCV",
    component: CV,
    props: true,
  },
  {
    path: "/options/close",
    name: "OptionsCloseFromCV",
    component: CV,
  },
  {
    path: "/options/order",
    name: "OptionsOrderFromCV",
    component: CV,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: CV },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
