import axios from "axios";

export default {
  methods: {
    //calcuation of midpoint value of stock
    markPosition: function (position) {
      if(position.PositionType == "group") {
        return "";
      }
      if (position.AssetType != null && position.AssetType.indexOf("OPTION") != -1) {
        return parseFloat(
          (parseFloat(position.Ask) + parseFloat(position.Bid)) / 2
        );
      } else {
        return parseFloat(position.Last);
      }
    },
    markProfitLossAll: function (position) {
      if(position.PositionType == "group") {
        return "";
      }
      var positionMark = parseFloat(
        this.dollarWithCommas(this.markPosition(position))
      ); //to ensure that the P/L is calculated using the same rounded value that is displayed
      if (position.AssetType != null && position.AssetType.indexOf("OPTION") != -1) {
        if (position.LongShort == "Long") {
          //works as expected.
          return (
            parseFloat(positionMark * 100 * parseFloat(position.Quantity)) -
            parseFloat(position.TotalCost)
          );
        } else if (position.LongShort == "Short") {
          return (
            parseFloat(position.TotalCost * -1) +
            parseFloat(parseFloat(position.Quantity) * positionMark * 100)
          );
        } else {
          return "—";
        }
      } else {
        /*
          for unrealized profits or loss
          (currentMarkSharePrice * amount of shares) - cost basis
        */
        if (position.LongShort == "Long") {
          return (
            positionMark * parseFloat(position.Quantity) -
            parseFloat(position.TotalCost)
          );
        } else if (position.LongShort == "Short") {
          return (
            (parseFloat(position.TotalCost) -
              positionMark * parseInt(position.Quantity)) *
            -1
          );
        } else {
          return "—";
        }
      }
    },
    markProfitLossPercent(position) {
      /*
          for unrealized profits or loss
          (current value - cost basis) / cost basis * 100
      */
      if(position.PositionType == "group") {
        return "";
      }
      var positionMark = parseFloat(
        this.dollarWithCommas(this.markPosition(position))
      ); //to ensure that the P/L is calculated using the same rounded value that is displayed
      if (position.AssetType != null && position.AssetType.indexOf("OPTION") != -1) {
        return Math.abs(
          ((positionMark * parseFloat(position.Quantity) * 100 -
            parseFloat(position.TotalCost)) /
            parseFloat(position.TotalCost)) *
            100
        );
      } else {
        return Math.abs(
          ((positionMark * parseFloat(position.Quantity) -
            parseFloat(position.TotalCost)) /
            parseFloat(position.TotalCost)) *
            100
        );
      }
    },
    numberWithCommas: function (x) {
      if (x === "") {
        return x;
      }
      x = parseFloat(x);
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    cryptoFormat(x) {
      if (!isNaN(x)) {
        x = x.toString();
      }
      var whole = x.split(".")[0];
      whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var deci = x.split(".")[1];
      if (deci == undefined) {
        deci = "00";
      }
      if (deci.length > 8) {
        deci = deci.substr(0, 8);
      }
      while (deci.slice(-1) == "0" && deci.length > 2) {
        deci = deci.substr(0, deci.length - 1);
      }
      if (deci.length == 1) {
        deci += "0";
      }
      return `${whole}.${deci}`;
    },
    dollarWithCommas: function (x, withoutCents) {
      if (x == "" || x === "-" || parseFloat(x) == 0 ) {
        return x;
      }
      x = parseFloat(x);
      x = x.toFixed(2);
      var dollars = String(x).split(".")[0];
 
      if (withoutCents) {
        return dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        var cents = "." + String(x).split(".")[1];
        return dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + cents;
      }
    },
    shortDollar: function (x, withoutCents) {
      if (x < 10000) {
        return this.dollarWithCommas(x, withoutCents);
      } else if (x < 1000000) {
        x = x / 1000;
        return this.dollarWithCommas(x, withoutCents) + "K";
      } else if (x < 1000000000) {
        x = x / 1000000;
        return this.dollarWithCommas(x, withoutCents) + "M";
      } else {
        x = x / 1000000000;
        return this.dollarWithCommas(x, withoutCents) + "B";
      }
    },
    timeDisplay: function (x) {
      var date = new Date(x),
        datevalues = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          date: date.getDate(),
          hours: date.getHours(),
          minutes: date.getMinutes(),
          seconds: date.getSeconds(),
        };
      var ampm = "am";
      if (datevalues.hours >= 12) {
        ampm = "pm";
        if (datevalues.hours > 12) {
          datevalues.hours = datevalues.hours - 12;
        }
      }
      return (
        datevalues.hours +
        ":" +
        datevalues.minutes.toString().padStart(2, "0") +
        ampm
      );
    },
    monthNameShort: function (x) {
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[x];
    },
    monthNameLong: function (x) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return months[x];
    },
    displaySymbol: function (x, full = false) {
      if (x.PositionType == "group") {
        return x.Symbol.split("\n")[0] + (full ? " Spread" : "");
      }
      if (x.Spread && x.Spread.length > 0) {
        var returnText = x.Spread;
        if (returnText.indexOf("Ratio") != -1) {
          returnText = "Custom";
        }
        return x.Legs[0].Symbol.split(" ")[0] + " " + returnText;
      }
      x = x.Legs ? x.Legs[0] : x;
      var symbolDisplay = x["Symbol"];
      if (
        (x["AssetType"] == "UNKNOWN" && x.Symbol.split(" ").length > 1) ||
        x["AssetType"] == "OP" ||
        x["AssetType"].indexOf("OPTION") != -1 ||
        (x["AssetType"].indexOf("Option") != -1 &&
          symbolDisplay.indexOf(" ") != -1)
      ) {
        var symbolTicker = symbolDisplay.split(" ")[0];
        var symbolYear = symbolDisplay.split(" ")[1].substring(0, 2);
        var symbolMonth = symbolDisplay.split(" ")[1].substring(2, 4);
        var symbolDay = symbolDisplay.split(" ")[1].substring(4, 6);
        var symbolOptionType = symbolDisplay.split(" ")[1].substring(6, 7);
        var symbolOptionStrike = "$" + symbolDisplay.split(" ")[1].substring(7);
        if (symbolMonth == "01") {
          symbolMonth = "JAN";
        } else if (symbolMonth == "02") {
          symbolMonth = "FEB";
        } else if (symbolMonth == "03") {
          symbolMonth = "MAR";
        } else if (symbolMonth == "04") {
          symbolMonth = "APR";
        } else if (symbolMonth == "05") {
          symbolMonth = "MAY";
        } else if (symbolMonth == "06") {
          symbolMonth = "JUN";
        } else if (symbolMonth == "07") {
          symbolMonth = "JUL";
        } else if (symbolMonth == "08") {
          symbolMonth = "AUG";
        } else if (symbolMonth == "09") {
          symbolMonth = "SEP";
        } else if (symbolMonth == "10") {
          symbolMonth = "OCT";
        } else if (symbolMonth == "11") {
          symbolMonth = "NOV";
        } else if (symbolMonth == "12") {
          symbolMonth = "DEC";
        }
        if (symbolOptionType == "C") {
          symbolOptionType = "CALL";
        } else if (symbolOptionType == "P") {
          symbolOptionType = "PUT";
        }
        symbolDisplay =
          symbolTicker +
          "\n" +
          symbolOptionStrike +
          " " +
          symbolOptionType +
          "\n" +
          symbolMonth +
          " " +
          symbolDay +
          "/" +
          symbolYear;
        if (x.PositionType == "child" && full == false) {
          symbolDisplay =
            symbolOptionStrike +
            " " +
            symbolOptionType +
            "\n" +
            symbolMonth +
            " " +
            symbolDay +
            "/" +
            symbolYear;
        }
        return symbolDisplay;
      } else {
        return x.Symbol;
      }
    },
    getGcd(x, y) {
      if (typeof x !== "number" || typeof y !== "number") return false;
      x = Math.abs(x);
      y = Math.abs(y);
      while (y) {
        var t = y;
        y = x % y;
        x = t;
      }
      return x;
    },
    postOrder({
      callOptionsOrders,
      final,
      symbol,
      quantity,
      limitPrice,
      bracketProfit,
      bracketLoss,
      stopPrice,
      trailAmount,
      trailPercent,
      orderType,
      orderDuration,
      legs,
      close,
      tradeAction,
      assetType,
      accountID,
      orderRoute,
      crypto,
      isRollOptions
    }) {
      if (tradeAction == "SELLSHORT" && assetType == "FUTURE") {
        tradeAction = "SELL";
      } else if (tradeAction == "BUYTOCOVER" && assetType == "FUTURE") {
        tradeAction = "BUY";
      }
      if (orderType == "Bracket %") {
        orderType = "Bracket";
      }
      if (crypto) {
        orderRoute = "TSSR";
        if (symbol == "USDCUSD") {
          orderRoute = "USDC";
        }
      }
      var sendLegs = [];
      var bracketLegs = [];

      if (assetType == "OP") {
        legs.forEach((leg) => {
          sendLegs.push({
            Symbol: leg.Symbol,
            Quantity:
              legs.length == 1
                ? String(quantity)
                : (parseInt(leg["Quantity"]) * parseInt(quantity)).toFixed(0).replace("-",""),
                TradeAction: isRollOptions
                ? leg.TradeAction
                : (close
                    ? (leg.Buy ? "BUYTOCLOSE" : "SELLTOCLOSE")
                    : (leg.Buy ? "BUYTOOPEN" : "SELLTOOPEN")),
          });
          if (orderType == "Bracket") {
            bracketLegs.push({
              Symbol: leg.Symbol,
              Quantity: String(quantity),
              TradeAction: leg.Buy ? "SELLTOCLOSE" : "BUYTOCLOSE",
            });
          }
        });
      }
      var durationMap = {
        Day: "DAY",
        Extended: "DYP",
        "Until Cancel": "GTC",
        "All Hours": "GCP",
        "Open Only": "OPG",
        "Close Only": "CLO",
        Immediate: "IOC",
        "Fill or Kill": "FOK",
      };
      var typeMap = {
        Limit: "Limit",
        Market: "Market",
        Bracket: "BRK",
        "Stop Market": "StopMarket",
        "Stop Limit": "StopLimit",
        "Trailing Stop": "StopMarket",
        "Trailing Stop %": "StopMarket",
      };
      function generateOSOs(accountID, durationMap, orderRoute, callOptionsOrders) {
        const OSOs = [];
        if(!callOptionsOrders) return OSOs;
        for (const order of callOptionsOrders) {
            const data = [];
            if (order !== callOptionsOrders[0]) {
                data.push({
                    Type: "NORMAL",
                    Orders: [
                        {
                            AccountID: accountID,
                            TimeInForce: {
                                Duration: durationMap[order.orderDuration],
                            },
                            OrderType: "Limit",
                            LimitPrice: order.limitPrice,
                            Route: orderRoute,
                            TradeAction: order.tradeAction,
                            Legs: order.legs?.map(leg => {
                              let newLeg = {...leg};
                              newLeg.TradeAction = leg['LimitTradeAction'];
                              delete newLeg['LimitTradeAction'];
                              return newLeg;
                            }),
                        },
                    ],
                });
            }
            data.push({
                Type: "BRK",
                Orders: [
                    {
                        AccountID: accountID,
                        TimeInForce: {
                            Duration: durationMap[order.orderDuration],
                        },
                        OrderType: "Limit",
                        LimitPrice: order.bracketProfit,
                        Route: orderRoute,
                        TradeAction: order.tradeAction == "BUY" ? "SELL" : "BUY",
                        Legs:order.legs,
                    },
                    {
                        AccountID: accountID,
                        TimeInForce: {
                            Duration: durationMap[order.orderDuration],
                        },
                        OrderType: "StopMarket",
                        StopPrice: order.bracketLoss,
                        Route: orderRoute,
                        TradeAction: order.tradeAction == "BUY" ? "SELL" : "BUY",
                        Legs: order.legs,
                    },
                ],
            });
            OSOs.push(...data);
        }
        return OSOs;
    }
   const OSOs = generateOSOs(accountID, durationMap, orderRoute, callOptionsOrders);

      let requestBody = {};
      if (!callOptionsOrders) {
        if (orderType == "Bracket") {
          if (close) {
            requestBody = {
              Orders: [
                {
                  AccountID: accountID,
                  TimeInForce: {
                    Duration: durationMap[orderDuration],
                  },
                  OrderType: "Limit",
                  LimitPrice: String(
                    parseFloat(bracketProfit.replaceAll(",", ""))
                  ),
                  Route: orderRoute,
                  TradeAction: tradeAction,
                },
                {
                  AccountID: accountID,
                  TimeInForce: {
                    Duration: durationMap[orderDuration],
                  },
                  OrderType: "StopMarket",
                  StopPrice: String(
                    parseFloat(bracketLoss.replaceAll(",", ""))
                  ),
                  Route: orderRoute,
                  TradeAction: tradeAction,
                },
              ],
              Type: "BRK",
            };
            if (assetType == "OP") {
              requestBody["Orders"][0]["Legs"] = sendLegs;
              requestBody["Orders"][1]["Legs"] = sendLegs;
            } else {
              requestBody["Orders"][0]["Symbol"] = symbol;
              requestBody["Orders"][0]["Quantity"] = String(quantity);
              requestBody["Orders"][1]["Symbol"] = symbol;
              requestBody["Orders"][1]["Quantity"] = String(quantity);
            }
          } else {
            requestBody = {
              AccountID: accountID,
              // "AssetType": assetType,
              TimeInForce: {
                Duration: durationMap[orderDuration],
              },
              OrderType: "Limit",
              LimitPrice: String(parseFloat(limitPrice.replaceAll(",", ""))),
              Route: orderRoute,
              TradeAction: tradeAction,
              OSOs: [
                {
                  Type: "BRK",
                  Orders: [
                    {
                      AccountID: accountID,
                      // "AssetType": assetType,
                      TimeInForce: {
                        Duration: durationMap[orderDuration],
                      },
                      OrderType: "Limit",
                      LimitPrice: String(
                        parseFloat(bracketProfit.replaceAll(",", ""))
                      ),
                      Route: orderRoute,
                      TradeAction: tradeAction == "BUY" ? "SELL" : "BUY",
                    },
                    {
                      AccountID: accountID,
                      // "AssetType": assetType,
                      TimeInForce: {
                        Duration: durationMap[orderDuration],
                      },
                      OrderType: "StopMarket",
                      StopPrice: String(
                        parseFloat(bracketLoss.replaceAll(",", ""))
                      ),
                      Route: orderRoute,
                      TradeAction: tradeAction == "BUY" ? "SELL" : "BUY",
                    },
                  ],
                },
              ],
            };
            if (assetType == "OP") {
              requestBody["Legs"] = sendLegs;
              requestBody["OSOs"][0]["Orders"][0]["Legs"] = bracketLegs;
              requestBody["OSOs"][0]["Orders"][1]["Legs"] = bracketLegs;
            } else {
              requestBody["Symbol"] = symbol;
              requestBody["Quantity"] = String(quantity);
              requestBody["OSOs"][0]["Orders"][0]["Symbol"] = symbol;
              requestBody["OSOs"][0]["Orders"][0]["Quantity"] =
                String(quantity);
              requestBody["OSOs"][0]["Orders"][1]["Symbol"] = symbol;
              requestBody["OSOs"][0]["Orders"][1]["Quantity"] =
                String(quantity);
            }
          }
        } else {
          requestBody = {
            AccountID: accountID,
            // "AssetType": assetType,
            TimeInForce: {
              Duration: durationMap[orderDuration],
            },
            OrderType: typeMap[orderType],
            Route: orderRoute,
            TradeAction: tradeAction,
          };
          if (assetType == "OP") {
            requestBody["Legs"] = sendLegs;
          } else {
            requestBody["Symbol"] = symbol;
            requestBody["Quantity"] = String(quantity);
          }
          if (orderType == "Limit" || orderType == "Stop Limit") {
            requestBody["LimitPrice"] = String(
              parseFloat(limitPrice.replaceAll(",", ""))
            );
          }
          if (orderType == "Stop Market" || orderType == "Stop Limit") {
            requestBody["StopPrice"] = String(parseFloat(stopPrice).toFixed(2));
          }
          if (orderType == "Trailing Stop") {
            requestBody["AdvancedOptions"] = {
              TrailingStop: {
                Amount: String(parseFloat(trailAmount).toFixed(2)),
              },
            };
          }
          if (orderType == "Trailing Stop %") {
            requestBody["AdvancedOptions"] = {
              TrailingStop: {
                Percent: String(parseFloat(trailPercent).toFixed(2)),
              },
            };
          }
        }    
      } else {
        if (orderType == "Bracket") {
          
          requestBody =
            close == true
              ? {
                  Type: "BRK",
                  Orders: callOptionsOrders.flatMap((order) => [
                    {
                      AccountID: accountID,
                      TimeInForce: {
                        Duration: durationMap[order.orderDuration],
                      },
                      OrderType: "Limit",
                      LimitPrice: order.bracketProfit,
                      Route: orderRoute,
                      TradeAction: order.tradeAction == "BUY" ? "SELL" : "BUY",
                      Legs: order.legs,
                    },
                    {
                      AccountID: accountID,
                      TimeInForce: {
                        Duration: durationMap[order.orderDuration],
                      },
                      OrderType: "StopMarket",
                      StopPrice: order.bracketLoss,
                      Route: orderRoute,
                      TradeAction: order.tradeAction == "BUY" ? "SELL" : "BUY",
                      Legs: order.legs,
                    },
                  ]),
                }
              : {
                  AccountID: accountID,
                  TimeInForce: {
                    Duration:
                      durationMap[callOptionsOrders?.[0]?.orderDuration],
                  },
                  Legs:  callOptionsOrders?.[0]?.legs?.map(leg => {
                    let newLeg = {...leg};
                    newLeg.TradeAction = leg['LimitTradeAction'];
                    delete newLeg['LimitTradeAction'];
                    return newLeg;
                  }),
                  OrderType: "Limit",
                  LimitPrice: callOptionsOrders?.[0]?.limitPrice,
                  Route: orderRoute,
                  TradeAction: tradeAction,
                  OSOs: OSOs
                };
        } else {
          requestBody = {
            Type: "NORMAL",
            Orders: callOptionsOrders.map((order) => {
              let orderObj = {
                AccountID: accountID,
                TimeInForce: {
                  Duration: durationMap[order.orderDuration],
                },
                OrderType: order.orderType,
                Route: orderRoute,
                TradeAction: order.tradeAction == "BUY" ? "SELL" : "BUY",
                Symbol: order.symbol,
                Legs: order.legs,
              };

              if (["Limit", "Stop Limit"].includes(order.orderType)) {
                orderObj["LimitPrice"] = order.limitPrice;
              }

              if (["Stop Market", "Stop Limit"].includes(order.orderType)) {
                orderObj["StopPrice"] = order.stopPrice;
              }

              if (order.orderType == "Trailing Stop") {
                orderObj["AdvancedOptions"] = {
                  TrailingStop: {
                    Amount: order.trailAmount,
                  },
                };
              }

              if (order.orderType == "Trailing Stop %") {
                orderObj["AdvancedOptions"] = {
                  TrailingStop: {
                    Percent: order.trailPercent,
                  },
                };
              }

              return orderObj;
            }),
          };
        }
      }
      function getOrderUrl() {
        const baseUrl =
          localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS;

        let path;
        if (!callOptionsOrders) {
          if (orderType === "Bracket" && close) {
            path = final ? "/ordergroups" : "/ordergroupconfirm";
          } else {
            path = final ? "/orders" : "/orderconfirm";
          }
        } else {
          if (orderType === "Bracket" && !close) {
            path = final ? "/orders" : "/orderconfirm";
          } else {
            path = final ? "/ordergroups" : "/ordergroupconfirm";
          }
        }

        return `${baseUrl}orderexecution${path}?access_token=${localStorage.accessToken}`;
      }
      let sendUrl = getOrderUrl();
      return axios.post(sendUrl, requestBody, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      });
    },
    playSound(sound, canPlay) {
      if (canPlay) {
        if (typeof sound != "string") {
          console.error(`${sound} is not a string!`);
        } else if (!sound.includes(".mp3")) {
          console.error(`${sound} file is not of type mp3!`);
        }

        let audio = new Audio(sound);
        audio.loop = false;
        audio.play();
      } else {
        console.log("sound is toggled off", canPlay);
      }
    },
    // updateLog(requestBody) {
    //   // console.log("updating log");
    //   var sendUrl = process.env.VUE_APP_JOURNAL + `/tradelog/update`;
    //   return axios.post(sendUrl, requestBody, {
    //     headers: { "Content-Type": "application/json" },
    //   });
    // },
    tagPLstart(requestBody) {
      // console.log("Send Tag");
      var sendUrl = process.env.VUE_APP_JOURNAL + `/profit/tag`;
      return axios.post(sendUrl, requestBody, {
        headers: { "Content-Type": "application/json" },
      });
    },
    updatePL(requestBody) {
      // console.log("Update Tag");
      var sendUrl = process.env.VUE_APP_JOURNAL + `/profit/update`;
      return axios.post(sendUrl, requestBody, {
        headers: { "Content-Type": "application/json" },
      });
    },
    getPL() {
      // console.log("Update Tag");
      var sendUrl =
        process.env.VUE_APP_JOURNAL +
        `/profit/list?session=${encodeURIComponent(
          localStorage.session
        )}&token=${encodeURIComponent(localStorage.sessionKey)}`;
      return axios.get(sendUrl, {
        headers: { "Content-Type": "application/json" },
      });
    },
  },
};
