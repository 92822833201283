<template>
  <div class="graph">
    <div v-if="parseFloat(account.CashBalance) +
      parseFloat(account.BalanceDetail.UnsettledFunds) +
      parseFloat(account.MarketValue) !=
      0
      ">
      <RadialProgressBar :diameter="380" :stroke-width="19" :inner-stroke-width="19" start-color="#5831C7"
        stop-color="#5831C7" class="networth-big" inner-stroke-color="#10BC74"
        :completed-steps="parseFloat(account.MarketValue)" :total-steps="parseFloat(account.CashBalance) +
      parseFloat(account.BalanceDetail.UnsettledFunds) +
      parseFloat(account.MarketValue)
      ">
        <p class="account-name">
          {{ account.Alias ? account.Alias : account.AccountID }}
        </p>
        <p class="balance" :style="{
      fontSize:
        parseFloat(account.CashBalance) +
          parseFloat(account.BalanceDetail.UnsettledFunds) +
          parseFloat(account.MarketValue) <
          1000
          ? '48px'
          : parseFloat(account.CashBalance) +
            parseFloat(account.BalanceDetail.UnsettledFunds) +
            parseFloat(account.MarketValue) >
            10000000
            ? '30px'
            : '40px',
    }">
          ${{
      dollarWithCommas(
        parseFloat(account.CashBalance) +
        parseFloat(account.BalanceDetail.UnsettledFunds) +
        parseFloat(account.MarketValue)
      )
    }}
        </p>
        <p class="balance-tag">ACCOUNT NET WORTH</p>
        <p class="change" :class="{
        red:
          parseFloat(account.TodaysProfitLoss) +
          parseFloat(account.BalanceDetail.RealizedProfitLoss) <
          0,
        green:
          parseFloat(account.TodaysProfitLoss) +
          parseFloat(account.BalanceDetail.RealizedProfitLoss) >=
          0,
      }">
          {{
      parseFloat(account.TodaysProfitLoss) +
        parseFloat(account.BalanceDetail.RealizedProfitLoss) >=
        0
        ? "+"
        : "-"
    }}
          ${{
        dollarWithCommas(
          parseFloat(account.TodaysProfitLoss) +
          parseFloat(account.BalanceDetail.RealizedProfitLoss)
        ).replace("-", "")
      }}
        </p>
      </RadialProgressBar>
      <div class="networth-small">
        <p class="account-name">
          {{ account.Alias ? account.Alias : account.AccountID }}
        </p>
        <p class="balance" :style="{
      fontSize:
        parseFloat(account.CashBalance) +
          parseFloat(account.BalanceDetail.UnsettledFunds) +
          parseFloat(account.MarketValue) <
          1000
          ? '48px'
          : parseFloat(account.CashBalance) +
            parseFloat(account.BalanceDetail.UnsettledFunds) +
            parseFloat(account.MarketValue) >
            10000000
            ? '30px'
            : '40px',
    }">
          ${{
      dollarWithCommas(
        parseFloat(account.CashBalance) +
        parseFloat(account.BalanceDetail.UnsettledFunds) +
        parseFloat(account.MarketValue)
      )
    }}
        </p>
        <p class="balance-tag">ACCOUNT NET WORTH</p>
        <!-- <p class="change" :class="{
              red:
                parseFloat(account.TodaysProfitLoss) +
                parseFloat(account.BalanceDetail.RealizedProfitLoss) <
                0,
              green:
                parseFloat(account.TodaysProfitLoss) +
                parseFloat(account.BalanceDetail.RealizedProfitLoss) >=
                0,
            }">
          {{
            parseFloat(account.TodaysProfitLoss) +
            parseFloat(account.BalanceDetail.RealizedProfitLoss) >=
            0
            ? "+"
            : "-"
          }}
          ${{
            dollarWithCommas(
              parseFloat(account.TodaysProfitLoss) +
              parseFloat(account.BalanceDetail.RealizedProfitLoss)
            ).replace("-", "")
          }}
        </p> -->
      </div>
      <div class="cash-position-split">
        <div class="cash-box">
          <p class="value">
            ${{
        dollarWithCommas(
          parseFloat(account.CashBalance) +
          parseFloat(account.BalanceDetail.UnsettledFunds)
        )
      }}
          </p>
          <div class="icon"><img src="../assets/dot-green.png" /> Cash</div>
        </div>
        <div class="position-box">
          <p class="value">${{ dollarWithCommas(account.MarketValue) }}</p>
          <div class="icon">
            <img src="../assets/dot-purple.png" /> Positions
          </div>
        </div>
      </div>
    </div>
    <div class="no-funds" v-if="parseFloat(account.CashBalance) +
      parseFloat(account.BalanceDetail.UnsettledFunds) +
      parseFloat(account.MarketValue) ==
      0
      ">
      <img src="../assets/emptywallet.jpg" alt="Empty Wallet" />
      <p>No cleared funds in account</p>
      <a :href="fundLink" target="_blank">Add Funds</a>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import RadialProgressBar from "../components/RadialProgressBar.vue";

export default {
  name: "AccountGraph",
  props: {
    account: Object,
  },
  data() {
    return {
      fundLink: `https://clientcenter.tradestation.com/support/transfer_funds_cashiering.aspx?page=transfers`
    };
  },
  mixins: [Mixins],
  components: {
    RadialProgressBar,
  },
  methods: {},
};
</script>

<style scoped>
.graph p {
  font-family: "Roboto";
  margin: 0;
}

.graph .balance-tag {
  font-size: 16px;
}

.graph .cash-position-split {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 32px;
  margin-bottom: 30px; */
  margin-top: 30px;
  margin-bottom: 15px;
}

.graph .change {
  font-size: 16px;
  margin-top: 4px; 
}

.graph .change.green {
  color: #10bc74;
}

.graph .change.red {
  color: #ce0606;
}

.graph .cash-position-split .cash-box {
  margin-right: 40px;
}

.graph .cash-position-split>div {
  text-align: center;
}

.graph .cash-position-split .value {
  font-family: "Roboto";
  margin: 0;
  font-size: 20px;
  margin-bottom: 4px;
}

.graph .cash-position-split .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-size: 17px;
}

.graph .cash-position-split .icon img {
  margin-right: 8px;
}

a {
  display: block;
  text-decoration: none;
  color: #10bc74;
  text-align: center;
}

.no-funds {
  padding-top: 80px;
}

.no-funds img {
  display: block;
  width: 200px;
  margin: auto;
}

.no-funds p {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.networth-small {
  display: none;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  .networth-big {
    display: none;
  }

  .networth-small {
    display: flex;
  }

  .graph .cash-position-split {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
