<template>
  <a class="head-link" href="#" @click.prevent="onClick()"
    :class="{ active: active, disabled: disabled, nohover: nohover }">{{ title }}</a>
</template>

<script>
export default {
  name: "HeadLink",
  props: {
    title: String,
    active: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    nohover: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick: function () {
      this.$emit("on-click");
    },
  },
};
</script>

<style scoped>
.head-link {
  font-family: "Roboto";
  text-decoration: none;
  color: #000000;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 21px;
  margin-right: 15px;
}

.dark .head-link {
  color: #d9d9d9;
}

.head-link.nohover {
  cursor: default;
}

.head-link.active {
  color: #10bc74;
  border-bottom: #10bc74 2px solid;
}

.head-link:hover {
  border-bottom: #000000 2px solid;
}

.dark .head-link:hover {
  border-bottom: #d9d9d9 2px solid;
}

.head-link:focus {
  border-bottom: #10bc74 2px solid;
}

.head-link.disabled {
  display: none;
}

.head-link.nohover:hover {
  border-bottom: none;
}

.head-link.nohover:focus {
  border-bottom: none;
}

.head-link.active.nohover {
  border-bottom: none;
}

@media only screen and (max-width: 425px) {
  .head-link {
    font-size: 16px;
    margin-right: 16px;
  }
}</style>
