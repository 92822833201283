<template>
  <div class="buy-form">
    <p class="ticker" v-if="!submitted && !haveMap">
      {{ action }} {{ symbol }}
    </p>
    <p class="ticker alt" v-if="!submitted && haveMap">
      {{ action }} {{ pairMap[symbol][0] }}
    </p>
    <p class="ticker" v-if="!submitted && haveMap">
      for {{ pairMap[symbol][1] }}
    </p>
    <div class="form-body" v-if="!submitted">
      <div class="input-line">
        <div class="input-row">
          <label for="quantity">Quantity</label>
          <input
            type="text"
            name="quantity"
            id="quantity"
            v-model="quantity"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.quantity">{{ errors.quantity }}</p>
      </div>
      <div class="input-line" v-if="this.symbol != 'USDCUSD'">
        <div class="input-row">
          <label for="type">Order Type</label>
          <select name="type" id="type" v-model="orderType">
            <option>Limit</option>
            <option>Market</option>
            <option v-if="action == 'Buy'">Bracket</option>
            <option>Stop Market</option>
            <option>Stop Limit</option>
            <option>Trailing Stop</option>
            <option>Trailing Stop %</option>
          </select>
        </div>
        <p class="description">
          {{ typeDescription[orderType] }}
        </p>
      </div>
      <div
        class="input-line"
        v-if="
          ['Limit', 'Bracket', 'Stop Limit'].indexOf(orderType) != -1 &&
          this.symbol != 'USDCUSD'
        "
      >
        <div class="input-row">
          <label for="limitPrice">Limit Price</label>
          <input
            type="text"
            name="limitPrice"
            id="limitPrice"
            v-model="limitPrice"
            placeholder="0.00"
          />
        </div>
        <div class="price-select">
          <p class="bid" @click="limitPrice = bid">Bid: {{ bid }}</p>
          <p class="mid" @click="limitPrice = mid">Mid: {{ mid }}</p>
          <p class="ask" @click="limitPrice = ask">Ask: {{ ask }}</p>
        </div>
        <p class="error" v-if="errors.limitPrice">{{ errors.limitPrice }}</p>
      </div>
      <div
        class="input-line"
        v-if="['Stop Market', 'Stop Limit'].indexOf(orderType) != -1"
      >
        <div class="input-row">
          <label for="stopPrice">Stop Price</label>
          <input
            type="text"
            name="stopPrice"
            id="stopPrice"
            v-model="stopPrice"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.stopPrice">{{ errors.stopPrice }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="profitLimit">Profit Limit</label>
          <input
            type="text"
            name="profitLimit"
            id="profitLimit"
            v-model="profitLimit"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.profitLimit">{{ errors.profitLimit }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopLoss">Stop Loss</label>
          <input
            type="text"
            name="stopLoss"
            id="stopLoss"
            v-model="stopLoss"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.stopLoss">{{ errors.stopLoss }}</p>
      </div>
      <div class="input-line" v-if="['Trailing Stop'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="trailAmount">Trail Amount</label>
          <input
            type="text"
            name="trailAmount"
            id="trailAmount"
            v-model="trailAmount"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.trailAmount">{{ errors.trailAmount }}</p>
      </div>
      <div
        class="input-line"
        v-if="['Trailing Stop %'].indexOf(orderType) != -1"
      >
        <div class="input-row">
          <label for="trailPercent">Trailing Stop %</label>
          <input
            type="text"
            name="trailPercent"
            id="trailPercent"
            v-model="trailPercent"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.trailPercent">
          {{ errors.trailPercent }}
        </p>
      </div>
      <KeyValue
        :key-string="`Estimated ${action == 'Buy' ? 'Cost' : 'Return'} ${
          haveMap ? pairMap[symbol][1] : ''
        }`"
        :value="estimatedTotal"
      />
      <KeyValue
        v-if="haveMap"
        :key-string="`${pairMap[symbol][0]} Available`"
        :value="
          wallets.find((obj) => obj.Currency == pairMap[symbol][0])
            .BalanceAvailableForTrading
        "
      />
      <KeyValue
        v-if="haveMap"
        last
        :key-string="`${pairMap[symbol][1]} Available`"
        :value="
          wallets.find((obj) => obj.Currency == pairMap[symbol][1])
            .BalanceAvailableForTrading
        "
      />
    </div>
    <div class="preview-body" v-if="preview && !submitted">
      <KeyValue key-string="Account" :value="preview.AccountID" />
      <KeyValue
        key-string="Estimated Commission"
        :value="preview.EstimatedCommission"
      />
      <KeyValue key-string="Estimated Cost" :value="preview.EstimatedCost" />
      <KeyValue key-string="Duration" :value="preview.TimeInForce.Duration" />
      <p class="summary-title">Summary:</p>
      <p class="summary-message">{{ preview.SummaryMessage }}</p>
    </div>
    <div class="success-body" v-if="submitted">
      <p>{{ submitted.Message }}</p>
    </div>
    <p class="error" v-if="errors.global">{{ errors.global }}</p>
    <div class="buttons" :class="{ done: submitted }">
      <button class="secondary" v-if="!submitted" @click="close">Cancel</button>
      <!-- <button class="secondary" v-if="preview && !submitted" @click="preview = null">Back</button> -->
      <!-- <button class="secondary" v-if="!loading && submitted" @click="chart">Coin</button> -->

      <button class="blank" v-if="loading">
        <img class="loading" src="../assets/loading.gif" />
      </button>
      <!-- <button v-if="!loading && !preview" @click="reviewOrder">Review</button> -->
      <button v-if="!loading && !submitted" @click="submitOrder">Submit</button>
      <button v-if="!loading && submitted" @click="goHome">Done</button>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import KeyValue from "./KeyValue.vue";
import Toast from "./Toasts.vue";

export default {
  name: "BuyCrypto",
  inject: {
    stream: { from: "stream" },
  },
  mixins: [Mixins],
  props: {
    symbol: String,
    action: String,
    wallets: Array,
    sound: Boolean,
  },
  components: {
    KeyValue,
  },
  data() {
    return {
      quantity: "1",
      pairMap: {
        BTCUSD: ["BTC", "USD"],
        ETHBTC: ["ETH", "BTC"],
        ETHUSD: ["ETH", "USD"],
        LTCBTC: ["LTC", "BTC"],
        LTCUSD: ["LTC", "USD"],
        USDCUSD: ["USDC", "USD"],
        BCHUSD: ["BCH", "USD"],
        XRPUSD: ["XRP", "USD"],
        ETHUSDC: ["ETH", "USDC"],
        BTCUSDC: ["BTC", "USDC"],
      },
      haveMap: false,
      preview: null,
      submitted: null,
      loading: false,
      bid: "-",
      mid: "-",
      ask: "-",
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      orderType: "Limit",
      limitPrice: "",
      stopPrice: "",
      profitLimit: "",
      stopLoss: "",
      trailAmount: "",
      trailPercent: "",
      duration: "Until Cancel",
      typeDescription: {
        Limit: "Order only if price is equal to or better than my limit",
        Market: "Order as soon as possible, regardless of the price",
        // "Bracket" : "Place a limit order and when it fills create two closing orders. One to limit my loss, the other to automatically take profit",
        "Stop Market":
          "Order as soon as possible once the price is equal to or worse than my stop price",
        "Stop Limit":
          "Order once the price is equal to or worse than my stop price, but only if the price is equal to or better than my limit",
        "Trailing Stop":
          "Create a stop market order with trail amount difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
        "Trailing Stop %":
          "Create a stop market order with trail % difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
      },
      durationDescription: {
        "Until Cancel":
          "Valid until I cancel, trades during regular market hours",
        Day: "Valid until the end of the regular trading session",
        Extended:
          "Valid until during one extended trading session (pre or post market)",
        "All Hours":
          "Valid until I cancel, trades during regular market hours and extended trading hours",
        Immediate:
          "Only try to fill immediately, accept partial fills, then cancel",
        "Fill or Kill": "Only fill this order completely, reject partial fills",
      },
      errors: {},
    };
  },
  computed: {
    estimatedTotal() {
      var returnTotal = "-";
      if (
        this.orderType.indexOf("Stop") != -1 &&
        this.orderType.indexOf("Trailing") == -1
      ) {
        returnTotal =
          parseFloat(this.quantity.replaceAll(",", "")) *
          parseFloat(this.stopPrice.replaceAll(",", ""));
      } else if (
        this.orderType.indexOf("Limit") != -1 ||
        this.orderType == "Bracket"
      ) {
        returnTotal =
          parseFloat(this.quantity.replaceAll(",", "")) *
          parseFloat(this.limitPrice.replaceAll(",", ""));
      } else if (this.orderType == "Market" && this.action == "Buy") {
        returnTotal =
          parseFloat(this.quantity.replaceAll(",", "")) *
          parseFloat(this.ask.replaceAll(",", ""));
      } else if (this.orderType == "Market" && this.action == "Sell") {
        returnTotal =
          parseFloat(this.quantity.replaceAll(",", "")) *
          parseFloat(this.bid.replaceAll(",", ""));
      } else {
        returnTotal = "-";
      }
      if (isNaN(returnTotal)) {
        returnTotal = "-";
      } else if (this.haveMap && this.pairMap[this.symbol][1] == "USD") {
        returnTotal = returnTotal.toFixed(2);
      } else {
        returnTotal = parseFloat(returnTotal.toFixed(8));
      }
      return returnTotal;
    },
  },
  beforeUnmount() {
    if (this.quoteStream.connection) {
      this.quoteStream.connection.end();
      this.quoteStream.connection.destroy();
    }
  },
  mounted() {
    this.streamData();
    if (this.pairMap[this.symbol]) {
      this.haveMap = true;
    }
    if (this.symbol == "USDCUSD") {
      this.duration = "Immediate";
      this.orderType = "Limit";
      this.limitPrice = "1.00";
    } else {
      this.duration = "Day";
    }
  },
  methods: {
    streamData() {
      var _this = this;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      this.quoteStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${this.symbol}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.quoteStream.connection = this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.quoteStream || !this.quoteStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.quoteStream.leftOver != null) {
                chunk = _this.quoteStream.leftOver + chunk;
                _this.quoteStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Bid) {
                    _this.bid = _this.cryptoFormat(snapShot.Bid);
                  }
                  if (snapShot.Ask) {
                    _this.ask = _this.cryptoFormat(snapShot.Ask);
                  }
                  _this.mid = _this.cryptoFormat(
                    (parseFloat(_this.bid.replaceAll(",", "")) +
                      parseFloat(_this.ask.replaceAll(",", ""))) /
                      2
                  );
                } else {
                  _this.quoteStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    reviewOrder() {
      this.errors = {};
      this.loading = true;
      if (
        this.quantity == 0 ||
        this.quantity.length == 0 ||
        isNaN(this.quantity)
      ) {
        this.errors = {
          quantity: "Quantity must be a valid number that is not zero",
        };
        this.loading = false;
        return;
      }
      var tradeAction = this.action.toUpperCase();
      var sendQuantity = String(this.quantity);
      if (this.orderType == "Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.profitLimit == 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit: "Profit Limit must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss == 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss: "Stop Loss must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Market") {
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop") {
        if (
          this.trailAmount == 0 ||
          this.trailAmount.length == 0 ||
          isNaN(this.trailAmount)
        ) {
          this.errors = {
            trailAmount: "Trail Amount must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop %") {
        if (
          this.trailPercent == 0 ||
          this.trailPercent.length == 0 ||
          isNaN(this.trailPercent)
        ) {
          this.errors = {
            trailPercent:
              "Trailing Stop % must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      }
      if (
        (this.orderType == "Bracket" ||
          this.orderType == "Stop Market" ||
          this.orderType == "Trailing Stop" ||
          this.orderType == "Trailing Stop %" ||
          this.orderType == "Market") &&
        this.duration == "Extended"
      ) {
        this.errors.global =
          "Extended hours orders must be limit orders. Please change order type or duration.";
        this.loading = false;
        return;
      }

      var _this = this;
      this.postOrder({
        final: false,
        symbol: this.symbol,
        quantity: sendQuantity,
        limitPrice: this.limitPrice,
        bracketProfit: this.profitLimit,
        bracketLoss: this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderType: this.orderType,
        orderDuration: this.duration,
        legs: null,
        close: false,
        tradeAction: tradeAction,
        assetType: "CRYPTO",
        accountID: this.wallets[0].AccountID,
        crypto: true,
      })
        .then(function (res) {
          _this.loading = false;
          _this.preview = res.data.Confirmations[0];
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
          }
        });
    },
    submitOrder() {
      this.errors = {};
      this.loading = true;
      var tradeAction = this.action.toUpperCase();
      var sendQuantity = String(this.quantity);

      var _this = this;
      this.postOrder({
        final: true,
        symbol: this.symbol,
        quantity: sendQuantity,
        limitPrice: this.limitPrice,
        bracketProfit: this.profitLimit,
        bracketLoss: this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderType: this.orderType,
        orderDuration: this.duration,
        legs: null,
        close: false,
        tradeAction: tradeAction,
        assetType: "CRYPTO",
        accountID: this.wallets[0].AccountID,
        crypto: true,
      })
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
          } else {
            //_this.submitted = res.data.Orders[0];
            Toast.success(res.data.Orders[0].Message);
            _this.playSound("../../order-placed-updated.mp3", _this.sound);
          }
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
          }
        });
    },
    close() {
      this.$emit("close");
    },
    chart() {
      this.loading = true;
      this.$emit("chart", this.symbol);
    },
    goHome() {
      this.$emit("home");
    },
  },
};
</script>

<style scoped>
.buy-form {
  padding: 10px;
  width: 400px;
  box-sizing: border-box;
}
p {
  margin: 0;
  font-family: "Roboto";
}
.ticker {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
}
.ticker.alt {
  margin-bottom: 0;
}
.input-line {
  margin: auto;
  padding: 20px 0;
  border-bottom: 1px solid #eef0f3;
  max-width: 400px;
}
.dark .input-line {
  border-bottom: 1px solid #333333;
}
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}
.input-line:last-of-type {
  border-bottom: none;
}
.input-line label {
  font-weight: 600;
  font-size: 21px;
}
.input-line select,
.input-line input {
  background: none;
  border: none;
  font-size: 21px;
  color: #10bc74;
  text-align: right;
  font-family: "Roboto";
  font-weight: 600;
  max-width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-line select:focus,
.input-line input:focus {
  outline: none;
}
.input-line option {
  color: #10bc74;
  background: none;
}
.input-line .description {
  color: #616164;
  margin-top: 5px;
  font-size: 16px;
}
.input-line .price-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
}
.input-line .price-select p {
  font-weight: 600;
  color: #616164;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.buttons.done {
  justify-content: flex-end;
}
button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}
.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}
button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}
.dark button.secondary {
  background: #000000;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}
button.blank {
  background: #ffffff;
}
.dark button.blank {
  background: #000000;
}
.loading {
  width: 18px;
  height: 18px;
}
.summary-title {
  margin-top: 21px;
  font-size: 20px;
}
.summary-message {
  font-size: 18px;
  margin-bottom: 20px;
}
.success-body p {
  /* color: #10BC74; */
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.error {
  color: #ce0606;
}
@media only screen and (max-width: 425px) {
  .ticker {
    font-size: 24px;
  }
  .input-line label {
    font-size: 18px;
  }
  .input-line select,
  .input-line input {
    font-size: 18px;
  }
  .input-line .description {
    font-size: 14px;
  }
  button {
    font-size: 16px;
  }
}
</style>
