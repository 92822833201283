<template>
  <div id="app" :class="{ dark: dark }">
    <AppHeader v-if="session" :session="session" :token="token" :login="loggedIn" :tab="tab" :dark="dark"
      @account="accountMenu = true" @signOut="signOut" @home="returnCv" @openTab="openTab" @loginFlow="loginFlow"
      @toggleDark="toggleDark" />

    <div class="loading-main" v-if="!error && ((!haveData && loggedIn) || !session)">
      <img src="./assets/loading.gif" />
      <p>Loading...</p>
    </div>

    <div class="main red error" v-if="error && error != 'noAccount'">
      <p>{{ error }}</p>
    </div>

    <div class="no-account" v-if="error == 'noAccount'">
      <img src="./assets/waitingaccount.jpg" alt="Waiting for Account" />
      <p class="waiting">Your account is still processing. Check back soon!</p>
      <a href="https://www.tradestation.com/contact-us/">Contact Support</a>
    </div>

    <div class="welcome" v-if="!loggedIn && session && !delayedFlowUser">
      <img src="./assets/logo-big.png" class="login-logo" alt="Aries Logo" />
      <div class="card">
        <p class="req">Aries requires a TradeStation account.</p>
        <p class="instructions">Select a trading login:</p>
        <div class="welcome-row">
          <a class="login" :href="liveLink">Trade Live</a>
          <a class="login" :href="simLink">Trade Simulated</a>
        </div>
        <a class="register" href="https://tradestation.pxf.io/c/2974389/1138996/8402" target="_blank">Don't have an
          account yet? <span>Register here.</span></a>
      </div>
      <p class="legal">
        Equities, equities options, and commodity futures products and services
        are offered by TradeStation Securities, Inc. (Member NYSE, FINRA, CME
        and SIPC). TradeStation Securities, Inc.’s SIPC coverage is available
        only for securities, and for cash held in connection with the purchase
        or sale of securities, in equities and equities options accounts.
      </p>
    </div>

    <div>
      <router-view v-if="haveData || delayedFlowUser" class="cv-page" :sound="sound" :byMark="byMark" :account="account"
        :profits="profits" :wallets="cryptoWallets" :accountsList="accountsList" :orders="orders" :eventEmitter="eventEmitter"
        :orderHistory="orderHistory" :positions="positions" :globalPositionsQuotesPrice="globalPositionsQuotesPrice"
        :watchlist="watchlist" :flowData="optionsFlowData" :nextPage="nextPage" :delayedFlowUser="delayedFlowUser"
        :orderExecRoutes="orderExecRoutes" :dark="dark" @open-ticker="openTicker" @changeAccount="changeAccount"
        @viewWatchlist="checkwatch" @loginFlow="loginFlow" @return-cv="returnCv" @update-cv="manualDataRefresh"
        @new-log="newLog" @historicFlow="updateHistoricData" @update-watchlist="getWatchlist(this)" />
    </div>

    <Modal style="z-index: 1000" v-if="accountMenu" @close="accountMenu = false">
      <AccountMenu :byMark="byMark" @toggleMark="toggleMark" :sound="sound" @toggleSound="toggleSound" />
    </Modal>
    <Modal style="z-index: 1000" v-if="zeroDayWarning" @close="readExpiryWarn">
      <ZeroDayWarning @close="readExpiryWarn" />
    </Modal>
    <Modal style="z-index: 1000" v-if="simWarning && haveData" @close="readSimWarn">
      <SimWarning @close="readSimWarn" />
    </Modal>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import Modal from "./components/Modal.vue";
import AccountMenu from "./components/AccountMenu.vue";
import ZeroDayWarning from "./components/ZeroDayWarning.vue";
import SimWarning from "./components/SimWarning.vue";
import Toast from "./components/Toasts.vue";
import Mixins from "./Mixins";

const EventEmitter = require("events");


export default {
  name: "App",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  metaInfo: {
    title: "Aries Trading - Commission Free Options & Equities",
  },
  components: {
    AppHeader,
    Modal,
    AccountMenu,
    ZeroDayWarning,
    SimWarning
  },
  mixins: [Mixins],
  data: function () {
    return {
      tab: "trade",
      flowData: null,
      zeroDayWarning: false,
      simWarning: false,
      loggedHistory: false,
      historicFlowData: null,
      dark: localStorage.dark == "true",
      loggedIn: false,
      delayedFlowUser: false,
      accessToken: null,
      eventEmitter: EventEmitter,
      tokenExpires: null,
      sim: false,
      username: null,
      selectedAccount: null,
      selectedAccountType: "Margin" ,
      haveData: false,
      refreshTimer: null,
      viewWatchlist: false,
      watchlist: [],
      session: null,
      token: null,
      account: null,
      accountsList: null,
      orders: [],
      filledOrders: [],
      orderHistory: [],
      profits: null,
      positions: [],
      byMark: localStorage.byMark == "true",
      sound: localStorage.sound != "false",
      cryptoWallets: [],
      liveLink: null,
      simLink: null,
      accountMenu: false,
      ordersQuoteStartTimer: null,
      streams: {
        orderStream: {
          symbols: "",
          connection: null,
          leftOver: null,
          data: null,
          timeout: null,
        },
        position: {
          connection: null,
          leftOver: null,
          data: null,
          timeout: null,
        },
        openOrderQuotes: {
          symbols: "",
          subscribers: [],
          connection: null,
          leftOver: null,
          data: null,
          timeout: null,
        }
      },
      error: null,
      subTabName: "LIVE_FLOW",
      nextPage: "",
      ticks: 0,
      orderExecRoutes: [],
      lastTimerOrdersHeartbeat: 0,
      lastTimerPositionsHeartbeat: 0,
      lastOrdersUpdate: null,
      lastPositionsUpdate: null,
      lastOrdersHeartbeat: 0,
      lastPositionsHeartbeat: 0,
      requestedOrderPrices: false,
      processedOrders: new Set(),
      positionAndOrderStreamData: null,
      globalStreamedData: "",
      globalPositionsQuotesPrice: new Set(),
      ws: null,
      retryCount: undefined
    };
  },
  beforeMount: function () {

    this.eventEmitter = new EventEmitter();

    if (localStorage.redirect) {
      this.$router.replace(localStorage.redirect);
      localStorage.removeItem("redirect");
    }
  },
  mounted: function () {
    if (this.dark) {
      document.body.classList.add("dark");
    }
    var _this = this;

    // take updates from chart's watchlist
    this.eventEmitter.on("chrtWatchLst", async (watchlist) => {
      let lst = JSON.parse(watchlist);

      lst = lst.map((item) => {
        return {
          symbol: item, 
          companyName: item,
          latestPrice: 0,
          changePercent: 0,
        }
      });
      _this.updateWatchlist(_this, lst);
    });
    
    if (localStorage.session && localStorage.sessionKey) {
      var checkSessionUrl =
        process.env.VUE_APP_SERVER +
        "/user/v3-session?_id=" +
        encodeURIComponent(localStorage.session) +
        "&token=" +
        encodeURIComponent(localStorage.sessionKey);
      this.http
        .get(checkSessionUrl)
        .then(function (res) {
          if (localStorage.lastAccount) {
            _this.selectedAccount = localStorage.lastAccount;
          }
          if (localStorage.selectedAccountType) {
            _this.selectedAccountType = localStorage.selectedAccountType;
          }
          var expireTime = new Date();
          expireTime.setSeconds(expireTime.getSeconds() + 1080);
          _this.session = localStorage.session;
          _this.token = localStorage.sessionKey;
          _this.accessToken = res.data.accessToken;
          localStorage.accessToken = res.data.accessToken;
          _this.sim = res.data.sim;
          localStorage.sim = res.data.sim;
          _this.tokenExpires = expireTime;
          _this.username = res.data.username;
          localStorage.u = res.data.username;
          _this.loggedIn = true;
          _this.newAccess(_this.getAccountList);
          // check with pash for entry point
          if (localStorage.sim == "true" && localStorage.simWarned != "true") {
            _this.simWarning = true;
          }
          if (localStorage.sim == "true" && localStorage.expiryWarn != "true") {
            _this.zeroDayWarning = false;
            _this.checkForWarnings(_this)
          }
          if (location.pathname.indexOf("/flow") == 0) {
            _this.tab = "flow";
            _this.getFlow();
          }
        })
        .catch(function (err) {
          console.log(err);
          if (location.pathname.indexOf("/flow") == 0) {
            _this.tab = "flow";
            _this.delayedFlowUser = true;
            _this.getFlow();
          }
          _this.newKey();
        });
    } else {
      if (location.pathname.indexOf("/flow") == 0) {
        this.tab = "flow";
        this.delayedFlowUser = true;
        this.getFlow();
      }
      _this.newKey();
    }
  },
  watch: {
    filledOrders(a, b) {
      //makes sure toasts don't happen automatically when first order is retrieved
      this.ticks++;
      if (a > b && this.ticks > 1) {
        let newFilledOrders = a.filter((order) => {
          return !b.find((o) => o.OrderID == order.OrderID);
        });
        for (let order of newFilledOrders) {
          Toast.success(
            `Order Filled: ${order.Legs[0].ExecQuantity} ${order.Legs[0].Symbol} @ ${order.FilledPrice}`
          );
        }
        this.$.ctx.playSound("../order-filled.mp3", this.sound);
      }
    },
  },
  computed: {
    optionsFlowData() {
      return this.historicFlowData ? this.historicFlowData : this.flowData;
    },
  },
  methods: {
    openTicker: function (ticker) {
      console.log(ticker)
      this.$router.push({ path: `/trade/${ticker}` });
      // document.getElementsByTagName('html')[0].scrollTop = 0;
    },
    toggleDark(newMode) {
      this.dark = newMode;
      localStorage.dark = newMode;
      if (newMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    },
    toggleMark() {
      if (!this.byMark) {
        this.byMark = true;
      } else {
        this.byMark = false;
      }
      this.$gtag.event(`by_mark_${this.byMark ? "on" : "off"}`);
      localStorage.byMark = this.byMark.toString();
    },
    toggleSound() {
      if (!this.sound) {
        this.sound = true;
      } else {
        this.sound = false;
      }
      this.$gtag.event(`sound_${this.sound ? "on" : "off"}`);
      localStorage.sound = this.sound.toString();
    },
    loginFlow: function () {
      this.tab = "trade";
      this.delayedFlowUser = false;
      localStorage.redirect = "/flow";
      this.$router.push("/");
    },
    returnCv: function () {
      this.$router.push({ path: `/` });
    },
    checkwatch: function (visible) {
      if (this.viewWatchlist == false && visible == true) {
        this.viewWatchlist = visible;
        // this.getData();
      } else {
        this.viewWatchlist = visible;
      }
    },
    makeSession(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    newKey() {
      if (this.$route.path != "/" && this.$route.path != "/flow") {
        localStorage.redirect = this.$route.fullPath;
      }
      this.$gtag.pageview({
        page_title: "Welcome",
        page_location: location.href,
        page_path: "/",
      });
      var newKey = this.makeSession(13);
      var newSession = this.makeSession(21);
      localStorage.session = newSession;
      localStorage.sessionKey = newKey;
      this.session = newSession;
      this.token = newKey;
      this.liveLink = `https://signin.tradestation.com/authorize?response_type=code&client_id=r90riTzbB3d6AeASefmrRbTnAslE0phI&audience=https%3A%2F%2Fapi.tradestation.com&redirect_uri=${encodeURIComponent(
        process.env.VUE_APP_SERVER + "/user/webauth"
      )}&scope=openid%20offline_access%20email%20MarketData%20ReadAccount%20Trade%20Crypto%20OptionSpreads%20Matrix&state=${encodeURIComponent(
        this.session + "|" + this.token
      )}`;
      this.simLink = `https://signin.tradestation.com/authorize?response_type=code&client_id=r90riTzbB3d6AeASefmrRbTnAslE0phI&audience=https%3A%2F%2Fapi.tradestation.com&redirect_uri=${encodeURIComponent(
        process.env.VUE_APP_SERVER + "/user/webauthsim"
      )}&scope=openid%20offline_access%20email%20MarketData%20ReadAccount%20Trade%20Crypto%20OptionSpreads%20Matrix&state=${encodeURIComponent(
        this.session + "|" + this.token
      )}`;
      //check if url is beta.tradearies.com
      if (location.hostname.indexOf("beta") != -1) {
        this.liveLink += encodeURIComponent("|beta");
        this.simLink += encodeURIComponent("|beta");
      }
    },
    removeDuplicates(filterArray) {
      var noDupes = filterArray.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          filterArray.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      return noDupes;
    },
    getAccountList(_this) {
      if (_this.orderExecRoutes.length == 0) {
        _this.getOrderExecutionRoutes();
      }
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) + `brokerage/accounts`,
          { headers: { Authorization: `Bearer ${_this.accessToken}` } }
        )
        .then(function (res) {
          var accountKeys = [];
          var foundAccount = false;
          var selectedAccount = _this.selectedAccount;
          if (
            !res.data.Accounts.find((obj) => obj.AccountID == selectedAccount)
          ) {
            selectedAccount = null;
          }
          if (_this.$route.path.indexOf("/crypto") != -1) {
            for (var a = 0; a < res.data.Accounts.length; a++) {
              if (
                !foundAccount &&
                selectedAccount == null &&
                res.data.Accounts[a].AccountType == "Crypto"
              ) {
                foundAccount = true;
                selectedAccount = res.data.Accounts[a].AccountID;
                _this.account = res.data.Accounts[a];
              }
              if (
                selectedAccount == res.data.Accounts[a].AccountID &&
                _this.account == null
              ) {
                foundAccount = true;
                _this.account = res.data.Accounts[a];
              }
            }
            if (!foundAccount) {
              _this.$router.replace("/");
            }
          }
          for (var i = 0; i < res.data.Accounts.length; i++) {
            if (
              !foundAccount &&
              selectedAccount == null &&
              (res.data.Accounts[i].AccountType == "Margin" ||
                res.data.Accounts[i].AccountType == "Cash")
            ) {
              foundAccount = true;
              selectedAccount = res.data.Accounts[i].AccountID;
              _this.account = res.data.Accounts[i];
            }
            if (
              selectedAccount == res.data.Accounts[i].AccountID &&
              _this.account == null
            ) {
              foundAccount = true;
              _this.account = res.data.Accounts[i];
            }
            accountKeys.push({
              key: res.data.Accounts[i].AccountID,
              name:
                res.data.Accounts[i].Alias ?? res.data.Accounts[i].AccountID,
              type: res.data.Accounts[i].AccountType,
            });
          }
          if (selectedAccount == null) {
            _this.error = "noAccount";
            return;
          }
          _this.selectedAccount = selectedAccount;

          if (_this.$route.path.indexOf("/crypto") == -1) {
            localStorage.lastAccount = selectedAccount;
          }
          _this.accountsList = accountKeys;
          // _this.startTimer(_this);
          _this.refreshData(_this);
          _this.getWatchlist(_this);
          _this.connectWebSocket(_this);
          _this.getOrders(_this);
          _this.getPositions(_this);
        })
        .catch(function (error) {
          console.log(error);
          if (
            error &&
            error.response &&
            error.response.data.StatusCode == 401
          ) {
            _this.newAccess(_this.getAccountList);
          } else if (
            error & error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.error = error.response.data.Message;
          } else {
            _this.error = "Something went wrong... Please try again later.";
          }
        });
    },
    newAccess(func) {
      var _this = this;
      var checkSessionUrl =
        process.env.VUE_APP_SERVER +
        "/web/token?_id=" +
        encodeURIComponent(localStorage.session) +
        "&token=" +
        encodeURIComponent(localStorage.sessionKey);
      this.http
        .get(checkSessionUrl)
        .then(function (res) {
          var expireTime = new Date();
          expireTime.setSeconds(expireTime.getSeconds() + 1080);
          _this.accessToken = res.data.token;
          if (_this.accessToken == "expired") {
            _this.signOut();
          }
          localStorage.accessToken = res.data.token;
          _this.tokenExpires = expireTime;
          if (func) {
            func(_this);
          }
        })
        .catch(function () {
          _this.signOut();
        });
    },
    isFilled(x) {
      if (x.StatusDescription == "Filled") {
        return true;
      } else return false;
    },
    async getBalances(_this) {
      var getType = _this.accountsList.find(
        (obj) => obj.key == _this.selectedAccount
      ).type;
      if (getType == "Crypto") {
        if (_this.$route.path.indexOf("/crypto") == -1) {
          _this.$router.push("/crypto");
        }
        if (_this.cryptoWallets.length == 0) {
          this.http
            .get(
              (localStorage.sim == "true"
                ? process.env.VUE_APP_TS_SIM
                : process.env.VUE_APP_TS) +
              `brokerage/accounts/${this.selectedAccount}/wallets`,
              { headers: { Authorization: `Bearer ${_this.accessToken}` } }
            )
            .then(function (res) {
              _this.cryptoWallets = res.data.Wallets;
              _this.account = _this.accountsList.find(
                (obj) => obj.key == _this.selectedAccount
              );
              _this.haveData = true;
            })
            .catch(function (error) {
              console.log(error);
              // TODO: handle broken session logout
              // console.log(error);
              // if(error && error.response && error.response.data && error.response.data.error){
              //   if(error.response.data.error == "expired"){
              //     _this.signOut();
              //   } else {
              //     _this.error = error.response.data.error;
              //   }
              // }
            });
        } else {
          _this.haveData = true;
        }
      } else {
        _this.cryptoWallets = [];
        this.http
          .get(
            (localStorage.sim == "true"
              ? process.env.VUE_APP_TS_SIM
              : process.env.VUE_APP_TS) +
            `brokerage/accounts/${this.selectedAccount}/balances`,
            { headers: { Authorization: `Bearer ${_this.accessToken}` } }
          )
          .then(function (res) {
            // var rememberCost = _this.account ? _this.account.BalanceDetail['CostOfPositions'] : 0;
            var newAccount = res.data.Balances[0];
            var updateAccount = JSON.parse(JSON.stringify(_this.account));
            if (newAccount.BalanceDetail.UnsettledFunds == undefined) {
              newAccount.BalanceDetail.UnsettledFunds = 0;
            }
            for (const [key, value] of Object.entries(newAccount)) {
              updateAccount[key] = value;
            }
            _this.account = updateAccount;

            if (document.location.pathname == '/' || document.location.pathname == '/flow') {
              document.title = `${_this.selectedAccountType} | $${
                _this.numberWithCommas((parseFloat(_this.account.CashBalance) +
                parseFloat(_this.account.BalanceDetail.UnsettledFunds) +
                parseFloat(_this.account.MarketValue)).toFixed(2))}`
            }
            
            _this.haveData = true;
            // _this.account.BalanceDetail['CostOfPositions'] = rememberCost;
            // _this.startTimer(_this);
            // _this.getWatchlist(_this);
          })
          .catch(function (error) {
            console.log(error);
            // TODO: handle broken session logout
            // console.log(error);
            // if(error && error.response && error.response.data && error.response.data.error){
            //   if(error.response.data.error == "expired"){
            //     _this.signOut();
            //   } else {
            //     _this.error = error.response.data.error;
            //   }
            // }
          });
      }
    },
    groupSpreads(positions) {
      // Create a map to group options positions by root symbol
      const groups = new Map();
      var foundGroups = [];

      // Iterate through the positions array
      positions.forEach((position) => {
        if (position.Symbol.includes(" ")) {
          // This is an options position
          const rootSymbol = position.Symbol.split(" ")[0];
          if (!groups.has(rootSymbol)) {
            // Create a new group object
            const group = {
              Symbol: rootSymbol + "\nSpread",
              PositionType: "group",
              Children: [],
              TodaysProfitLoss: 0,
              AveragePrice: 0,
              UnrealizedProfitLoss: 0,
              UnrealizedProfitLossPercent: 0,
              MarketValue: 0,
              TotalCost: 0,
              Last: "",
              LongShort: "",
              Quantity: ""
            };
            groups.set(rootSymbol, group);
          } else {
            foundGroups.push(rootSymbol);
          }

          // Update the group's properties with relative options positions
          const group = groups.get(rootSymbol);
          group.TodaysProfitLoss += parseFloat(position.TodaysProfitLoss || 0);
          group.AveragePrice += parseFloat(position.AveragePrice || 0);
          group.UnrealizedProfitLoss += parseFloat(position.UnrealizedProfitLoss || 0);
          group.MarketValue += parseFloat(position.MarketValue || 0);
          group.TotalCost += parseFloat(position.TotalCost || 0);

          // Add the options position to the group's Children array
          group.Children.push(position);
        } else {
          // This is not an options position, mark it as "position"
          position.PositionType = "position";
        }
      });

      // Filter groups to only include those with more than one child
      const filteredGroups = [...groups.values()].filter(
        (group) => group.Children.length > 1
      );
      var returnGroups = [...positions, ...filteredGroups];

      returnGroups.forEach((position) => {
        if (position.Symbol.indexOf(" ") != -1 && foundGroups.includes(position.Symbol.split(" ")[0])) {
          position.PositionType = "child";
        }
      });
      // Flatten the filtered groups and add them to positions
      return [...positions, ...filteredGroups];
    },
    async getPositions(_this) {
      // Ensure previous connections and data are cleaned up properly to avoid memory leaks or duplicate data
      if (this.streams.position.connection) {
        this.streams.position.connection?.destroy();
        this.streams.position.connection = null;
      }
      this.streams.position.data = []; // Reset data to an empty array instead of null for consistency
      this.streams.position.leftOver = null;

      // Clear any existing timeout to avoid unnecessary resets
      if (_this.streams.position.timeout) {
        clearTimeout(_this.streams.position.timeout);
      }

      _this.streams.position.timeout = setTimeout(() => _this.restartPositionStream(), 10000)

      const positionUrl = `/v3/brokerage/stream/accounts/${this.selectedAccount}/positions`;
      const options = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: positionUrl,
        headers: { Authorization: `Bearer ${this.accessToken}` },
      };

      const startListening = () => {
        this.stream
          .get(options, async (tsRes) => {
            _this.streams.position.connection = tsRes;
            tsRes.setEncoding("binary");
            tsRes.on("data", (chunk) => {
              clearTimeout(_this.streams.position.timeout);
              _this.streams.position.timeout = setTimeout(() => _this.restartPositionStream(), 10000);
              if (!_this.streams.position || !_this.streams.position.connection) {
                tsRes?.destroy();
                return;
              }
              if(_this.streams.position.leftOver){
                chunk = _this.streams.position.leftOver + chunk;
                _this.streams.position.leftOver = null;
              }
              chunk = chunk.replace(/END/g, "").replace(/\r/g, "");
              const positionsData = chunk.split("\n");
              positionsData.forEach((position) => {
                if (position.trim().endsWith("}")) {
                  try {
                    const snapShot = JSON.parse(position);
                    if (snapShot["Heartbeat"] != null || snapShot["StreamStatus"] != null || snapShot["Error"] != null) {
                      return;
                    }
                    const index = this.streams.position.data.findIndex(data => data.PositionID === snapShot.PositionID);
                    if (snapShot["Deleted"]) {
                      if(index !== -1){
                        this.streams.position.data.splice(index, 1);
                      } else {
                        return;
                      }
                    } else if (index !== -1) {
                      this.streams.position.data[index] = snapShot;
                    } else {
                      this.streams.position.data.push(snapShot);
                    }
                  } catch (e) {
                    _this.streams.position.leftOver = null;
                    console.log(e);
                  }
                } else {
                  _this.streams.position.leftOver = position;
                }
              });
              _this.positions = this.streams.position.data;
              this.eventEmitter.emit("positions", _this.positions);
              _this.checkForWarnings(_this);
            });
          }).on("error", function (err) {
            console.log(err);
          });
      };

      startListening();
    },
    async checkForWarnings(_this) {
      const positionList = _this.positions;
      if (localStorage.expiryWarn != "true") {
       let lastWarningString = localStorage.last0DayWarning ?? "nope";
       let lastWarning= lastWarningString == "nope"
          ? null
          : new Date(localStorage.last0DayWarning);
        var timeNow = new Date();
        if (
          lastWarning == null ||
          (timeNow - lastWarning) / 3600000 >= 20
          ) {
        await positionList?.forEach((position) => {
          if (position["AssetType"] != null && position["AssetType"].indexOf("OPTION") != -1) {
            var optionExpiry = new Date(position["ExpirationDate"]);
            if (
              optionExpiry.getUTCFullYear() == timeNow.getUTCFullYear() &&
              optionExpiry.getUTCMonth() == timeNow.getUTCMonth() &&
              optionExpiry.getUTCDay() == timeNow.getUTCDay()
            ) {
              if (localStorage.expiryWarn != "true" && localStorage.accessToken != null) {
                _this.zeroDayWarning = true;
                localStorage.last0DayWarning = timeNow.toString();
              }
            }
          }
        });
      }
      }
    },
    processNewOrders(newOrders) {
      let _this = this;
      // var totalFetch = 0;
      // var getTickers = [];
      if (newOrders.length > 0) {
        newOrders.forEach((order, i) => {
          var found = false;
          let search = _this.orders.find((obj) => obj.OrderID == order.OrderID);
          // if (_this.orders.length == 0) {
          if (search !== undefined) {
            found = true;
            order.Last = search.Last;
            order.lastPriceText = search.lastPriceText;
          }
          // }
          order?.Legs?.forEach((leg, c) => {
            if (found) {
              newOrders[i].Legs[c].Last = _this.orders.find(
                (obj) => obj.OrderID == order.OrderID
              ).Legs[c].Last;
              newOrders[i].Legs[c].Ask = _this.orders.find(
                (obj) => obj.OrderID == order.OrderID
              ).Legs[c].Ask;
              newOrders[i].Legs[c].Bid = _this.orders.find(
                (obj) => obj.OrderID == order.OrderID
              ).Legs[c].Bid;
            }
          });
        });
        this.orders = newOrders;
        this.orders.forEach((order) => {
          order.Legs?.forEach((leg) => {
            if (
              ["OPN", "ACK", "UCN", "FPR", "DON"].indexOf(order.Status) != -1
            ) {
              this.subQuote(leg);
            }
          });
        });
        _this.filledOrders = _this.orders.filter(_this.isFilled);

        if (_this.profits != null) {
          var updateProfits = [];
          var checkProfits = _this.profits.filter(
            (obj) =>
              [
                "BRO",
                "CAN",
                "EXP",
                "FLL",
                "FLP",
                "OUT",
                "REJ",
                "UCH",
                "TSC",
                "SUS",
              ].indexOf(obj.Status) == -1
          );
          var checkBrackets = _this.orders.filter(
            (obj) =>
              obj.GroupName != null &&
              ["ACK", "FPR", "OPN", "DON"].indexOf(obj.Status) != -1
          );
          var checkUntracked = _this.orders.filter(
            (obj) =>
              ["ACK", "FPR", "OPN", "DON"].indexOf(obj.Status) != -1 &&
              obj.Legs[0].OpenOrClose == "Close" &&
              _this.profits.find((pr) => pr.OrderID == obj.OrderID) == null
          );
          if (
            checkProfits.length > 0 ||
            checkBrackets.length > 0 ||
            checkUntracked.length > 0
          ) {
            checkProfits.forEach((profitLine) => {
              var findOrder = _this.orders.find(
                (obj) => obj["OrderID"] == profitLine["OrderID"]
              );
              if (findOrder == null && _this.orderHistory != null) {
                findOrder = _this.orderHistory.find(
                  (obj) => obj["OrderID"] == profitLine["OrderID"]
                );
              }
              if (
                findOrder != null &&
                [
                  "BRO",
                  "CAN",
                  "EXP",
                  "FLL",
                  "FLP",
                  "OUT",
                  "REJ",
                  "UCH",
                  "TSC",
                  "SUS",
                ].indexOf(findOrder["Status"]) != -1
              ) {
                updateProfits.push({
                  OrderID: findOrder["OrderID"],
                  AverageCost: profitLine["AverageCost"],
                  Status: findOrder["Status"],
                  Order: findOrder,
                });
              }
            });
            checkBrackets.forEach((bracketOrder) => {
              var parentOrder;
              if (
                bracketOrder.Legs[0].OpenOrClose == "Close" &&
                _this.profits.find(
                  (obj) => obj.OrderID == bracketOrder.OrderID
                ) == null
              ) {
                bracketOrder.ConditionalOrders.forEach((sibling) => {
                  if (sibling.Relationship == "OSP") {
                    parentOrder = sibling.OrderID;
                  }
                });
                var findOpen = _this.orders.find(
                  (obj) =>
                    obj["OrderID"] == parentOrder && obj["Status"] == "FLL"
                );
                if (findOpen == null && _this.orderHistory != null) {
                  findOpen = _this.orderHistory.find(
                    (obj) =>
                      obj["OrderID"] == parentOrder && obj["Status"] == "FLL"
                  );
                }
                if (findOpen != null) {
                  updateProfits.push({
                    OrderID: bracketOrder["OrderID"],
                    AverageCost: findOpen["FilledPrice"],
                    Status: "Pending",
                    Order: bracketOrder,
                    New: true,
                  });
                }
              }
            });
            checkUntracked.forEach((freshOrder) => {
              var totalAverage = 0.0;
              var broken = false;
              var gcdFound = null;
              freshOrder.Legs.forEach((leg) => {
                var findPosition = _this.positions.find(
                  (pos) => pos.Symbol == leg.Symbol
                );
                if (findPosition != null) {
                  if (gcdFound == null) {
                    gcdFound = parseInt(leg.QuantityOrdered);
                  } else {
                    gcdFound = _this.getGcd(
                      gcdFound,
                      parseInt(leg.QuantityOrdered)
                    );
                  }
                } else {
                  broken = true;
                }
              });
              if (!broken) {
                freshOrder.Legs.forEach((leg) => {
                  var findPosition = _this.positions.find(
                    (pos) => pos.Symbol == leg.Symbol
                  );
                  if (findPosition.LongShort == "Long") {
                    totalAverage +=
                      (parseFloat(findPosition.AveragePrice) *
                        parseInt(leg.QuantityOrdered)) /
                      gcdFound;
                  } else {
                    totalAverage -=
                      (parseFloat(findPosition.AveragePrice) *
                        parseInt(leg.QuantityOrdered)) /
                      gcdFound;
                  }
                });
                updateProfits.push({
                  OrderID: freshOrder["OrderID"],
                  AverageCost: totalAverage.toString(),
                  Status: "Pending",
                  Order: freshOrder,
                  New: true,
                });
              }
            });
            if (updateProfits.length > 0) {
              var updateProfitsBody = {
                session: localStorage.session,
                token: localStorage.sessionKey,
                trades: updateProfits,
              };
              _this.updatePL(updateProfitsBody).then((res) => {
                if (res.status == 200) {
                  _this.profits = res.data.profits;
                }
              });
            }
          }
        }
      } else {
        _this.orders = [];
      }
    },
    restartOrdersQuotesStream() {
      this.streams.openOrderQuotes.connection?.destroy();
      this.streams.openOrderQuotes.connection = null;
      this.streams.openOrderQuotes.data = [];
      this.streams.openOrderQuotes.leftOver = null;
      clearTimeout(this.streams.openOrderQuotes.timeout);
      this.streams.openOrderQuotes.timeout = null;
      this.openOrderQuoteStream(this);
    },
    restartPositionStream() {
      this.streams.position.connection?.destroy();
      this.streams.position.connection = null;
      this.streams.position.data = [];
      this.streams.position.leftOver = null;
      clearTimeout(this.streams.position.timeout);
      this.streams.position.timeout = null;
      this.getPositions(this);
    },
    restartOrderStream() {
      this.streams.orderStream.connection?.destroy();
      this.streams.orderStream.connection = null;
      this.streams.orderStream.data = [];
      this.streams.orderStream.symbols = "";
      this.streams.orderStream.leftOver = null;
      clearTimeout(this.streams.orderStream.timeout);
      this.streams.orderStream.timeout = null;
      this.getOrders(this);
    },
    async getOrders(_this) {
      var today = new Date();
      today.setDate(today.getDate() - 14);
      var sinceString =
        today.getFullYear().toString() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0");

      if (_this.streams.orderStream.connection) {
        _this.streams.orderStream.connection?.destroy();
        _this.streams.orderStream.connection = null;
      }
      _this.streams.orderStream.data = [];
      _this.streams.orderStream.leftOver = null;

      if (_this.streams.orderStream.timeout) {
        clearTimeout(_this.streams.orderStream.timeout);
      }
      _this.streams.orderStream.timeout = setTimeout(() => _this.restartOrderStream(), 10000);

      const ordersUrl = `/v3/brokerage/stream/accounts/${this.selectedAccount}/orders`;
      const options = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: ordersUrl,
        headers: { Authorization: `Bearer ${this.accessToken}` },
      };

      const startListening = () => {
        this.stream
          .get(options, async (tsRes) => {
            _this.streams.orderStream.connection = tsRes;
            tsRes.setEncoding("binary");
            tsRes.on("data", (chunk) => {
              clearTimeout(_this.streams.orderStream.timeout);
              _this.streams.orderStream.timeout = setTimeout(() => _this.restartOrderStream(), 10000);
              if (!_this.streams.orderStream.connection){
                tsRes?.destroy();
                return;
              }
              if (_this.streams.orderStream.leftOver) {
                chunk = _this.streams.orderStream.leftOver + chunk;
                _this.streams.orderStream.leftOver = null;
              }
              chunk = chunk.replace(/END/g, "").replace(/\r/g, "");
              const ordersData = chunk.split("\n");
              var existingOrders = JSON.parse(JSON.stringify(_this.orders));
              let orderUpdates = [];
              ordersData.forEach((order) => {
                if (order.trim().endsWith("}")) {
                  try {
                    const snapShot = JSON.parse(order);
                    if (snapShot["Heartbeat"] != null || snapShot["StreamStatus"] != null || snapShot["Error"] != null) {
                      return;
                    }
                    orderUpdates.push(snapShot);
                    const index = existingOrders.findIndex(data => data.OrderID === snapShot.OrderID);
                    if(index !== -1){
                      existingOrders[index] = snapShot;
                    } else {
                      existingOrders.unshift(snapShot);
                    }
                  } catch (e) {
                    console.log(e);
                    return;
                  }
                } else {
                  _this.streams.orderStream.leftOver = order;
                }
              });

              // emits updated orders for chart library
              this.eventEmitter.emit("orders",orderUpdates);
              this.processNewOrders(existingOrders);
            });
          })
          .on("error", function (err) {
            console.log(err)
          });
      };

      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `brokerage/accounts/${this.selectedAccount}/orders`,
          { headers: { Authorization: `Bearer ${_this.accessToken}` } }
        )
        .then(async function (res) {
          _this.orders = res.data.Orders;
          startListening();
        })
        .catch(function (error) {
          console.log(error);
          startListening();
        });

      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `brokerage/accounts/${this.selectedAccount}/historicalorders?since=${sinceString}`,
          { headers: { Authorization: `Bearer ${_this.accessToken}` } }
        )
        .then(async function (res) {
          _this.orderHistory = res.data.Orders;
        })
        .catch(function (error) {
          console.log(error);
          // TODO: handle errors
        });
    },
    updateOrderList({ orders, order }) {
      const orderIndex = orders.findIndex(
        (element) => element["OrderID"] === order["OrderID"]
      );
      if (orderIndex !== -1) {
        orders[orderIndex] = order;
      } else {
        orders.push(order);
      }
      return orders;
    },
    async getWatchlist(_this) {
      var checkSessionUrl =
        process.env.VUE_APP_SERVER +
        "/web/watchlist?_id=" +
        encodeURIComponent(localStorage.session) +
        "&token=" +
        encodeURIComponent(localStorage.sessionKey);
      this.http
        .get(checkSessionUrl)
        .then(function (res) {
          _this.updateWatchlist(_this, res.data.watchlist);
        })
        .catch(function () {
          _this.watchlist = [];
        });
    },
    updateWatchlist(_this, newWatchlist) {
      let hasChanged = newWatchlist?.length !== _this.watchlist?.length;

      var newListCompare = "";
      var oldListCompare = "";

      newWatchlist.forEach((newItem) => {
        newListCompare += newItem.symbol;
      });
      _this.watchlist?.forEach((oldItem) => {
        oldListCompare += oldItem.symbol;
      });
      if (newListCompare != oldListCompare || hasChanged) {
        _this.watchlist = newWatchlist;
      }
    },
    connectWebSocket(_this) {
      if (typeof _this.retryCount === 'undefined') {
      _this.retryCount = 0;
      }
      if (_this.ws) {
        _this.ws.close();
      }
      _this.ws = new WebSocket(`wss://server.tradearies.com/ws?_id=${encodeURIComponent(localStorage.session)}&token=${encodeURIComponent(localStorage.sessionKey)}`);
      _this.ws.onmessage = (event) => {
        const messageData = JSON.parse(event.data)
        if (messageData.type == "watchlist") {
          if(messageData.data===null){messageData.data=[]}
          _this.updateWatchlist(_this, messageData.data);
        }
      };
      _this.ws.onerror = (error) => {
        console.error('WebSocket error', error);
        _this.retryCount++;
        _this.ws.close()
        if (_this.retryCount == 5) {
          console.error('Failed to connect after 5 attempts');
        }
      };
      _this.ws.onclose = () => {
        if (_this.retryCount < 5) {
          setTimeout(() => {
            _this.connectWebSocket(_this)
          }, 5000);
        }
        console.log('WebSocket connection closed');
      };
    },
    getOrderExecutionRoutes() {
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) + `orderexecution/routes`,
          { headers: { Authorization: `Bearer ${this.accessToken}` } }
        )
        .then((res) => {
          this.orderExecRoutes = res.data.Routes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newLog(profits) {
      this.profits = profits;
    },
    manualDataRefresh() {
      let _this = this;
      _this.refreshData(_this);
    },
    refreshData(_this) {
      var timeNow = new Date();
      // if(_this.positionAndOrderStreamData!==null)
      // clearInterval(_this.positionAndOrderStreamData);

      if (_this.tokenExpires < timeNow || localStorage.testMe == "hola") {
        localStorage.testMe = "nope";
        _this.newAccess(_this.refreshData);
        _this.restartOrderStream();
        _this.restartPositionStream();
      } else {
        _this.getBalances(_this);

        _this.getPL().then((res) => {
          if (res.status == 200) {
            _this.profits = res.data.profits ?? [];
          }
        });
        if (_this.loggedIn) {
          if (_this.refreshTimer) {
            clearTimeout(_this.refreshTimer);
          }
          _this.refreshTimer = setTimeout(function () {
            _this.refreshData(_this);
          }, 5000);
        }
      }
    },
    subQuote(sub){
      if(this.streams.openOrderQuotes.subscribers.findIndex(obj => obj.Symbol == sub.Symbol) == -1){
        this.streams.openOrderQuotes.subscribers.push(JSON.parse(JSON.stringify(sub)));
        if(this.ordersQuoteStartTimer == null){
          var _this = this;
          this.ordersQuoteStartTimer = setTimeout(() => {
            _this.restartOrdersQuotesStream();
            clearTimeout(_this.ordersQuoteStartTimer);
            _this.ordersQuoteStartTimer = null;
          }, 2000);
        }
      }
    },
    openOrderQuoteStream(_this){
      // Ensure previous connections and data are cleaned up properly to avoid memory leaks or duplicate data
      if (this.streams.openOrderQuotes.connection) {
        this.streams.openOrderQuotes.connection?.destroy();
        this.streams.openOrderQuotes.connection = null;
      }
      this.streams.openOrderQuotes.data = []; // Reset data to an empty array instead of null for consistency
      this.streams.openOrderQuotes.leftOver = null;

      // Clear any existing timeout to avoid unnecessary resets
      if (_this.streams.openOrderQuotes.timeout) {
        clearTimeout(_this.streams.openOrderQuotes.timeout);
      }

      _this.streams.openOrderQuotes.timeout = setTimeout(() => _this.restartOrdersQuotesStream(), 10000);
      
      var symbolString = "";
      _this.streams.openOrderQuotes.subscribers.forEach(sub => {
        if(symbolString != ""){
          symbolString += ",";
        }
        symbolString += sub.Symbol;
      });
      const quotesUrl = `/v3/marketdata/stream/quotes/${symbolString}`;
      const options = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: quotesUrl,
        headers: { Authorization: `Bearer ${this.accessToken}` },
      };

      const startListening = () => {
        this.stream
          .get(options, async (tsRes) => {
            _this.streams.openOrderQuotes.connection = tsRes;
            tsRes.setEncoding("binary");
            tsRes.on("data", (chunk) => {
              clearTimeout(_this.streams.openOrderQuotes.timeout);
              _this.streams.openOrderQuotes.timeout = setTimeout(() => _this.restartOrdersQuotesStream(), 10000);
              if (!_this.streams.openOrderQuotes || !_this.streams.openOrderQuotes.connection) {
                tsRes?.destroy();
                return;
              }
              if(_this.streams.openOrderQuotes.leftOver){
                chunk = _this.streams.openOrderQuotes.leftOver + chunk;
                _this.streams.openOrderQuotes.leftOver = null;
              }
              chunk = chunk.replace(/END/g, "").replace(/\r/g, "");
              const quotesData = chunk.split("\n");
              quotesData.forEach((quote) => {
                if (quote.trim().endsWith("}")) {
                  try {
                    const snapShot = JSON.parse(quote);
                    _this.newOrderData(snapShot);
                  } catch (e) {
                    _this.streams.openOrderQuotes.leftOver = null;
                    console.log(e);
                  }
                } else {
                  _this.streams.openOrderQuotes.leftOver = quote;
                }
              });
              
            });
          }).on("error", function (err) {
            console.log(err);
          });
      };
      startListening();
    },
    startStream(stream, symbols) {
      if (symbols == undefined || symbols == "" || symbols == null) {
        return;
      }
      if (this.requestedOrderPrices) {
        // Start streaming logic
        this.requestedOrderPrices = true; // Set streaming status to true
      }
      try {
        this.requestedOrderPrices = false;
        var _this = this;
        if (this.streams[stream].connection) {
          this.streams[stream].connection.removeAllListeners();
        }
        this.streams[stream].data = null;
        this.streams[stream].leftOver = null;
        if (_this.streams[stream].timeout) {
          clearTimeout(_this.streams[stream].timeout);
        }
        this.streams[stream] = {
          symbols: symbols,
          connection: null,
          leftOver: null,
          data: null,
          timeout: setTimeout(function () {
            _this.streams[stream] = {
              symbols: "",
              connection: null,
              leftOver: null,
              data: null,
              timeout: null,
            };
          }, 10000),
        };
        var options = {
          hostname:
            localStorage.sim == "true"
              ? "sim-api.tradestation.com"
              : "api.tradestation.com",
          path: `/v3/marketdata/stream/quotes/${symbols}`,
          headers: { Authorization: `Bearer ${_this.accessToken}` },
        };
        this.streams[stream].connection = this.stream
          .get(options, async (tsRes) => {
            tsRes.setEncoding("binary");
            await tsRes.on("data", (chunk) => {
              try {
                if (_this.streams[stream].timeout) {
                  clearTimeout(_this.streams[stream].timeout);
                }
                _this.streams[stream].timeout = setTimeout(function () {
                  _this.streams[stream] = {
                    symbols: "",
                    connection: null,
                    leftOver: null,
                    data: null,
                    timeout: null,
                  };
                }, 10000);
                if (
                  !_this.streams[stream] ||
                  !this.streams[stream].connection
                ) {
                  tsRes?.destroy();
                  return;
                }
                if (chunk.indexOf("END") != -1) {
                  clearTimeout(_this.streams[stream].timeout);
                }
                chunk = chunk.replace(/END/g, "");
                chunk = chunk.replace(/\r/g, "");
                if (_this.streams[stream].leftOver != null) {
                  chunk = _this.streams[stream].leftOver + chunk;
                  _this.streams[stream].leftOver = null;
                }
                var quotes = chunk.split("\n");
                for (var a = 0; a < quotes.length; a++) {
                  if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                    var snapShot = JSON.parse(quotes[a]);

                    if (snapShot["Heartbeat"] != null) {
                      return;
                    }
                    if (!_this.streams[stream].data) {
                      _this.streams[stream].data = [snapShot];
                      _this.newData(stream, snapShot);
                    } else if (
                      _this.streams[stream].data.find(
                        (obj) => obj["Symbol"] == snapShot["Symbol"]
                      )
                    ) {
                      _this.streams[stream].data.forEach((dataItem, index) => {
                        let shouldUpdate = dataItem["Symbol"] === snapShot["Symbol"];
                        if (shouldUpdate) {
                          Object.entries(snapShot).forEach(([key, value]) => {
                            _this.streams[stream].data[index][key] = value;
                          });
                        }
                        _this.newData(stream, _this.streams[stream].data[index]);
                      });
                    } else {
                      _this.streams[stream].data.push(snapShot);
                      _this.newData(stream, snapShot);
                    }
                  } else {
                    _this.streams[stream].leftOver = quotes[a];
                  }
                }
              } catch (e) {
                if (_this.streams[stream].timeout) {
                  clearTimeout(_this.streams[stream].timeout);
                }
                _this.streams[stream] = {
                  symbols: "",
                  connection: null,
                  leftOver: null,
                  data: null,
                  timeout: null,
                };
                console.log(e);
              }
            });
          })
          .on("error", function (err) {
            if (_this.streams[stream].timeout) {
              clearTimeout(_this.streams[stream].timeout);
            }
            _this.streams[stream] = {
              symbols: "",
              connection: null,
              leftOver: null,
              data: null,
              timeout: null,
            };
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    },
    newOrderData(streamObj) {
      var found = false;
      var orderList = JSON.parse(JSON.stringify(this.orders));
      orderList.forEach((order, d) => {
        if (["OPN", "ACK", "UCN", "FPR", "DON"].indexOf(order.Status) != -1) {
          var totalLast = 0;
          var gcdFound = null;
          var gcdFailed = false;
          var loaded = true;
          order.Legs.forEach((leg, e) => {
            if (leg["QuantityOrdered"] == null) {
              gcdFailed = true;
            } else if (gcdFound == null) {
              gcdFound = parseInt(leg["QuantityOrdered"]);
            } else {
              gcdFound = this.getGcd(
                gcdFound,
                parseInt(leg["QuantityOrdered"])
              );
            }

            if (leg.Symbol == streamObj.Symbol) {
              found = true;
              if (streamObj.Last != null) {
                orderList[d].Legs[e].Last = streamObj.Last;
              }
              if (streamObj.Ask != null) {
                orderList[d].Legs[e].Ask = streamObj.Ask;
              }
              if (streamObj.Bid != null) {
                orderList[d].Legs[e].Bid = streamObj.Bid;
              }
            }
            if (orderList[d].Legs[e].Last == null || gcdFailed) {
              loaded = false;
            }
          });
          if (loaded) {
            order.Legs.forEach((leg) => {
              if (order.Legs.length == 1 || leg.buyOrSell == "Buy") {
                totalLast +=
                  (parseFloat(leg.Last) *
                    parseFloat(leg.QuantityOrdered || "0")) /
                  gcdFound;
              } else {
                totalLast -=
                  (parseFloat(leg.Last) *
                    parseFloat(leg.QuantityOrdered || "0")) /
                  gcdFound;
              }
            });
            orderList[d].Last = totalLast;
            orderList[d].LastPriceText = "$" + totalLast.toFixed(2);
          }
        }
      });
      if(!found){
        //remove symbol from subscribers
        var index = this.streams.openOrderQuotes.subscribers.findIndex(obj => obj.Symbol == streamObj.Symbol);
        if(index !== -1){
          this.streams.openOrderQuotes.subscribers.splice(index, 1);
        }
      }
      this.orders = orderList;
    },
    getFlow() {
      var _this = this;
      if (this.tab == "flow") {
        var flowUrl =
          process.env.VUE_APP_FLOW +
          `/flow?ticker=universal&_id=` +
          encodeURIComponent(localStorage.session) +
          "&token=" +
          encodeURIComponent(localStorage.sessionKey);
        if (this.flowData && this.flowData.length > 0) {
          flowUrl += `&last=${encodeURIComponent(this.flowData[0].timestamp)}`;
        }
        this.http
          .get(flowUrl)
          .then(function (res) {
            if (res.data.last) {
              var noRepeat = _this.removeDuplicates(
                res.data.flow.filter(
                  (row) =>
                    new Date(row.timestamp) >
                    new Date(_this.flowData[0].timestamp)
                )
              );
              _this.flowData = noRepeat.concat(_this.flowData).splice(0, 600);
            } else {
              _this.flowData = _this
                .removeDuplicates(res.data.flow)
                .splice(0, 600);
            }
            setTimeout(function () {
              if (
                (_this.loggedIn || _this.delayedFlowUser) &&
                _this.subTabName == "LIVE_FLOW"
              ) {
                _this.getFlow();
              }
            }, 3000);
          })
          .catch(function (error) {
            console.log(error);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              _this.error = error.response.data.error;
            } else {
              _this.error = "Something went wrong... Please try again later";
            }
            setTimeout(function () {
              if (
                (_this.loggedIn || _this.delayedFlowUser) &&
                this.subTabName == "LIVE_FLOW"
              ) {
                _this.getFlow();
              }
            }, 3000);
          });
      }
    },
    killStreams(){
      this.streams.position.connection?.destroy();
      this.streams.position.connection = null;
      this.streams.position.data = [];
      this.streams.position.leftOver = null;
      clearTimeout(this.streams.position.timeout);
      this.streams.position.timeout = null;
      this.streams.orderStream.connection?.destroy();
      this.streams.orderStream.connection = null;
      this.streams.orderStream.data = [];
      this.streams.orderStream.symbols = "";
      this.streams.orderStream.leftOver = null;
      clearTimeout(this.streams.orderStream.timeout);
      this.streams.orderStream.timeout = null;
      this.streams.openOrderQuotes.connection?.destroy();
      this.streams.openOrderQuotes.connection = null;
      this.streams.openOrderQuotes.data = [];
      this.streams.openOrderQuotes.subscribers = [];
      this.streams.openOrderQuotes.leftOver = null;
      clearTimeout(this.streams.openOrderQuotes.timeout);
      this.streams.openOrderQuotes.timeout = null;
    },
    changeAccount(account) {
      this.ticks = 0; //kills order filled notifications when orders were filled that day
      this.killStreams();
      localStorage.lastAccount = account.key;
      if (account.type == "Crypto") {
        this.$router.push("/crypto");
      } else {
        this.$router.push("/");
      }
      this.haveData = false;
      var _this = this;
      _this.selectedAccount = account.key;
      _this.selectedAccountType = account.type;
      localStorage.setItem('selectedAccountType',account.type);
      _this.getBalances(_this);
      _this.getOrders(_this);
      _this.getPositions(_this);
      this.$gtag.event("account_switch", { type: account.type });
    },
    openTab(newTab) {
      if (newTab == this.tab) {
        return;
      } else if (newTab == "trade") {
        this.tab = "trade";
        this.$router.push("/");
      } else if (newTab == "flow") {
        this.tab = "flow";
        this.$router.push("/flow");
        this.getFlow();
      } else if (newTab.includes("trade")) {
        this.tab = "trade";
      }
    },
    signOut() {
      localStorage.clear();
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
      this.ws.close()
      this.tab = "trade";
      this.loggedIn = false;
      this.haveData = false;
      this.account = null;
      this.positions = null;
      this.orders = null;
      this.killStreams();
      this.newKey();
      // localStorage.expiryWarn = "false";
      if (localStorage.expiryWarn != "true") {
        this.zeroDayWarning = false;
        // localStorage.last0DayWarning = "null";
      }
    },
    updateHistoricData(startDate, endDate, pageId, search) {
      if (startDate && endDate) {
        this.subTabName = "HISTORIC_FLOW";
        this.flowData = null;
        const ticker = search ? search : "universal";
        const pageQueryParam = pageId ? `&nextPage=${pageId}` : "";

        const url = `${process.env.VUE_APP_FLOW}/flow/history?ticker=${ticker}&startDate=${startDate}&endDate=${endDate}${pageQueryParam}`;
        this.http
          .get(url)
          .then((response) => {
            this.historicFlowData = response.data.flow;
            // to avoid conflicts from previous tokens
            this.nextPage =
              response.data.nextPage +
              "$latest" +
              Math.floor(Math.random() * 1001);
          })
          .catch((error) => console.log(error));
      } else {
        this.subTabName = "LIVE_FLOW";
        this.historicFlowData = null;
        this.getFlow();
      }
    },
    readSimWarn() {
      this.simWarning = false
      localStorage.simWarned = "true";
    },
    readExpiryWarn() {
      this.zeroDayWarning = false
      localStorage.expiryWarn = "true";
    }
  },
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Outfit/Outfit-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Outfit/Outfit-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Outfit/Outfit-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Outfit/Outfit-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Roboto";
  background: #F7F9FB;
}

body.dark {
  background: #000000;
}

#app *::-webkit-scrollbar {
  display: none;
}

.ps {
  height: 100vh;
}

#app>.loading-main img {
  margin-top: 100px;
  width: 100px;
  height: 100px;
}

#app>.loading-main p {
  margin-top: 10px;
  font-size: 24px;
}

* {
  font-family: "Roboto";
}

.red {
  color: #ce0606;
}

.green {
  color: #10bc74;
}

#app {
  background-color: #F7F9FB;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100%;
  /* min-width: 1440px; */
}

#app.dark {
  background-color: #000000;
  color: #d9d9d9;
  background-image: none;
}

.welcome {
  background: #2f1e5f;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.welcome .login-logo {
  margin-bottom: 30px;
}

.welcome .card {
  width: 400px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #eceff2;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 15px 30px RGBA(0, 0, 0, 0.08);
}

.dark .welcome .card {
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
}

.welcome p {
  text-align: center;
}

.welcome .req {
  font-size: 24px;
  margin-top: 20px;
}

.welcome .instructions {
  margin-top: 60px;
  text-align: center;
  color: #909194;
}

.welcome .welcome-row {
  width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 90px;
}

.welcome .welcome-row .login {
  text-decoration: none;
  color: #10bc74;
  font-weight: 500;
  font-size: 21px;
}

.welcome .register {
  color: #000000;
  display: block;
  text-decoration: none;
  font-size: 16px;
}

.welcome .register span {
  color: #10bc74;
  display: block;
}

.welcome .legal {
  color: #aaa;
  font-size: 12px;
  width: 400px;
  max-width: 100%;
  text-align: center;
  margin-top: 20px;
}

.no-account img {
  width: 200px;
  margin-top: 100px;
}

.no-account a {
  text-decoration: none;
  color: #10bc74;
}

.main.error {
  margin-top: 100px;
}

.hidden {
  visibility: hidden;
  display: none;
}

.Vue-Toastification__toast--default {
  background-color: #2f1e5f;
}

.Vue-Toastification__toast--info {
  background-color: #2f1e5f;
}

.Vue-Toastification__toast--success {
  background-color: #10bc74;
}

.Vue-Toastification__toast--error {
  background-color: #ce0606;
}

.Vue-Toastification__toast--warning {
  background-color: #ce0606;
}

.aries-red {
  background-color: #ce0606;
}

.clickable {
  cursor: pointer;
}
</style>
