<template>
  <div class="analyst-details">
    <div
      class="rating-detail"
      v-for="(rating, index) in ratings"
      :key="`rating${index}`"
    >
      <p class="time">Updated {{ rating.date }} @ {{ rating.time }}</p>
      <p class="analyst">{{ rating.analyst }}, {{ rating.analyst_name }}</p>
      <p class="action">
        {{ rating.action_pt }} price target. {{ rating.action_company }} rating.
      </p>
      <p class="rating">Rating: {{ rating.rating_current }}</p>
      <p class="target">Price Target: {{ rating.pt_current }}</p>
      <!-- <p>Prior Rating: {{rating.rating_prior}}</p> -->
      <p class="prior">Prior: {{ rating.pt_prior }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalystDetails",
  props: {
    ratings: Array,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
.analyst-details {
  padding: 10px;
}
.rating-detail {
  border-bottom: #616164 1px solid;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
p {
  margin: 0;
}
.time {
  font-size: 12px;
  color: #616164;
}
.analyst {
  font-size: 16px;
  font-weight: 500;
}
.action {
  font-size: 14px;
  margin-bottom: 7px;
}
.rating,
.target {
  font-size: 18px;
  text-align: right;
}
.target {
  margin-bottom: 7px;
}
.prior {
  font-size: 14px;
  text-align: right;
  color: #616164;
}
</style>
