<template>
  <div class="options-activity-chart">
    <div class="legend">
      <p>{{ date }} Contracts</p>
      <div class="green-dot dot"></div>
      <p>Calls</p>
      <div class="purple-dot dot"></div>
      <p>Puts</p>
    </div>
    <GChart type="ColumnChart" :data="options" :options="chartOptions" />
  </div>
</template>

<script>
import { GChart } from "vue3-googl-chart";

export default {
  name: "OptionsActivityChart",
  props: {
    options: Array,
    date: String,
    dark: Boolean,
  },
  components: {
    GChart,
  },
  data() {
    return {
      ready: false,
      chartOptions: {
        colors: ["#10BC74", "#5831C7"],
        fontName: "Roboto",
        fontSize: 16,
        height: 500,
        width: "100%",
        hAxis: {
          title: "Strike",
          titleTextStyle: {
            bold: true,
            italic: false,
          },
        },
        vAxis: {
          gridlines: { color: "#fff" },
          minorGridlines: { color: "#fff" },
          baselineColor: "#fff",
        },
        legend: { position: "none" },
        chartArea: { top: 35, bottom: 50, right: 0, left: 100 },
        bar: {
          groupWidth: 25,
        },
      },
    };
  },
  methods: {},
  mounted() {
    if (this.dark) {
      this.chartOptions.vAxis = {
        textStyle: { color: "#d9d9d9" },
        gridlines: { color: "#1F2324" },
        minorGridlines: { color: "#1F2324" },
        baselineColor: "#1F2324",
      };
      this.chartOptions.hAxis = {
        title: "Strike",
        textStyle: { color: "#d9d9d9" },
        titleTextStyle: {
          bold: true,
          italic: false,
          color: "#d9d9d9",
        },
      };
      this.chartOptions.backgroundColor = "#1F2324";
    }
  },
};
</script>

<style scoped>
.options-activity-chart {
  width: 100%;
  font-family: "Roboto";
  text-align: left;
}
.legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
p {
  margin: 0;
  margin-right: 13px;
  font-size: 16px;
  font-weight: 500;
}
.dot {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  margin-right: 7px;
}
.green-dot {
  background: #10bc74;
}
.purple-dot {
  background: #5831c7;
}
</style>
