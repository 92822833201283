<template>
  <div class="crypto">
    <div class="account-list">
      <HeadLink class="crypto-help" title="Help" @on-click="help = true" />
      <HeadLink
        v-for="availableAccount in accountsList"
        :active="availableAccount.key == account.key"
        :key="availableAccount.key"
        :title="availableAccount.type"
        @on-click="changeAccount(availableAccount)"
      />
    </div>

    <h2 v-if="walletBalances.length > 0">Coins</h2>
    <div class="loading-crypto" v-if="walletBalances.length == 0">
      <img src="../assets/loading.gif" />
      <p>Loading...</p>
    </div>
    <div class="wallets" v-if="walletBalances.length > 0">
      <Coin
        :dark="dark"
        :coin="wallet"
        @openCrypto="walletClick"
        v-for="(wallet, index) in walletBalances
          .slice()
          .sort((a, b) =>
            parseFloat(a.BalanceAccountCurrency) <
            parseFloat(b.BalanceAccountCurrency)
              ? 1
              : parseFloat(b.BalanceAccountCurrency) <
                parseFloat(a.BalanceAccountCurrency)
              ? -1
              : 0
          )"
        :key="`wallet${index}`"
      />
    </div>
    <div class="totals" v-if="walletBalances.length > 0">
      <div class="stat-house" v-if="accountTotals">
        <h2>Account Totals</h2>
        <KeyValue
          first
          key-string="Accrued Interest"
          :value="
            '$' + dollarWithCommas(accountTotals.AccruedInterestAccountCurrency)
          "
        />
        <KeyValue
          key-string="Total Balance"
          :value="'$' + dollarWithCommas(accountTotals.BalanceAccountCurrency)"
        />
        <KeyValue
          key-string="Total Available for Trading"
          :value="
            '$' +
            dollarWithCommas(
              accountTotals.BalanceAvailableForTradingAccountCurrency
            )
          "
        />
        <KeyValue
          key-string="Total Available for Withdrawal"
          :value="
            '$' +
            dollarWithCommas(
              accountTotals.BalanceAvailableForWithdrawalAccountCurrency
            )
          "
        />
        <KeyValue
          key-string="Total Available for Withdrawal"
          :value="
            '$' +
            dollarWithCommas(
              accountTotals.BalanceAvailableForWithdrawalAccountCurrency
            )
          "
        />
        <KeyValue
          key-string="Total Paid Interest"
          :value="
            '$' +
            dollarWithCommas(accountTotals.TotalPaidInterestAccountCurrency)
          "
        />
        <KeyValue
          last
          key-string="YTD Paid Interest"
          :value="
            '$' + dollarWithCommas(accountTotals.YTDPaidInterestAccountCurrency)
          "
        />
      </div>
      <div class="order-house">
        <h2>Order History</h2>
        <OrdersTable
          :sound="sound"
          :orders="orders"
          :orderHistory="orderHistory"
          @openOrder="openOrder"
          :recent="false"
          :dark="dark"
        />
      </div>
    </div>
    <Modal
      wide
      style="z-index: 1000"
      v-if="openCrypto"
      @close="openCrypto = null"
    >
      <CryptoTrade
        :coin="openCrypto"
        :dark="dark"
        :pairs="
          symbolNames.filter((str) => str.indexOf(openCrypto.Currency) != -1)
        "
        @buyPair="buyPair"
        @sellPair="sellPair"
      />
    </Modal>
    <Modal v-if="buy" @close="buy = null">
      <BuyCrypto
        :sound="sound"
        :symbol="buy"
        action="Buy"
        :wallets="walletBalances"
        @home="buy = null"
      />
    </Modal>
    <Modal v-if="sell" @close="sell = null">
      <BuyCrypto
        :sound="sound"
        :symbol="sell"
        action="Sell"
        :wallets="walletBalances"
        @home="sell = null"
      />
    </Modal>
    <Modal v-if="selectedOrder" @close="selectedOrder = null">
      <OrderCard
        :sound="sound"
        crypto
        :order="selectedOrder"
        @close="selectedOrder = null"
      />
    </Modal>
    <Modal style="z-index: 1000" v-if="help" @close="help = false">
      <CryptoHelp />
    </Modal>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import HeadLink from "../components/HeadLink.vue";
// import SearchBox from '../components/SearchBox.vue';
// import TopCubes from '../components/TopCubes.vue';
// import NewsArticle from '../components/NewsArticle.vue';
import CryptoHelp from "../components/CryptoHelp.vue";
import KeyValue from "../components/KeyValue.vue";
import BuyCrypto from "../components/BuyCrypto.vue";
// import PositionsTable from '../components/PositionsTable.vue';
import OrdersTable from "../components/OrdersTable.vue";
import Modal from "../components/Modal.vue";
import CryptoTrade from "../components/CryptoTrade.vue";
import Coin from "../components/Coin.vue";
// import PositionCard from '../components/PositionCard.vue';
import OrderCard from "../components/OrderCard.vue";
// import BuyPosition from '../components/BuyPosition.vue';
// import SellPosition from '../components/SellPosition.vue';
// import BuyOptions from '../components/BuyOptions.vue';
// import SellOptions from '../components/SellOptions.vue';

export default {
  name: "Crypto",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  mixins: [Mixins],
  components: {
    HeadLink,
    // SearchBox,
    // TopCubes,
    // NewsArticle,
    CryptoHelp,
    KeyValue,
    BuyCrypto,
    // PositionsTable,
    OrdersTable,
    Modal,
    CryptoTrade,
    Coin,
    // PositionCard,
    OrderCard,
    // BuyPosition,
    // SellPosition,
    // BuyOptions,
    // SellOptions
  },
  props: {
    account: Object,
    accountsList: Array,
    wallets: Array,
    orders: Array,
    orderHistory: Array,
    dark: Boolean,
    sound: Boolean,
  },
  data: function () {
    return {
      loading: false,
      help: false,
      symbolNames: [],
      openCrypto: null,
      walletBalances: [],
      accountTotals: null,
      walletStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      activeCoins: ["USDC", "USD", "ETH", "LTC", "XRP", "BCH", "BTC"],
      buy: null,
      sell: null,
      selectedOrder: null,

      errors: {},
    };
  },
  beforeUnmount: function () {
    if (this.walletStream.connection) {
      this.walletStream.connection.end();
      this.walletStream.connection.destroy();
    }
  },
  mounted() {
    this.getCryptoPairs();
    this.streamWallets();
    this.$gtag.pageview({
      page_title: `Crypto`,
      page_location: location.href,
      page_path: this.$route.path,
    });
  },
  watch: {
    orders: function (newOrders) {
      if (
        this.selectedOrder != null &&
        newOrders.filter((order) => order.OrderID == this.selectedOrder.OrderID)
          .length > 0
      ) {
        this.selectedOrder = newOrders.filter(
          (order) => order.OrderID == this.selectedOrder.OrderID
        )[0];
      }
    },
  },
  methods: {
    getCryptoPairs() {
      var _this = this;
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
            `marketdata/symbollists/cryptopairs/symbolnames`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          _this.symbolNames = res.data.SymbolNames;
        })
        .catch(function (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.mEssage
          ) {
            _this.error = error.response.data.Message;
          } else {
            _this.error =
              "Could not fetch Crypto Symbols... Please try again later.";
          }
        });
    },
    buyPair(pairSymbol) {
      this.openCrypto = null;
      this.buy = pairSymbol;
    },
    sellPair(pairSymbol) {
      this.openCrypto = null;
      this.sell = pairSymbol;
    },
    streamWallets() {
      var _this = this;
      if (this.walletStream.connection) {
        this.walletStream.connection.end();
        this.walletStream.connection.destroy();
      }
      this.walletStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var streamURL = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/brokerage/stream/accounts/${this.account.key}/wallets`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.walletStream.connection = this.stream
        .get(streamURL, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.walletStream || !this.walletStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.walletStream.leftOver != null) {
                chunk = _this.walletStream.leftOver + chunk;
                _this.walletStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Currency) {
                    _this.accountTotals = snapShot.AccountTotals;
                    if (
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ) &&
                      _this.activeCoins.indexOf(snapShot.Currency) != -1
                    ) {
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).AveragePrice = snapShot.AveragePrice;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).Balance = snapShot.Balance;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).BalanceAccountCurrency =
                        snapShot.BalanceAccountCurrency;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).BalanceAvailableForTrading =
                        snapShot.BalanceAvailableForTrading;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).BalanceAvailableForTradingAccountCurrency =
                        snapShot.BalanceAvailableForTradingAccountCurrency;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).BalanceAvailableForWithdrawal =
                        snapShot.BalanceAvailableForWithdrawal;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).BalanceAvailableForWithdrawalAccountCurrency =
                        snapShot.BalanceAvailableForWithdrawalAccountCurrency;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).Interest = snapShot.Interest;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).InterestRate = snapShot.InterestRate;
                      _this.walletBalances.find(
                        (obj) => obj.Currency == snapShot.Currency
                      ).UnrealizedProfitLossAccountCurrency =
                        snapShot.UnrealizedProfitLossAccountCurrency;
                    } else if (
                      _this.activeCoins.indexOf(snapShot.Currency) != -1
                    ) {
                      _this.walletBalances.push(snapShot);
                    }
                  }
                } else {
                  _this.walletStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    changeAccount(account) {
      this.$emit("changeAccount", account);
    },
    openOrder(order) {
      this.selectedOrder = order;
    },
    walletClick(coin) {
      this.openCrypto = coin;
    },
  },
};
</script>

<style scoped>
.crypto {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  max-width: 1440px;
  margin: auto;
  margin-bottom: 129px;
  text-align: left;
}
.loading-crypto {
  text-align: center;
}
.loading-crypto img {
  width: 100px;
  height: 100px;
}
.account-list {
  margin-bottom: 50px;
}
.crypto-help {
  float: right;
}

h2 {
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.wallets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 50px;
  column-gap: 50px;
}

.totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
.totals .stat-house {
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  margin-right: 50px;
}
.totals .order-house {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.totals .order-house h2 {
  align-self: center;
}

@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 768px) {
  .crypto {
    padding: 20px;
  }
  .totals {
    flex-direction: column;
    justify-content: center;
  }
  .totals .stat-house {
    max-width: none;
    margin-right: 0;
  }
  .totals .order-house {
    display: block;
    max-width: none;
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 650px) {
  .wallets {
    flex-direction: column;
    align-items: center;
  }
}
</style>
