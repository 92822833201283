import { UDFCompatibleDatafeedBase } from './udf-compatible-datafeed-base';
import { QuotesProvider } from './quotes-provider';
import { Requester } from './requester';
export class UDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
    constructor(datafeedURL,localStorageData, updateFrequency = 10 * 1000, limitedServerResponse) {
        const requester = new Requester(localStorageData);
        const quotesProvider = new QuotesProvider(datafeedURL, requester);
        super(
          datafeedURL,
          quotesProvider,
          requester,
          updateFrequency,
          limitedServerResponse,
          localStorageData
        );
    }
}
