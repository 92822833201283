<template>
  <div class="active-trader">
    <b-card no-body>
      <b-tabs card v-model="activeTabIndex" @click="handleTabChange(event)">
        <!-- Render Tabs, supply a unique `key` to each tab -->
        <b-tab v-for="(tab, i)  in tabs" :key="'dyn-tab-' + i" style="padding:0;" :disabled="this.searchPopup == true" :title="i">
          <template #title>
            <span style="padding:1px" class="header-tab">{{ tab.activeTraderOption ?
        tab.activeTraderOption.Symbol : tab.ticker.Symbol }}</span>
            <!-- <b @click="closeTab(i)"
              style="color: rgb(151, 153, 154);font-weight: 900 !important;font-size: 16px !important;margin-left:10px">⨯</b> -->
          </template>

          <div class="tab-content" v-if="searchPopup == false">
            <div class="first-row">
              <div>
                <span class="first-description"
                  :title="tab.activeTraderOption ? tab.activeTraderOption.Description : tab.ticker.Description">{{
        tab.activeTraderOption ? tab.activeTraderOption.Description : tab.ticker.Description
      }}</span>
              </div>
              <div class="main-name">
                <div v-if="position"
                  style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <span style="display: flex; flex-direction: row">
                    <span :class="{ red: position.LongShort == 'Short', green: position.LongShort == 'Long', }"
                      style="font-size: 14px; font-weight: 400">{{ position.Quantity }}</span>
                    <span style="font-size: 14px; font-weight: 400; color: #151c26" class="dark-title">@{{
        formatUnrealizedProfitLoss(position.AveragePrice) }}</span>
                  </span>
                  <span :class="{ red: position.UnrealizedProfitLoss < 0, green: position.UnrealizedProfitLoss >= 0, }"
                    class="second-title-span">{{ position.UnrealizedProfitLoss >= 0 ? '+' : ''
                    }}{{ roundFigure(position.UnrealizedProfitLoss) }}({{
        roundFigure(position.UnrealizedProfitLossPercent)
      }}%)</span>
                </div>
              </div>
              <div class="select-container">
                <input type="text" v-model.number="quantity" @keypress="isNumber($event)" @input="filterFn"
                  @keydown.enter.prevent="addChip" step="1" min="1" />
              </div>
            </div>
            <div class="second-row">
              <button class="buy-mkt" @click="postDataOrder(true, true)">
                Buy Mkt
              </button>
              <button class="exit-mkt" @click="deleteAllOrders()">
                Exit at Mkt & Cxl
              </button>
              <button class="sell-mkt" @click="postDataOrder(false, true)">
                Sell Mkt
              </button>
            </div>
            <div class="third-row">
              <table>
                <thead>
                  <th :style="{ background: dark ? '#000000' : '#FFFFFF', color: '#757575' }">
                    <span>BUY</span>
                  </th>
                  <th :style="{ background: dark ? '#000000' : '#FFFFFF', color: '#0BAA5E' }">
                    <span>BID</span>
                  </th>
                  <th :style="{ background: dark ? '#000000' : '#FFFFFF', color: '#757575' }">
                    <span>PRICE</span>
                  </th>
                  <th :style="{ background: dark ? '#000000' : '#FFFFFF', color: '#D20B3B' }">
                    <span>ASK</span>
                  </th>
                  <th :style="{ background: dark ? '#000000' : '#FFFFFF', color: '#757575' }">
                    <span>SELL</span>
                  </th>
                </thead>
                <tbody ref="orderTable">
                  <tr v-for="(order, index) in sortedOrders" :key="index" ref="items"
                    :style="{ background: dark ? ((index + 1) % 2 ? '#000000' : '#D9D9D926') : ((index + 1) % 2 ? '#fff' : '#D9D9D926') }">
                    <td class="position-relative" :draggable="getBuyQuantity(order[1].Price) > 0"
                      @dragstart="getBuyQuantity(order[1].Price) > 0 && handleDragStart($event, getBuyQuantity(order[1].Price), index, order[1].Price, 'Buy')"
                      @dragend="getBuyQuantity(order[1].Price) > 0 && handleDragEnd"
                      @drop="handleDrop($event, order[1].Price, getBuyQuantity(order[1].Price), true, true, index)"
                      @dragover.prevent="getBuyQuantity(order[1].Price) > 0">
                      <span class="order-tab" v-if="getBuyQuantity(order[1].Price) > 0" draggable="true"
                        style="cursor: pointer">
                        {{ getBuyQuantity(order[1].Price) }}
                        <span class="close-icon" @click="handleOrderClose(order[1].Price, 'Buy')">X</span>
                      </span>
                    </td>

                    <td
                      :style="{ paddingLeft: '4px', display: isBid(order[1].Price) > 0 ? 'flex' : '', justifyContent: 'flex-end', cursor: 'pointer' }"
                      class="hover-ask-border" @click="hanldeBidAskClick(order[1].Price, this.quantity, true, true)">
                      <div class="bid-bg"
                        :style="{ width: bidValueTotal == 0 ? 0 : `${(isBid(order[1].Price) / bidValueTotal) * 100}%`, background: dark ? '#06552F' : '#82D1AB', color: dark ? '#FFFFFF' : '#000000', whiteSpace: 'nowrap' }">
                        <span style="margin-right: 4px;">{{ isBid(order[1].Price) }}</span>
                      </div>
                    </td>


                    <td class="price-display" :ref="'lastPrice' + i" :class="{ highlight: order[1].IsLast }"
                      :style="{ color: dark ? '#fff' : '#000000', position: 'relative' }">
                      <span class="triangle" style="
                  position: absolute;
                  left: 15%;
                  transform: translateX(-50%);
                  border-bottom: 16px solid #0baa5e;
                " v-if="order[1].Price == roundFigure(position?.AveragePrice) && position.LongShort == 'Long'"></span>
                      <span class="triangle" style="
                  position: absolute;
                  left: 15%;
                  transform: translateX(-50%);
                  border-top: 16px solid #d20b3b;
                " v-if="order[1].Price == roundFigure(position?.AveragePrice) && position.LongShort == 'Short'"></span>
                      {{ dollarWithCommas(order[1].Price) }}
                    </td>
                    <td
                      :style="{ paddingLeft: '4px', display: isAsk(order[1].Price) > 0 ? 'flex' : '', justifyContent: 'flex-start', cursor: 'pointer', textAlign: 'start' }"
                      class="hover-bid-border" @click="hanldeBidAskClick(order[1].Price, this.quantity, false, true)">
                      <div class="ask-bg"
                        :style="{ width: askValueTotal == 0 ? 0 : `${(isAsk(order[1].Price) / askValueTotal) * 100}%`, background: dark ? '#67051D' : '#EA8FA5', color: dark ? '#FFFFFF' : '#000000', whiteSpace: 'nowrap' }">
                        <span style="margin-left: 4px">{{ isAsk(order[1].Price) }} </span>
                      </div>
                    </td>
                    <td class="position-relative" :draggable="getSellQuantity(order[1].Price) > 0"
                      @dragstart="getSellQuantity(order[1].Price) > 0 && handleDragStart($event, getSellQuantity(order[1].Price), index, order[1].Price, 'Sell')"
                      @dragend="getSellQuantity(order[1].Price) > 0 && handleDragEnd"
                      @drop="handleDrop($event, order[1].Price, getSellQuantity(order[1].Price), false, true, index)"
                      @dragover.prevent="getSellQuantity(order[1].Price) > 0">
                      <span class="sell-order-tab" v-if="getSellQuantity(order[1].Price) > 0" draggable="true"
                        style="cursor: pointer">
                        {{ getSellQuantity(order[1].Price) }}
                        <span class="close-icon" @click="handleOrderClose(order[1].Price, 'Sell')">X</span>
                      </span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            <div class="fourth-row">
              <div class="count-div">
                <span style="color: #0baa5e">{{ totalBuyQuantity }}({{ totalBuyOrders }})</span>
                <span>{{ bidValueTotal }}</span>
                <span style="color: #5182ff; cursor: pointer; " class="gotoLast" @click="scrollToHighlighted(i)">Go To Last
                  <span style="width : 100%"></span> </span>
                <span>{{ askValueTotal }}</span>
                <span style="color: #d20b3b">{{ totalSellQuantity }}({{ totalSellOrders }})</span>
              </div>
              <div class="show-wanning">
                <div class="duration"
                  :style="{ background: dark ? '#000000' : '#FFFFFF', border: dark ? '1px solid #000' : '' }">
                  <span class="duration-choices" :style="getChoiceStyle('Day')"
                    :class="{ 'selected-duration': durationChoice === 'Day' }"
                    @click="setDurationChoice('Day')">DAY</span><span class="duration-choices"
                    :style="getChoiceStyle('Until Cancel')"
                    :class="{ 'selected-duration': durationChoice === 'Until Cancel' }"
                    @click="setDurationChoice('Until Cancel')">GTC</span>
                </div>
                <span class="warning" v-if="message">{{ message }}</span>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="this.searchPopup == true">
          <template #title>
            <div style="display:flex; position:relative">
              <div class="search-box">
                <input type="text" ref="searchInput" v-model="search" @input="handleSearch" placeholder="Search..."
                  @keyup.enter="handleSave(search)" />
                <img v-if="!loading" src="../assets/search-normal.png" />
                <img v-if="loading" src="../assets/loading.gif" />
              </div>
              <div class="search-results" v-if="results.length > 0">
                <div v-for="option in results" :key="option.Name" @click="addSearchOption(option)" class="result">
                  <div class="ticker-container">
                    <p class="ticker"> {{ option.Name }}</p>
                  </div>
                  <p class="name">{{ option.Description }}</p>
                </div>
              </div>
              <b @click="closeSearch"
                style="color: rgb(151, 153, 154);font-weight: 900 !important;font-size: 16px !important;margin-left:10px">⨯</b>
            </div>

          </template>
        </b-tab>

        <!-- <template #tabs-end>
          <span role="presentation" @click="this.searchPopup = true"
            style="display: flex;align-items: center;justify-content: center;padding: 3.5px 12px; cursor: pointer;"><b
              style="color:#97999A !important;font-weight: 900 !important;font-size: 22px !important;">+</b></span>
        </template> -->
      </b-tabs>
    </b-card>
  </div>
</template>


<script>
import Mixins from "../Mixins";
import Toast from "./Toasts.vue";
import axios from 'axios';
// import { watchEffect } from 'vue';

export default {
  name: "Depth",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  mixins: [Mixins],

  props: {
    ticker: Object,
    orderLadder: Map,
    orders: Array,
    dark: Boolean,
    activeTraderOption: Object,
    largestVolume: Number,
    traderSettings: {
      type: Boolean,
      default: false,
    },
    tabOpened: Boolean,
    positions: Array,
    sound: Boolean,
    tickersData: Array,
    account: Object,
    tickerTabSymbol: Map,
  },
  data() {
    return {
      searchPopup: false,
      tabs: [{ activeTraderOption: this.activeTraderOption ? this.activeTraderOption : null, ticker: !this.activeTraderOption ? this.ticker : null, orderLadder: this.orderLadder }],
      tabCounter: 1,
      activeTabIndex: 0,
      quantityType: localStorage.activeQuantity
        ? localStorage.activeQuantity
        : "Single",
      quantityTypeDescription: {
        Single: "Active trader uses quantity of '1' for orders.",
        "Set Size": "Set custom quantity amount.",
        "Set Lot":
          "Quantity will be set so that total lot price is closest to a pre-set dollar amount (rounded down).",
      },
      behaviourType: localStorage.activeBehaviour
        ? localStorage.activeBehaviour
        : "Prefill order on tap",
      behaviourTypeDescription: {
        "Prefill order on tap":
          "Tapping on a bid or ask will pre-fill a limit order for you to review & send.",
        "Send order on double tap":
          "Tapping twice on the same bid or ask will automatically send a limit order.",
      },
      setSize: localStorage.activeSize ? localStorage.activeSize : 1,
      setLot: localStorage.activeLot ? localStorage.activeLot : 10.0,
      readyBid: null,
      readyAsk: null,
      readyMarket: null,
      quantity: '1',
      priceIncrement: {
        style: null,
        increment: null,
        schedule: [],
      },
      tickers: [],
      updatedTicker: {},
      tickerDescription: "",
      duration: "Until Cancel",
      typeDescription: {
        Limit: "Order only if price is equal to or better than my limit",
      },
      orderRoute: {
        Id: "Intelligent",
        Name: "Intelligent",
      },
      bidAskValue: {
        Bids: [],
        Asks: [],
      },
      bidAskStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      durationChoice: "Day",
      input: "",
      options: [1, 2, 3, 4, 5, 10, 15, 20, 25],
      chips: [],
      filteredOptions: [],
      showOptions: false,
      message: "",
      localLimitPrice: null,
      sellShort: false,
      selectedSymbol: null,
      search: "",
      selectedSearch: '',
      results: [],
      selectedOption: null,
      timer: null,
      loading: false,
      error: null,
      isLast: false,
    };
  },
  watch: {
    tabCounter(newValue) {
      console.log("newValue", this.tabs, newValue);
    },
    tabOpened(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.scrollToHighlighted(this.activeTabIndex);

        },1500 )
      }
    },
    showOrders(newValue) {
      let currentTab = this.tabs[this.activeTabIndex];
      if (newValue) {
        setTimeout(() => {
          var findIndex = [...currentTab.orderLadder.entries()]
            .sort()
            .reverse()
            .findIndex((elem) => elem[1].IsLast);
          this.$refs.orderTable.scrollTop = 24 * (findIndex - 5);
        }, 100);
      }
    },
    traderSettings(newValue) {
      let currentTab = this.tabs[this.activeTabIndex];
      if (!newValue) {
        setTimeout(() => {
          var findIndex = [...currentTab.orderLadder.entries()]
            .sort()
            .reverse()
            .findIndex((elem) => elem[1].IsLast);
          this.$refs.orderTable.scrollTop = 24 * (findIndex - 5);
        }, 100);
      }
    },
    quantityType(newValue) {
      localStorage.activeQuantity = newValue;
    },
    behaviourType(newValue) {
      localStorage.activeBehaviour = newValue;
    },
    setSize(newValue) {
      this.setSize = this.setSize.replace(/[^0-9]/g, "");
      if (newValue != this.setSize) {
        return;
      }
      localStorage.activeSize = newValue;
    },
    setLot(newValue) {
      this.setLot = this.setLot.replace(/[^0-9.]/g, "");
      if (newValue != this.setLot) {
        return;
      }
      localStorage.activeLot = newValue;
    },
    activeTraderOption(newValue) {
      if (newValue) {
        setTimeout(() => {
        this.addTab(newValue, this.orderLadder);
        this.fetchSymbolDescription();
        this.streamOptions();
        this.searchPopup = false;
        this.search = ""
        this.tabs.length - 1
          this.scrollToHighlighted();
        }, 1500)
      }
    },
    activeTabIndex(newValue){
      setTimeout(() => {
          this.scrollToHighlighted(newValue);
        }, 1500)
    }
  },
  computed: {
    sortedOrders() {
      let currentTab = this.tabs[this.activeTabIndex];
      currentTab.orderLadder.forEach((e)=>this.isLast = e?.IsLast)
      return [...currentTab.orderLadder.entries()].sort().reverse();
    },
    bidValueTotal() {
      let currentTab = this.tabs[this.activeTabIndex];
      let orderKeys = [...currentTab.orderLadder.entries()].map((order) =>
        order[0]
      )
      const minValue = Math.min(...orderKeys);
      const maxValue = Math.max(...orderKeys);
      const uniqueBids = {};
      const filteredBids = this.bidAskValue.Bids.filter(bid => {
        const price = parseFloat(bid.Price);
        return price >= minValue && price <= maxValue;
      });

      filteredBids.forEach(bid => {
        uniqueBids[bid.Price] = bid;
      });

      let total = 0;
      Object.values(uniqueBids).forEach(bid => {
        total += parseInt(bid.Size);
      });
      return total;
    },
    askValueTotal() {
      let currentTab = this.tabs[this.activeTabIndex];
      let orderKeys = [...currentTab.orderLadder.entries()].map((order) =>
        order[0]
      )
      const minValue = Math.min(...orderKeys);
      const maxValue = Math.max(...orderKeys);
      const uniqueAsks = {};
      const filteredAsks = this.bidAskValue.Asks.filter(ask => {
        const price = parseFloat(ask.Price);
        return price >= minValue && price <= maxValue;
      });
      filteredAsks.forEach(bid => {
        uniqueAsks[bid.Price] = bid;
      });

      let total = 0;
      Object.values(uniqueAsks).forEach(bid => {
        total += parseInt(bid.Size);
      });
      return total;
    },
    position() {
      let currentTab = this.tabs[this.activeTabIndex];
      let symbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
      return this.positions.find((pos) => pos.Symbol == symbol);
    },
    showOrders() {
      if (this.orderLadder.size > 0) {
        return true;
      } else {
        return false;
      }
    },
    totalBuyOrders() {
      return this.orders.reduce((acc, order) => {
        const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
        const { Status, Legs } = order;
        const { OpenOrClose, BuyOrSell, Symbol } = Legs[0];
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Buy" || BuyOrSell === "BuyToCover") && Symbol === compareSymbol) {
          acc += 1;
        }
        return acc;
      }, 0);
    },
    totalSellOrders() {
      return this.orders.reduce((acc, order) => {
        const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
        const { Status, Legs } = order;
        const { OpenOrClose, BuyOrSell, Symbol } = Legs[0];
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Sell" || BuyOrSell === "SellShort") && Symbol === compareSymbol) {
          acc += 1;
        }
        return acc;
      }, 0);
    },
    totalBuyQuantity() {
      return this.orders.reduce((acc, order) => {
        const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
        const { Status, Legs } = order;
        const { OpenOrClose, BuyOrSell, QuantityOrdered, Symbol } = Legs[0];
        const roundedQuantity = Math.ceil(QuantityOrdered);
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol; if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Buy" || BuyOrSell === "BuyToCover") && Symbol === compareSymbol) {
          acc += roundedQuantity;
        }
        return acc;
      }, 0);
    },
    totalSellQuantity() {
      return this.orders.reduce((acc, order) => {
        const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
        const { Status, Legs } = order;
        const { OpenOrClose, BuyOrSell, QuantityOrdered, Symbol } = Legs[0];
        const roundedQuantity = Math.ceil(QuantityOrdered);
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Sell" || BuyOrSell === "SellShort") && Symbol === compareSymbol) {
          acc += roundedQuantity;
        }
        return acc;
      }, 0);
    },
    isBid() {
      const bidMap = new Map();
      this.bidAskValue.Bids.forEach(bid => {
        if (bid.Side === "Bid") {
          bidMap.set(bid.Price, bid.Size);
        }
      });
      return(price) => {
        return bidMap.get(price) || "";
      };
    },
    isAsk() {
      const askMap = new Map();
      this.bidAskValue.Asks.forEach(ask => {
        if (ask.Side === "Ask") {
          askMap.set(ask.Price, ask.Size);
          }
        });
      return(price) => {
        return askMap.get(price) || "";
      };
    },
    recentBids() {
      var getBids = this.orders.filter(
        (elem) => elem.Legs[0].BuyOrSell == "Sell"
      );
      var pending = getBids.filter(
        (elem) => ["ACK", "DON", "FPR", "OPN", "FLP"].indexOf(elem.Status) != -1
      );
      var filled = getBids.filter(
        (elem) => ["FLL", "FLP", "FPR"].indexOf(elem.Status) != -1
      );

      var bidList = {};
      pending.forEach((pend) => {
        if (bidList[pend.LimitPrice]) {
          bidList[pend.LimitPrice]["pending"] += parseInt(
            pend.Legs[0].QuantityRemaining
          );
          if (pend.Status == "DON") {
            bidList[pend.LimitPrice]["pending"] += parseInt(
              pend.Legs[0].QuantityOrdered
            );
          }
        } else {
          if (pend.Status == "DON") {
            bidList[pend.LimitPrice] = {
              pending: parseInt(pend.Legs[0].QuantityOrdered),
              filled: 0,
            };
          } else {
            bidList[pend.LimitPrice] = {
              pending: parseInt(pend.Legs[0].QuantityRemaining),
              filled: 0,
            };
          }
        }
      });
      filled.forEach((fill) => {
        if (bidList[fill.FilledPrice]) {
          bidList[fill.FilledPrice]["filled"] += parseInt(
            fill.Legs[0].ExecQuantity
          );
        } else {
          bidList[fill.FilledPrice] = {
            pending: 0,
            filled: parseInt(fill.Legs[0].ExecQuantity),
          };
        }
      });
      return bidList;
    },
    recentAsks() {
      var getAsks = this.orders.filter(
        (elem) => elem.Legs[0].BuyOrSell == "Buy"
      );
      var pending = getAsks.filter(
        (elem) => ["ACK", "DON", "FPR", "OPN", "FLP"].indexOf(elem.Status) != -1
      );
      var filled = getAsks.filter(
        (elem) => ["FLL", "FLP", "FPR"].indexOf(elem.Status) != -1
      );

      var askList = {};
      pending.forEach((pend) => {
        if (askList[pend.LimitPrice]) {
          askList[pend.LimitPrice]["pending"] += parseInt(
            pend.Legs[0].QuantityRemaining
          );
          if (pend.Status == "DON") {
            askList[pend.LimitPrice]["pending"] += parseInt(
              pend.Legs[0].QuantityOrdered
            );
          }
        } else {
          if (pend.Status == "DON") {
            askList[pend.LimitPrice] = {
              pending: parseInt(pend.Legs[0].QuantityOrdered),
              filled: 0,
            };
          } else {
            askList[pend.LimitPrice] = {
              pending: parseInt(pend.Legs[0].QuantityRemaining),
              filled: 0,
            };
          }
        }
      });
      filled.forEach((fill) => {
        if (askList[fill.FilledPrice]) {
          askList[fill.FilledPrice]["filled"] += parseInt(
            fill.Legs[0].ExecQuantity
          );
        } else {
          askList[fill.FilledPrice] = {
            pending: 0,
            filled: parseInt(fill.Legs[0].ExecQuantity),
          };
        }
      });
      return askList;
    },
  },
  async mounted() {
    // watchEffect(() => {
    //   this.tabs.forEach((order, i) => {
    //     if (this.isLast) {
    //       this.scrollToHighlighted(i);
    //     }
    //   });
    // });

    let isBool = false;
    let data = this.positions.map((position) => {
      return position.Symbol.split(/[^\w]/)[0]; // Extract the base symbol only
    });
    let currentTab = this.tabs[this.activeTabIndex];
    let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
    this.position = this.positions.find(
      (pos) => pos.Symbol == compareSymbol
    );
    for (let key of data) {
      if (this.tickers.indexOf(key) === -1) {
        this.tickers.push(key);
        isBool = true;
      } else {
        isBool = false;
      }
    }
    this.quantity = '1';
    this.sellShort = this.position && this.position.LongShort == "Short" ? true : false;
    this.streamOptions();
    if (isBool) {
      await this.fetchSymbolDescription();
    }
    await this.fetchSymbolDescription();
  },
  methods: {
    closeSearch() {
      this.results = [];
      this.searchPopup = false;
      this.search = ""
      this.selectedOption = null;
      if (this.tabs.length > 0) {
        this.activeTabIndex = 0;
      }
    },
    handleSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.search) {
        this.results = [];
        return;
      }
      this.timer = setTimeout(() => {
        this.fetchResults(this.search);
      }, 500);
    },
    fetchResults(query) {
      this.loading = true;
      const searchUrl = `${process.env.VUE_APP_TS_V2}data/symbols/suggest/${query}?access_token=${encodeURIComponent(localStorage.accessToken)}`;
      axios
        .get(searchUrl)
        .then((res) => {
          this.loading = false;
          if (query.toLowerCase() === this.search.toLowerCase()) {
            this.results = res.data.filter((el) => !el.Name.startsWith('@') && !el.Name.endsWith('.D'));
          }
        })
        .catch((error) => {
          console.log("<<<error", error)
          this.loading = false;
          this.error = "Something went wrong... Please try again later";
        });
    },
    addSearchOption(option) {
      this.search = option.Name;
      this.selectedOption = option;
      this.results = [];
      this.$refs.searchInput.focus();
    },
    handleSave(ticker) {
      this.$gtag.event("tap_search", {
        ticker: ticker,
      });
      let symbol = ticker
      this.$emit('selectedSymbol', symbol)
      setTimeout(() => {
        this.newTab(this.ticker, this.tickerTabSymbol);
        this.fetchSymbolDescription();
        this.streamOptions();
      }, 1000)
      setTimeout(() => {
        this.scrollToHighlighted(this.activeTabIndex);
      }, 2000)
      this.searchPopup = false;
      this.search = "";
      this.tabs.length - 1;
      this.results = [];
    },
    handleTabChange(newIndex) {
      this.activeTabIndex = newIndex;
    },
    addTab(activeTraderOption, orderLadder) {
      this.tabs.push({ activeTraderOption, orderLadder: orderLadder });
      this.activeTabIndex = this.tabs.length - 1; 
    },
    closePopup() {
      this.searchPopup = false;
    },
    closeTab(index) {
      if (this.tabs.length === 1) return;

      this.tabs.splice(index, 1);
      if (this.activeTabIndex >= this.tabs.length) {
        this.activeTabIndex = this.tabs.length - 1;
      }
    },
    newTab(ticker) {
      this.tabs.push({ activeTraderOption: null, ticker, orderLadder: this.orderLadder });
      this.tabCounter++;
      this.activeTabIndex = this.tabs.length - 1;
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    filterFn() {
      const inputLower = this.quantity;
      this.filteredOptions = this.options.filter((option) =>
        option.toString().toLowerCase().includes(inputLower)
      );
      this.showOptions = true;
    },
    addOption(option) {
      if (!this.chips.includes(option)) {
        this.quantity = option;
        this.chips.push(option);
      }
      this.input = "";
      this.filteredOptions = [];
      this.showOptions = false;
    },
    addChip() {
      if (this.input && !this.chips.includes(this.input)) {
        this.chips.push(this.input);
      }
      this.input = "";
      this.filteredOptions = [];
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
      if (this.showOptions) {
        this.filterFn();
      }
    },
    getChoiceStyle(choice) {
      if (this.dark) {
        return {
          backgroundColor: this.durationChoice === choice ? '#97999A' : '#000000',
          border: this.durationChoice === choice ? '1px solid #97999A' : '1px solid #000',
          color: this.durationChoice === choice ? '#FFFFFF' : '#757575'
        };
      } else {
        return {
          backgroundColor: this.durationChoice === choice ? '#e8e6e6' : '#ffffff',
          border: this.durationChoice === choice ? '1px solid #e8e6e6' : '1px solid #ffffff',
          color: this.durationChoice === choice ? '#000000' : '#757575'
        };
      }
    },
    handleDragEnd(event) {
      console.log("<<event", event);
    },
    handleDragStart(event, quantity, index, oldPrice, buyOrSell) {
      event.dataTransfer.setData('quantity', quantity);
      event.dataTransfer.setData('index', index)
      event.dataTransfer.setData('oldPrice', oldPrice)
      event.dataTransfer.setData('buyOrSell', buyOrSell)
    },
    async handleDrop(event, prices, quantity, isClose, isPositionSell, index) {
      const draggedData = parseInt(event.dataTransfer.getData('quantity'));

      if (isNaN(draggedData) || draggedData <= 0) return;

      const getIndex = parseInt(event.dataTransfer.getData('index'));
      const getPrice = event.dataTransfer.getData('oldPrice');
      const buyOrSell = event.dataTransfer.getData('buyOrSell');
      const targetTd = event.target.closest('td');

      if (targetTd) {
        const existingSpan = targetTd.querySelector('.order-tab');
        if (existingSpan) {
          if (getIndex != index) {
            // await this.hanldeBidAskClick(prices, draggedData, isClose, isPositionSell);
            this.handleOrderUpdate(getPrice, prices, buyOrSell);
          }
        } else {
          // await this.hanldeBidAskClick(prices, draggedData, isClose, isPositionSell);
          this.handleOrderUpdate(getPrice, prices, buyOrSell);
        }
      }
    },
    async handleOrderUpdate(price, newPrice, buyOrSell) {
      this.loading = true;
      let requestBody = {}
      var _this = this;

      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds = [];

        this.orders.forEach(order => {
          if (validStatuses.includes(order.Status) && order.Legs[0].BuyOrSell.includes(buyOrSell) && order.OrderType == "Limit" && order.PriceUsedForBuyingPower == price && order.Legs[0].OpenOrClose) {
            orderIds.push(order.OrderID);
            requestBody = {
              Quantity: order.Legs[0].QuantityOrdered,
              OrderType: 'Limit',
              LimitPrice: newPrice
            };
          }
        });

        if (orderIds.length === 0) {
          return;
        }

        let successCount = 0;

        const updateOrder = async (orderId) => {
          let updateUrl =
            (localStorage.sim == "true"
              ? process.env.VUE_APP_TS_SIM
              : process.env.VUE_APP_TS) +
            `orders/${orderId}?access_token=${encodeURIComponent(
              localStorage.accessToken
            )}`;

          try {
            const res = await this.http.put(updateUrl, requestBody, {
              headers: { "Content-Type": "application/json; charset=utf-8" },
            });

            if (res.data.OrderStatus == "Ok") {
              successCount++;
            } else if (res.data.Message) {
              _this.errors.global = res.data.Message;
              _this.playSound("order-rejected-cancelled.mp3", _this.sound);
              Toast.error(_this.errors.global);
              _this.$gtag.event("web_error", {
                action: "order_update",
                res_data: JSON.stringify(res.data),
              });
            } else {
              _this.errors.global = "Something went wrong... Please try again later";
              Toast.error(_this.errors.global);
              _this.playSound("order-rejected-cancelled.mp3", _this.sound);
              _this.$gtag.event("web_error", {
                action: "order_update",
                res_data: JSON.stringify(res.data),
              });
            }
          } catch (error) {
            console.log(error);
            _this.loading = false;
            if (error.response && error.response.data && error.response.data.Message) {
              _this.errors.global = error.response.data.Message;
              Toast.error(_this.errors.global);
              _this.playSound("order-rejected-cancelled.mp3", _this.sound);
              _this.$gtag.event("web_error", {
                action: "order_update",
                res_data: JSON.stringify(error.response.data),
              });
            } else {
              _this.errors.global = "Something went wrong... Please try again later";
              Toast.error(_this.errors.global);
              _this.playSound("order-rejected-cancelled.mp3", _this.sound);
              _this.$gtag.event("web_error", {
                action: "order_update",
                internal_error: JSON.stringify(error),
              });
            }
          }
        };

        const updatePromises = orderIds.map(orderId => updateOrder(orderId));
        await Promise.all(updatePromises);

        this.loading = false;
        if (successCount === orderIds.length) {
          this.serverResult = "Successfully updated all orders!";
          Toast.info(this.serverResult);
          this.playSound("order-placed-updated.mp3", this.sound);
        } else if (successCount > 0) {
          this.serverResult = `Successfully updated ${successCount} out of ${orderIds.length} orders!`;
          Toast.info(this.serverResult);
          this.playSound("order-placed-updated.mp3", this.sound);
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async handleOrderClose(price, buyOrSell) {
      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds = [];

        this.orders.forEach(order => {
          const comparePrice = order.Last !== undefined ? order.Last : order.PriceUsedForBuyingPower;

          if (validStatuses.includes(order.Status) && order.Legs[0].BuyOrSell.includes(buyOrSell) && order.OrderType == "Market" && comparePrice == price && order.Legs[0].OpenOrClose) {
            orderIds.push(order.OrderID);
          } else if (validStatuses.includes(order.Status) && order.Legs[0].BuyOrSell.includes(buyOrSell) && order.OrderType == "Limit" && order.PriceUsedForBuyingPower == price && order.Legs[0].OpenOrClose) {
            orderIds.push(order.OrderID);
          }
        });

        if (orderIds.length === 0) {
          return;
        }
        this.deleteOrder(orderIds);
      } catch (e) {
        console.log(e);
      }
    },
    setDurationChoice(choice) {
      this.durationChoice = choice;
    },
    hanldeBidAskClick(bid, newQuantity, isClose, isPositionSell) {
      let _this = this;
      _this.localLimitPrice = bid;
      _this.isClosing = isClose;
      this.errors = {};
      this.loading = true;

      var calculatedProfit = 0;
      var calculatedLoss = 0;
      this.quantity.toString().includes("-")
        ? this.quantity.toString().substring(1)
        : this.quantity.toString()
      let originalQantity = newQuantity > 0 ? newQuantity : this.quantity;

      const splitOrders = originalQantity > 50 ? [] : null;
      const maxOrderSize = 50;
      let remainingQuantity = originalQantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);
      var legList = [];
      let currentTab = this.tabs[this.activeTabIndex];
      let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
      let selectedTicker = this.positions.find((position) => position.Symbol == compareSymbol)

      legList.push({
        Symbol: currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol,
        Quantity: this.quantity,
        TradeAction: !selectedTicker
          ? _this.isClosing
            ? "BUYTOOPEN"
            : "SELLTOOPEN"
          : _this.isClosing
            ? selectedTicker.LongShort === "Long"
              ? "SELLTOCLOSE"
              : "BUYTOCLOSE"
            : selectedTicker.LongShort === "Long"
              ? "BUYTOOPEN"
              : "SELLTOOPEN",
        Buy: !selectedTicker
          ? _this.isClosing
          : this.sellShort
            ? _this.isClosing
            : (_this.isClosing
              ? selectedTicker.LongShort === "Long"
              : selectedTicker.LongShort !== "Long")
      });

      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = legList.map(({ Buy, ...rest }) => ({
          ...rest,
          Quantity: orderSize.toString(),
          TradeAction: this.sellShort
            ? (_this.isClosing
              ? (Buy ? "BUYTOCLOSE" : "SELLTOCLOSE")
              : (Buy ? "BUYTOOPEN" : "SELLTOOPEN"))
            : !this.position
              ? (_this.isClosing
                ? "BUYTOOPEN"
                : "SELLTOOPEN")
              : (!_this.isClosing
                ? (!Buy ? "SELLTOCLOSE" : "BUYTOCLOSE")
                : (Buy ? "BUYTOOPEN" : "SELLTOOPEN"))
        }));

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: (() => {
            if (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") ||
              this.ticker.AssetType.includes("OPTION")) {
              if (selectedTicker) {
                if (this.sellShort && this.position) {
                  return _this.isClosing ? "SELL" : "BUY";
                } else if (this.position) {
                  return _this.isClosing ? "BUY" : "SELL";
                } else {
                  if (_this.isClosing) {
                    return selectedTicker.LongShort == "Long" ? "BUYTOCLOSE" : "SELLTOCLOSE";
                  } else {
                    return selectedTicker.LongShort == "Long" ? "BUYTOTOPEN" : "SELLTOPEN";
                  }
                }
              } else {
                if (this.position) {
                  return _this.isClosing ? "BUY" : "SELL";
                } else {
                  return "BUY";
                }
              }
            } else {
              if (this.sellShort) {
                return _this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
              } else {
                return _this.position
                  ? _this.isClosing ? "BUY" : "SELL"
                  : _this.isClosing ? "BUY" : "SELLSHORT";
              }
            }
          })(),
          orderType: "Limit",
          limitPrice: bid,
          orderDuration: this.durationChoice,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickersData.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );

        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      // }
      const orderPayload = {
        orderType: "Limit",
        callOptionsOrders: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? splitOrders : null,
        close: this.sellShort ? _this.isClosing : false || (selectedTicker ? !_this.isClosing : false),
        final: true,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol,
        quantity: _this.isClosing || isPositionSell ? originalQantity : this.position.Quantity,
        limitPrice: bid,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.durationChoice,
        legs: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? legList : null,
        tradeAction: (() => {
          if (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") ||
            this.ticker.AssetType.includes("OPTION")) {
            if (selectedTicker) {
              if (this.sellShort && this.position) {
                return _this.isClosing ? "SELL" : "BUY";
              } else if (this.position) {
                return _this.isClosing ? "BUY" : "SELL";
              } else {
                if (_this.isClosing) {
                  return selectedTicker.LongShort == "Long" ? "BUYTOCLOSE" : "SELLTOCLOSE";
                } else {
                  return selectedTicker.LongShort == "Long" ? "BUYTOTOPEN" : "SELLTOPEN";
                }
              }
            } else {
              if (this.position) {
                return _this.isClosing ? "BUY" : "SELL";
              } else {
                return "BUY";
              }
            }
          } else {
            if (this.sellShort) {
              return _this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
            } else {
              return _this.position
                ? _this.isClosing ? "BUY" : "SELL"
                : _this.isClosing ? "BUY" : "SELLSHORT";
            }
          }
        })(),
        assetType: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? "OP" : this.updatedTicker.AssetType,
      };
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(res.data),
            });
          } else {
            res.data.Orders.forEach((order) => {
              var sendLegs = "";
              if (order["Error"] == null) {
                _this.tickersData.forEach((leg) => {
                  sendLegs += `${leg["Symbol"]},`;
                });
                _this.$gtag.event("order_closing", { legs: sendLegs });
              } else {
                _this.$gtag.event("web_error", {
                  action: "order_closing",
                  res_data: order["Error"],
                });
              }
            });
            if (res.data.Orders[0].Error) {
              if (
                !_this.sellShort &&
                !_this.isClosing &&
                (res.data.Orders[0].Message.includes("EC703") ||
                  res.data.Orders[0].Message.includes("EC704"))
              ) {
                _this.deleteAndRepost(_this, "Limit");
              } else {
                _this.submitted = res.data.Orders[0];
                Toast.error(_this.submitted.Message);
                _this.playSound(
                  "../../order-rejected-cancelled.mp3",
                  _this.sound
                );
                _this.submitted = false;
                return;
              }
            } else {
              _this.submitted = res.data.Orders[0];
              Toast.success(_this.submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.isClosing) {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var averageCost = 0.0;
                  _this.tickersData.forEach((orderTicker) => {
                    if (orderTicker.Buy == true) {
                      averageCost -=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    } else {
                      averageCost +=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    }
                  });
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: averageCost.toString(),
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                      _this.$emit("close");
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }
            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    async deleteAndRepost(_this, orderType) {
      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds = []
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        orderIds = _this.orders
          .filter(element =>
            element.Legs[0].Symbol === compareSymbol &&
            element.Legs[0].BuyOrSell === 'Sell' &&
            validStatuses.includes(element.Status))
          .map(e => e.OrderID);
        if (orderType !== "Market") {
          await _this.deleteOrder(orderIds, () => _this.hanldeBidAskClick(_this.localLimitPrice, this.quantity, false, true));
        } else {
          await _this.deleteOrder(orderIds, () => _this.postDataOrder(false, true));
        }
      } catch (e) {
        console.log(e)
      }
    },
    getBuyQuantity(price) {
      const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
      return this.orders.reduce((acc, order) => {
        const { Status, Legs, PriceUsedForBuyingPower } = order;
        const { OpenOrClose, BuyOrSell, QuantityOrdered, Symbol } = Legs[0];
        // const comparePrice = Last !== undefined ? Last : PriceUsedForBuyingPower;
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;        // if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Buy" || BuyOrSell === "BuyToCover") && Symbol === compareSymbol && order.OrderType == "Market" && comparePrice == price) {
        //   return acc + Math.ceil(QuantityOrdered);
        // } else 
        if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Buy" || BuyOrSell === "BuyToCover") && Symbol === compareSymbol && order.OrderType != "Market" && PriceUsedForBuyingPower == price) {
          return acc + Math.ceil(QuantityOrdered);
        }
        return acc;
      }, 0);
    },
    getSellQuantity(price) {
      const validStatuses = new Set(["ACK", "FPR", "OPN", "DON", "UCN"]);
      return this.orders.reduce((acc, order) => {
        const { Status, Legs, PriceUsedForBuyingPower } = order;
        const { OpenOrClose, BuyOrSell, QuantityOrdered, Symbol } = Legs[0];
        // const comparePrice = Last !== undefined ? Last : PriceUsedForBuyingPower;
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        // if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Sell" || BuyOrSell === "SellShort") && Symbol === compareSymbol && order.OrderType == "Market" && comparePrice == price) {
        //   return acc + Math.ceil(QuantityOrdered);
        // } else 
        if (validStatuses.has(Status) && OpenOrClose && (BuyOrSell === "Sell" || BuyOrSell === "SellShort") && Symbol === compareSymbol && order.OrderType != "Market" && PriceUsedForBuyingPower == price) {
          return acc + Math.ceil(QuantityOrdered);
        }
        return acc;
      }, 0);
    },
    postDataOrder(isClose, isPositionSell) {
      let _this = this;
      _this.isClosing = isClose;
      this.errors = {};
      this.loading = true;

      var calculatedProfit = 0;
      var calculatedLoss = 0;

      const splitOrders = this.quantity > 50 ? [] : null;
      const maxOrderSize = 50;
      this.quantity.toString().includes("-")
        ? this.quantity.toString().substring(1)
        : this.quantity.toString()
      let remainingQuantity = this.quantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);

      var legList = [];
      let currentTab = this.tabs[this.activeTabIndex];
      let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
      let selectedTicker = this.positions.find((position) => position.Symbol == compareSymbol)
      legList.push({
        Symbol: currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol,
        Quantity: this.quantity,
        TradeAction: !selectedTicker
          ? _this.isClosing
            ? "BUYTOOPEN"
            : "SELLTOOPEN"
          : _this.isClosing
            ? selectedTicker.LongShort === "Long"
              ? "SELLTOCLOSE"
              : "BUYTOCLOSE"
            : selectedTicker.LongShort === "Long"
              ? "BUYTOOPEN"
              : "SELLTOOPEN",
        Buy: !selectedTicker
          ? _this.isClosing
          : this.sellShort
            ? _this.isClosing
            : (_this.isClosing
              ? selectedTicker.LongShort === "Long"
              : selectedTicker.LongShort !== "Long"),
      });

      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = legList.map(({ Buy, ...rest }) => ({
          ...rest,
          Quantity: orderSize.toString(),
          TradeAction: this.sellShort
            ? (_this.isClosing
              ? (Buy ? "BUYTOCLOSE" : "SELLTOCLOSE")
              : (Buy ? "BUYTOOPEN" : "SELLTOOPEN"))
            : !this.position
              ? (_this.isClosing
                ? "BUYTOOPEN"
                : "SELLTOOPEN")
              : (!_this.isClosing
                ? (!Buy ? "SELLTOCLOSE" : "BUYTOCLOSE")
                : (Buy ? "BUYTOOPEN" : "SELLTOOPEN"))
        }));

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: (() => {
            if (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") ||
              this.ticker.AssetType.includes("OPTION")) {
              if (selectedTicker) {
                if (this.sellShort && this.position) {
                  return _this.isClosing ? "SELL" : "BUY";
                } else if (this.position) {
                  return _this.isClosing ? "BUY" : "SELL";
                } else {
                  if (_this.isClosing) {
                    return selectedTicker.LongShort == "Long" ? "BUYTOCLOSE" : "SELLTOCLOSE";
                  } else {
                    return selectedTicker.LongShort == "Long" ? "BUYTOTOPEN" : "SELLTOPEN";
                  }
                }
              } else {
                if (this.position) {
                  return _this.isClosing ? "BUY" : "SELL";
                } else {
                  return "BUY";
                }
              }
            } else {
              if (this.sellShort) {
                return _this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
              } else {
                return _this.position
                  ? _this.isClosing ? "BUY" : "SELL"
                  : _this.isClosing ? "BUY" : "SELLSHORT";
              }
            }
          })(),
          orderType: "Market",
          orderDuration: this.durationChoice,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickersData.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );

        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      const orderPayload = {
        orderType: "Market",
        callOptionsOrders: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? splitOrders : null,
        close: this.sellShort ? _this.isClosing : false || (selectedTicker ? !_this.isClosing : false),
        final: true,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol,
        quantity:
          _this.isClosing || isPositionSell
            ? this.quantity
            : (this.position.Quantity.toString().includes("-")
              ? this.position.Quantity.toString().substring(1)
              : this.position.Quantity.toString()),
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.durationChoice,
        legs: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? legList : null,
        tradeAction: (() => {
          if (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") ||
            currentTab.ticker.AssetType.includes("OPTION")) {
            if (selectedTicker) {
              if (this.sellShort && this.position) {
                return _this.isClosing ? "SELL" : "BUY";
              } else if (this.position) {
                return _this.isClosing ? "BUY" : "SELL";
              } else {
                if (_this.isClosing) {
                  return selectedTicker.LongShort == "Long" ? "BUYTOCLOSE" : "SELLTOCLOSE";
                } else {
                  return selectedTicker.LongShort == "Long" ? "BUYTOTOPEN" : "SELLTOPEN";
                }
              }
            } else {
              if (this.position) {
                return _this.isClosing ? "BUY" : "SELL";
              } else {
                return "BUY";
              }
            }
          } else {
            if (this.sellShort) {
              return _this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
            } else {
              return _this.position
                ? _this.isClosing ? "BUY" : "SELL"
                : _this.isClosing ? "BUY" : "SELLSHORT";
            }
          }
        })(),
        assetType: (currentTab.activeTraderOption || currentTab.activeTraderOption?.AssetType.includes("OPTION") || currentTab.ticker.AssetType.includes("OPTION")) ? "OP" : this.updatedTicker.AssetType,
      };
      // var _this = this;
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(res.data),
            });
          } else {
            res.data.Orders.forEach((order) => {
              var sendLegs = "";
              if (order["Error"] == null) {
                _this.tickersData.forEach((leg) => {
                  sendLegs += `${leg["Symbol"]},`;
                });
                _this.$gtag.event("order_closing", { legs: sendLegs });
              } else {
                _this.$gtag.event("web_error", {
                  action: "order_closing",
                  res_data: order["Error"],
                });
              }
            });
            if (res.data.Orders[0].Error) {
              if (
                !_this.sellShort &&
                !_this.isClosing &&
                (res.data.Orders[0].Message.includes("EC703") ||
                  res.data.Orders[0].Message.includes("EC704"))
              ) {
                _this.deleteAndRepost(_this, 'Market');
              } else {
                _this.submitted = res.data.Orders[0];
                Toast.error(_this.submitted.Message);
                _this.playSound(
                  "../../order-rejected-cancelled.mp3",
                  _this.sound
                );
                _this.submitted = false;
                return;
              }
            } else {
              _this.submitted = res.data.Orders[0];
              Toast.success(_this.submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.isClosing) {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var averageCost = 0.0;
                  _this.tickersData.forEach((orderTicker) => {
                    if (orderTicker.Buy == true) {
                      averageCost -=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    } else {
                      averageCost +=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    }
                  });
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: averageCost.toString(),
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                      _this.$emit("close");
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }
            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    deleteOrder(orderIds, callback = () => { }) {
      this.loading = true;
      if (!orderIds.length) return;
      var _this = this;
      this.http
        .delete(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) + `orderexecution/orders/${orderIds[0]}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          _this.loading = false;
          if (res.status == 200) {
            orderIds.shift();
            if (orderIds.length > 0) {
              _this.deleteOrder(orderIds, callback);
              // callback();
            } else {
              _this.$emit("close");
              _this.serverResult = "Successfully deleted order!";
              Toast.success(_this.serverResult, { toastClassName: "aries-red" });
              _this.playSound("order-rejected-cancelled.mp3", _this.sound);
              var legSymbols = "";
              _this.order?.Legs.forEach((leg) => {
                legSymbols += `${leg["Symbol"]},`;
              });
              _this.$gtag.event("order_delete", { legs: legSymbols });
              callback();
            }
          } else if (res.data.Message) {
            _this.errors.global = res.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(res.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(res.data),
            });
          }
          _this.$emit("update-cv");
        })
        .catch(function (error) {
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    async deleteAllOrders() {
      var _this = this;
      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds = [];
        let currentTab = this.tabs[this.activeTabIndex];
        let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;
        orderIds = _this.orders
          .filter(
            (element) =>
              element.Legs[0].Symbol === compareSymbol &&
              validStatuses.includes(element.Status)
          )
          .map((e) => e.OrderID);

        if (orderIds.length > 0) {
          _this.deleteOrder(orderIds, () => _this.position ? _this.postDataOrder(false, false) : false);
        } else {
          _this.position ? _this.postDataOrder(false, false) : false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollToHighlighted(tabIndex) {
      const tableBody = this.$refs[`lastPrice${tabIndex}`];
      if(tableBody){

      const highlightedTDs = tableBody.filter((td) =>
        td.classList.contains("highlight")
      );

      const lastHighlightedTD = highlightedTDs[highlightedTDs.length - 1];

      if (lastHighlightedTD) {
        lastHighlightedTD.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
    },
    // isBid(price) {
    //   let compare = "";
    //   const bids = this.bidAskValue.Bids;

    //   bids.forEach((bid) => {
    //     if (bid.Side === "Bid" && bid.Price == price) {
    //       compare = bid.Size;
    //     }
    //   });

    //   return compare;
    // },
    // isAsk(price) {
    //   let compare = "";
    //   const bids = this.bidAskValue.Asks;

    //   bids.forEach((bid) => {
    //     if (bid.Side === "Ask" && bid.Price == price) {
    //       compare = bid.Size;
    //     }
    //   });

    //   return compare;
    // },
    roundFigure(num) {
      return Math.floor(num * 100) / 100;
    },
    formatUnrealizedProfitLoss(value) {
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      let formattedValue = formatter.format(Math.abs(value));

      return formattedValue;
    },
    streamOptions() {
      var _this = this;
      if (this.bidAskStream.connection) {
        this.bidAskStream.connection.end();
        this.bidAskStream.connection.destroy();
      }
      let currentTab = this.tabs[this.activeTabIndex];
      let compareSymbol = currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol : currentTab.ticker.Symbol;

      var optionOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/marketdepth/quotes/${compareSymbol}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      _this.bidAskStream.connection = _this.stream
        .get(optionOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            if (_this.bidAskStream.timeout) {
              clearTimeout(_this.bidAskStream.timeout);
            }
            _this.bidAskStream.timeout = setTimeout(function () {
              _this.bidAskStream = {
                symbols: "",
                connection: null,
                leftOver: null,
                data: null,
                timeout: null,
              };
            }, 10000);
            if (!_this.bidAskStream || !this.bidAskStream.connection) {
              tsRes.destroy();
              return;
            }
            if (chunk.indexOf("END") != -1) {
              clearTimeout(_this.bidAskStream.timeout);
            }
            chunk = chunk.replace(/END/g, "");
            chunk = chunk.replace(/\r/g, "");
            if (_this.bidAskStream.leftOver != null) {
              chunk = _this.bidAskStream.leftOver + chunk;
              _this.bidAskStream.leftOver = null;
            }
            var quotes = chunk.split("\n");

            for (var a = 0; a < quotes.length; a++) {
              if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                var snapShot = JSON.parse(quotes[a]);
                if (snapShot.Error === "Unauthorized" || snapShot.Error) {
                  if (this.ticker) {
                    this.bidAskValue.Bids.push({ Side: 'Bid', Price: this.ticker.Bid, Size: this.ticker.BidSize });
                    this.bidAskValue.Asks.push({ Side: 'Ask', Price: this.ticker.Ask, Size: this.ticker.AskSize });
                  }
                  // this.message = "No level 2 data access for ticker";
                  return;
                }
                if (snapShot.Bids) {
                  _this.bidAskValue.Bids = snapShot.Bids;
                }
                if (snapShot.Asks) {
                  _this.bidAskValue.Asks = snapShot.Asks;
                }
              }
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    async fetchSymbolDescription() {
      if (!this.tickers) {
        return;
      }
      let _this = this;
      let descriptionList = [];
      let currentTab = this.tabs[this.activeTabIndex];

      try {
        const response = await _this.http.get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `marketdata/symbols/${currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol.split(" ")[0] : currentTab.ticker.Symbol.split(" ")[0]}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        );

        descriptionList = response.data.Symbols.map((symbolInfo) => ({
          [symbolInfo.Symbol]: symbolInfo.Description,
        }));

        _this.tickerDescription = descriptionList[0][currentTab.activeTraderOption ? currentTab.activeTraderOption.Symbol.split(" ")[0] : currentTab.ticker.Symbol.split(" ")[0]];
        currentTab.activeTraderOption ? currentTab.activeTraderOption.Description = _this.tickerDescription : currentTab.ticker.Description = _this.tickerDescription;

        if (response.data.Symbols.length > 0) {
          _this.updatedTicker.AssetType = response.data.Symbols[0].AssetType;
          if (
            response.data.Symbols[0].PriceFormat &&
            response.data.Symbols[0].PriceFormat.IncrementStyle
          ) {
            _this.priceIncrement.style =
              response.data.Symbols[0].PriceFormat.IncrementStyle;
            if (_this.priceIncrement.style == "Simple") {
              _this.priceIncrement.increment =
                response.data.Symbols[0].PriceFormat.Increment;
            } else if (_this.priceIncrement.style == "Schedule") {
              _this.priceIncrement.schedule =
                response.data.Symbols[0].PriceFormat.IncrementSchedule;
            }
          }
        }
        _this.loading = false;
      } catch (error) {
        console.log(error);
        _this.loading = false;
      }
    },
    tapBid(bid) {
      if (localStorage.activeBehaviour == "Send order on double tap") {
        if (this.readyBid != bid) {
          this.readyBid = bid;
          return;
        }
      }
      this.$emit("tapBid", bid);
      this.readyBid = null;
      this.readyAsk = null;
      this.readyMarket = null;
    },
    tapAsk(ask) {
      if (localStorage.activeBehaviour == "Send order on double tap") {
        if (this.readyAsk != ask) {
          this.readyAsk = ask;
          return;
        }
      }
      this.$emit("tapAsk", ask);
      this.readyBid = null;
      this.readyAsk = null;
      this.readyMarket = null;
    },
    tapMarket(buyOrSell) {
      if (localStorage.activeBehaviour == "Send order on double tap") {
        if (this.readyMarket != buyOrSell) {
          this.readyMarket = buyOrSell;
          return;
        }
      }
      this.$emit("tapMarket", buyOrSell);
      this.readyBid = null;
      this.readyAsk = null;
      this.readyMarket = null;
    },
  },
};
</script>

<style scoped>
.dark .header-tab{
  color: rgb(151, 153, 154);
}
.result {
  margin-top: 20px;
  cursor: pointer;
}

.result p {
  font-family: "Roboto";
  margin: 0;
  text-align: left;
  font-size: 14px;
}

.result .ticker {
  font-weight: 500;
}

.search-results {
  position: absolute;
  top: 45px;
  left: 20px;
  right: 20px;
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 10px 50px RGBA(0, 0, 0, 0.16);
  z-index: 20;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 375px;
  overflow-y: scroll;
}

.dark .search-results {
  background: #191919;
}

.ticker-container {
  display: flex;
  align-items: center;
}

.search-box {
  position: relative;
}

img {
  position: absolute;
  top: 7px;
  right: 18px;
  z-index: 30;
  width: 14px;
  height: 14px;
}

.search-box input {
  position: relative;
  border: none;
  height: 28px;
  font-size: 14px;
  box-shadow: 0px 1px 2px 0px #00000014;
  border-radius: 4px;
}

.noShadow .search-box input {
  box-shadow: none;
}

.dark .search-box input {
  background: #1F2324;
  color: #d9d9d9;
  box-shadow: none;
}

.search-box input:focus {
  outline: none;
}

th:last-child span {
  border-right: hidden !important;
}

table td:last-child span {
  border-right: hidden !important
}

.main-depth {
  margin-top: 18px !important;
  min-width: 100%;
}

.first-row {
  display: flex;
  gap: 70px;
  justify-content: center;
  background-color: #D9D9D926;
  padding: 12px
}

.main-name {
  display: flex;
  align-items: center
}

.main-name span {
  display: block;
  align-items: center
}

.title-span {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.second-title-span {
  font-size: 14px;
  font-weight: 400;
}

.quantity-div {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.quantity-select select {
  width: 300px;
  height: 25px;
  padding: 5px;
  gap: 0;
  border: 0.5px solid #d8dbde;
  background: white;
  /* margin-top: 4px; */
}

.quantity-select select:focus {
  outline: none;
}

.dark .quantity-select select {
  background: #333333;
  color: #ffffff;
}

.second-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 5px;
}

.buy-mkt {
  width: 84px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #10bc74;
  color: #ffffff;
  font-size: 12px;
  height: 21px;
}

.exit-mkt {
  width: 151px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #97999a;
  color: #ffffff;
  font-size: 12px;
  height: 21px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.sell-mkt {
  width: 85px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background-color: #d20b3b;
  color: #ffffff;
  font-size: 12px;
  height: 21px;
}

.dark .dark-title {
  color: #ffffff !important;
}

.third-row {
  margin-top: 10px;
  max-height: 349px;
  overflow-y: auto;
}

thead {
  position: sticky;
  top: 0;
  z-index: 30;
  background: #ffffff;
}

thead th {
  min-width: 68px;
  justify-content: space-evenly;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: white;
  z-index: 10;
  padding-bottom: 2px;
  border-collapse: collapse;
}

thead th span {
  display: inline-block;
  width: 100.5%;
  border-right: 1px solid #757575;

}

table {
  position: relative;
}

tbody tr td {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  white-space: break-spaces;
  vertical-align: text-top;
}

.highlight {
  background: #97999a;
}

.order-tab {
  width: 47px !important;
  min-width: 47px;
  min-height: 20px;
  height: 20px;
  border-radius: 4px;
  background: #0baa5e;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 2px;
}

.position-relative {
  position: relative;
}

.close-icon {
  display: none;
  position: absolute;
  right: 0;
  color: white;
  padding: 2px;
  cursor: pointer;
  font-size: 12px;
  padding-top: 0px;
  margin-top: -2px;
}

.position-relative:hover .close-icon {
  display: block;
}

.sell-order-tab {
  width: 47px !important;
  height: 20px;
  min-width: 47px;
  min-height: 20px;
  border-radius: 4px;
  background: #d20b3b;
  position: absolute;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 2px;
}

.price-display {
  border-left: 1px solid #0baa5e;
  border-right: 1px solid #d20b3b;
  min-width: 150px;
}

.tbody {
  max-height: 500px;
  overflow-y: auto;
  padding-top: 90px;
  transform: translateY(36px);
}

.fourth-row {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  background: #d9d9d926;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
}

.count-div {
  min-width: 68px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
}

.count-div span {
  border-right: 1px solid #757575;
  text-align: center;
  width: 50%;
}

.count-div span:last-child {
  gap: 0;
  border-right: none;
}


.triangle {
  width: 0 !important;
  height: 0 !important;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}


.bid-bg {
  display: flex;
  justify-content: flex-end;
}

.hover-bid-border:hover {
  border: 1px solid #d20b3b;
  border-left: none;
}

.hover-ask-border:hover {
  border: 1px solid #0baa5e;
  border-right: none;
}

.gotoLast {
  min-width: 300px !important;
}

.duration {
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px;
  margin-top: 10px
}

.duration-choices {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: black;
  height: 22px;
  border-radius: 2px;
  width: 36px;
  text-align: center;
  cursor: pointer;
}

.selected-duration {
  background-color: #e8e6e6;
  width: 84%;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  color: black;
  border: 1px solid #e8e6e6;
  height: 22px;
  border-radius: 2px;
  width: 36px;
  text-align: center;
  cursor: pointer;
}

.select-style {
  width: 84px;
  height: 24px;
  border: 0.5px solid #d8dbde;
  background-color: #fff;
  display: inline-block;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-style select {
  width: 100%;
  height: 100%;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  thead th {
    min-width: 40px;
  }

  .price-display {
    max-width: 92px;
  }

  .gotoLast {
    min-width: 186px !important;
  }

}

@media only screen and (min-width: 1024px) {
  .input-container input {
    width: 52% !important;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }

  .down-arrow {
    cursor: pointer;
    position: absolute;
    left: 44% !important;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line-between {
    position: absolute;
    left: 39% !important;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65%;
    top: -5px
  }

}

@media only screen and (min-width: 1444px) {
  .input-container input {
    width: 84% !important;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }

  .down-arrow {
    cursor: pointer;
    /* padding: 8px; */
    position: absolute;
    left: 46% !important;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line-between {
    position: absolute;
    left: 39% !important;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65%;
    top: -5px
  }

}



@media only screen and (max-width: 1024px) {
  .first-row {
    gap: 220px
  }

  .second-row {
    gap: 210px
  }

  .third-row {
    max-height: 280px
  }
}

.select-container {
  display: flex;
  align-items: center;
  min-width: 20%
}

.input-container {
  display: flex;
  position: relative;
  margin: 8px
}

.input-container input {
  width: 84% !important;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
}

.dark .input-container input {
  background: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  outline: none;
}

.options-container {
  position: absolute;
  width: 70%;
  max-height: 200%;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  z-index: 80px;
}

.option {
  padding: 5px;
  cursor: pointer;
  margin-left: 5px;
  color: #2e2b2b
}

.option:hover {
  background: #f0f0f0;
}



.chips-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.chip {
  background: #007bff;
  color: white;
  padding: 4px 8px;
  border-radius: 16px;
  margin: 4px;
  display: flex;
  align-items: center;
}

.chip span {
  margin-left: 8px;
  cursor: pointer;
}

.down-arrow {
  cursor: pointer;
  /* padding: 8px; */
  position: absolute;
  left: 62%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.line-between {
  position: absolute;
  left: 48%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65%;
  top: -5px
}

.warning {
  color: red;
  font-weight: bold;
  font-size: 14px;
  padding-left: 6px;
}

.show-wanning {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.card-header {
  padding: 0rem !important;
}

.select-container input {
  width: 75px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  text-align: center;
}

.dark .select-container input {
  background: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  outline: none;
}

.first-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  color: #757575;
  max-width: 49px;
  line-height: 1.2;
  cursor: default;
}

.dark .first-description {
  color: #ffffff;
}

.tab-content {
  margin-top: -9.6px !important;
}

</style>