<template>
    <footer class="ts-footer">
        <p>Equities, equities options, and commodity futures products and services (including user accounts) are offered
            by TradeStation Securities, Inc. (Member NYSE, FINRA, CME and SIPC). TradeStation Securities, Inc.'s SIPC
            coverage is available only for securities, and for cash held in connection with the purchase or sale of
            securities, in equities and equities options accounts.<br>
            v2.8.3
        </p>
    </footer>
</template>

<script>
export default {
    name: "TsFooter",
    props: {
    },
    components: {},
    methods: {
    },
};
</script>

<style scoped>
p {
    color: #aaa;
    font-size: 12px;
    width: 900px;
    max-width: 100%;
    text-align: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}
</style>
