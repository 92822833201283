<template>
  <div class="flow-table">
    <table>
      <tr class="flow-headings">
        <th @click="updateSort('time')" class="actionable">
          Time
          <Sorting columnName="time" :sort="sort"></Sorting>
        </th>
        <th>Ticker</th>
        <th @click="updateSort('exp')" class="actionable">
          Exp.
          <Sorting columnName="exp" :sort="sort"></Sorting>
        </th>
        <th @click="updateSort('strike')" class="actionable">
          Strike
          <Sorting columnName="strike" :sort="sort"></Sorting>
        </th>
        <th>C/P</th>
        <th>Spot</th>
        <th @click="updateSort('size')" class="actionable">
          Size
          <Sorting columnName="size" :sort="sort"></Sorting>
        </th>
        <th @click="updateSort('price')" class="actionable">
          Price
          <Sorting columnName="price" :sort="sort"></Sorting>
        </th>
        <th>Bid/Ask</th>
        <th>Type</th>
        <th @click="updateSort('totalValue')" class="actionable">
          Total
          <Sorting columnName="totalValue" :sort="sort"></Sorting>
        </th>
        <th>Sentiment</th>
        <!-- <th></th> -->
      </tr>
      <FlowRow
        v-for="(row, index) in flow"
        :rowData="row"
        :key="'row' + index"
      />
    </table>
  </div>
</template>

<script>
import FlowRow from "./FlowRow.vue";
import Sorting from "./Sorting.vue";

export default {
  name: "FlowTable",
  props: {
    flow: Array,
  },
  components: {
    FlowRow,
    Sorting,
  },
  data() {
    return {
      sort: {
        column: "",
        state: "asc",
      },
    };
  },
  methods: {
    updateSort(column) {
      console.log("Sort called", column);
      if (this.sort.column != column) {
        this.sort.column = column;
        this.sort.state = "";
      }

      if (this.sort.state == "") {
        this.sort.state = "asc";
      } else if (this.sort.state == "asc") {
        this.sort.state = "desc";
      } else {
        this.sort.state = "";
      }
      localStorage.setItem("flowSort", JSON.stringify(this.sort));
      this.$emit("newSort", this.sort);
    },
  },
  mounted: function () {
    if (localStorage.flowSort != null && JSON.parse(localStorage.flowSort)) {
      this.sort = JSON.parse(localStorage.flowSort);
    }
  },
};
</script>

<style scoped>
.flow-table {
  box-shadow: 0px 15px 30px RGBA(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #eceff2;
}
.dark .flow-table {
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
  background: #000000;
  border: 1px solid #333333;
}
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
tr {
  border-top: 1px solid #eef0f3;
}
.dark tr {
  border-top: 1px solid #333333;
}
.flow-headings {
  height: 53px;
  border-bottom: 1px solid #eef0f3;
  border-top: none;
}
.dark .flow-headings {
  border-bottom: 1px solid #333333;
}
th {
  font-size: 15px;
  font-weight: 600;
}
th:first-of-type {
  padding-left: 32px;
}
th.actionable {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  table {
    text-align: center;
  }
  .flow-headings {
    display: none;
  }
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
