<template>
  <div class="sort">
    <div
      v-if="sort.column == columnName && sort.state == 'desc'"
      class="triangle-down"
    ></div>
    <div
      v-else-if="sort.column == columnName && sort.state == 'asc'"
      class="triangle-up"
    ></div>
    <div v-else class="triangle-neutral">
      <div class="triangle-up"></div>
      <div class="triangle-down"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sorting",
  props: {
    columnName: String,
    sort: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.sort {
  display: inline-block;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #555;
}
.dark .triangle-down {
  border-top: 10px solid #d9d9d9;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 6px solid #555;
}
.triangle-neutral .triangle-up {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 4px solid #999;
  margin-bottom: 2px;
}
.triangle-neutral .triangle-down {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 4px solid #999;
}
.dark .triangle-up {
  border-bottom: 10px solid #d9d9d9;
}
</style>
