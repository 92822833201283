<template>
  <div class="crypto-help-menu">
    <p class="heading main">How do I fund my crypto account?</p>
    <p>You can fund your crypto account in two ways:</p>
    <div class="indent">
      <p>
        <strong>From TS Account:</strong> Login to
        <a href="https://www.tradestation.com/" target="_blank"
          >TradeStation Client Center</a
        >
        and make a funds transfer from your equities account into your crypto
        account.
      </p>
      <p>
        <strong>Fund with Crypto:</strong> Login to
        <a href="https://www.tradestation.com/" target="_blank"
          >TradeStation Crypto</a
        >, select your account in the top right, then select Crypto Transfers.
      </p>
    </div>
    <p class="heading">How do I trade crypto?</p>
    <p>
      Select one of the available coins and click the "Buy" button for a crypto
      pair.
    </p>
    <p class="heading">What are crypto pairs?</p>
    <p>
      Crypto trades in pairs. For example BTCUSD trades BTC for USD. The default
      pair is always USD, but some coins can trade between eachother (example:
      ETHBTC).
    </p>
    <p class="heading">How do I earn interest?</p>
    <p>
      In order to earn interest on an eligible cryptocurrency, you must maintain
      a minimum daily notional value of USD $50 of such cryptocurrency. Interest
      is calculated daily, but paid out and compounded monthly.
    </p>
    <p class="heading">Can I use crypto for equities?</p>
    <p>
      Users are free to move USD between their crypto and equities account at
      any time, given that the funds have been cleared. Interest is still earned
      on crypto holdings if the funds are back in the cryptocurrency before the
      end of the trading day.
    </p>
    <p class="heading">What are the trading fees?</p>
    <p>
      For USD to USDC conversion there are no fees. For other coins please
      review
      <a href="https://www.tradestation.com/pricing/" target="_blank"
        >TradeStation Pricing</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "CryptoHelp",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.crypto-help-menu {
  padding: 10px;
  min-width: 280px;
}
p {
  margin: 0;
}
.indent {
  padding-left: 1em;
}
.heading {
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
.heading.main {
  margin-top: 0;
}
a {
  color: #10bc74;
}
@media only screen and (max-width: 425px) {
}
</style>
