<template>
  <div class="page-counter">
    <span class="previous">
      <span
        v-if="!previousLoader"
        :class="disablePrevious"
        @click="loadPage('previous')"
      >
        &lt;
      </span>
      <img
        v-if="previousLoader"
        class="spinner-icon"
        src="../assets/loading.gif"
      />
    </span>
    <span class="count">&nbsp;Page {{ currentPage + 1 }}&nbsp;</span>
    <span class="next">
      <span v-if="!nextLoader" :class="disableNext" @click="loadPage('next')">
        &gt;
      </span>
      <img v-if="nextLoader" class="spinner-icon" src="../assets/loading.gif" />
    </span>
  </div>
</template>

<script>
export default {
  name: "PageCounter",
  props: {
    currentPage: Number,
    nextPage: String,
  },
  data: function () {
    return {
      previousLoader: false,
      nextLoader: false,
    };
  },
  watch: {
    nextPage() {
      this.previousLoader = false;
      this.nextLoader = false;
    },
  },
  computed: {
    disablePrevious() {
      return this.currentPage < 1 ? "previous-disabled" : "";
    },
    disableNext() {
      return !this.nextPage ? "next-disabled" : "";
    },
  },
  methods: {
    loadPage(direction) {
      if (!this.disableNext && direction == "next") {
        this.nextLoader = true;
        this.$emit("loadPage", direction);
      } else if (!this.disablePrevious && direction == "previous") {
        this.previousLoader = true;
        this.$emit("loadPage", direction);
      }
    },
  },
};
</script>

<style scoped>
.page-counter {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  font-family: "Outfit";
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #151c26;
  margin: 10px;
}
.dark .page-counter {
  color: #d9d9d9;
}
.previous,
.next {
  width: 24px;
  height: 24px;
  margin: 0px 5px;
  cursor: pointer;
}
.spinner-icon {
  width: 24px;
  height: 24px;
  margin: 0px 5px;
}

.previous-disabled,
.next-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.previous:active,
.next:active,
.previous:hover,
.next:hover {
  color: #0baa5e;
}
</style>
