<template>
  <tr class="flow-row" :class="{
    calls: rowData.contract.charAt(12) == 'C',
    puts: rowData.contract.charAt(12) == 'P',
    'flag-purple': isUnusual(rowData),
    'flag-green': rowData.total_value >= 1000000,
  }">
    <td>
      {{
          `${monthNameShort(
            parseInt(rowData.timestamp.substring(5, 7)) - 1
          )} ${rowData.timestamp.substring(8, 10)} ${rowData.timestamp.substring(
            2,
            4
          ) == currentYear ? '' : ", '" + rowData.timestamp.substring(
            2,
            4
          )}`
      }}

      - {{ timeDisplay(rowData.timestamp) }}
    </td>
    <td>
      <div class="ticker">{{ rowData.symbol }}</div>
    </td>
    <td>
      <span class="small">Expiry: </span>{{
          `${monthNameShort(
            parseInt(rowData.contract.substring(8, 10)) - 1
          )} ${rowData.contract.substring(
            10,
            12
          )}, 20${rowData.contract.substring(6, 8)}`
      }}
    </td>
    <td>
      <span class="small">Strike: </span>{{
          dollarWithCommas(parseFloat(rowData.contract.substring(13, 21)) / 1000)
      }}
    </td>
    <td>{{ rowData.contract.charAt(12) == "C" ? "Calls" : "Puts" }}</td>
    <td>
      <span class="small">Spot: </span>{{
          rowData.underlying_price_at_execution
            ? dollarWithCommas(rowData.underlying_price_at_execution)
            : "-"
      }}
    </td>
    <td>
      <span class="small">Size: </span>{{ numberWithCommas(rowData.total_size) }}
    </td>
    <td>
      <span class="small">Price: </span>{{ dollarWithCommas(rowData.average_price) }}
    </td>
    <td>
      <span class="small">Bid/Ask: </span>{{ dollarWithCommas(rowData.bid_at_execution) }}/{{
          dollarWithCommas(rowData.ask_at_execution)
      }}
    </td>
    <td>
      <span class="small">Type: </span>{{ rowData.type.charAt(0).toUpperCase() + rowData.type.slice(1) }}
    </td>
    <td class="value">
      <span class="small">Value: </span>${{ shortDollar(rowData.total_value) }}
    </td>
    <td :class="'sentiment ' + rowData.sentiment">
      {{
    rowData.sentiment.charAt(0).toUpperCase() + rowData.sentiment.slice(1)
      }}
    </td>
    <!-- <td class="more"><button class="big">...</button><span class="small">View More</span></td> -->
  </tr>
</template>

<script>
import Mixins from "../Mixins";
export default {
  name: "FlowRow",
  props: {
    rowData: Object,
  },
  mixins: [Mixins],
  data() {
    return {
      currentYear: process.env.VUE_APP_YEAR
    };
  },
  methods: {
    isUnusual(row) {
      var dateExpire = new Date(
        row.contract.substring(8, 10) +
        "/" +
        row.contract.substring(10, 12) +
        "/" +
        row.contract.substring(6, 8)
      );
      var timeDif = dateExpire.getTime() - new Date();
      var daysDif = timeDif / (1000 * 3600 * 24);
      if (
        row.underlying_price_at_execution &&
        daysDif <= 30 &&
        ((row.contract.charAt(12) == "C" &&
          parseFloat(row.contract.substring(13, 21)) / 1000 >=
          row.underlying_price_at_execution * 1.1) ||
          (row.contract.charAt(12) == "P" &&
            parseFloat(row.contract.substring(13, 21)) / 1000 <=
            row.underlying_price_at_execution * 0.9))
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
td {
  font-size: 13px;
  height: 40px;
  padding: 0 5px;
}

td:first-of-type {
  padding-left: 32px;
}

.ticker {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  color: #ffffff;
}

.calls .ticker {
  background: #10bc74;
}

.puts .ticker {
  background: #ff6c4b;
}

.value {
  color: #7152c9;
  font-weight: 600;
}

.sentiment {
  font-weight: 600;
}

.bearish.sentiment {
  color: #ff6c4b;
}

.bullish.sentiment {
  color: #10bc74;
}

td button {
  background: none;
  border: none;
  font-weight: 600;
  font-size: 1.5em;
  position: relative;
  padding-bottom: 7px;
  cursor: pointer;
}

.flag-green {
  background: #effbf6;
  border-top: 1px solid #10bc74;
}

.dark .flag-green {
  background: rgba(16, 188, 116, 0.33);
}

.flag-purple {
  background: #f7f4ff;
  border-top: 1px solid #5831c7;
}

.dark .flag-purple {
  background: rgba(89, 49, 199, 0.33);
}

.small {
  display: none;
}

@media only screen and (max-width: 768px) {
  .big {
    display: none;
  }

  .flow-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  td {
    display: block;
    width: 33%;
    box-sizing: border-box;
    height: 20px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  td:first-of-type {
    padding-left: 0;
  }

  .small {
    display: inline;
  }

  .more {
    font-weight: 600;
  }
}

@media only screen and (max-width: 500px) {
  td {
    width: 50%;
    height: auto;
  }
}
</style>
