<template>
  <div class="order-card">
    <p class="symbol" :class="{ green: greenSymbol, red: !greenSymbol }">
      {{ order.Type }} {{ order.Legs[0].QuantityOrdered }}x
      {{ displaySymbol(order) }}
    </p>
    <p class="time-date">
      {{ longShort
      }}{{ order.Legs[0].OpenOrClose ? `to ${order.Legs[0].OpenOrClose}` : "" }}

      {{ orderTime }} ({{ orderDate.toLocaleDateString() }})
    </p>
    <p class="price" v-if="stillOpen(order) && order.LastPriceText">
      Last: {{ order.LastPriceText.replace("-", "") }}
    </p>
    <p class="fill" v-if="stillOpen(order)">
      Filled: {{ order.Legs[0].ExecQuantity }}
    </p>
    <p class="price" v-if="!stillOpen(order)">
      Filled: {{ order.Legs[0].ExecQuantity }}
    </p>

    <KeyValue last v-if="!stillOpen(order) && !serverResult" key-string="Fill Price" :value="order.FilledPrice" />

    <div v-if="serverResult">
      <p class="success-message green">{{ serverResult }}</p>
    </div>

    <div class="controls" v-if="stillOpen(order) && !serverResult">
      <div class="input-line">
        <KeyValue :key-string="statusMap[order.Status]" :value="order.OrderType + ' Order'" />
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="quantity">Quantity</label>
          <input :disabled="crypto" type="text" name="quantity" id="quantity" v-model="quantity" placeholder="0" />
        </div>
        <p class="error" v-if="errors.quantity">{{ errors.quantity }}</p>
      </div>
      <div class="input-line" v-if="order.OrderType.indexOf('Limit') != -1">
        <div class="input-row">
          <label for="limitPrice">Limit Price</label>
          <input :disabled="crypto" type="text" name="limitPrice" id="limitPrice" v-model="limitPrice" class="limit-price" ref="limitPriceInput"
            placeholder="0.00" />
        </div>
        <div class="price-select" v-if="order.LastPriceText">
          <p @click="limitPrice = totalBid">
            Bid: ${{ dollarWithCommas(totalBid) }}
          </p>
          <p @click="limitPrice = ((totalAsk + totalBid) / 2).toFixed(2)">
            Mid: ${{ dollarWithCommas((totalAsk + totalBid) / 2) }}
          </p>
          <p @click="limitPrice = totalAsk">
            Ask: ${{ dollarWithCommas(totalAsk) }}
          </p>
        </div>
        <p class="error" v-if="errors.limitPrice">{{ errors.limitPrice }}</p>
      </div>
      <div class="input-line" v-if="order.OrderType.indexOf('Stop') != -1">
        <div class="input-row">
          <label for="stopPrice">Stop Price</label>
          <input :disabled="crypto" type="text" name="stopPrice" id="stopPrice" v-model="stopPrice" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.stopPrice">{{ errors.stopPrice }}</p>
      </div>
    </div>

    <p class="error red" v-if="errors.global">{{ errors.global }}</p>
    <p class="error red" v-if="order.RejectReason">{{ order.RejectReason }}</p>

    <div class="buttons" :class="{ crypto: crypto }">
      <div class="row">
        <button class="cancel" @click="close" v-if="!loading && !serverResult">
          Cancel
        </button>
        <button class="delete" @click="deleteOrder" v-if="!loading && !serverResult && stillOpen(order)">
          Delete
        </button>
        <button class="blank full" v-if="loading">
          <img class="loading" src="../../assets/loading.gif" />
        </button>
        <button @click="updateOrder" v-if="!loading && !serverResult && !crypto && stillOpen(order)">
          Update
        </button>
        <button class="full" @click="close" v-if="!loading && serverResult">
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../../Mixins";
import KeyValue from "../KeyValue.vue";
import Toast from "../Toasts.vue";

export default {
  name: "OrderCard",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  props: {
    order: Object,
    refresh: Boolean,
    crypto: {
      type: Boolean,
      default: false,
    },
    sound: Boolean,
  },
  components: {
    KeyValue,
  },
  data() {
    return {
      quantity: this.order.Legs[0].QuantityOrdered,
      limitPrice: this.order.LimitPrice,
      stopPrice: this.order.StopPrice,
      loading: false,
      serverResult: null,
      errors: {},
      statusMap: {
        OPN: "Open",
        ACK: "Pending",
        UCN: "Cancel Pending",
        FLL: "Filled",
        FLP: "Partially-Filled & Cancelled",
        FPR: "Partial Fill",
        OUT: "Cancelled",
        REJ: "Rejected",
        TSC: "Rejected",
        EXP: "Expired",
        BRO: "Broken",
        CAN: "Cancelled by Exchange",
        LAT: "Too late",
        DON: "Queued",
      },
    };
  },
  watch: {
    refresh: function () {
      this.quantity = this.order.Legs[0].QuantityOrdered;
      this.limitPrice = this.order.LimitPrice;
      this.stopPrice = this.order.StopPrice;
    },
    order(newValue) {
      if (newValue) {
        this.$refs.limitPriceInput.focus();
        this.limitPrice = this.order.LimitPrice;
      }
    },
  },
  computed: {
    orderDate() {
      if (this.stillOpen(this.order)) {
        return new Date(this.order.OpenedDateTime);
      } else {
        return new Date(this.order.ClosedDateTime);
      }
    },
    longShort() {
      if (this.order.Legs.length == 1) {
        if (this.order.Legs[0].BuyOrSell == "SellShort") {
          return "Short ";
        } else if (this.order.Legs[0].BuyOrSell == "Buy") {
          return "Buy ";
        } else if (this.order.Legs[0].BuyOrSell == "Sell") {
          return "Sell ";
        }
      }
      return null;
    },
    greenSymbol() {
      if (this.longShort == "Short " || this.longShort == "Sell ") {
        return false;
      } else {
        return true;
      }
    },
    orderTime() {
      var getOrderTime = new Date(this.order.OpenedDateTime);
      if (!this.stillOpen(this.order)) {
        getOrderTime = new Date(this.order.ClosedDateTime);
      }
      return `${getOrderTime.getHours()}:${getOrderTime.getMinutes() < 10
        ? "0" + getOrderTime.getMinutes()
        : getOrderTime.getMinutes()
        }`;
    },
    totalBid: function () {
      if (!this.order.LastPriceText) {
        return null;
      }
      var totalBid = 0;
      for (var i = 0; i < this.order.Legs.length; i++) {
        if (this.order.Legs.length > 1) {
          if (this.order.Legs[i].BuyOrSell == "Buy") {
            totalBid += parseFloat(this.order.Legs[i].Bid);
          } else {
            totalBid -= parseFloat(this.order.Legs[i].Bid);
          }
        } else {
          totalBid += parseFloat(this.order.Legs[i].Bid);
        }
      }

      return totalBid;
    },
    totalAsk: function () {
      if (!this.order.LastPriceText) {
        return null;
      }
      var totalAsk = 0;
      for (var i = 0; i < this.order.Legs.length; i++) {
        if (this.order.Legs.length > 1) {
          if (this.order.Legs[i].BuyOrSell == "Buy") {
            totalAsk += parseFloat(this.order.Legs[i].Ask);
          } else {
            totalAsk -= parseFloat(this.order.Legs[i].Ask);
          }
        } else {
          totalAsk += parseFloat(this.order.Legs[i].Ask);
        }
      }

      return totalAsk;
    },
  },
  mounted() {
    if(this.$refs.limitPriceInput)
    this.$refs.limitPriceInput.focus();
  },
  methods: {
    stillOpen(x) {
      if (
        this.statusMap[x["Status"]] == "Open" ||
        this.statusMap[x["Status"]] == "Pending" ||
        this.statusMap[x["Status"]] == "Cancel Pending" ||
        this.statusMap[x["Status"]] == "Queued" ||
        this.statusMap[x["Status"]] == "Partial Fill"
      ) {
        return true;
      } else {
        return false;
      }
    },
    deleteOrder() {
      this.loading = true;

      var _this = this;
      this.http
        .delete(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `orderexecution/orders/${this.order.OrderID}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          _this.loading = false;
          if (res.status == 200) {
            _this.$emit("close");
            _this.serverResult = "Successfully deleted order!";
            Toast.success(_this.serverResult, { toastClassName: "aries-red" });
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            var legSymbols = "";
            _this.order["Legs"].forEach((leg) => {
              legSymbols += `${leg["Symbol"]},`;
            });
            _this.$gtag.event("order_delete", { legs: legSymbols });
          } else if (res.data.Message) {
            _this.errors.global = res.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(res.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(res.data),
            });
          }
          _this.$emit("update-cv");
        })
        .catch(function (error) {
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_delete",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    updateOrder() {
      this.loading = true;

      var _this = this;
      var updateUrl =
        (localStorage.sim == "true"
          ? process.env.VUE_APP_TS_SIM
          : process.env.VUE_APP_TS) +
        `orders/${this.order.OrderID}?access_token=${encodeURIComponent(
          localStorage.accessToken
        )}`;
      var requestBody = {
        Quantity: this.quantity,
        OrderType: this.order.OrderType,
      };
      if (this.order.OrderType.indexOf("Limit") != -1) {
        requestBody["LimitPrice"] = this.limitPrice;
      }
      if (this.order.OrderType.indexOf("Stop") != -1) {
        requestBody["StopPrice"] = this.stopPrice;
      }
      this.http
        .put(updateUrl, requestBody, {
          headers: { "Content-Type": "application/json; charset=utf-8" },
        })
        .then(function (res) {
          _this.loading = false;
          if (res.data.OrderStatus == "Ok") {
            _this.serverResult = "Successfully updated order!";
            _this.$emit("close");
            Toast.info(_this.serverResult);
            _this.playSound("order-placed-updated.mp3", _this.sound);
            var legSymbols = "";
            _this.order["Legs"].forEach((leg) => {
              legSymbols += `${leg["Symbol"]},`;
            });
            _this.$gtag.event("order_update", { legs: legSymbols });
          } else if (res.data.Message) {
            _this.errors.global = res.data.Message;
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            Toast.error(_this.errors.global);
            _this.$gtag.event("web_error", {
              action: "order_update",
              res_data: JSON.stringify(res.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_update",
              res_data: JSON.stringify(res.data),
            });
          }

          _this.$emit("update-cv");
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_update",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_update",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    close() {
      console.log("made it here");
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.limit-price{
  max-width: 75px !important;
  /* min-width: 60px; */
  border:  1px solid !important;
  border-radius: 2px;
  box-sizing: border-box;
  height:35px
}

.order-card {
  min-width: 280px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.order-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

p {
  margin: 0;
}

.success-message {
  font-size: 21px;
  text-align: center;
  margin: 20px 0;
}

.symbol {
  text-align: center;
  font-weight: 500;
  font-size: 21px;
}

.open {
  text-align: center;
  font-size: 16px;
  color: #909194;
  margin-bottom: 0px;
}

.time-date {
  text-align: center;
  font-size: 16px;
  color: #909194;
  margin-bottom: 10px;
}

.price {
  text-align: center;
  font-size: 18px;
}

.fill {
  text-align: center;
}

.error {
  text-align: center;
  margin-bottom: 10px;
}

.loading {
  width: 18px;
  height: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.buttons.crypto .row {
  justify-content: flex-end;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}

.dark button {
  background: #000000;
  color: #10bc74;
  border: 1px solid #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

.dark button.secondary {
  color: #f5f7fa;
  border: 1px solid #f5f7fa;
  background: #000000;
}

button.full {
  width: 100%;
}

button.blank {
  background: #ffffff;
  color: #000000;
}

.dark button.blank {
  background: #000000;
  color: #d9d9d9;
}

button.delete {
  background: #ce0606;
  margin-right: 1rem;
  margin-left: 1rem;
}

button.cancel {
  background-color: #ffffff;
  border: #616164 2px solid;
  color: #616164;
}

.dark button.delete {
  background: #000000;
  border: 1px solid #ce0606;
  color: #ce0606;
}

.dark button.cancel {
  background-color: #000000;
}

.input-line {
  margin: 0 10px;
  margin-bottom: 12px;
  border-bottom: 1px solid #333333;
  padding-bottom: 12px;
  /* max-width: 400px; */
}

.dark .input-line {
  border-bottom: 1px solid #333333;
}

.input-line .key-value {
  border-bottom: none !important;
  padding: 0;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.input-line:last-of-type {
  border-bottom: none;
}

.input-line label {
  font-weight: 600;
  font-size: 18px;
}

.input-line select,
.input-line input {
  background: none;
  border: none;
  font-size: 18px;
  color: #10bc74;
  text-align: right;
  font-family: "Roboto";
  font-weight: 600;
  max-width: 200px;
}

.input-line select:focus,
.input-line input:focus {
  outline: none;
}

.input-line option {
  color: #10bc74;
  background: none;
}

.input-line .description {
  color: #616164;
  margin-top: 5px;
  font-size: 16px;
}

.input-line .price-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
}

.input-line .price-select p {
  font-size: 14px;
  font-weight: 600;
  color: #616164;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 425px) {}
</style>
