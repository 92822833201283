<template>
  <div class="position-card">
    <p class="symbol" :class="{
      red: position.OpenProfitLoss < 0,
      green: position.OpenProfitLoss >= 0,
    }">
      {{ displaySymbol(position) }}
    </p>
    <div class="first-row">
      <KeyValue class="is-half" last key-string="Position" :value="
        position.LongShort +
        ' ' +
        numberWithCommas(position.Quantity.replace('-', ''))
      " />
      <KeyValue last v-if="!byMark" class="is-half" key-string="Last:" :value="'$' + dollarWithCommas(position.Last)" />
      <KeyValue last v-else class="is-half" key-string="Last:"
        :value="'$' + dollarWithCommas(markPosition(position))" />
    </div>
    <KeyValue style="padding: 1.1rem 0rem 0.5rem 0px" last key-string="Average Cost"
      :value="dollarWithCommas(position.AveragePrice)" />
    <div class="bid-mid-ask">
      <p>Bid: {{ dollarWithCommas(position.Bid) }}</p>
      <p>
        Mid:
        {{
  dollarWithCommas(
        (parseFloat(position.Ask) + parseFloat(position.Bid)) / 2
)
        }}
      </p>
      <p>Ask: {{ dollarWithCommas(position.Ask) }}</p>
    </div>

    <KeyValue last key-string="Market Value" :value="dollarWithCommas(position.MarketValue)" />

    <KeyValue v-if="!byMark" last :class="{
      red: position.UnrealizedProfitLoss < 0,
      green: position.UnrealizedProfitLoss >= 0,
    }" key-string="Profit / Loss" :value="dollarWithCommas(position.UnrealizedProfitLoss)" />
    <KeyValue v-else last :class="{
      red: markProfitLossAll(position) < 0,
      green: markProfitLossAll(position) >= 0,
    }" key-string="Profit / Loss" :value="dollarWithCommas(markProfitLossAll(position))" />

    <p class="error red" v-if="error">{{ error }}</p>
    <div class="buttons">
      <div class="row">
        <button @click="cancel()" v-if="!loadingChart" class="cancel">
          Cancel
        </button>
        <button @click="buy()" v-if="!loadingBuy">Add</button>
        <button class="blank" v-if="loadingBuy">
          <img class="loading" src="../../assets/loading.gif" />
        </button>

        <button @click="sell()" v-if="!loadingSell">Close</button>
        <button class="blank" v-if="loadingSell">
          <img class="loading" src="../../assets/loading.gif" />
        </button>
        <button class="blank" v-if="loadingChart">
          <img class="loading" src="../../assets/loading.gif" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../../Mixins";
import KeyValue from "../KeyValue.vue";

export default {
  name: "PositionCard",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  props: {
    position: Object,
    byMark: Boolean,
  },
  components: {
    KeyValue,
  },
  data() {
    return {
      loadingChart: false,
      loadingBuy: false,
      loadingSell: false,
      error: null,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", this.position.Symbol);
    },
    buy() {
      if (this.position.Symbol.indexOf(" ") != -1) {
        this.$emit("buyOptions", [
          {
            Symbol: this.position.Symbol,
            Buy: this.position.LongShort == "Long",
            Bid: this.position.Bid,
            Ask: this.position.Ask,
            AssetType: this.position.AssetType,
          },
        ]);
      } else {
        this.$emit("buy", this.position.Symbol);
      }
    },
    sell() {
      if (this.position.Symbol.indexOf(" ") != -1) {
        this.$emit("sellOptions", [
          {
            Symbol: this.position.Symbol,
            Bid: this.position.Bid,
            Ask: this.position.Ask,
            AssetType: this.position.AssetType,
            Quantity: this.position.Quantity
          },
        ]);
      } else {
        this.$emit("sell", this.position.Symbol);
      }
    },
  },
};
</script>

<style scoped>
.position-card {
  padding: 10px 4rem;
}

.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #eceff2 1px solid;
}

.first-row>.is-half:first-child {
  margin-right: 1rem;
}

.bid-mid-ask {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bid-mid-ask>* {
  padding: 0;
  color: #616164;
  font-size: 16px;
  font-weight: 400;
}

p {
  margin: 0;
}

.is-half {
  width: 40%;
}

.symbol {
  text-align: left;
  font-weight: 500;
  font-size: 21px;
}

.price {
  text-align: center;
  font-size: 18px;
}

.error {
  text-align: center;
  margin-bottom: 10px;
}

.loading {
  width: 18px;
  height: 18px;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.row>button:not(:last-child) {
  margin-right: 1rem;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: #10bc74 2px solid;
  color: #10bc74;
  background: none;
  cursor: pointer;
}

button.cancel {
  border: #616164 2px solid;
  color: #616164;
}

.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

button.full {
  width: 100%;
}

button.blank {
  background: #ffffff;
  color: #000000;
}

@media only screen and (max-width: 720px) {
  .position-card {
    padding: 0;
  }
}

@media only screen and (max-width: 425px) {}
</style>
