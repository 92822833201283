<template>
  <div class="crypto-help-menu">
    <img src="../assets/crypto/BTC.png" alt="BitCoin Image" />
    <p class="heading main">Trade Crypto &amp; Earn Interest!</p>
    <br />
    <p>
      You can now trade Crypto on Aries with your TradeStation account and earn
      interest (up to 5% APY).
    </p>
    <div class="left">
      <p class="heading">How do I start?</p>
      <div class="indent">
        <p>
          1. Login to
          <a href="https://www.tradestation.com/" target="_blank"
            >TradeStation Client Center</a
          >.
        </p>
        <p>2. In the top right select "Add an Account".</p>
        <p>3. Select "Crypto" and complete the application.</p>
      </div>
    </div>
    <br />
    <p>Once approved you're good to go!</p>
  </div>
</template>

<script>
export default {
  name: "NoCrypto",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.crypto-help-menu {
  padding: 10px;
  min-width: 280px;
  text-align: center;
}
img {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
p {
  margin: 0;
}
.indent {
  padding-left: 1em;
}
.left {
  text-align: left;
}
.heading {
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}
a {
  color: #10bc74;
}
@media only screen and (max-width: 425px) {
}
</style>
