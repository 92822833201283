<template>
  <div class="coin" @click="openCrypto" :class="{ borderless: borderless }">
    <img :src="getImgUrl(coin.Currency)" aria-hidden="true" />
    <p class="currency">{{ coin.Currency }}</p>
    <p class="price" v-if="price">
      ${{ price == "-" ? price : dollarWithCommas(price) }}
    </p>
    <div class="details">
      <p class="key">Balance</p>
      <p class="value">
        {{ coin.Balance }} (${{
          dollarWithCommas(coin.BalanceAccountCurrency)
        }})
      </p>
    </div>
    <div class="details">
      <p class="key">Interest Rate</p>
      <p class="value">{{ coin.InterestRate }}%</p>
    </div>
    <div class="details">
      <p class="key">Interest Earned</p>
      <p class="value">
        {{ coin.Interest.Accrued }} (${{
          dollarWithCommas(coin.Interest.AccruedAccountCurrency)
        }})
      </p>
    </div>
    <div class="details">
      <p class="key">Interest Paid</p>
      <p class="value">
        {{ coin.Interest.TotalPaid }} (${{
          dollarWithCommas(coin.Interest.TotalPaidAccountCurrency)
        }})
      </p>
    </div>
    <div class="details">
      <p class="key">Available for Trading</p>
      <p class="value">
        {{ coin.BalanceAvailableForTrading }} (${{
          dollarWithCommas(coin.BalanceAvailableForTradingAccountCurrency)
        }})
      </p>
    </div>
    <div class="details last">
      <p class="key">Available for Withdrawal</p>
      <p class="value">
        {{ coin.BalanceAvailableForWithdrawal }} (${{
          dollarWithCommas(coin.BalanceAvailableForWithdrawalAccountCurrency)
        }})
      </p>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";

export default {
  name: "Coin",
  props: {
    coin: Object,
    borderless: {
      type: Boolean,
      default: false,
    },
    price: String,
    dark: Boolean,
  },
  mixins: [Mixins],
  data() {
    return {};
  },
  methods: {
    getImgUrl(tag) {
      try {
        if ((tag == "USD" || tag == "XRP") && this.dark) {
          return require("../assets/crypto/" + tag + "-dark.png");
        } else {
          return require("../assets/crypto/" + tag + ".png");
        }
      } catch (e) {
        return null;
      }
    },
    openCrypto() {
      this.$emit("openCrypto", this.coin);
    },
  },
};
</script>

<style scoped>
.coin {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 250px;
  min-width: 250px;
  border: 1px solid #eceff2;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 15px 30px RGBA(0, 0, 0, 0.08);
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  overflow: hidden;
}
.dark .coin {
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
}
.coin.borderless {
  border: none;
  box-shadow: none;
  cursor: auto;
}
.coin p {
  margin: 0;
}

.coin img {
  width: 50px;
  height: 50px;
}

.coin .currency {
  margin-top: 10px;
  margin-bottom: 10px;
}

.coin .price {
  font-weight: 500;
  font-size: 18px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.coin .details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceff2;
}

.coin .details.last {
  border-bottom: none;
}

.coin .details .key {
  font-weight: 500;
  align-self: flex-start;
}
.coin .details .value {
  font-weight: 300;
  align-self: flex-end;
}
</style>
