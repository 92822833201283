<template>
  <div class="option-row" :class="{ active: active, bullish: bullish }" @click="rowSelect">
    <div class="top-row">
      <div class="option-head">
        <p class="name">${{ strike }} {{ call ? "Calls" : "Puts" }}</p>
        <img src="../assets/swap.png" @click="testMe" />
      </div>
      <p class="oi">
        V/OI: {{ numberWithCommas(volume) }} / {{ numberWithCommas(oi) }}
      </p>
      <p class="price">${{ price }}</p>
    </div>
    <div class="bottom-row">
      <div class="bottom-left">
        <div class="left-col">
          <p class="value">${{ breakEven }}</p>
          <p class="label">Break Even</p>
        </div>
        <div class="left-col">
          <p class="value">{{ toBreak.toFixed(2) }}%</p>
          <p class="label">To Break Even</p>
        </div>
      </div>
      <div class="bottom-right">
        <p :class="{ down: change.indexOf('-') != -1 }">{{ change }}%</p>
      </div>
    </div>
    <div class="greeks">
      <p>
        Delta <span>{{ parseFloat(delta).toFixed(2) }}</span>
      </p>
      <p>
        Theta <span>{{ parseFloat(theta).toFixed(2) }}</span>
      </p>
      <p>
        Gamma <span>{{ parseFloat(gamma).toFixed(2) }}</span>
      </p>
      <p>
        Rho <span>{{ parseFloat(rho).toFixed(2) }}</span>
      </p>
      <p>
        Vega <span>{{ parseFloat(vega).toFixed(2) }}</span>
      </p>
      <p>
        IV <span>{{ parseFloat(iv).toFixed(2) }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
export default {
  name: "OptionRow",
  props: {
    strike: String,
    call: Boolean,
    breakEven: String,
    price: String,
    change: String,
    toBreak: Number,
    active: Boolean,
    bullish: Boolean,
    delta: String,
    theta: String,
    gamma: String,
    rho: String,
    vega: String,
    oi: Number,
    iv: String,
    volume: Number,
  },
  mixins: [Mixins],
  methods: {
    rowSelect: function () {
      this.$emit("rowSelect");
    },
    testMe() {
      this.$emit("activeTradeRow");
    }
  },
};
</script>

<style scoped>
.option-row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eef0f3;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.dark .option-row {
  border-bottom: 1px solid #333333;
}

.option-row.active {
  background: rgba(206, 6, 6, 0.33);
}

.option-row.active.bullish {
  background: rgba(16, 188, 116, 0.33);
}

.top-row,
.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top-row {
  align-items: center;
}

.option-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option-head img {
  height: 21px;
  margin-left: 6px;
  opacity: 0;
}

.option-row:hover .option-head img {
  opacity: 1;
}

.bottom-row {
  /* margin-top: 10px; */
}

.bottom-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.left-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.left-col:first-of-type {
  margin-right: 15px;
}

p {
  margin: 0;
  font-family: "Roboto";
  font-size: 18px;
}

.name,
.price {
  font-size: 21px;
  font-weight: bold;
}

.oi,
.vol {
  font-size: 14px;
  color: #616164;
}

.left-col p {
  font-weight: 300;
}

.left-col .label {
  font-size: 16px;
  color: #616164;
}

.bottom-right p {
  color: #10bc74;
}

.bottom-right p.down {
  color: #ce0606;
}

.greeks {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.greeks p {
  color: #616164;
  font-size: 12px;
}

.greeks p span {
  font-weight: 600;
}

@media only screen and (max-width: 550px) {
  p {
    font-size: 14px;
  }

  .name,
  .price {
    font-size: 16px;
  }

  .oi {
    font-size: 10px;
  }

  .left-col .label {
    font-size: 14px;
  }

  .greeks p {
    color: #616164;
    font-size: 10px;
    margin-right: 5px;
  }
}
</style>
