<template>
  <div class="buy-form" :class="{ compact: compactMode }">
    <div v-if="!submitted && !editRatio" class="form-header">
      <p class="close-position" v-if="isClosing">Close Position</p>
      <p
        class="ticker"
        v-for="(ticker, index) in tickerList"
        :key="'title' + index"
      >
        {{ ticker.Buy ? "Buy" : "Sell" }}
        <span class="green" v-if="tickerList.length > 1"
          >{{ ticker.Quantity }}x</span
        >
        {{ displaySymbol(ticker) }}
      </p>
      <p
        class="edit-ratio green"
        v-if="tickerList.length > 1 && !preview"
        @click="editRatio = true"
      >
        Edit Ratio
      </p>
      <p
        class="add-legs green"
        v-if="isClosing && legList.length > 0 && !addLegs && !preview"
        @click="addLegs = true"
      >
        Add Legs
      </p>
      <div
        class="leg-list"
        v-if="isClosing && legList.length > 0 && addLegs && !preview"
      >
        <p
          class="ticker"
          v-for="(leg, index) in legList"
          :key="'title' + index"
          @click="addLeg(leg)"
        >
          {{ leg.Buy ? "Buy" : "Sell" }} {{ displaySymbol(leg) }}
        </p>
      </div>
      <p
        class="add-legs"
        v-if="isClosing && legList.length > 0 && addLegs && !preview"
      >
        Available Legs
      </p>
    </div>
    <div class="form-body" v-if="!preview && !editRatio">
      <div class="input-line">
        <div class="input-row">
          <label for="quantity">Quantity</label>
          <input
            type="text"
            name="quantity"
            id="quantity"
            v-model="quantity"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.quantity">{{ errors.quantity }}</p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="type">Order Type</label>
          <select name="type" id="type" v-model="orderType">
            <option>Limit</option>
            <option>Market</option>
            <option v-if="tickers.length == 1 && quantity<=50">Bracket</option>
            <option v-if="tickers.length == 1 && !isClosing">Bracket %</option>
            <option>Stop Market</option>
            <option>Stop Limit</option>
            <option>Trailing Stop</option>
            <option>Trailing Stop %</option>
          </select>
        </div>
        <p class="description">
          {{ typeDescription[orderType] }}
        </p>
      </div>
      <div
        class="input-line limit"
        v-if="
          (!isClosing &&
            ['Limit', 'Bracket', 'Bracket %', 'Stop Limit'].indexOf(
              orderType
            ) != -1) ||
          ['Limit', 'Stop Limit'].indexOf(orderType) != -1
        "
      >
        <div class="input-row">
          <label for="limitPrice">Limit Price</label>
          <input
            type="text"
            name="limitPrice"
            id="limitPrice"
            v-model="limitPrice"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.limitPrice">{{ errors.limitPrice }}</p>
      </div>
      <div class="bid-ask">
        <p
          class="clickable"
          @click="
            () => {
              if (preview != null) {
                return;
              }
              if (orderType == 'Limit' || orderType == 'Stop Limit') {
                limitPrice = totalBid.toFixed(2);
              } else if (orderType == 'Bracket') {
                if (isClosing) {
                  profitLimit = totalBid.toFixed(2);
                } else {
                  limitPrice = totalBid.toFixed(2);
                }
              } else if (orderType == 'Stop Market') {
                stopPrice = totalBid.toFixed(2);
              }
            }
          "
        >
          Bid: {{ dollarWithCommas(totalBid) }}
        </p>

        <p
          class="clickable"
          @click="
            () => {
              if (preview != null) {
                return;
              }
              var midPrice = ((totalAsk + totalBid) / 2).toFixed(2);
              if (orderType == 'Limit' || orderType == 'Stop Limit') {
                limitPrice = midPrice;
              } else if (orderType == 'Bracket') {
                if (isClosing) {
                  profitLimit = midPrice;
                } else {
                  limitPrice = midPrice;
                }
              } else if (orderType == 'Stop Market') {
                stopPrice = midPrice;
              }
            }
          "
        >
          Mid: ${{ dollarWithCommas((totalAsk + totalBid) / 2) }}
        </p>
        <p
          class="clickable"
          @click="
            () => {
              if (preview != null) {
                return;
              }
              if (orderType == 'Limit' || orderType == 'Stop Limit') {
                limitPrice = totalAsk.toFixed(2);
              } else if (orderType == 'Bracket') {
                if (isClosing) {
                  profitLimit = totalAsk.toFixed(2);
                } else {
                  limitPrice = totalAsk.toFixed(2);
                }
              } else if (orderType == 'Stop Market') {
                stopPrice = totalAsk.toFixed(2);
              }
            }
          "
        >
          Ask: ${{ dollarWithCommas(totalAsk) }}
        </p>
      </div>
      <div
        class="input-line"
        v-if="['Stop Market', 'Stop Limit'].indexOf(orderType) != -1"
      >
        <div class="input-row">
          <label for="stopPrice">Stop Price</label>
          <input
            type="text"
            name="stopPrice"
            id="stopPrice"
            v-model="stopPrice"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.stopPrice">{{ errors.stopPrice }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="profitLimit">Profit Limit</label>
          <input
            type="text"
            name="profitLimit"
            id="profitLimit"
            v-model="profitLimit"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.profitLimit">{{ errors.profitLimit }}</p>
      </div>
      <div class="input-line" v-if="['Bracket %'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="profitLimit">Profit Limit %</label>
          <input
            type="text"
            name="profitLimit"
            id="profitLimit"
            v-model="profitLimit"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.profitLimit">{{ errors.profitLimit }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopLoss">Stop Loss</label>
          <input
            type="text"
            name="stopLoss"
            id="stopLoss"
            v-model="stopLoss"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.stopLoss">{{ errors.stopLoss }}</p>
      </div>
      <div class="input-line" v-if="['Bracket %'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopLoss">Stop Loss %</label>
          <input
            type="text"
            name="stopLoss"
            id="stopLoss"
            v-model="stopLoss"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.stopLoss">{{ errors.stopLoss }}</p>
      </div>
      <div class="input-line" v-if="['Trailing Stop'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="trailAmount">Trail Amount</label>
          <input
            type="text"
            name="trailAmount"
            id="trailAmount"
            v-model="trailAmount"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.trailAmount">{{ errors.trailAmount }}</p>
      </div>
      <div
        class="input-line"
        v-if="['Trailing Stop %'].indexOf(orderType) != -1"
      >
        <div class="input-row">
          <label for="trailPercent">Trailing Stop %</label>
          <input
            type="text"
            name="trailPercent"
            id="trailPercent"
            v-model="trailPercent"
            placeholder="0"
          />
        </div>
        <p class="error" v-if="errors.trailPercent">
          {{ errors.trailPercent }}
        </p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="duration">Duration</label>
          <select name="duration" id="duration" v-model="duration">
            <option>Day</option>
            <option>Extended</option>
            <option>Until Cancel</option>
            <option>All Hours</option>
            <option>Immediate</option>
            <option>Fill or Kill</option>
          </select>
        </div>
        <p class="description">
          {{ durationDescription[duration] }}
        </p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="execRoute">Route</label>
          <select name="execRoute" id="execRoute" v-model="orderRoute.Id">
            <option
              v-for="route in filteredExecRoutes"
              :key="route.Id"
              :value="route.Id"
            >
              {{ route.Name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="isClosing">
        <KeyValue key-string="Buying Power" :value="'$' + dollarWithCommas(account.BuyingPower)" />
        <KeyValue key-string="P/L All" :value="sumUnrealizedProfitLoss!='-'?`$ ${dollarWithCommas(sumUnrealizedProfitLoss)}`:`${dollarWithCommas(sumUnrealizedProfitLoss)}`" />
        <KeyValue last key-string="P/L Today" :value="sumTodaysProfitLoss!='-'?`$ ${dollarWithCommas(sumTodaysProfitLoss)}`:`${dollarWithCommas(sumTodaysProfitLoss)}`" />
      </div>
      <div v-else>
      <KeyValue last key-string="Buying Power" :value="'$' + dollarWithCommas(account?.BuyingPower)" />
      </div>
    </div>
    <div class="form-body" v-if="editRatio">
      <div
        class="input-line"
        v-for="(ticker, index) in tickerList"
        :key="'ratio' + index"
      >
        <div class="input-row">
          <label :for="'quantity' + index">{{ displaySymbol(ticker) }}</label>
          <div class="ratio-input">
            <p class="ratio-action">{{ ticker.Buy ? "Buy" : "Sell" }}</p>
            <input
              type="text"
              :name="'quantity' + index"
              :id="'quantity' + index"
              v-model="ticker.Quantity"
              placeholder="1"
            />
          </div>
        </div>
      </div>
      <p class="error" v-if="errors.ratio">{{ errors.ratio }}</p>
    </div>
    <div class="preview-body" v-if="preview && !submitted">
      <KeyValue key-string="Account" :value="preview[0].AccountID" />
      <KeyValue
        key-string="Estimated Commission"
        :value="estimatedCommission"
      />
      <KeyValue key-string="Estimated Cost" :value="estimatedCost" />
      <KeyValue
        key-string="Duration"
        :value="preview[0].TimeInForce.Duration"
      />
      <p class="summary-title">Summary:</p>
      <p
        class="summary-message"
        v-for="(msg, index) in preview"
        :key="`msg${index}`"
      >
        {{ msg.SummaryMessage }}
      </p>
    </div>
    <Modal
      style="z-index: 1000"
      v-if="isConfirmationModalOpen == true"
      @close="(isConfirmationModalOpen = false), (loading = false)"
    >
      <ConfirmationOrder
        @close="
          (isConfirmationModalOpen = false),
            (loading = false),
            (isConfirmationOrder = false)
        "
        @confirm="(isConfirmationOrder = true), reviewOrder()"
      />
    </Modal>
    <div class="success-body" v-if="submitted">
      <p>{{ submitted.Message }}</p>
    </div>
    <ShareLink v-if="!editRatio" :link="shareLink" />
    <p class="error" v-if="errors.global">{{ errors.global }}</p>
    <div class="buttons" v-if="!editRatio">
      <button class="secondary" v-if="!preview" @click="close">Cancel</button>
      <button
        class="secondary"
        v-if="preview && !submitted"
        @click="(preview = null), (isConfirmationOrder = false)"
      >
        Back
      </button>
      <button class="secondary" v-if="!loading && submitted" @click="close">
        Close
      </button>

      <button class="blank" v-if="loading">
        <img class="loading" src="../assets/loading.gif" />
      </button>
      <button v-if="!loading && !preview" @click="reviewOrder">Review</button>
      <button v-if="!loading && !submitted && preview" @click="submitOrder">
        Submit
      </button>
      <button v-if="!loading && submitted" @click="goHome">Home</button>
    </div>
    <div class="buttons" v-if="editRatio">
      <button style="width: 100%" @click="finishRatio">Done</button>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import KeyValue from "./KeyValue.vue";
import ShareLink from "./ShareLink.vue";
import Toast from "./Toasts.vue";
import Modal from "./Modal.vue";
import ConfirmationOrder from "./ConfirmationOrder.vue";
export default {
  name: "BuyOptions",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  mixins: [Mixins],
  props: {
    tickers: Array,
    account: Object,
    availableLegs: {
      type: Array,
      default() {
        return [];
      },
    },
    sound: Boolean,
    isClosing: {
      type: Boolean,
      default: false,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
    startPrice: {
      type: String,
      default: null,
    },
    refresh: {
      type: Number,
      default: null,
    },
    orderExecRoutes: Array,
    position: Object,
    orders: Array
  },
  components: {
    KeyValue,
    ShareLink,
    ConfirmationOrder,
    Modal,
  },
  data() {
    return {
      tickerList: this.tickers,
      addLegs: false,
      editRatio: false,
      legList: JSON.parse(JSON.stringify(this.availableLegs)),
      quantity:
        this.tickers[0]["Quantity"] != null && this.tickers.length == 1
          ? this.tickers[0]["Quantity"].replaceAll("-", "")
          : "1",
      preview: null,
      submitted: null,
      loading: true,
      priceIncrement: {
        style: null,
        increment: null,
        schedule: [],
      },
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      orderType: this.startPrice == "market" ? "Market" : "Limit",
      limitPrice:
        this.startPrice && this.startPrice != "market" ? this.startPrice : "",
      stopPrice: "",
      profitLimit: "",
      stopLoss: "",
      trailAmount: "",
      trailPercent: "",
      duration: "Until Cancel",
      typeDescription: {
        Limit: "Order only if price is equal to or better than my limit",
        Market: "Order as soon as possible, regardless of the price",
        Bracket:
          "Place a limit order and when it fills create two closing orders. One to limit my loss, the other to automatically take profit",
        "Bracket %":
          "Place a limit order and when it fills create two closing orders. One to limit my loss, the other to automatically take profit",
        "Stop Market":
          "Order as soon as possible once the price is equal to or worse than my stop price",
        "Stop Limit":
          "Order once the price is equal to or worse than my stop price, but only if the price is equal to or better than my limit",
        "Trailing Stop":
          "Create a stop market order with trail amount difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
        "Trailing Stop %":
          "Create a stop market order with trail % difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
      },
      durationDescription: {
        "Until Cancel":
          "Valid until I cancel, trades during regular market hours",
        Day: "Valid until the end of the regular trading session",
        Extended:
          "Valid until during one extended trading session (pre or post market)",
        "All Hours":
          "Valid until I cancel, trades during regular market hours and extended trading hours",
        Immediate:
          "Only try to fill immediately, accept partial fills, then cancel",
        "Fill or Kill": "Only fill this order completely, reject partial fills",
      },
      queryTypeMap: [
        "Limit", //0
        "Market", //1
        "Bracket", //2
        "Stop Market", //3
        "Stop Limit", //4
        "Trailing Stop", //5
        "Trailing Stop %", //6
        "Bracket %", //7
      ],
      queryDurationMap: [
        "Until Cancel", //0
        "Day", //1
        "Extended", //2
        "All Hours", //3
        "Immediate", //4
        "Fill or Kill", //5
      ],
      errors: {},
      orderRoute: {
        Id: "Intelligent",
        Name: "Intelligent",
      },
      estimatedCost: 0,
      estimatedCommission: 0,
      isConfirmationModalOpen: false,
      isConfirmationOrder: false,
      sumTodaysProfitLoss:'-',
      sumUnrealizedProfitLoss:'-'
    };
  },
  watch: {
    availableLegs(){
      this.calculateSums();
    },
    refresh(ping) {
      if (ping == 0) {
        return;
      } else {
        this.remount();
      }
    },
    tickers() {
      this.remount();
      this.calculateSums();
    },
    quantity(){
      if(this.quantity>50 && this.orderType == "Bracket"){
        this.orderType = "Limit";
      }
    }
  },
  computed: {
    totalBid: function () {
      var totalBid = 0;
      for (var i = 0; i < this.tickerList.length; i++) {
        if (this.tickerList.length > 1) {
          if (this.tickerList[i].Buy) {
            totalBid +=
              parseFloat(this.tickerList[i].Bid) *
              parseFloat(this.tickerList[i].Quantity);
          } else {
            totalBid -=
              parseFloat(this.tickerList[i].Bid) *
              parseFloat(this.tickerList[i].Quantity);
          }
        } else {
          totalBid += parseFloat(this.tickerList[i].Bid);
        }
      }
      return totalBid;
    },
    totalAsk: function () {
      var totalAsk = 0;
      for (var i = 0; i < this.tickerList.length; i++) {
        if (this.tickerList.length > 1) {
          if (this.tickerList[i].Buy) {
            totalAsk +=
              parseFloat(this.tickerList[i].Ask) *
              parseFloat(this.tickerList[i].Quantity);
          } else {
            totalAsk -=
              parseFloat(this.tickerList[i].Ask) *
              parseFloat(this.tickerList[i].Quantity);
          }
        } else {
          totalAsk += parseFloat(this.tickerList[i].Ask);
        }
      }
      return totalAsk;
    },
    shareLink() {
      var returnUrl = location.origin;
      returnUrl += this.isClosing ? `/options/close` : `/options/order`;

      if (this.orderType == "Limit") {
        returnUrl += "?t=0";
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
      } else if (this.orderType == "Market") {
        returnUrl += "?t=1";
      } else if (this.orderType == "Bracket") {
        returnUrl += "?t=2";
        if (this.limitPrice.length > 0 && !this.isClosing) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
        if (this.profitLimit.length > 0) {
          returnUrl += `&pl=${encodeURIComponent(this.profitLimit)}`;
        }
        if (this.stopLoss.length > 0) {
          returnUrl += `&sl=${encodeURIComponent(this.stopLoss)}`;
        }
      } else if (this.orderType == "Bracket %") {
        returnUrl += "?t=7";
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
        if (this.profitLimit.length > 0) {
          returnUrl += `&pl=${encodeURIComponent(this.profitLimit)}`;
        }
        if (this.stopLoss.length > 0) {
          returnUrl += `&sl=${encodeURIComponent(this.stopLoss)}`;
        }
      } else if (this.orderType == "Stop Market") {
        returnUrl += "?t=3";
        if (this.stopPrice.length > 0) {
          returnUrl += `&s=${encodeURIComponent(this.stopPrice)}`;
        }
      } else if (this.orderType == "Stop Limit") {
        returnUrl += "?t=4";
        if (this.stopPrice.length > 0) {
          returnUrl += `&s=${encodeURIComponent(this.stopPrice)}`;
        }
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
      } else if (this.orderType == "Trailing Stop") {
        returnUrl += "?t=5";
        if (this.trailAmount.length > 0) {
          returnUrl += `&ta=${encodeURIComponent(this.trailAmount)}`;
        }
      } else if (this.orderType == "Trailing Stop %") {
        returnUrl += "?t=6";
        if (this.trailPercent.length > 0) {
          returnUrl += `&tp=${encodeURIComponent(this.trailPercent)}`;
        }
      }

      returnUrl += "&o=";
      this.tickerList.forEach((option, i) => {
        returnUrl += option.Buy ? "B!" : "S!";
        returnUrl += option.Symbol.replaceAll(" ", "_");
        if (this.tickerList.length > 1) {
          returnUrl += "!" + option.Quantity;
        }
        if (i < this.tickerList.length - 1) {
          returnUrl += ",";
        }
      });

      returnUrl += `&d=${this.queryDurationMap.indexOf(this.duration)}`;
      return returnUrl;
    },
    filteredExecRoutes() {
      return [
        { Id: "Intelligent", Name: "Intelligent" },
        ...this.orderExecRoutes.filter((route) =>
          route.AssetTypes.includes(this.tickerList[0].AssetType.toUpperCase())
        ),
      ];
    },
  },
  beforeUnmount() {
    if (this.quoteStream.connection) {
      this.quoteStream.connection.end();
      this.quoteStream.connection.destroy();
    }
  },
  mounted() {
    if (
      this.$route.path.indexOf("/options/order") != -1 ||
      this.$route.path.indexOf("/options/close") != -1
    ) {
      if (this.$route.query.t && parseInt(this.$route.query.t) < 8) {
        if (
          (this.$route.query.t != "2" && this.$route.query.t != "7") ||
          this.tickers.length == 1
        ) {
          //no bracket on multileg
          this.orderType = this.queryTypeMap[parseInt(this.$route.query.t)];
        }
      }
      if (this.$route.query.d && parseInt(this.$route.query.d) < 6) {
        this.duration = this.queryDurationMap[parseInt(this.$route.query.d)];
      }
      if (this.$route.query.l) {
        this.limitPrice = this.$route.query.l;
      }
      if (this.$route.query.pl) {
        this.profitLimit = this.$route.query.pl;
      }
      if (this.$route.query.sl) {
        this.stopLoss = this.$route.query.sl;
      }
      if (this.$route.query.s) {
        this.stopPrice = this.$route.query.s;
      }
      if (this.$route.query.ta) {
        this.trailAmount = this.$route.query.ta;
      }
      if (this.$route.query.tp) {
        this.trailPercent = this.$route.query.tp;
      }
    } else if (this.isClosing) {
      if (this.tickerList[0].Buy == undefined) {
        this.tickerList[0].Buy = this.tickerList[0].Quantity < 0;
      }
      var indexSplice = 0;
      for (var i = 0; i < this.legList.length; i++) {
        this.legList[i].Buy = this.legList[i].LongShort == "Short";
        this.legList[i].Bid = this.legList[i].BidPrice;
        this.legList[i].Ask = this.legList[i].AskPrice;
        if (this.legList[i].Symbol == this.tickerList[0].Symbol) {
          indexSplice = i;
        }
      }
      if (indexSplice > -1) {
        this.legList.splice(indexSplice, 1);
      }
    }
    if (this.tickers.length == 1) {
      this.fetchSymbolDescription();
    } else {
      this.loading = false;
    }

    this.tickerList.forEach((leg) => {
      if (!leg.Quantity) {
        leg.Quantity = "1";
      } else {
        leg.Quantity = leg.Quantity.replaceAll("-", "");
      }
    });
    
    this.checkGCD();
    this.streamData();
    this.calculateSums();
  },
  methods: {
    checkGCD(){
      var gcdFound = null;
      this.tickerList.forEach((leg) => {
        leg.Quantity = leg.Quantity.replaceAll("-", "");
        if (gcdFound == null) {
          gcdFound = parseInt(leg.Quantity);
        } else {
          gcdFound = this.getGcd(gcdFound, parseInt(leg.Quantity));
        }
      });
      this.tickerList.forEach((leg) => {
        leg.Quantity = parseFloat(leg.Quantity) / gcdFound;
        this.quantity = gcdFound.toFixed(0);
      });
    },
    calculateSums() {      
      let sumUnrealizedProfitLossLocal = 0;
      let sumTodaysProfitLossLocal = 0; 
      this.tickers.forEach(ticker => {
        let found = this.availableLegs.find(leg => leg.Symbol === ticker.Symbol);
        if (found) {
          sumTodaysProfitLossLocal += parseFloat(found.TodaysProfitLoss);
          sumUnrealizedProfitLossLocal += parseFloat(found.UnrealizedProfitLoss);
        }
      });
      this.sumTodaysProfitLoss = sumUnrealizedProfitLossLocal;
      this.sumUnrealizedProfitLoss = sumTodaysProfitLossLocal;
    },
    remount() {
      this.loading = true;
      this.limitPrice = this.startPrice ? this.startPrice : "";
      this.quantity =
        this.tickers[0]["Quantity"] != null
          ? this.tickers[0]["Quantity"].replaceAll("-", "")
          : "1";
      this.preview = null;
      this.submitted = null;
      this.tickerList = this.tickers;
      if (this.tickers.length == 1) {
        this.fetchSymbolDescription();
      } else {
        this.loading = false;
      }

      if (this.isClosing) {
        this.tickerList.forEach((leg) => {
          if (!leg.Quantity) {
            leg.Quantity = "1";
          } else {
            leg.Quantity = leg.Quantity.replaceAll("-", "");
          }
        });
      } else {
        this.tickerList.forEach((leg) => {
          leg.Quantity = "1";
        });
      }
      this.checkGCD();
      this.streamData();
    },
    fetchSymbolDescription() {
      var _this = this;
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
            `marketdata/symbols/${encodeURIComponent(this.tickers[0].Symbol)}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          if (res.data.Symbols.length > 0) {
            if (
              res.data.Symbols[0].PriceFormat &&
              res.data.Symbols[0].PriceFormat.IncrementStyle
            ) {
              _this.priceIncrement.style =
                res.data.Symbols[0].PriceFormat.IncrementStyle;
              if (_this.priceIncrement.style == "Simple") {
                _this.priceIncrement.increment =
                  res.data.Symbols[0].PriceFormat.Increment;
              } else if (_this.priceIncrement.style == "Schedule") {
                _this.priceIncrement.schedule =
                  res.data.Symbols[0].PriceFormat.IncrementSchedule;
              }
            }
          }
          _this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
        });
    },
    streamData() {
      var _this = this;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      this.quoteStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var tickerString = "";
      for (var i = 0; i < this.tickerList.length; i++) {
        tickerString += this.tickerList[i].Symbol + ",";
      }
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${tickerString}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.quoteStream.connection = this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.quoteStream || !this.quoteStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.quoteStream.leftOver != null) {
                chunk = _this.quoteStream.leftOver + chunk;
                _this.quoteStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              var newData = this.tickerList;
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Ask) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Ask =
                      parseFloat(snapShot.Ask);
                  }
                  if (snapShot.Bid) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Bid =
                      parseFloat(snapShot.Bid);
                  }
                  if (snapShot.Mid) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Mid =
                      parseFloat(snapShot.Mid);
                  }
                } else {
                  _this.quoteStream.leftOver = quotes[a];
                }
              }
              _this.tickerList = newData;
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    checkIncrement(price) {
      if (this.tickerList.length != 1 || this.priceIncrement.style == null) {
        return false;
      }
      var incrementStyle = this.priceIncrement.style;
      if (incrementStyle == "Simple") {
        var simpleIncrement = this.priceIncrement.increment;
        var incrementMultiply =
          1 * Math.pow(10, simpleIncrement.split(".")[1].length);
        if (
          Math.round(price * incrementMultiply) %
            Math.round(simpleIncrement * incrementMultiply) !=
          0
        ) {
          return `At the current market price this ticker only accepts price increments of ${simpleIncrement}`;
        }
      } else if (incrementStyle == "Schedule") {
        var targetIncrement = null;
        var incrementMultiplySchedule = null;
        for (var i = 0; i < this.priceIncrement.schedule.length; i++) {
          if (
            parseFloat(price) >
            parseFloat(this.priceIncrement.schedule[i]["StartsAt"])
          ) {
            targetIncrement = this.priceIncrement.schedule[i]["Increment"];
            incrementMultiplySchedule =
              1 *
              Math.pow(
                10,
                this.priceIncrement.schedule[i]["Increment"].split(".")[1]
                  .length
              );
          }
        }
        if (
          Math.round(price * incrementMultiplySchedule) %
            Math.round(targetIncrement * incrementMultiplySchedule) !=
          0
        ) {
          return `At the current market price this ticker only accepts price increments of ${targetIncrement}`;
        }
      }
      return false;
    },
    addLeg(leg) {
      if (this.orderType == "Bracket") {
        this.orderType = "Limit";
      }
      const index = this.legList.indexOf(leg);
      if (index > -1) {
        this.legList.splice(index, 1);
      }
      if (!leg.Bid) {
        leg.Bid = 0;
      }
      if (!leg.Ask) {
        leg.Ask = 0;
      }
      if (this.tickerList.length > 1) {
        this.tickerList.forEach((leg) => {
          leg.Quantity = (
            parseFloat(leg.Quantity) * parseFloat(this.quantity)
          ).toFixed(0);
        });
      }
      leg.Quantity = leg.Quantity.replace("-", "");
      this.tickerList.push(leg);

      this.checkGCD();
      this.streamData();
      this.calculateSums();
    },
    reviewOrder() {
      this.errors = {};
      this.loading = true;
      if (
        this.quantity == 0 ||
        this.quantity.length == 0 ||
        isNaN(this.quantity)
      ) {
        this.errors = {
          quantity: "Quantity must be a valid number that is not zero",
        };
        this.loading = false;
        return;
      }
      if (this.orderType == "Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var limitIncrement = this.checkIncrement(this.limitPrice);
        if (limitIncrement) {
          this.errors = {
            limitPrice: limitIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket") {
        if (
          (this.limitPrice == 0 ||
            this.limitPrice.length == 0 ||
            isNaN(this.limitPrice)) &&
          !this.isClosing
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.profitLimit == 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit: "Profit Limit must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss == 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss: "Stop Loss must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var bracketLimitIncrement = this.isClosing
          ? null
          : this.checkIncrement(this.limitPrice);
        var bracketProfitIncrement = this.checkIncrement(this.profitLimit);
        var bracketStopIncrement = this.checkIncrement(this.stopLoss);
        if (
          bracketLimitIncrement ||
          bracketProfitIncrement ||
          bracketStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketLimitIncrement,
            profitLimit: bracketProfitIncrement,
            stopLoss: bracketStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket %") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        this.profitLimit = this.profitLimit.replace("%", "");
        this.stopLoss = this.stopLoss.replace("%", "");
        if (
          this.profitLimit <= 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit:
              "Profit Limit must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss <= 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss:
              "Stop Loss must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Market") {
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopMarketIncrement = this.checkIncrement(this.stopPrice);
        if (stopMarketIncrement) {
          this.errors = {
            stopPrice: stopMarketIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopLimitLimitIncrement = this.checkIncrement(this.limitPrice);
        if (stopLimitLimitIncrement) {
          this.errors = {
            limitPrice: stopLimitLimitIncrement,
          };
          this.loading = false;
          return;
        }
        var stopLimitStopIncrement = this.checkIncrement(this.stopPrice);
        if (stopLimitStopIncrement) {
          this.errors = {
            stopPrice: stopLimitStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop") {
        if (
          this.trailAmount == 0 ||
          this.trailAmount.length == 0 ||
          isNaN(this.trailAmount)
        ) {
          this.errors = {
            trailAmount: "Trail Amount must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop %") {
        if (
          this.trailPercent == 0 ||
          this.trailPercent.length == 0 ||
          isNaN(this.trailPercent)
        ) {
          this.errors = {
            trailPercent:
              "Trailing Stop % must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      }
      if (this.quantity > 50) {
        this.isConfirmationModalOpen = true;
        if (!this.isConfirmationOrder) return;
        this.isConfirmationModalOpen = false;
      }
      if (
        (this.orderType == "Bracket" ||
          this.orderType == "Bracket %" ||
          this.orderType == "Stop Market" ||
          this.orderType == "Trailing Stop" ||
          this.orderType == "Trailing Stop %" ||
          this.orderType == "Market") &&
        this.duration == "Extended"
      ) {
        this.errors.global =
          "Extended hours orders must be limit orders. Please change order type or duration.";
        this.loading = false;
        return;
      }
      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        try {
          calculatedProfit =
            (parseFloat(this.limitPrice) *
              (100 + parseFloat(this.profitLimit))) /
            100;
          calculatedProfit = calculatedProfit.toFixed(2);
        } catch (e) {
          this.errors.profitLimit = "Can not calculate profit limit";
          return;
        }
        try {
          calculatedLoss =
            (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
            100;
          calculatedLoss = calculatedLoss.toFixed(2);
        } catch (e) {
          this.errors.stopLoss = "Can not calculate profit limit";
          return;
        }
        var bracketPercentLimitIncrement = this.checkIncrement(this.limitPrice);
        var bracketPercentProfitIncrement =
          this.checkIncrement(calculatedProfit);
        var bracketPercentStopIncrement = this.checkIncrement(calculatedLoss);
        if (
          bracketPercentLimitIncrement ||
          bracketPercentProfitIncrement ||
          bracketPercentStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketPercentLimitIncrement,
            profitLimit: bracketPercentProfitIncrement,
            stopLoss: bracketPercentStopIncrement,
          };
          if (!bracketPercentLimitIncrement) {
            this.orderType = "Bracket";
            this.profitLimit = calculatedProfit;
            this.stopLoss = calculatedLoss;
          }
          this.loading = false;
          return;
        }
      }

      const splitOrders = this.quantity > 50 ? [] : null;
      const maxOrderSize = 50;
      let remainingQuantity = this.quantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);
      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = this.tickerList.map((leg) => {
          let tradeAction;
          let limitTradeAction;

          if (this.isClosing) {
            tradeAction = leg["Buy"] ? "BUYTOCLOSE" : "SELLTOCLOSE";
          } else if (
            this.orderType === "Bracket" ||
            this.orderType === "Bracket %"
          ) {
            tradeAction = leg["Buy"] ? "SELLTOCLOSE" : "BUYTOCLOSE";
            limitTradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          } else {
            tradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          }

          const legData = {
            Symbol: leg["Symbol"],
            Quantity: orderSize.toString(),
            TradeAction: tradeAction,
          };

          if (
            !this.isClosing &&
            (this.orderType === "Bracket" || this.orderType === "Bracket %")
          ) {
            legData["LimitTradeAction"] = limitTradeAction;
          }

          return legData;
        });

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          bracketProfit:
            this.orderType === "Bracket %"
              ? calculatedProfit
              : this.profitLimit,
          bracketLoss:
            this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: this.isClosing ? "SELL" : "BUY",
          orderType: this.orderType,
          limitPrice: this.limitPrice,
          orderDuration: this.duration,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickerList.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );

        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      const orderPayload = {
        orderType: this.orderType,
        callOptionsOrders: splitOrders,
        close: this.isClosing,
        final: false,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: null,
        quantity: this.quantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.duration,
        legs: this.tickerList,
        tradeAction: this.isClosing ? "SELL" : "BUY",
        assetType: "OP",
      };

      var _this = this;
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          const totals = res.data.Confirmations?.reduce(
            (acc, item) => {
              acc.totalCost += parseInt(item.EstimatedCost, 10);
              acc.totalCommission += parseInt(item.EstimatedCommission, 10);
              return acc;
            },
            { totalCost: 0, totalCommission: 0 }
          );

          _this.estimatedCommission = totals?.totalCommission.toString();
          _this.estimatedCost = totals?.totalCost.toString();
          _this.preview = res.data.Confirmations;
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            return;
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            return;
          }
        });
    },
    postDataOrder(_this) {
      this.errors = {};
      this.loading = true;

      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        calculatedProfit =
          (parseFloat(this.limitPrice) * (100 + parseFloat(this.profitLimit))) /
          100;
        calculatedProfit = calculatedProfit.toFixed(2);
        calculatedLoss =
          (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
          100;
        calculatedLoss = calculatedLoss.toFixed(2);
      }
      const splitOrders = this.quantity > 50 ? [] : null;
      const maxOrderSize = 50;
      let remainingQuantity = this.quantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);
      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = this.tickerList.map((leg) => {
          let tradeAction;
          let limitTradeAction;

          if (this.isClosing) {
            tradeAction = leg["Buy"] ? "BUYTOCLOSE" : "SELLTOCLOSE";
          } else if (
            this.orderType === "Bracket" ||
            this.orderType === "Bracket %"
          ) {
            tradeAction = leg["Buy"] ? "SELLTOCLOSE" : "BUYTOCLOSE";
            limitTradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          } else {
            tradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          }

          const legData = {
            Symbol: leg["Symbol"],
            Quantity: orderSize.toString(),
            TradeAction: tradeAction,
          };

          if (
            !this.isClosing &&
            (this.orderType === "Bracket" || this.orderType === "Bracket %")
          ) {
            legData["LimitTradeAction"] = limitTradeAction;
          }

          return legData;
        });

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          bracketProfit:
            this.orderType === "Bracket %"
              ? calculatedProfit
              : this.profitLimit,
          bracketLoss:
            this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: this.isClosing ? "SELL" : "BUY",
          orderType: this.orderType,
          limitPrice: this.limitPrice,
          orderDuration: this.duration,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickerList.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );
        
        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      const orderPayload = {
        orderType: this.orderType,
        callOptionsOrders: splitOrders,
        close: this.isClosing,
        final: true,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: null,
        quantity: this.quantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.duration,
        legs: this.tickerList,
        tradeAction: this.isClosing ? "SELL" : "BUY",
        assetType: "OP",
      };
      // var _this = this;
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(res.data),
            });
          } else {
            res.data.Orders.forEach((order) => {
              var sendLegs = "";
              if (order["Error"] == null) {
                _this.tickerList.forEach((leg) => {
                  sendLegs += `${leg["Symbol"]},`;
                });
                _this.$gtag.event("order_closing", { legs: sendLegs });
              } else {
                _this.$gtag.event("web_error", {
                  action: "order_closing",
                  res_data: order["Error"],
                });
              }
            });
            if (res.data.Orders[0].Error) {
              if(!_this.sellShort && _this.isClosing && (res.data.Orders[0].Message.includes("EC703") || res.data.Orders[0].Message.includes("EC704"))) {
                _this.deleteAndRepost(_this);
              } else {
                _this.submitted = res.data.Orders[0];
                Toast.error(_this.submitted.Message);
                _this.playSound(
                  "../../order-rejected-cancelled.mp3",
                  _this.sound
                );
                _this.submitted = false;
                return;
              }
            } else {
              _this.submitted = res.data.Orders[0];
              Toast.success(_this.submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.isClosing) {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var averageCost = 0.0;
                  _this.tickerList.forEach((orderTicker) => {
                    if (orderTicker.Buy == true) {
                      averageCost -=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    } else {
                      averageCost +=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    }
                  });
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: averageCost.toString(),
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                      _this.$emit("close");
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }
            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later0000";
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    async deleteAndRepost(_this) {
      try {
      const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
      let orderIds =[]
      
      orderIds = _this.orders
        .filter(element =>
            element.Legs[0].Symbol === _this.tickers[0]?.Symbol &&
            element.Legs[0].BuyOrSell === 'Sell' &&
            validStatuses.includes(element.Status))
        .map(e => e.OrderID);
      const deletePromises = orderIds.map(orderId => _this.deleteOrder(_this, orderId));
      await Promise.all(deletePromises);
        await _this.postDataOrder(_this)
      } catch (e) {
        console.log(e)
      }
    },
    async deleteOrder(_this,orderId) {
    _this.loading = true;
   
    const url = `${localStorage.sim === "true" ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS}orderexecution/orders/${orderId}`;
    const headers = { Authorization: `Bearer ${localStorage.accessToken}` };

    try {
        const response = await _this.http.delete(url, { headers });

        _this.loading = false;
        if (response.status === 200) {
            _this.$emit("close");
            let legSymbols = "";
            _this.order?.Legs.forEach((leg) => {
                legSymbols += `${leg.Symbol},`;
            });
            _this.$gtag.event("order_delete", { legs: legSymbols });

        } else if (response.data.Message) {
            _this.errors.global = response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
                action: "order_delete",
                res_data: JSON.stringify(response.data),
            });
        } else {
            throw new Error("Something went wrong... Please try again later");
        }
      } catch (error) {
        _this.loading = false;

        const errorMessage = error.response && error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "Something went wrong... Please try again later";
        _this.errors.global = errorMessage;
        Toast.error(_this.errors.global);
        _this.playSound("order-rejected-cancelled.mp3", _this.sound);
        _this.$gtag.event("web_error", {
            action: "order_delete",
            res_data: JSON.stringify(error.response ? error.response.data : error),
        });
      }
      _this.$emit("update-cv");
    },
    submitOrder() {
      this.errors = {};
      this.loading = true;
      this.postDataOrder(this);
    },
    close() {
      this.$emit("close");
    },
    goHome() {
      this.$emit("home");
    },
    finishRatio() {
      this.errors = {};
      var gcdFound = null;
      var nanFail = false;
      this.tickerList.forEach((leg) => {
        if (
          leg.Quantity == 0 ||
          leg.Quantity.length == 0 ||
          isNaN(leg.Quantity) ||
          nanFail
        ) {
          nanFail = true;
          return;
        }
        if (gcdFound == null) {
          gcdFound = parseInt(leg.Quantity);
        } else {
          gcdFound = this.getGcd(gcdFound, parseInt(leg.Quantity));
        }
      });
      if (nanFail) {
        this.errors = {
          ratio: "Ratio quantities must be a valid number greater than 0",
        };
        return;
      }
      if (gcdFound != 1) {
        this.tickerList.forEach((leg) => {
          leg["Quantity"] = (leg["Quantity"] / gcdFound).toFixed(0);
        });
      }
      this.editRatio = false;
    },
  },
};
</script>

<style scoped>
.buy-form {
  padding: 10px;
}

.compact.buy-form {
  padding: 20px;
}

p {
  margin: 0;
  font-family: "Roboto";
}

.form-header {
  text-align: left;
}

.ticker {
  font-size: 24px;
  font-weight: 500;
}

.compact .ticker {
  font-size: 21px;
}

.bid-ask {
  font-size: 14px;
}

.compact .bid-ask {
  font-size: 12px;
}

.add-legs {
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.edit-ratio {
  font-size: 16px;
  text-align: right;
  cursor: pointer;
  font-weight: 500;
}

.leg-list {
  margin-top: 12px;
}

.leg-list .ticker {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
  color: #616164;
}

.leg-list .ticker:hover {
  color: #10bc74;
}

.bid-ask {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eef0f3;
}

.dark .bid-ask {
  border-bottom: 1px solid #333333;
}

.limit ~ .bid-ask {
  margin-top: -15px;
}

.bid-ask p {
  width: 33%;
  text-align: center;
}

.bid-ask p:first-of-type {
  text-align: left;
}

.bid-ask p:last-of-type {
  text-align: right;
}

.compact .bid-ask {
  font-size: 14px;
}

.input-line {
  margin: auto;
  padding: 20px 0;
  border-bottom: 1px solid #eef0f3;
}

.dark .input-line {
  border-bottom: 1px solid #333333;
}

.input-line.limit {
  border-bottom: none;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.input-line:last-of-type {
  border-bottom: none;
}

.input-line label {
  font-weight: 600;
  font-size: 21px;
}

.compact .input-line label {
  font-size: 18px;
}

.input-line select,
.input-line input {
  background: none;
  border: none;
  font-size: 21px;
  color: #10bc74;
  text-align: right;
  font-family: "Roboto";
  font-weight: 600;
  max-width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.compact .input-line select,
.compact .input-line input {
  font-size: 18px;
}

.input-line select:focus,
.input-line input:focus {
  outline: none;
}

.input-line option {
  color: #10bc74;
  background: none;
}

.input-line .description {
  color: #616164;
  margin-top: 5px;
  font-size: 16px;
  text-align: left;
}

.compact .input-line .description {
  font-size: 14px;
}

.input-line .price-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-line .price-select p {
  font-weight: 600;
  color: #616164;
  cursor: pointer;
}

.ratio-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ratio-input .ratio-action {
  color: #909194;
}

.ratio-input input {
  max-width: 100px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}

.compact button {
  font-size: 16px;
}

.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

.dark button.secondary {
  background: #000000;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

button.blank {
  background: #ffffff;
}

.dark button.blank {
  background: #000000;
}

.loading {
  width: 18px;
  height: 18px;
}

.preview-body {
  text-align: left;
}

.compact .summary-title {
  font-size: 18px;
}

.summary-title {
  margin-top: 21px;
  font-size: 20px;
}

.summary-message {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.summary-message:last-of-type {
  margin-bottom: 20px;
}

.success-body p {
  /* color: #10BC74; */
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.success-body p {
  font-size: 21px;
}

.error {
  color: #ce0606;
}

@media only screen and (max-width: 425px) {
  .ticker {
    font-size: 24px;
  }

  .input-line label {
    font-size: 18px;
  }

  .input-line select,
  .input-line input {
    font-size: 18px;
  }

  .input-line .description {
    font-size: 14px;
  }

  button {
    font-size: 16px;
  }
}
</style>
