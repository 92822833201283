<template>
  <div class="share">
    <p @click="openShare" class="share-toggle">
      Share<span v-if="share"> link:</span>
    </p>
    <p v-if="notify" class="copied green">Copied to clipboard!</p>
    <textarea ref="copylink" @click="copy" v-model="localLink" v-if="share" v-on:focus="$event.target.select()"
      readonly></textarea>
  </div>
</template>

<script>
export default {
  name: "ShareLink",
  props: {
    link: String,
  },
  data() {
    return {
      share: false,
      notify: false,
    };
  },
  methods: {
    openShare() {
      this.share = !this.share;
    },
    copy() {
      try {
        this.$refs.copylink.focus();
        this.$refs.copylink.select();
        document.execCommand("copy");
        this.notify = true;
        var _this = this;
        setTimeout(function () {
          _this.notify = false;
        }, 2000);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  height: 1.5em;
  word-break: break-all;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dark textarea {
  background: #d9d9d9;
}

.share-toggle {
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  color: #616164;
  text-align: right;
}

.share-toggle:hover {
  color: #10bc74;
}

.copied {
  margin: 0;
  font-size: 10px;
  text-align: center;
  margin-top: -12.5px;
}
</style>
