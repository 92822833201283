<!-- eslint-disable no-prototype-builtins -->
<template>
  <div>

    <div style="
        overflow-x: auto;
        max-width: 800px;
      ">

      <table v-if="Object.entries(this.positionsData).length > 0">
        <!-- Table headers -->
        <thead>
          <tr class="positions-header clickable">
            <th v-for="(header, index) in headers" :key="index" v-show="header.isShow" @click="toggleSort(header.text)">
              {{ header.text }}
              <!-- Sorting icons -->
              <span v-if="currentSortColumn === header.text">
                <svg v-if="currentSortAscending" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1-.708.708L8 5.707 4.354 9.354a.5.5 0 1 1-.708-.708l4-4z" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                  class="bi bi-chevron-down" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
              <!-- Settings icon -->
              <span v-if="header.showSettings">
                <img :style="{ marginRight: settingsStyle ? '20px' : '28px' }" class="traderSettingsIcon" src="../../assets/settings.png"
                  @click="handleSettings" />
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <template v-for="(positions, symbol, index) in positionsData">
            <tr v-if="positions.length > 1 ||
        (positions.length === 1 &&
          positions[0].AssetType === 'STOCKOPTION')
        " :key="'group-' + index" style="text-align: left">
              <td v-for="(header, headerIndex) in headers" :key="'header-' + headerIndex" v-show="header.isShow">
                <!-- <td colspan="100%" class="symbol-data clickable"> -->
                <div v-if="header.text === 'Symbol'" colspan="100%" class="symbol-data clickable">
                  <span class="expand-arrow" @click="toggleDropdown(symbol)" :class="{
      expanded:
        dropdownIndex === symbol || dropdownIndexes.has(symbol),
    }">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 7l5 5-5 5" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <line x1="20" y1="7" x2="20" y2="19" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </span>
                  <span @click="openPosition(positions[0])" class="symbol-description-main">
                    <strong>{{ symbol }}</strong>
                    <span class="symbol-description" v-for="descriptionObject in descriptionData"
                      :key="descriptionObject">
                      <span
                        v-if="Object.keys(descriptionObject)[0] === symbol && shouldDisplayDescription(positions)">
                        {{ Object.values(descriptionObject)[0] }}
                      </span>
                    </span>
                  </span>
                </div>
                <!-- </td> -->
                <div
                  v-if="header.text === 'Average Price' || header.text === 'Last' || header.text === 'Bid' || header.text === 'Ask' || header.text === 'Mark'">

                </div>
                <div v-else-if="header.text === 'P/L All'">
                  <span
                    :class="{ red: parseFloat(sums[symbol]?.UnrealizedProfitLoss) < 0, green: parseFloat(sums[symbol]?.UnrealizedProfitLoss) >= 0 }">
                    {{ sums[symbol].UnrealizedProfitLoss }}
                    <br />
                    {{ `(${markPercentage(positions)})` }}
                  </span>
                </div>
                <div v-else-if="header.text === 'P/L Today'">
                  <span
                    :class="{ red: parseFloat(sums[symbol]?.TodaysProfitLoss) < 0, green: parseFloat(sums[symbol]?.TodaysProfitLoss) >= 0 }">
                    {{ sums[symbol]?.TodaysProfitLoss }}
                  </span>
                </div>
                <div v-else-if="header.text === 'Market Value'">
                  {{ sums[symbol]?.MarketValue }}
                </div>
                <div v-else-if="header.text === 'Cost Basis'">
                  {{ sums[symbol]?.TotalCost }}
                </div>
              </td>

              <!-- <td @click="openPosition(positions[0])" v-if="headers[1].isShow"></td>
              <td @click="openPosition(positions[0])" v-if="headers[2].text === 'P/L All' && headers[2].isShow"
                :class="{
      red: parseFloat(sums[symbol]?.UnrealizedProfitLoss) < 0,
      green: parseFloat(sums[symbol]?.UnrealizedProfitLoss) >= 0,
    }">
                {{ sums[symbol]?.UnrealizedProfitLoss }}sdfsff
                <br />{{ `(${markPercentage(positions)})` }}
              </td>
              <td @click="openPosition(positions[0])" v-if="headers[3].text == 'P/L Today' && headers[3].isShow" :class="{
      red: parseFloat(sums[symbol]?.TodaysProfitLoss) < 0,
      green: parseFloat(sums[symbol]?.TodaysProfitLoss) >= 0,
    }">
                {{ sums[symbol]?.TodaysProfitLoss }}ddd
              </td>
              <td @click="openPosition(positions[0])" v-if="headers[4].isShow">
                {{ sums[symbol]?.MarkToMarketPrice }}nsd
              </td>
              <td @click="openPosition(positions[0])" v-if="headers[5].isShow">{{ sums[symbol]?.TotalCost }}</td>
              <td @click="openPosition(positions[0])" v-if="headers[6].isShow"></td>
              <td @click="openPosition(positions[0])" v-if="headers[7].isShow"></td>
              <td @click="openPosition(positions[0])" v-if="headers[8].isShow"></td>
              <td @click="openPosition(positions[0])" v-if="headers[9].isShow"></td> -->
            </tr>
            <tr class="main-symbol" v-else :key="index" @click="openPosition(positions[0])">
              <td v-for="(header, headerIndex) in headers" :key="'header-' + headerIndex" v-show="header.isShow">
                <div v-if="header.text === 'Symbol'" class="child-description">
                  {{ positions[0].Symbol }}
                  <span :style="{
      color: isItmOrNot(positions[0]) ? '#FFB74B' : 'inherit',
    }">
                    {{ isItmOrNot(positions[0]) ? "ITM " : "" }}
                  </span>
                  <span :class="{
      long: positions[0].LongShort === 'Long',
      short: positions[0].LongShort === 'Short',
    }">
                    {{
      positions[0].LongShort == "Long"
        ? `+${positions[0].Quantity}`
        : positions[0].Quantity
    }}
                  </span>
                </div>
                <div v-else-if="header.text === 'Last'">
                  ${{ dollarWithCommas(positions[0].Last) }}
                </div>
                <div v-else-if="header.text === 'P/L All'">
                  <span v-if="!byMark"
                    :class="{ red: parseFloat(positions[0].UnrealizedProfitLoss) < 0, green: parseFloat(positions[0].UnrealizedProfitLoss) >= 0 }">
                    {{ dollarWithCommas(positions[0].UnrealizedProfitLoss) }}
                    <br />
                    {{ `(${parseFloat(positions[0].UnrealizedProfitLossPercent).toFixed(2)}%)` }}
                  </span>
                  <span v-else
                    :class="{ red: parseFloat(markProfitLossAll(positions[0])) < 0, green: parseFloat(markProfitLossAll(positions[0])) >= 0 }">
                    {{ dollarWithCommas(markProfitLossAll(positions[0])) }}<br />({{
        markProfitLossPercent(positions[0]).toFixed(2)
      }}%)
                  </span>
                </div>
                <div v-else-if="header.text === `P/L Today`">
                  <span
                    :class="{ red: parseFloat(positions[0].TodaysProfitLoss) < 0, green: parseFloat(positions[0].TodaysProfitLoss) >= 0 }">
                    {{ positions[0].TodaysProfitLoss != null ? dollarWithCommas(positions[0].TodaysProfitLoss) : "" }}
                  </span>
                </div>
                <div v-else-if="header.text === 'Market Value'">
                  ${{ dollarWithCommas(positions[0].MarketValue) }}
                </div>
                <div v-else-if="header.text === 'Cost Basis'">
                  ${{ dollarWithCommas(positions[0].TotalCost) }}
                </div>
                <div v-else-if="header.text === 'Average Price'">
                  ${{ dollarWithCommas(positions[0].AveragePrice) }}
                </div>
                <div v-else-if="header.text === 'Bid'">
                  {{ positions[0].Bid }}
                </div>
                <div v-else-if="header.text === 'Ask'">
                  {{ positions[0].Ask }}
                </div>
                <div v-else-if="header.text === 'Mark'">
                  {{ calculateMark(positions[0]) }}
                </div>
              </td>

              <!-- <td v-if="headers[0].isShow" class="child-description">
                {{ positions[0].Symbol }}
                <span :style="{
      color: isItmOrNot(positions[0]) ? '#FFB74B' : 'inherit',
    }">
                  {{ isItmOrNot(positions[0]) ? "ITM " : "" }}
                </span>
                <span :class="{
      long: positions[0].LongShort === 'Long',
      short: positions[0].LongShort === 'Short',
    }">
                  {{
      positions[0].LongShort == "Long"
        ? `+${positions[0].Quantity}`
        : positions[0].Quantity
    }}
                </span>
              </td>

              <td v-if="headers[1].isShow && !byMark">
                ${{ dollarWithCommas(positions[0].Last) }}
              </td>
              <td v-if="headers[2].isShow && !byMark" :class="{
      red: parseFloat(positions[0].UnrealizedProfitLoss) < 0,
      green: parseFloat(positions[0].UnrealizedProfitLoss) >= 0,
    }">
                {{ dollarWithCommas(positions[0].UnrealizedProfitLoss) }}kdfk
                <br />{{ `(${parseFloat(positions[0].UnrealizedProfitLossPercent).toFixed(2)}%)` }}

              </td>
              <td v-if="headers[3].isShow && !byMark" :class="{
      red: parseFloat(positions[0].TodaysProfitLoss) < 0,
      green: parseFloat(positions[0].TodaysProfitLoss) >= 0,
    }">
                {{ dollarWithCommas(positions[0].TodaysProfitLoss) }}
              </td>
              <td v-if="headers[4].isShow && !byMark">
                ${{ dollarWithCommas(positions[0].MarketValue) }}
              </td>
              <td v-if="headers[5].isShow && !byMark">
                ${{ dollarWithCommas(positions[0].TotalCost) }}
              </td>
              <td v-if="headers[6].isShow && !byMark">
                ${{ dollarWithCommas(positions[0].AveragePrice) }}
              </td>
              <td v-if="headers[7].isShow && !byMark">
                {{ positions[0].Bid }}
              </td>
              <td v-if="headers[8].isShow && !byMark">
                {{ positions[0].Ask }}
              </td>
              <td v-if="headers[9].isShow && !byMark">
                {{ calculateMark(positions[0]) }}
              </td> -->
            </tr>
            <template v-if="positions.length > 1 ||
        (positions.length === 1 &&
          positions[0].AssetType === 'STOCKOPTION')
        ">
              <tr v-for="(position, i) in positions" :key="'child-' + i + '-' + index" class="child"
                v-show="dropdownIndexes.has(symbol)" @click="openPosition(position)">

                <td v-for="(header, headerIndex) in headers" :key="'header-' + headerIndex" v-show="header.isShow">
                  <div v-if="header.text === 'Symbol'">
                    <div class="symbol-content">
                      <div class="checkbox">
                        <input type="checkbox" v-model="position.isChecked"
                          @click.stop="handleCheckBox(symbol, position)" class="custom-checkbox" />
                      </div>
                      <div class="child-description">
                        {{ position.AssetType.includes('OPTION') ? getOptionSymbolNameFormatted(position) :
        position.Symbol }}
                        <span :class="{ long: position.LongShort === 'Long', short: position.LongShort === 'Short' }">
                          <span :style="{ color: isItmOrNot(position) ? '#FFB74B' : 'inherit' }">
                            {{ isItmOrNot(position) ? 'ITM ' : '' }}
                          </span>
                          {{ position.LongShort === 'Long' ? `+${position.Quantity}` : position.Quantity }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div  v-else-if="header.text === 'Last'">
                    ${{ dollarWithCommas(position.Last) }}
                  </div>
                  <div v-else-if="header.text === 'P/L All'">
                    <span v-if="!byMark"
                    :class="{ red: parseFloat(position.UnrealizedProfitLoss) < 0, green: parseFloat(position.UnrealizedProfitLoss) >= 0 }">
                    {{ dollarWithCommas(position.UnrealizedProfitLoss) }}
                    <br />
                    {{ `(${parseFloat(position.UnrealizedProfitLossPercent).toFixed(2)}%)` }}
                  </span>
                  <span v-else
                    :class="{ red: parseFloat(markProfitLossAll(position)) < 0, green: parseFloat(markProfitLossAll(position)) >= 0 }">
                    {{ dollarWithCommas(markProfitLossAll(position)) }}<br />({{
        markProfitLossPercent(position).toFixed(2)
      }}%)
                  </span>
                  </div>
                  <div v-else-if="header.text === `P/L Today`">
                    <span
                      :class="{ red: parseFloat(position.TodaysProfitLoss) < 0, green: parseFloat(position.TodaysProfitLoss) >= 0 }">
                      {{ dollarWithCommas(position.TodaysProfitLoss) }}
                    </span>
                  </div>
                  <div v-else-if="header.text === 'Market Value'">
                    ${{ dollarWithCommas(position.MarketValue) }}
                  </div>
                  <div v-else-if="header.text === 'Cost Basis'">
                    ${{ dollarWithCommas(position.TotalCost) }}
                  </div>
                  <div v-else-if="header.text === 'Average Price'">
                    ${{ dollarWithCommas(position.AveragePrice) }}
                  </div>
                  <div v-else-if="header.text === 'Bid'">
                    {{ position.Bid }}
                  </div>
                  <div v-else-if="header.text === 'Ask'">
                    {{ position.Ask }}
                  </div>
                  <div v-else-if="header.text === 'Mark'">
                    {{ calculateMark(position) }}
                  </div>
                </td>


                <!-- <td v-if="headers[0].isShow" class="symbol-cell">
                  <div class="symbol-content">
                    <div class="checkbox">
                      <input type="checkbox" v-model="position.isChecked"
                        @click.stop="handleCheckBox(symbol, position)" class="custom-checkbox" />
                    </div>
                    <div class="child-description">
                      {{
      position.AssetType.includes("OPTION")
        ? getOptionSymbolNameFormatted(position)
        : position.Symbol
    }}
                      <span :class="{
        long: position.LongShort === 'Long',
        short: position.LongShort === 'Short',
      }">
                        <span :style="{
      color: isItmOrNot(position)
        ? '#FFB74B'
        : 'inherit',
    }">
                          {{ isItmOrNot(position) ? "ITM " : "" }} </span>{{
      position.LongShort == "Long"
        ? `+${position.Quantity}`
        : position.Quantity
    }}</span>
                    </div>
                  </div>
                </td>

                <td v-if="headers[1].isShow && !byMark">
                  ${{ dollarWithCommas(position.Last) }}
                </td>
                <td v-if="headers[2].isShow && !byMark" :class="{
      red: parseFloat(position?.UnrealizedProfitLoss) < 0,
      green: parseFloat(position?.UnrealizedProfitLoss) >= 0,
    }">
                  {{ dollarWithCommas(position?.UnrealizedProfitLoss) }}
                  <br />{{ `(${parseFloat(position.UnrealizedProfitLossPercent).toFixed(2)}%)` }}
                </td>
                <td v-if="headers[3].isShow && !byMark" :class="{
      red: parseFloat(position.TodaysProfitLoss) < 0,
      green: parseFloat(position.TodaysProfitLoss) >= 0,
    }">
                  {{ dollarWithCommas(position.TodaysProfitLoss) }}
                </td>
                <td v-if="headers[4].isShow && !byMark">
                  ${{ dollarWithCommas(position.MarketValue) }}
                </td>
                <td v-if="headers[5].isShow && !byMark">
                  ${{ dollarWithCommas(position.TotalCost) }}
                </td>
                <td v-if="headers[6].isShow && !byMark">
                  ${{ dollarWithCommas(position.AveragePrice) }}
                </td>
                <td v-if="headers[7].isShow">{{ position.Bid }}</td>
                <td v-if="headers[8].isShow">{{ position.Ask }}</td>
                <td v-if="headers[9].isShow && !byMark">
                  {{ calculateMark(position) }}
                </td> -->
              </tr>

              <tr v-if="positions.some((pos) => pos.isChecked) &&
        dropdownIndexes.has(symbol)
        " class="child" :key="index">
                <td :colspan="headers.length">
                  <div class="button-position">
                    <span class="text-position">Option:</span>
                    <button class="buttons" @click="rollOptions(positions.filter((pos) => pos.isChecked))"
                      v-if="false && positions.some((pos) => pos.isChecked && pos.AssetType === 'STOCKOPTION')">Roll
                      Selected</button>
                    <button class="close-selected" @click="sell(positions.filter((pos) => pos.isChecked))">
                      Close Selected
                    </button>
                    <div class="text-position close-icon" @click="handleOptionclose(positions)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <div v-else>
        <p class="loading-main">Loading...</p>
      </div>


      <Modal style="z-index: 1000" v-if="titleData" @close="readExpiryWarn">
        <SettingsPopup @close="readExpiryWarn" :headerKey="this.key" :headerTitle="headers" @update-header="updateHeaders" :dark="dark" />
      </Modal>

      <!-- <div v-if="this.selectedPositions.some(selectedPos => selectedPos.isChecked == true)" class="footer-div"
        :key="index">
        <div :colspan="headers.length">
          <div class="button-position">
            <button class="buttons" @click="rollOptions(selectedPositions.filter(selectedPos => selectedPos.isChecked))"
              v-if="false && selectedPositions.some(selectedPos => selectedPos.isChecked && selectedPos.AssetType.includes('OPTION'))">
              Roll Selected
            </button>
            <button class="close-selected"
              @click="sell(this.selectedPositions.filter(selectedPos =>selectedPos.isChecked))">
              Close Selected
            </button>
            <div class="text-position close-icon" @click="handleOptionclose(this.selectedPositions)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Mixins from "../../Mixins";
import SettingsPopup from "../SettingsPopup.vue";
import Modal from "../Modal.vue";

export default {
  name: "Positions",
  props: {
    positions: Array,
    byMark: Boolean,
    recent: Boolean,
    globalPositionsQuotesPrice: Object,
    ticker: String,
    dark: Boolean,
  },
  mixins: [Mixins],
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  emits: ['positions-data'],
  data() {
    return {
      key:"positionsHeader",
      allPositions: [],
      dropdownIndexes: new Set(),
      items: [
        {
          name: "African Elephant",
          species: "Loxodonta africana",
          diet: "Herbivore",
          habitat: "Savanna, Forests",
        },
      ],
      positionsData: {},
      dropdownIndex: null,
      isChecked: false,
      titleData: false,
      headers: [
        { text: "Symbol", showSettings: true, isShow: true },
        { text: "Last", showSettings: false, isShow: true },
        { text: "P/L All", showSettings: false, isShow: true },
        { text: "P/L Today", showSettings: false, isShow: true },
        { text: "Market Value", showSettings: false, isShow: true },
        { text: "Cost Basis", showSettings: false, isShow: true },
        { text: "Average Price", showSettings: false, isShow: true },
        { text: "Bid", showSettings: false, isShow: true },
        { text: "Ask", showSettings: false, isShow: true },
        { text: "Mark", showSettings: false, isShow: true },
      ],
      sums: {},
      currentSortColumn: "Symbol",
      currentSortAscending: true,
      selectedPositions: [],
      tickers: [],
      closeSelected: false,
      updatedTicker: { Symbol: this.ticker },
      oldPosition: [],
      descriptionData: [],
      priceIncrement: {
        style: null,
        increment: null,
        schedule: [],
      },
      isExpanded: false,
      settingsStyle : false
    };
  },
  components: {
    SettingsPopup,
    Modal,
  },
  computed: {
    positionList() {
      return this.positions;
    },
    getAllPositions() {
      const allPositions = [];
      for (const symbol in this.positionsData) {
        allPositions.push(...this.positionsData[symbol]);
      }
      return allPositions;
    }
  },
  watch: {
    positions: {
      handler() {
        this.updatePositionsData();
        this.calculateSums();
      },
      deep: true,
    },
    // selectedPositions: {
    //   handler() {
    //     this.calculateSums();
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    const storedHeaders = localStorage.getItem("positionsHeader");
    if (storedHeaders) {
      this.headers = JSON.parse(storedHeaders);
    }
    this.sortPositions("Symbol", true);
    if (this.positionList.length > 0) {
      this.positionList.forEach((el) => {
        this.dropdownIndexes.add(el.Symbol);
      });
    }
    this.isExpanded = true
    this.updatePositionsData();
    this.calculateSums();
  },

  methods: {
    shouldDisplayDescription(positions) {

      let hasStock = false;
      let hasOptionBeforeStock = false;
      let stockTimestamp = null;

      for (const position of positions) {
        if (position.AssetType === 'STOCK') {
          hasStock = true;
          stockTimestamp = position.Timestamp;
        }
        if (position.AssetType === 'STOCKOPTION') {
          if (!stockTimestamp || new Date(position.Timestamp) < new Date(stockTimestamp)) {
            hasOptionBeforeStock = true;
          }
        }
      }

      return hasStock && !hasOptionBeforeStock;
    },
    scrollToBottom() {
      if (window.innerWidth <= 768) {
        this.$nextTick(() => {
          document.querySelector("html").scrollTop =
            document.querySelector("html").scrollHeight;
        });
      }
    },
    async updatePositionsData() {
      const groupedBySymbol = this.positions.reduce((acc, position) => {
        const symbolKey = position.Symbol.split(" ")[0];
        if (!acc[symbolKey]) {
          acc[symbolKey] = [];
        }
        acc[symbolKey].push(position);
        return acc;
      }, {});

      this.positionsData = Object.keys(groupedBySymbol).reduce(
        (acc, symbolKey) => {
          const positions = groupedBySymbol[symbolKey];
          acc[symbolKey] = positions.map((pos) => {
            const isSelected = this.selectedPositions.some(selectedPos =>
              selectedPos.Symbol === pos.Symbol &&
              selectedPos.AssetType === pos.AssetType);
            return { ...pos, isChecked: isSelected };
          });
          return acc;
        },
        {}
      );

      if (this.isExpanded == true) {
        Object.keys(this.positionsData).forEach((symbol) => {
          if (this.positionsData[symbol].length === 1) {
            this.dropdownIndexes.add(symbol);
          }
        });
        this.isExpanded = false
      }


      let isBool = false;

      for (let key in this.positionsData) {
        if (this.tickers.indexOf(key) === -1) {
          this.tickers.push(key)
          isBool = true
        } else {
          isBool = false
        }
      }
      if (isBool) {
        await this.fetchSymbolDescription();
      }
      if (this.currentSortColumn !== null) {
        this.sortPositions(this.currentSortColumn, this.lastSortAsc);
      }
      this.$emit('positions-data', this.positionsData);

    },
    async fetchSymbolDescription() {
      if (!this.tickers) {
        return;
      }
      let descriptionList = []
      try {
        const response = await this.http.get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `marketdata/symbols/${this.tickers.join(",")}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        );

        descriptionList = response.data.Symbols.map(symbolInfo => ({
          [symbolInfo.Symbol]: symbolInfo.Description
        }));
        this.descriptionData = descriptionList;

        if (response.data.Symbols.length > 0) {
          this.updatedTicker.AssetType = response.data.Symbols[0].AssetType;
          if (
            response.data.Symbols[0].PriceFormat &&
            response.data.Symbols[0].PriceFormat.IncrementStyle
          ) {
            this.priceIncrement.style =
              response.data.Symbols[0].PriceFormat.IncrementStyle;
            if (this.priceIncrement.style == "Simple") {
              this.priceIncrement.increment =
                response.data.Symbols[0].PriceFormat.Increment;
            } else if (this.priceIncrement.style == "Schedule") {
              this.priceIncrement.schedule =
                response.data.Symbols[0].PriceFormat.IncrementSchedule;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    rollOptions(position) {
      this.$emit("openPosition", position);

      this.$emit("rollOptions", position.map((pos) => ({ ...pos, isClosing: true })));
      this.handleOptionclose(position);
      this.scrollToBottom();
    },

    markPercentage(values) {
      let groupProfit = 0;
      let groupTotalCost = 0;

      values.forEach(element => {
        groupProfit += parseFloat(element?.UnrealizedProfitLoss || '0');
        groupTotalCost += parseFloat(element.TotalCost || '0');
      });

      let groupProfitPer = ((groupProfit / groupTotalCost) * 100);
      let per = `${groupProfitPer.toFixed(2).toString()}%`;
      return per;
    },

    getOptionSymbolNameFormatted(position) {
      const text = position.Symbol.split(' ').pop();
      const end = text.substring(6); 
      try {
        const date = new Date(position.ExpirationDate);
        const day = date.getUTCDate();
        const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
        const year = date.getUTCFullYear().toString().slice(-2);
        return `${day} ${month} ${year} ${end.charAt(0)} ${end.substring(1)}`;
      } catch (e) {
        console.error('Error formatting date:', e);
        return text; 
      }
    },
    isItmOrNot(position) {
      let isItm = false;
      if (position.AssetType?.includes("OPTION")) {
        let underlyingPrice = parseFloat(
          this.globalPositionsQuotesPrice[position.Symbol.split(" ")[0]]
        );
        let optionType = position.Symbol.split(" ")[1].includes("C")
          ? "CALL"
          : "PUT";
        if (optionType === "CALL") {
          let strikePrice = parseFloat(
            position.Symbol.split(" ")[1].split("C")[1]
          );
          isItm = strikePrice < underlyingPrice;
        }
        if (optionType === "PUT") {
          let strikePrice = parseFloat(
            position.Symbol.split(" ")[1].split("P")[1]
          );
          isItm = strikePrice > underlyingPrice;
        }
      }
      return isItm;
    },

    sell(position) {

      if (position[0].Symbol.indexOf(" ") != -1) {
        this.$emit("openPosition", position);
        this.$emit(
          "sellOptions",
          position.map((pos) => ({
            Symbol: pos.Symbol,
            Bid: pos.Bid,
            Ask: pos.Ask,
            AssetType: pos.AssetType,
            Quantity: pos.Quantity,
          }))
        );
      } else {
        this.$emit("openPosition", position[0]);
        this.$emit("sell", position[0].Symbol);
      }
      this.handleOptionclose(position);
      this.scrollToBottom();
    },

    calculateMark(position) {
      if (position.AssetType && position.AssetType.includes("STOCKOPTION")) {
        const decimalPlaces =
          position.Ask && position.Ask.includes(".")
            ? position.Ask.split(".")[1].length
            : 2;
        const bid = parseFloat(position.Bid || 0);
        const ask = parseFloat(position.Ask || 0);
        const midValue = ((bid + ask) / 2).toString();
        return this.formatMoney(midValue, decimalPlaces);
      } else {
        return this.formatMoney(position.Last, 2, true);
      }
    },

    formatMoney(value, decimal = 2, dynamicDecimal = false) {
      let newValue = value || "";
      let isNegative = false;

      if (dynamicDecimal) {
        if (!newValue.includes(".")) {
          newValue += ".00";
        }
        const splitValue = newValue.split(".");
        decimal = splitValue.length > 1 ? splitValue[1].length : 2;
      }

      decimal = Math.max(2, Math.min(decimal, 8));

      let convertMe = parseFloat(value);
      newValue = convertMe.toFixed(decimal);

      if (newValue.includes("-")) {
        isNegative = true;
        newValue = newValue.replace("-", "");
      }

      let [dollarValue, centValue] = newValue.split(".");

      if (centValue && centValue.length > decimal) {
        centValue = centValue.substring(0, decimal);
        newValue = dollarValue + "." + centValue;
      }

      const dollarValueLength = dollarValue.length;
      if (dollarValueLength > 12) {
        newValue =
          dollarValue.slice(0, dollarValueLength - 12) +
          "." +
          dollarValue.slice(1, 4) +
          "T";
      } else if (dollarValueLength > 9) {
        newValue =
          dollarValue.slice(0, dollarValueLength - 9) +
          "." +
          dollarValue.slice(1, 4) +
          "B";
      } else if (dollarValueLength > 6) {
        newValue =
          dollarValue.slice(0, dollarValueLength - 6) +
          "." +
          dollarValue.slice(1, 4) +
          "M";
      } else {
        dollarValue = dollarValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        newValue = dollarValue + "." + centValue;
      }

      if (isNegative) {
        newValue = "-" + newValue;
      }

      return newValue;
    },
    updateHeaders(updatedHeaders) {
      this.headers = updatedHeaders;
    },
    openPosition(position) {
      this.$emit("openPosition", position);
    },
    toggleDropdown(symbol) {
      if (this.dropdownIndexes.has(symbol)) {
        this.dropdownIndexes.delete(symbol);
        this.dropdownIndex = null;
        this.settingsStyle = true;
      } else {
        this.dropdownIndexes.add(symbol);
        this.dropdownIndex = symbol;
        this.settingsStyle = false;
      }
      this.dropdownIndexes = new Set([...this.dropdownIndexes]);
    },
    toggleSort(header) {
      if (this.currentSortColumn === header) {
        this.currentSortAscending = !this.currentSortAscending;
      } else {
        this.currentSortColumn = header;
        this.currentSortAscending = true;
      }
      this.sortPositions(header, this.currentSortAscending);
    },
    handleCheckBox(symbol, position) {
      const list = this.positionsData[symbol];
      list.forEach((element) => {
        if (
          element.Symbol == position.Symbol &&
          element.AssetType == position.AssetType
        ) {
          element.isChecked = !element.isChecked;

          const currentFirstPartOfSymbol = position.Symbol.split(" ")[0];
          const isConsistent = this.selectedPositions.every(
            (pos) =>
              pos.AssetType === position.AssetType &&
              pos.Symbol.split(" ")[0] === currentFirstPartOfSymbol
          );

          if (element.isChecked) {
            if (isConsistent || this.selectedPositions.length === 0) {
              this.selectedPositions.push(position);
              this.oldPosition.push(position)

            } else {
              this.selectedPositions = [position];
              this.oldPosition = [position]
            }
          } else {
            this.selectedPositions = this.selectedPositions.filter(
              (pos) =>
                !(
                  pos.Symbol === position.Symbol &&
                  pos.AssetType === position.AssetType
                )
            );
          }
        }
      });

      Object.values(this.positionsData).forEach((positions) => {
        positions.forEach((element) => {
          element.isChecked = this.selectedPositions.some(
            (pos) =>
              pos.Symbol === element.Symbol &&
              pos.AssetType === element.AssetType
          );
        });
      });

      this.positionsData[symbol] = list;
    },

    handleSettings() {
      this.titleData = true;
    },
    readExpiryWarn() {
      this.titleData = false;
    },
    generateKey(position, index) {
      return "child-" + position + "-" + index;
    },
    handleOptionclose(positions) {
      positions.map((pos) => (pos.isChecked = false));
      this.selectedPositions = [];
    },
    calculateSums() {
      const sums = {};
      Object.keys(this.positionsData).forEach((symbol) => {
        sums[symbol] = {
          UnrealizedProfitLoss: 0,
          TodaysProfitLoss: 0,
          MarketValue: 0,
          TotalCost: 0,
        };

        const positions = this.positionsData[symbol];
        if (
          positions.length > 1 ||
          (positions.length === 1 && positions[0].AssetType === "STOCKOPTION")
        ) {
          positions.forEach((position) => {
            sums[symbol].UnrealizedProfitLoss += parseFloat(
              position.UnrealizedProfitLoss || 0
            );
            sums[symbol].TodaysProfitLoss += parseFloat(
              position.TodaysProfitLoss || 0
            );
            sums[symbol].MarketValue += parseFloat(position.MarketValue || 0);
            sums[symbol].TotalCost += parseFloat(position.TotalCost || 0);
          });
        } else {
          sums[symbol].UnrealizedProfitLoss = parseFloat(
            positions.UnrealizedProfitLoss || 0
          );
          sums[symbol].TodaysProfitLoss = parseFloat(
            positions.TodaysProfitLoss || 0
          );
          sums[symbol].MarketValue = parseFloat(positions.MarketValue || 0);
          sums[symbol].TotalCost = parseFloat(positions.TotalCost || 0);
        }
        sums[symbol].UnrealizedProfitLoss =
          Math.floor(sums[symbol].UnrealizedProfitLoss * 100) / 100;
        sums[symbol].TodaysProfitLoss =
          Math.floor(sums[symbol].TodaysProfitLoss * 100) / 100;
        sums[symbol].MarketValue =
          Math.floor(sums[symbol].MarketValue * 100) / 100;
        sums[symbol].TotalCost = Math.floor(sums[symbol].TotalCost * 100) / 100;
      });
      this.sums = sums;
    },
    sortBySymbol(list, sort) {
      let sortedPositionsGroupMap = {};
      list
        .sort((a, b) => {
          return sort ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0]);
        })
        .forEach(([key, position]) => {
          const sortedPositions = position.sort((a, b) => {
            return sort
              ? a["Symbol"].localeCompare(b["Symbol"])
              : b["Symbol"].localeCompare(a["Symbol"]);
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Symbol";
      this.lastSortAsc = sort;
    },
    sortByAVGPrice(list, sort) {
      let sortedPositionsGroupMap = {};
      list
        .sort((a, b) => {
          let lastA = a[1].length === 1 ? a[1][0].AveragePrice || "0" : 0;
          let lastB = b[1].length === 1 ? b[1][0].AveragePrice || "0" : 0;
          return sort ? lastA - lastB : lastB - lastA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.Last || "0";
            const lastB = b.Last || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Average Price";
      this.lastSortAsc = sort;
    },
    sortByLast(list, sort) {
      let sortedPositionsGroupMap = {};
      list
        .sort((a, b) => {
          let lastA = a[1].length === 1 ? a[1][0].Last || "0" : 0;
          let lastB = b[1].length === 1 ? b[1][0].Last || "0" : 0;
          return sort ? lastA - lastB : lastB - lastA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.Last || "0";
            const lastB = b.Last || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Last";
      this.lastSortAsc = sort;
    },
    sortByBid(list, sort) {
      let sortedPositionsGroupMap = {};

      list
        .sort((a, b) => {
          let lastA = a[1].length === 1 ? a[1][0].Bid || "0" : 0;
          let lastB = b[1].length === 1 ? b[1][0].Bid || "0" : 0;
          return sort ? lastA - lastB : lastB - lastA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.Bid || "0";
            const lastB = b.Bid || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Bid";
      this.lastSortAsc = sort;
    },
    sortByAsk(list, sort) {
      let sortedPositionsGroupMap = {};
      list
        .sort((a, b) => {
          let lastA = a[1].length === 1 ? a[1][0].Ask || "0" : 0;
          let lastB = b[1].length === 1 ? b[1][0].Ask || "0" : 0;
          return sort ? lastA - lastB : lastB - lastA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.Ask || "0";
            const lastB = b.Ask || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Ask";
      this.lastSortAsc = sort;
    },
    sortByMark(list, sort) {
      let sortedPositionsGroupMap = {};
      list
        .sort((a, b) => {
          let lastA = a[1].length === 1 ? a[1][0].Last || "0" : 0;
          let lastB = b[1].length === 1 ? b[1][0].Last || "0" : 0;
          return sort ? lastA - lastB : lastB - lastA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.Last || "0";
            const lastB = b.Last || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });
      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Mark";
      this.lastSortAsc = sort;
    },
    sortByPLAll(sortedEntries, sort) {
      let sortedPositionsGroupMap = {};

      sortedEntries
        .sort((a, b) => {
          const totalA =
            a[1].length === 1 && a[1][0].AssetType !== "STOCKOPTION"
              ? a[1][0].UnrealizedProfitLoss || "0"
              : this.sums[a[0]].UnrealizedProfitLoss;

          const totalB =
            b[1].length === 1 && b[1][0].AssetType !== "STOCKOPTION"
              ? b[1][0].unrealizedProfitLoss || "0"
              : this.sums[b[0]].UnrealizedProfitLoss;

          return sort ? totalA - totalB : totalB - totalA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.UnrealizedProfitLoss || "0";
            const lastB = b.UnrealizedProfitLoss || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });

      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "P/L All";
      this.lastSortAsc = sort;
    },
    sortByPLToday(sortedEntries, sort) {
      let sortedPositionsGroupMap = {};

      sortedEntries
        .sort((a, b) => {
          const totalA =
            a[1].length === 1 && a[1][0].AssetType !== "STOCKOPTION"
              ? a[1][0].TodaysProfitLoss || "0"
              : this.sums[a[0]].TodaysProfitLoss;

          const totalB =
            b[1].length === 1 && b[1][0].AssetType !== "STOCKOPTION"
              ? b[1][0].TodaysProfitLoss || "0"
              : this.sums[b[0]].TodaysProfitLoss;

          return sort ? totalA - totalB : totalB - totalA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.TodaysProfitLoss || "0";
            const lastB = b.TodaysProfitLoss || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });

      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "P/L Today";
      this.lastSortAsc = sort;
    },
    sortByMarketValue(sortedEntries, sort) {
      let sortedPositionsGroupMap = {};

      sortedEntries
        .sort((a, b) => {
          const totalA =
            a[1].length === 1 && a[1][0].AssetType !== "STOCKOPTION"
              ? a[1][0].MarketValue || "0"
              : this.sums[a[0]].MarketValue;

          const totalB =
            b[1].length === 1 && b[1][0].AssetType !== "STOCKOPTION"
              ? b[1][0].MarketValue || "0"
              : this.sums[b[0]].MarketValue;
          return sort ? totalA - totalB : totalB - totalA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.MarketValue || "0";
            const lastB = b.MarketValue || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });

      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Market Value";
      this.lastSortAsc = sort;
    },
    sortByCostBasis(sortedEntries, sort) {
      let sortedPositionsGroupMap = {};

      sortedEntries
        .sort((a, b) => {
          const totalA =
            a[1].length === 1 && a[1][0].AssetType !== "STOCKOPTION"
              ? a[1][0].TotalCost || "0"
              : this.sums[a[0]].TotalCost;

          const totalB =
            b[1].length === 1 && b[1][0].AssetType !== "STOCKOPTION"
              ? b[1][0].TotalCost || "0"
              : this.sums[b[0]].TotalCost;

          return sort ? totalA - totalB : totalB - totalA;
        })
        .forEach(([key, positions]) => {
          const sortedPositions = positions.sort((a, b) => {
            const lastA = a.TotalCost || "0";
            const lastB = b.TotalCost || "0";
            return sort ? lastA - lastB : lastB - lastA;
          });
          sortedPositionsGroupMap[key] = sortedPositions;
        });

      this.positionsData = sortedPositionsGroupMap;
      this.currentSortColumn = "Cost Basis";
      this.lastSortAsc = sort;
    },
    sortPositions(header, sortAscending) {
      let sortedEntries = Object.entries(this.positionsData);

      switch (header) {
        case "Symbol":
          this.sortBySymbol(sortedEntries, sortAscending);
          break;

        case "Last":
          this.sortByLast(sortedEntries, sortAscending);
          break;

        case "Average Price":
          this.sortByAVGPrice(sortedEntries, sortAscending);
          break;
        case "Bid":
          this.sortByBid(sortedEntries, sortAscending);
          break;
        case "Ask":
          this.sortByAsk(sortedEntries, sortAscending);
          break;
        case "Mark":
          this.sortByMark(sortedEntries, sortAscending);
          break;
        case "P/L All":
          this.sortByPLAll(sortedEntries, sortAscending);
          break;
        case "P/L Today":
          this.sortByPLToday(sortedEntries, sortAscending);
          break;
        case "Market Value":
          this.sortByMarketValue(sortedEntries, sortAscending);
          break;
        case "Cost Basis":
          this.sortByCostBasis(sortedEntries, sortAscending);
          break;

        default:
          console.log("Header not recognized for sorting.");
          return;
      }
    },
  },
};
</script>

<style scoped>
.positions-header {
  background: white;
  color: #6a6c6c;
  border-bottom: 1px solid #f0f3f5;
  text-align: left;
}

.dark .positions-header {
  background: #1f2324;
  color: #ffffff;
}

.dark tbody tr td {
  color: #ffffff;
}

thead th {
  min-width: 120px;
}

table {
  position: relative;
}

tbody tr {
  cursor: pointer;
}

tbody tr td {
  min-width: 120px;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: break-spaces;
  vertical-align: top;
  color: #151c26;
}

tbody tr td:last-child {
  min-width: 60px;
}

thead th:last-child {
  min-width: 60px;
}

.traderSettingsIcon {
  display: flex;
  height: 18px;
  width: 18px;
  cursor: pointer;
  float: right;
}

.table-body {
  overflow-y: scroll;
}

tr.group {
  font-weight: bold;
}

tr.group td {
  padding-bottom: 5px;
}

.expand-arrow svg {
  border: 1px solid #cccccc;
  border-radius: 50%;
  cursor: pointer;
}

.expand-arrow.expanded svg {
  transform: rotate(90deg);
}

.expand-arrow.expanded+.position-row {
  border-left: 1px solid #d8dbde;
}

.symbol-data {
  display: flex;
  align-items: center;
  gap: 14px;
}

.symbol-cell {
  display: flex;
  align-items: center;
}

.symbol-content {
  display: flex;
  align-items: flex-start;
  transform: translateX(12px);
}

.checkbox {
  display: flex;
  justify-content: space-evenly;
  margin-right: 8px;
}

table-body td {
  min-width: 80px;
}

.main-symbol {
  text-align: left;
}

tr.child {
  opacity: 0.8;
  text-align: left;
}

.button-position {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f0f3f5;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  background: #F0F3F5;
}

.dark .button-position {
  border-top: 1px solid #333333;
  background: #FFFFFF0D;
}

.dark .text-position {
  color: #ffffff;
}



button {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  width: 130px;
  padding: 5px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #0baa5e;
  cursor: pointer;
  height: 40px;
}

.button-position button {
  margin: 0 10px;
  margin-top: 2px;
}

.close-selected {
  background-color: transparent;
  border: #0baa5e solid 2px;
  color: #0baa5e;
}

.text-position {
  color: #616164;
  text-align: left;
  font-family: "Roboto";
  font-size: 18px;
  margin-top: 8px;
  margin-left: 10px;
}

.option-div {
  display: flex;
  background: rgb(238, 236, 236);
  align-content: center;
  padding: 2px;
}

.close-icon svg {
  border: 1px solid #cccccc;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
  margin-top: 0.5px;
}

.long {
  font-weight: normal;
  color: #10bc74;
  font-size: 12px;
  text-align: left;
}

.short {
  color: #d20b3b;
  font-size: 12px;
  text-align: left;
}

.symbol-description-main {
  display: flex;
  flex-direction: column;
  margin-right: -66px;
  text-align: left;
}

.symbol-description {
  font-weight: normal;
  color: #0baa5e;
  font-size: 12px;
  white-space: nowrap;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.child-description {
  display: flex;
  flex-direction: column;
  min-width:130px
}

.red {
  color: #d20b3b;
}

.green {
  color: #0baa5e;
}

.dark .red {
  color: #d20b3b;
}

.dark .green {
  color: #0baa5e;
}

.bi-chevron-up {
  transform: translateY(-2px);
}

.bi-chevron-downP {
  transform: translateY(5px);
}

input[type="checkbox"]:checked {
  background-color: #0baa5e;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox {
  border-color: #cccccc
}

.custom-checkbox:checked::before {
  content: "\2713";
  font-size: 10px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-main {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.dark .loading-main {
  color: #ffffff;
}

.footer-div {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 2;
  /* Higher z-index to keep it on top */
}

.dark .footer-div {
  background-color: #1F2324;
}
</style>