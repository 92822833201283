<template>
  <div class="news-article-container" :style="{ borderBottom: dark ? '#333333 1px solid' : '1px solid #F0F3F5' }">
    <a :href="url" target="_blank" class="news-article" @click="tapNews">
      <div class="news-content">
        <p class="date" :style="{ color: dark ? '#FFFFFF' : '#151C26' }">
          {{ author }} <span style="margin-left: 5px">{{ date }}</span>
        </p>
        <p class="title" :style="{ color: dark ? '#FFFFFF' : '#6A6C6C' }">{{ title }}</p>
      </div>
      <span v-if="image">
        <img class="news-image" v-if="image" :src="image" />
      </span>
    </a>
    <div class="tickers clickable" v-if="showTickers">
      <span v-for="ticker in tickerValues" @click="openTicker(ticker.Symbol)" :key="ticker.Symbol" class="tickers-list"
        :class="{
      red: ticker.NetChangePct < 0,
      green: ticker.NetChangePct >= 0,
    }">
        {{ ticker.Symbol }} {{ ticker.NetChangePct >= 0 ? "+" : "" }}
        {{ parseFloat(ticker.NetChangePct).toFixed(2) }}%
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsArticle",
  inject: {
    http: { from: "http" }
  },
  props: {
    title: String,
    date: String,
    image: String,
    url: String,
    source: String,
    tickers: Array,
    author: String,
    showTickers: Boolean,
    dark: Boolean
  },
  data() {
    return {
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      tickerValues: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openTicker: function (ticker) {
      this.$router.push({ path: `/trade/${ticker}` });
    },
    tapNews() {
      this.$gtag.event("tap_news", {
        headline: this.title,
        source: this.source,
      });
    },
    getData() {
      var _this = this;
      var tickerString = "";
      for (var i = 0; i < this.tickers.length; i++) {
        tickerString += this.tickers[i] + ",";
      }
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `marketdata/quotes/${tickerString}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(async function (res) {
          _this.tickerValues = res.data.Quotes;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.news-article-container{
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.news-article {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  justify-content: space-between;
  padding-right: 24px;
}

.news-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 8px;
}

.news-content {
  width: 75%;
}

.news-content p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.dark .news-content p {
  color: #d9d9d9;
}

.news-content p.date {
  /* color: #151c26; */
  margin: 0;
  font-size: 11px;
  font-weight: 500;
}

.news-content p.title {
  margin: 8px 0px;
  font-size: 12px;
  font-weight: 400;
  color: #6a6c6c;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tickers-list:first-child {
  margin-left: 0px;
}

.tickers-list {
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
}

.tickers {
  margin: 0;
  /* width: 300px; */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .news-content {
    width: 60%;
  }
}
</style>