<template>
  <div class="orders-tab">
    <p class="no-orders" v-if="recent && orderHistory.length == 0">
      <img
        :src="
          dark
            ? require('../assets/dark-mode-orders-history.png')
            : require('../assets/light-mode-orders-history.png')
        "
        alt=""
      />
    </p>
    <p class="no-orders" v-if="!recent && ordersFilter.length == 0">
      <img
        :src="
          dark
            ? require('../assets/dark-orders-mode.png')
            : require('../assets/light-orders-mode.png')
        "
        alt=""
      />
    </p>
    <div>

    <div
      v-for="(order, index) in ordersFilter"
      :key="'order' + index"
      :class="['orders-components', { closed: !stillOpen(order) }]"
      @click="openOrder(order)"
    >
      <div class="flex-property">
        <span class="font-style">
          <span v-if="order.Legs[0].OpenOrClose">
            {{ `${order.Legs[0].BuyOrSell} to ${order.Legs[0].OpenOrClose}` }}
          </span>
          {{ getOrderTime(order) }}
        </span>
        <span class="font-style">
          {{ statusMap[order.Status] }}
        </span>
      </div>
      <div class="flex-property">
        <span class="text-style">
          {{ getQuantityDisplay(order) }}{{ displaySymbol(order) }}
        </span>
        <span class="text-style">
          Last: ${{
              parseFloat(order.Last
              ? order.Last
              : order.Legs[0].ExecutionPrice
              ? order.Legs[0].ExecutionPrice
              : 0).toFixed(2)
          }}
        </span>
      </div>
    </div>
  </div>
    <div v-if="recent">
      <div
        v-for="(order, index) in orderHistory"
        :key="'historic' + index"
        :class="[
          'orders-components',
          { closed: stillOpen(order) == false, hide: !recent },
        ]"
        @click="openOrder(order)"
      >
        <div class="flex-property">
          <span class="font-style">
            <span v-if="order.Legs[0].OpenOrClose">
              {{ `${order.Legs[0].BuyOrSell} to ${order.Legs[0].OpenOrClose}` }}
            </span>
            {{ getOrderTime(order) }}
          </span>
          <span class="font-style">
            {{ statusMap[order.Status] }}
          </span>
        </div>
        <div class="flex-property">
          <span class="text-style">
            {{ getQuantityDisplay(order) }}{{ displaySymbol(order) }}
          </span>
          <span class="text-style">
            Last: ${{
              parseFloat(order.Last
                ? order.Last
                : order.Legs[0].ExecutionPrice
                ? order.Legs[0].ExecutionPrice
                : 0).toFixed(2)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
// eslint-disable-next-line no-unused-vars
import Toast from "./Toasts.vue";
export default {
  name: "OrdersTable",
  props: {
    recent: {
      type: Boolean,
      default: false,
    },
    orders: Array,
    orderHistory: Array,
    sound: Boolean,
    dark: Boolean,
  },
  mixins: [Mixins],
  data() {
    return {
      statusMap: {
        OPN: "Open",
        ACK: "Pending",
        UCN: "Cancel Pending",
        FLL: "Filled",
        FLP: "Partially-Filled & Cancelled",
        FPR: "Partial Fill",
        OUT: "Cancelled",
        REJ: "Rejected",
        TSC: "Rejected",
        EXP: "Expired",
        BRO: "Broken",
        CAN: "Cancelled by Exchange",
        LAT: "Too late",
        DON: "Queued",
      },
    };
  },

  computed: {
    ordersFilter() {
      var returnList = this.orders;
      //sort orders by order.ClosedDateTime, newest first
      var _this = this;
      returnList.sort((a, b) => {
        return new Date(_this.stillOpen(b) ? b.OpenedDateTime : b.ClosedDateTime) - new Date(this.stillOpen(a) ? a.OpenedDateTime : a.ClosedDateTime);
      });
      if (this.recent) {
        return returnList;
      } else {
        return returnList.filter((order) => this.stillOpen(order));
      }
    },
  },
  methods: {
    getOrderTime(order) {
      var timeString = order.OpenedDateTime;
      if (!this.stillOpen(order)) {
        timeString = order.ClosedDateTime;
      }
      var orderTime = new Date(timeString);

      const hours = orderTime.getHours();
      const minutes =
        orderTime.getMinutes() < 10
          ? "0" + orderTime.getMinutes()
          : orderTime.getMinutes();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[orderTime.getMonth()];
      const day = orderTime.getDate();
      const year = orderTime.getFullYear().toString().slice(-2);

      return `@${hours}:${minutes} ${month} ${day}/${year}`;
    },
    stillOpen(x) {
      if (
        this.statusMap[x["Status"]] == "Open" ||
        this.statusMap[x["Status"]] == "Pending" ||
        this.statusMap[x["Status"]] == "Cancel Pending" ||
        this.statusMap[x["Status"]] == "Queued" ||
        this.statusMap[x["Status"]] == "Partial Fill"
      ) {
        return true;
      } else {
        return false;
      }
    },
    openOrder(order) {
      this.$emit("openOrder", order);
    },
    longShort(order) {
      if (order.Legs.length == 1) {
        if (order.Legs[0].BuyOrSell == "SellShort") {
          return "Short ";
        } else if (order.Legs[0].BuyOrSell == "Buy") {
          return "Buy ";
        } else if (order.Legs[0].BuyOrSell == "Sell") {
          return "Sell ";
        }
      }
      return null;
    },
    getQuantityDisplay(order) {
      if (order["Legs"].length > 1) {
        var legDisplay = "";
        if (order["Legs"][0]["QuantityOrdered"] != null) {
          legDisplay = order["Legs"][0]["QuantityOrdered"] + "x ";
          order["Legs"].forEach((leg) => {
            if (legDisplay != leg["QuantityOrdered"] + "x ") {
              legDisplay = "";
            }
          });
        }

        return legDisplay;
      } else {
        return order["Legs"][0]["QuantityOrdered"] != null
          ? order["Legs"][0]["QuantityOrdered"] + "x "
          : "";
      }
    },
  },
};
</script>

<style scoped>
.orders-tab {
  width: 100%;
  /* padding-top: 10px; */
}
.orders-components {
  min-width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 37px; */
  padding: 18px 0px;
  /* padding:5px; */
  border-bottom: 1px solid #f0f3f5;
  cursor: pointer;
}
.orders-components:last-child {
  border-bottom: none;
}
.text-style {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
}
.font-style {
  font-size: 12px;
  font-weight: 400;
  color: #6a6c6c;
}
.flex-property {
  display: flex;
  justify-content: space-between;
}

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25%;
  margin-left: -8px;
}

.orders-table {
  width: 100%;
  font-family: "Roboto";
  text-align: left;
  min-width: 420px;
  max-width: 600px;
}

.orders-table th {
  font-size: 16px;
  padding-bottom: 4px;
}

.orders-table tr {
  cursor: pointer;
}

.orders-table tr.noclick {
  cursor: default;
}

.orders-table td {
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: break-spaces;
  vertical-align: text-top;
}

.orders-table tr.closed {
  color: #909194;
}

.orders-table tr.hide {
  display: none;
}
</style>