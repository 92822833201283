<template>
  <div class="crypto-trade">
    <div class="trade-view">
      <Coin borderless class="coin-view" :coin="coin" :price="coinPrice" />
      <div
        class="chart-trade"
        :class="{
          'keep-size': showChart && pairs.length > 1,
          'keep-size-small': showChart,
        }"
      >
        <div class="tab-house" v-if="showChart && pairPrices.length > 1">
          <HeadLink
            :active="tab == 'chart'"
            title="Chart"
            @on-click="changeTab('chart')"
          />
          <HeadLink
            :active="tab == 'pairs'"
            title="Pairs"
            @on-click="changeTab('pairs')"
          />
        </div>
        <div class="chart-house" v-if="showChart && tab == 'chart'">
          <iframe :src="chartUrl" seamless />
        </div>

        <div>
          <div
            class="loading-pairs"
            v-if="pairPrices.length == 0 && tab == 'pairs'"
          >
            <img src="../assets/loading.gif" />
            <p>Loading...</p>
          </div>
          <PairTable
            v-if="
              tab == 'chart' &&
              pairPrices.find((obj) => obj.Symbol == coin.Currency + 'USD')
            "
            :pairs="[
              pairPrices.find((obj) => obj.Symbol == coin.Currency + 'USD'),
            ]"
            @buyPair="buyPair"
            @sellPair="sellPair"
          />
          <PairTable
            v-if="pairPrices.length > 0 && tab == 'pairs'"
            :pairs="pairPrices"
            @buyPair="buyPair"
            @sellPair="sellPair"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Coin from "./Coin.vue";
import PairTable from "./PairTable.vue";
import HeadLink from "./HeadLink.vue";

export default {
  name: "CryptoTrade",
  inject: {
    stream: { from: "stream" },
  },
  components: {
    Coin,
    PairTable,
    HeadLink,
  },
  props: {
    coin: Object,
    pairs: Array,
    dark: Boolean,
  },
  data() {
    return {
      selectedPair: this.pairs[0],
      chartUrl: `${process.env.VUE_APP_CHART}/?_id=${encodeURIComponent(
        localStorage.session
      )}&token=${encodeURIComponent(
        localStorage.sessionKey
      )}&symbol=${encodeURIComponent(
        this.coin.Currency + "USD"
      )}&t=${Date.now()}${this.dark ? "&dark=true" : ""}`,
      showChart: true,
      pairPrices: [],
      tab: "chart",
      priceStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
    };
  },
  computed: {
    coinPrice() {
      if (
        this.pairPrices.find((obj) => obj.Symbol == this.coin.Currency + "USD")
      ) {
        return this.pairPrices.find(
          (obj) => obj.Symbol == this.coin.Currency + "USD"
        ).Last;
      } else {
        return "-";
      }
    },
  },
  beforeUnmount: function () {
    if (this.priceStream.connection) {
      this.priceStream.connection.end();
      this.priceStream.connection.destroy();
    }
  },
  mounted() {
    if (
      this.coin.Currency == "USD" ||
      this.coin.Currency == "USDC" ||
      this.coin.Currency == "XRP"
    ) {
      this.showChart = false;
      this.tab = "pairs";
    }
    this.streamPairs();
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    buyPair(pairSymbol) {
      this.$emit("buyPair", pairSymbol);
    },
    sellPair(pairSymbol) {
      this.$emit("sellPair", pairSymbol);
    },
    streamPairs() {
      var _this = this;
      if (this.priceStream.connection) {
        this.priceStream.connection.end();
        this.priceStream.connection.destroy();
      }
      this.priceStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var streamURL = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${this.pairs.join()}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.priceStream.connection = this.stream
        .get(streamURL, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.priceStream || !this.priceStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.priceStream.leftOver != null) {
                chunk = _this.priceStream.leftOver + chunk;
                _this.priceStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (!snapShot.Heartbeat) {
                    if (
                      _this.pairPrices.find(
                        (obj) => obj.Symbol == snapShot.Symbol
                      )
                    ) {
                      for (const [key, value] of Object.entries(snapShot)) {
                        _this.pairPrices.find(
                          (obj) => obj.Symbol == snapShot.Symbol
                        )[key] = value;
                      }
                    } else {
                      _this.pairPrices.push(snapShot);
                    }
                  }
                } else {
                  _this.priceStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.trade-view {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.chart-trade {
  width: 100%;
}
.keep-size-small {
  min-height: 550px;
}
.keep-size {
  min-height: 595px;
}
.tab-house {
  margin-bottom: 20px;
  text-align: right;
  margin-right: -24px;
}
.chart-house {
  border: 1px solid #eef0f3;
  box-shadow: 0px 15px 30px RGBA(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}
.dark .chart-house {
  border: 1px solid #333333;
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
}
iframe {
  width: 100%;
  height: 440px;
  border: none;
}

.loading-pairs {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
.loading-pairs img {
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 850px) {
  .trade-view {
    flex-direction: column-reverse;
  }
  .coin {
    width: 100% !important;
    max-width: 100% !important;
  }
  iframe {
    height: 400px;
  }
}
</style>
