<template>
  <div class="options-flow">
    <div class="delayed-flow" v-if="flowData && delayedFlowUser">
      <p class="red">
        This data is 15 minutes delayed.<br /><a
          href="#"
          class="green"
          @click="loginFlow"
          >Login to Aries</a
        >
        to view live data and trade options commission-free.
      </p>
    </div>
    <FlowMeters :dark="dark" v-if="flowData" :flow="filteredFlow" />
    <div class="tab-container">
      <HeadLink
        :active="tab == 'LIVE_FLOW'"
        title="Live Flow"
        @click="setTab('LIVE_FLOW')"
      />
      <HeadLink
        :active="tab == 'HISTORIC_FLOW'"
        title="Historic Flow"
        @click="setTab('HISTORIC_FLOW')"
      />
    </div>
    <div class="search-container">
      <div v-if="tab == 'HISTORIC_FLOW'" class="dates">
        <div class="start-date-container">
          <Datepicker
            class="start-date"
            v-model="startDate"
            :minDate="minDate"
            :format="format"
            :enableTimePicker="false"
            :dark="dark"
          >
            <template #clear-icon> </template>
          </Datepicker>
          <div class="start-date-loader">
            <img
              v-if="startDateLoader"
              class="spinner-icon"
              src="../assets/loading.gif"
            />
          </div>
        </div>

        <div class="to-text">To</div>

        <div class="end-date-container">
          <Datepicker
            class="end-date"
            v-model="endDate"
            :minDate="minDate"
            :format="format"
            :enableTimePicker="false"
            :dark="dark"
          >
            <template #clear-icon> </template>
          </Datepicker>
          <div class="end-date-loader">
            <img
              v-if="endDateLoader"
              class="spinner-icon"
              src="../assets/loading.gif"
            />
          </div>
        </div>
      </div>
      <FlowSearch
        class="search"
        :pageUpdated="pageUpdated"
        @search="updateSearch"
      />
    </div>
    <FlowFilters
      v-if="flowData"
      :flow="filteredFlow"
      @newFilters="updateFilters"
      @rangeFilter="updateRangeFilter"
    />
    <PageCounterWrapper
      :tab="tab"
      :nextPage="nextPage"
      :reset="reset"
      :showPages="flowData != null"
      @loadPage="loadPage"
    >
      <FlowTable v-if="flowData" :flow="filteredFlow" @newSort="updateSort" />
    </PageCounterWrapper>

    <div class="end-of-page">
      <span
        class="btn-grey"
        v-if="
          (!nextPage || !startDate || !endDate) &&
          tab == 'HISTORIC_FLOW' &&
          flowData != null
        "
      >
        End of Search Results
      </span>
    </div>
    <div class="tab-switch-buttons" v-if="flowData">
      <button
        class="btn-green"
        v-if="tab == 'LIVE_FLOW'"
        @click="setTab('HISTORIC_FLOW')"
      >
        View Historic Flow
      </button>
      <button
        class="btn-green"
        v-if="tab == 'HISTORIC_FLOW'"
        @click="setTab('LIVE_FLOW')"
      >
        View Live Flow
      </button>
    </div>
    <div class="loading" v-if="!flowData">
      <img src="../assets/loading.gif" />
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import orderBy from "lodash.orderby";
import Mixins from "../Mixins";
import FlowMeters from "../components/FlowMeters.vue";
import FlowFilters from "../components/FlowFilters.vue";
import FlowSearch from "../components/FlowSearch.vue";
import FlowTable from "../components/FlowTable.vue";
import HeadLink from "../components/HeadLink.vue";
import PageCounterWrapper from "../components/PageCounterWrapper.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "OptionsFLow",
  props: {
    flowData: Array,
    positions: Array,
    watchlist: Array,
    delayedFlowUser: Boolean,
    dark: Boolean,
    nextPage: String,
  },
  components: {
    FlowMeters,
    FlowFilters,
    FlowSearch,
    FlowTable,
    HeadLink,
    PageCounterWrapper,
    Datepicker,
  },
  mixins: [Mixins],
  data() {
    return {
      refreshTimer: null,
      filter: {
        positions: false,
        size: false,
        million: false,
        unusual: false,
        weekly: false,
        "3day": false,
        bid: false,
        ask: false,
        sweep: false,
        less30: false,
        block: false,
        watchlist: false,
      },
      search: null,
      error: null,
      rangeFilters: {},
      tab: "LIVE_FLOW",
      pageUpdated: false,
      startDate: new Date(new Date() - 7 * 86400000),
      endDate: new Date(),
      minDate: new Date("03/15/2022"),
      reset: 123,
      startDateLoader: false,
      endDateLoader: false,
      format: (date) => {
        return `${this.monthNameShort(
          date.getMonth()
        )} ${date.getDate()}, ${date.getFullYear()}`;
      },
      sort: {},
    };
  },
  watch: {
    startDate() {
      this.startDateLoader = true;
      this.loadPageAndResetPage();
    },
    endDate() {
      this.endDateLoader = true;
      this.loadPageAndResetPage();
    },
    nextPage() {
      this.pageUpdated = false;
      this.startDateLoader = false;
      this.endDateLoader = false;
    },
  },
  computed: {
    filteredFlow: function () {
      var returnFlow = this.flowData;

      if (this.filter["positions"]) {
        var allPositions = [];
        this.positions.forEach((position) => {
          if (position.Symbol.split(" ").length > 0) {
            allPositions.push(position.Symbol.split(" ")[0]);
          } else {
            allPositions.push(position.Symbol);
          }
        });
        returnFlow = returnFlow.filter(
          (row) => allPositions.indexOf(row.symbol) != -1
        );
      }
      if (this.filter["large"]) {
        returnFlow = returnFlow.filter((row) => row.type == "large");
      }
      if (this.filter["million"]) {
        returnFlow = returnFlow.filter((row) => row.total_value >= 1000000);
      }
      if (this.filter["unusual"]) {
        returnFlow = returnFlow.filter((row) => {
          var dateExpire = new Date(
            row.contract.substring(8, 10) +
              "/" +
              row.contract.substring(10, 12) +
              "/" +
              row.contract.substring(6, 8)
          );
          var timeDif = dateExpire.getTime() - new Date();
          var daysDif = timeDif / (1000 * 3600 * 24);
          if (
            row.underlying_price_at_execution &&
            daysDif <= 30 &&
            ((row.contract.charAt(12) == "C" &&
              parseFloat(row.contract.substring(13, 21)) / 1000 >=
                row.underlying_price_at_execution * 1.1) ||
              (row.contract.charAt(12) == "P" &&
                parseFloat(row.contract.substring(13, 21)) / 1000 <=
                  row.underlying_price_at_execution * 0.9))
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.filter["weekly"]) {
        returnFlow = returnFlow.filter((row) => {
          var dateExpire = new Date(
            row.contract.substring(8, 10) +
              "/" +
              row.contract.substring(10, 12) +
              "/" +
              row.contract.substring(6, 8)
          );
          var beforeDate = this.nextDay(new Date(), 0);

          if (dateExpire < beforeDate) {
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.filter["3day"]) {
        returnFlow = returnFlow.filter((row) => {
          var dateExpire = new Date(
            row.contract.substring(8, 10) +
              "/" +
              row.contract.substring(10, 12) +
              "/" +
              row.contract.substring(6, 8)
          );

          var beforeDate = new Date();
          beforeDate.setDate(beforeDate.getDate() + 3);

          if (dateExpire < beforeDate) {
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.filter["bid"]) {
        returnFlow = returnFlow.filter(
          (row) => row.average_price <= row.bid_at_execution
        );
      }
      if (this.filter["ask"]) {
        returnFlow = returnFlow.filter(
          (row) => row.average_price >= row.ask_at_execution
        );
      }
      if (this.filter["sweep"]) {
        returnFlow = returnFlow.filter((row) => row.type == "sweep");
      }
      if (this.filter["less30"]) {
        returnFlow = returnFlow.filter((row) => row.average_price <= 0.3);
      }
      if (this.filter["block"]) {
        returnFlow = returnFlow.filter((row) => row.type == "block");
      }
      if (this.filter["watchlist"]) {
        var allWatched = [];
        this.watchlist.forEach((watched) => {
          if (watched.symbol.split(" ").length > 0) {
            allWatched.push(watched.symbol.split(" ")[0]);
          } else {
            allWatched.push(watched.symbol);
          }
        });
        returnFlow = returnFlow.filter(
          (row) => allWatched.indexOf(row.symbol) != -1
        );
      }
      if (this.search && this.search.length > 0) {
        returnFlow = returnFlow.filter(
          (row) =>
            row.symbol.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        );
      }
      if (this.filter["premium"]) {
        returnFlow = returnFlow.filter((row) => {
          if (this.rangeFilters["premium"].to == 1000000) {
            return row.total_value >= this.rangeFilters["premium"].from;
          } else {
            return (
              row.total_value >= this.rangeFilters["premium"].from &&
              row.total_value <= this.rangeFilters["premium"].to
            );
          }
        });
      }
      if (this.filter["size"]) {
        returnFlow = returnFlow.filter((row) => {
          if (this.rangeFilters["size"].to == 100000) {
            return row.total_size >= this.rangeFilters["size"].from;
          } else {
            return (
              row.total_size >= this.rangeFilters["size"].from &&
              row.total_size <= this.rangeFilters["size"].to
            );
          }
        });
      }
      if (this.filter["daysToExpire"]) {
        returnFlow = returnFlow.filter((row) => {
          const todayDate = new Date();
          const rowDate = new Date(
            row.contract.substring(8, 10) +
              "/" +
              row.contract.substring(10, 12) +
              "/" +
              row.contract.substring(6, 8)
          );
          const timeDiff = rowDate.getTime() - todayDate.getTime();
          const daysLeft = Math.round(timeDiff / (1000 * 3600 * 24));

          if (this.rangeFilters["daysToExpire"].to == 180) {
            return daysLeft >= this.rangeFilters["daysToExpire"].from;
          } else {
            return (
              daysLeft >= this.rangeFilters["daysToExpire"].from &&
              daysLeft <= this.rangeFilters["daysToExpire"].to
            );
          }
        });
      }

      if (this.filter["bullish"]) {
        returnFlow = returnFlow.filter((row) => row.sentiment == "bullish");
      }
      if (this.filter["bearish"]) {
        returnFlow = returnFlow.filter((row) => row.sentiment == "bearish");
      }
      if (this.filter["neutral"]) {
        returnFlow = returnFlow.filter((row) => row.sentiment == "neutral");
      }

      return this.sort.state == "asc" || this.sort.state == "desc"
        ? this.sortFilteredData(returnFlow)
        : returnFlow;
    },
  },
  methods: {
    updateFilters(newFilters) {
      this.filter = newFilters;
    },
    updateSearch(newSearch) {
      this.pageUpdated = true;
      this.search = newSearch;
      if (this.tab == "HISTORIC_FLOW") {
        this.loadPageAndResetPage();
      }
    },
    updateRangeFilter(newRange) {
      this.rangeFilters[newRange.filterName] = newRange;
    },
    updateSort(newSort) {
      this.sort = newSort;
    },
    nextDay(d, dow) {
      d.setDate(d.getDate() + ((dow + (7 - d.getDay())) % 7));
      return d;
    },
    loginFlow() {
      this.$emit("loginFlow");
    },
    setTab(tabName) {
      this.tab = tabName;
      if (this.tab == "HISTORIC_FLOW") {
        this.loadPageAndResetPage();
      } else {
        this.$emit("historicFlow");
      }
    },
    loadPageAndResetPage() {
      if (this.startDate && this.endDate) {
        this.$emit(
          "historicFlow",
          this.formatDate(this.startDate),
          this.formatDate(this.endDate, "END_DATE"),
          null,
          this.search
        );
      }
      this.reset = Math.floor(Math.random() * 1001);
    },
    loadPage(pageId) {
      this.$emit(
        "historicFlow",
        this.formatDate(this.startDate),
        this.formatDate(this.endDate, "END_DATE"),
        pageId,
        this.search
      );
    },
    formatDate(date, type) {
      if (type == "END_DATE") {
        console.log("ties");
        const newDate = new Date(date);
        newDate.setDate(date.getDate()+1);
        date = newDate;
      }
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      const dateArr = new Date(date)
        .toLocaleDateString("en-US", options)
        .split("/");
      // api expects date in YYYY-MM-DD
      return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
    },
    sortFilteredData(data) {
      const sortMap = {
        time: "time",
        exp: "exp",
        strike: "strike",
        size: "total_size",
        price: "average_price",
        totalValue: "total_value",
      };

      if (
        this.sort.column == "time" ||
        this.sort.column == "exp" ||
        this.sort.column == "strike"
      ) {
        if (this.sort.column == "time") {
          data.forEach((row) => {
            row["time"] = row.timestamp;
          });
        }
        if (this.sort.column == "exp") {
          data.forEach((row) => {
            row["exp"] = new Date(
              row.contract.substring(8, 10) +
                "/" +
                row.contract.substring(10, 12) +
                "/" +
                row.contract.substring(6, 8)
            );
          });
        }
        if (this.sort.column == "strike") {
          data.forEach((row) => {
            row["strike"] = parseFloat(row.contract.substring(13, 21)) / 1000;
          });
        }
      }
      return orderBy(data, sortMap[this.sort.column], this.sort.state);
    },
  },
  mounted() {
    if (localStorage.flowSort != null && JSON.parse(localStorage.flowSort)) {
      this.sort = JSON.parse(localStorage.flowSort);
    }
    this.$gtag.pageview({
      page_title: `Flow`,
      page_location: location.href,
      page_path: this.$route.path,
    });
  },
};
</script>

<style scoped>
.options-flow {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  max-width: 1440px;
  margin: auto;
  padding-bottom: 129px;
}

.delayed-flow p {
  margin-bottom: 40px;
  margin-top: 0;
}

.tab-container {
  display: flex;
  margin-top: 20px;
}

.search-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dates {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-end;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #151c26;
  margin-right: 10px;
}

.start-date-container,
.end-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.start-date-loader,
.end-date-loader {
  width: 25px;
  height: 25px;
  margin: 0px 10px;
}

.spinner-icon {
  width: 25px;
  height: 25px;
}

.start-date,
.end-date {
  width: 150px;
}

.to-text {
  align-self: center;
  margin-right: 40px;
}

.dark .to-text {
  color: #ffffff;
}

.search {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.flow-filters {
  margin-top: 24px;
  margin-bottom: 24px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.loading img {
  width: 100px;
  height: 100px;
}

.end-of-page,
.tab-switch-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.btn-green {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0baa5e;
  background: none;
  border: none;
}

.btn-grey {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #6a6c6c;
}

@media only screen and (max-width: 860px) {
  .options-flow {
    padding: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .search-container {
    justify-content: center;
  }
  .dates {
    flex-direction: column;
  }
  .search {
    margin-top: 20px;
  }
}
</style>
