<template>
  <div class="cv">
    <p v-if="errors.global" class="error red">{{ errors.global }}</p>
    <div class="controls">
      <div class="controls-links">
        <HeadLink
          :active="tab == 'active'"
          title="Most Active"
          @on-click="setTab('active')"
        />
        <HeadLink
          :active="tab == 'watch'"
          title="Watchlist"
          @on-click="setTab('watch')"
        />
        <HeadLink
          :active="tab == 'ipo'"
          :disabled="true"
          title="IPO's"
          @on-click="setTab('ipo')"
        />
      </div>
      <div class="controls-split">
        <SearchBox :watchlist="watchlist" @open-ticker="openTicker" />
      </div>
    </div>
    <TopCubes @open-ticker="openTicker" v-if="tab == 'active' && mostActive" :tab="tab" :cubes="mostActive"
      :dark="dark" />
    <TopCubes @open-ticker="openTicker" v-if="tab == 'watch'" :tab="tab" :cubes="watchlist" :dark="dark" />
    <div class="cv-main" :style="{ backgroundColor: dark ? '#000000' : '#F7F9FB' }">
      <div class="account-house" :style="[{ backgroundColor: dark ? '#1F2324' : '#FFFFFF' },{border: dark ? '#FFFFFF0D 1px solid' : '1px solid #F0F3F5'},{boxShadow: dark?'0px 2px 4px 0px #00000014' :'0px 2px 4px 0px #00000014'}]">
        <div class="account-info">
          <div class="account-detail">
             <HeadLink v-for="availableAccount in accountsList" :active="availableAccount.key == account.AccountID"
          :key="availableAccount.key" :title="availableAccount.type" @on-click="changeAccount(availableAccount)" />
        <HeadLink v-if="!accountsList.find((obj) => obj.type == 'Crypto') && !sim" title="Crypto"
          @on-click="noCryptoMenu = true" />
          <AccountGraph :account="account" />
          </div>
          <div class="stats" :style="{ borderLeft: dark ? '#333333 1px solid' : '1px solid #EEF0F3' }">
            <p class="account-title">Account Details</p>
            <div class="stat-house">
              <KeyValue
                first
                key-string="Account Balance"
                :value="
                  '$' +
                  dollarWithCommas(
                    parseFloat(account.CashBalance) +
                      parseFloat(account.BalanceDetail.UnsettledFunds)
                  )
                "
              />
              <KeyValue
                key-string="Uncleared Deposits"
                :value="'$' + dollarWithCommas(account.UnclearedDeposit)"
              />
              <KeyValue
                key-string="Buying Power"
                :value="'$' + dollarWithCommas(account.BuyingPower)"
              />
              <KeyValue
                v-if="
                  account.AccountType == 'Margin' ||
                  account.AccountType == 'Cash'
                "
                key-string="Cost of Positions"
                :value="
                  '$' + dollarWithCommas(account.BalanceDetail.CostOfPositions)
                "
              />
              <KeyValue
                v-if="account.AccountType == 'Futures'"
                key-string="Day Trade Margin"
                :value="
                  '$' + dollarWithCommas(account.BalanceDetail.DayTradeMargin)
                "
              />
              <KeyValue
                v-if="
                  account.AccountType == 'Margin' ||
                  account.AccountType == 'Cash'
                "
                key-string="Liquid Value of Options"
                :value="
                  '$' +
                  dollarWithCommas(account.BalanceDetail.OptionsMarketValue)
                "
              />
              <KeyValue key-string="Status" :value="account.Status" />
              <KeyValue
                v-if="account.AccountType == 'Margin'"
                last
                key-string="Day Trades (Last 4 Days)"
                :value="String(account.BalanceDetail.DayTrades)"
              />
              <KeyValue
                v-if="account.BalanceDetail.UnsettledFunds > 0"
                last
                key-string="Unsettled Funds"
                :value="
                  '$' + dollarWithCommas(account.BalanceDetail.UnsettledFunds)
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="news-house" :style="[{ backgroundColor: dark ? '#1F2324' : '#FFFFFF' },{border: dark ? '#FFFFFF0D 1px solid' : '1px solid #F0F3F5'},{boxShadow: dark?'0px 2px 4px 0px #00000014' :'0px 2px 4px 0px #00000014'}]">
        <HeadLink :active="false" title="Top News" :nohover="true" />
        <perfect-scrollbar class="news-list">
          <NewsArticle v-for="(newsItem, index) in news" :key="'news' + index" :url="newsItem.article_url"
            :date="getTimeDifference(new Date(newsItem.published_utc))" :author="newsItem.publisher.name" :source="newsItem.source" :title="newsItem.title"
            :image="newsItem.image_url" :tickers="newsItem.tickers" :showTickers="true" :dark="dark" />
        </perfect-scrollbar>
        <div class="fade" />
      </div>
      <!-- <HeadLink :active="false" title="Crypto" @on-click=""/> -->
    </div>
    <div class="bottom-row">
      <div class="bottom-left">
        <HeadLink :active="false" title="Positions" :nohover="true" />
        <div class="positions-house">
          <Positions
            :byMark="byMark"
            :positions="positions"
            @openPosition="openPosition"
            @rollOptions="rollTickerOptions"
            @sellOptions="sellTickerOptions"
            @sell="sellTicker"
            :dark="dark"
            v-if="positions.length > 0"
            :globalPositionsQuotesPrice="globalPositionsQuotesPrice"
          />
          <p class="no-positions" v-if="positions.length == 0">
            No open positions
          </p>
        </div>
      </div>
      <div
        class="bottom-right"
        :style="{ borderLeft: '1px solid ' + (dark ? '#FFFFFF0D' : '#F0F3F5') }"
      >
        <span class="orders-house">
          <HeadLink
            :active="!viewRecent"
            title="Open Orders"
            @on-click="switchOrderViewRecent(false)"
          />
          <HeadLink
            :active="viewRecent"
            title="Recent"
            @on-click="switchOrderViewRecent(true)"
          />
        </span>
        <div class="orders-house">
          <OrdersTable
            :sound="sound"
            :orders="orders"
            :orderHistory="orderHistory"
            @openOrder="openOrder"
            :dark="dark"
            :recent="viewRecent"
          />
        </div>
      </div>
    </div>
    <TsFooter />
    <Modal v-if="noCryptoMenu" @close="noCryptoMenu = false">
      <NoCrypto />
    </Modal>
    <Modal v-if="selectedPosition" @close="selectedPosition = null">
      <PositionCard
        :byMark="byMark"
        :position="selectedPosition"
        @chart="openTicker"
        @buy="buyTicker"
        @share="sharePosition"
        @shareMark="sharePositionMark"
        @buyOptions="buyTickerOptions"
        @sell="sellTicker"
        @sellOptions="sellTickerOptions"
        @update-cv="updateAccount"
      />
    </Modal>
    <Modal v-if="selectedOrder" @close="selectedOrder = null">
      <OrderCard
        :sound="sound"
        :order="selectedOrder"
        :profits="profits"
        @close="selectedOrder = null"
        @update-cv="updateAccount"
        @share="shareOrder"
        @reorder="reorder"
      />
    </Modal>
    <Modal v-if="orderToShare || positionToShare" @close="closeShare()">
      <ShareProfits
        :order="orderToShare"
        :position="positionToShare"
        @close="closeShare()"
      />
    </Modal>
    <Modal v-if="buy" @close="closeBuyWindow">
      <BuyPosition
        :startShort="buyShort"
        :sound="sound"
        :account="account"
        :ticker="buy"
        :position="positions.find((elem) => elem.Symbol == sell)"
        :orders="orders"
        :orderExecRoutes="orderExecRoutes"
        @close="closeBuyWindow"
        @chart="openTicker"
        @home="closeBuyWindow"
        @update-cv="updateAccount"
      />
    </Modal>
    <Modal v-if="buyOptions" @close="closeOptionsBuyWindow">
      <BuyOptions
        :sound="sound"
        :account="account"
        :tickers="buyOptions"
        :orderExecRoutes="orderExecRoutes"
        :position="
          positions.find(
            (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
          )
        "
        :orders="orders"
        @close="closeOptionsBuyWindow"
        @home="closeOptionsBuyWindow"
        @update-cv="updateAccount"
      />
    </Modal>
    <Modal v-if="sellOptions && !rollOptions" @close="sellOptions = null">
      <BuyOptions
        :isClosing="true"
        :sound="sound"
        :account="account"
        :tickers="sellOptions"
        :orderExecRoutes="orderExecRoutes"
        :orders="orders"
        :availableLegs="
          positions.filter(
            (position) =>
              position.Symbol.indexOf(' ') != -1 &&
              position.Symbol.split(' ')[0] ==
                sellOptions[0].Symbol.split(' ')[0]
          )
        "
        @close="sellOptions = null"
        @chart="openTicker"
        @home="sellOptions = null"
        @update-cv="updateAccount"
        :position="
          positions.find(
            (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
          )
        "
        @new-log="newLog"
      />
    </Modal>
    <Modal v-if="sell" @close="sell = null">
      <BuyPosition
        :isClosing="true"
        :sound="sound"
        :account="account"
        :ticker="sell"
        :existingQuantity="sellQuantity"
        :position="positions.find((elem) => elem.Symbol == sell)"
        :orders="orders"
        :averageCost="sellAverageCost"
        :orderExecRoutes="orderExecRoutes"
        @close="sell = null"
        @chart="openTicker"
        @home="sell = null"
        @update-cv="updateAccount"
        @new-log="newLog"
      />
    </Modal>
    <Modal v-if="sellOptions && rollOptions" @close="sellOptions = null">
      <RollSelect
        :isClosing="true"
        :sound="sound"
        :account="account"
        :tickers="sellOptions"
        :orderExecRoutes="orderExecRoutes"
        :orders="orders"
        :position="
          positions.find(
            (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
          )
        "
        @update-cv="updateAccount"
        :compactMode="true"
        @rollOptions="rollTickerOptions"
        :availableLegs="
          positions.filter(
            (position) =>
              position.Symbol.indexOf(' ') != -1 &&
              position.Symbol.split(' ')[0] ==
                sellOptions[0].Symbol.split(' ')[0]
          )
        "
        @close="sellOptions = null"
        @chart="openTicker"
        @home="sellOptions = null"
        @new-log="newLog"
      />
    </Modal>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import HeadLink from "../components/HeadLink.vue";
import SearchBox from "../components/SearchBox.vue";
import TopCubes from "../components/TopCubes.vue";
import NewsArticle from "../components/NewsArticle.vue";
import AccountGraph from "../components/AccountGraph.vue";
import KeyValue from "../components/KeyValue.vue";
// import PositionsTable from "../components/PositionsTable.vue";
import OrdersTable from "../components/OrdersTable.vue";
import Modal from "../components/Modal.vue";
import NoCrypto from "../components/NoCrypto.vue";
import PositionCard from "../components/PositionCard.vue";
import OrderCard from "../components/OrderCard.vue";
import ShareProfits from "../components/ShareProfits.vue";
import BuyPosition from "../components/BuyPosition.vue";
import BuyOptions from "../components/BuyOptions.vue";
import TsFooter from "../components/TsFooter.vue";
import Positions from "../components/orderAccordian/Positions.vue";
import RollSelect from "../components/RollSelect.vue";

export default {
  name: "CV",
  inject: {
    http: { from: "http" },
  },
  mixins: [Mixins],
  components: {
    HeadLink,
    SearchBox,
    TopCubes,
    NewsArticle,
    AccountGraph,
    KeyValue,
    Positions,
    OrdersTable,
    Modal,
    NoCrypto,
    PositionCard,
    OrderCard,
    ShareProfits,
    BuyPosition,
    BuyOptions,
    TsFooter,
    RollSelect,
  },
  props: {
    account: Object,
    accountsList: Array,
    profits: Array,
    orders: Array,
    orderHistory: Array,
    positions: Array,
    globalPositionsQuotesPrice: Object,
    watchlist: Array,
    dark: Boolean,
    byMark: Boolean,
    sound: Boolean,
    orderExecRoutes: Array,
  },
  data: function () {
    return {
      tab: "active",
      viewRecent: false,
      mostActive: null,
      news: null,
      selectedPosition: null,
      selectedOrder: null,
      orderToShare: null,
      positionToShare: null,
      buy: null,
      buyShort: false,
      buyOptions: null,
      sell: null,
      noCryptoMenu: false,
      sim: localStorage.sim == "true",
      sellOptions: null,
      rollOptions: null,
      sellQuantity: null,
      sellAverageCost: null,
      refreshTimer: null,
      errors: {},
      descriptionData: [],
    };
  },
  methods: {
    setTab: function (newValue) {
      this.tab = newValue;
      if (newValue == "watch") {
        this.$emit("viewWatchlist", true);
        this.$gtag.event("view_list_active");
      } else {
        this.$emit("viewWatchlist", true);
        this.$gtag.event("view_list_watchlist");
      }
    },
    closeBuyWindow: function () {
      this.buy = null;
      if (this.$route.path.indexOf("/order/") != -1) {
        this.$router.replace("/");
      }
    },
    closeOptionsBuyWindow: function () {
      this.buyOptions = null;
      if (this.$route.path.indexOf("/options/order") != -1) {
        this.$router.replace("/");
      }
    },
    openTicker: function (ticker) {
      this.selectedPosition = null;
      this.selectedOrder = null;
      this.$emit("open-ticker", ticker);
    },
    reorder(order) {
      if (order.Legs[0].AssetType.indexOf("OPTION") != -1) {
        var legList = [];
        var tickerListString = "";
        var isOpening = order.Legs[0].OpenOrClose == "Open";
        order.Legs.forEach((leg) => {
          legList.push({
            Symbol: leg.Symbol,
            Buy:
              (leg.BuyOrSell == "Buy" && isOpening) ||
              (leg.BuyOrSell == "Sell" && !isOpening),
            Bid: "",
            Ask: "",
            AssetType: leg.AssetType,
          });
          tickerListString += `${leg.Symbol}, `;
        });
        this.$gtag.event("order_again", {
          ticker: tickerListString,
        });
        this.selectedOrder = null;
        this.buyOptions = legList;
      } else {
        this.$gtag.event("order_again", {
          ticker: order.Legs[0].Symbol,
        });
        if (
          order.Legs[0].BuyOrSell == "SellShort" ||
          order.Legs[0].BuyOrSell == "BuyToCover"
        ) {
          this.buyShort = true;
        } else {
          this.buyShort = false;
        }
        this.selectedOrder = null;
        this.buy = order.Legs[0].Symbol;
      }
    },
    buyTicker: function (ticker) {
      this.$gtag.event("position_add", {
        ticker: this.selectedPosition["Symbol"],
      });
      if (
        this.selectedPosition != null &&
        this.selectedPosition.LongShort == "Short"
      ) {
        this.buyShort = true;
      } else {
        this.buyShort = false;
      }
      this.selectedPosition = null;
      this.buy = ticker;
    },
    buyTickerOptions: function (orderArray) {
      this.$gtag.event("position_add", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      this.buyOptions = orderArray;
    },
    sellTicker: function (ticker) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.sellQuantity = this.selectedPosition.Quantity;
      this.sellAverageCost = this.selectedPosition.AveragePrice;
      if (this.selectedPosition.LongShort == "Short") {
        this.sellAverageCost = `-${this.sellAverageCost}`;
      }
      this.selectedPosition = null;
      this.sell = ticker;
    },
    sellTickerOptions: function (orderArray) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      orderArray.forEach((order) => {
        if(order.Quantity.indexOf("-") != -1){
          order.Buy = true;
        } else {
          order.Buy = false;
        }
        order.Quantity = order.Quantity.replaceAll("-", "");
      });
      this.sellOptions = orderArray;
      this.rollOptions = null;
    },
    rollTickerOptions: function (orderArray) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      this.sellOptions = orderArray;
      this.rollOptions = orderArray;
    },
    openPosition: function (position) {
      this.selectedPosition = position;
      this.$gtag.event("position_view", { ticker: position["Symbol"] });
    },
    openOrder: function (order) {
      this.selectedOrder = order;
      var orderLegs = "";
      order["Legs"].forEach((leg) => {
        orderLegs = orderLegs + leg["Symbol"] + ",";
      });
      this.$gtag.event("order_view", {
        legs: orderLegs,
      });
    },
    switchOrderViewRecent(viewTrue) {
      this.viewRecent = viewTrue;
      if (viewTrue) {
        this.$gtag.event("orders_view_recent");
      } else {
        this.$gtag.event("orders_view_open");
      }
    },
    getTimeDifference(publishedDate) {
      const publishedUTC = new Date(publishedDate);
      const currentDate = new Date();
      const diff = currentDate - publishedUTC;
      const diffMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
      const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(diff / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      let formattedDiff = '';
      if (diffMonths > 0) {
        formattedDiff += `${diffMonths}M `;
      }
      if (diffMonths === 0 && diffDays > 0) {
        formattedDiff += `${diffDays}D `;
      }
      if (diffMonths === 0 && diffDays === 0 && diffHours > 0) {
        formattedDiff += `${diffHours}H `;
      }
      if (diffMonths === 0 && diffDays === 0 && diffHours == 0 && (diffMinutes > 0 || diffHours === 0)) {
        if (diffMinutes > 0) {
          formattedDiff += `${diffMinutes.toString().padStart(2, '0')}m`;
        } else {
          formattedDiff += `<1m`;
        }
      }
      return formattedDiff.trim();
    },
    updateData() {
      var _this = this;
      var mostActiveUrl =
        process.env.VUE_APP_MOST_ACTIVE_URL +
        "stocks/most-active?size=10"
      this.http
        .get(mostActiveUrl)
        .then(async function (res) {
          const symbolList = res.data.data.map((elem) => elem.ticker);
          localStorage.setItem("mostActive", JSON.stringify(symbolList));
          await _this.fetchSymbolDescription(symbolList);
          const descriptionsMap = _this.descriptionData.reduce((acc, obj) => {
            const [ticker, description] = Object.entries(obj)[0];
            acc[ticker] = description;
            return acc;
          }, {});
          
          const updatedTickerDetails = res.data.data.map(detail => {
            return {
              ...detail,
              description: descriptionsMap[detail.ticker] || ""
              };
            });
          updatedTickerDetails.forEach((ticker) => {
            ticker.changePercent = ticker.changePercent / 100;
            if(isNaN(ticker.changePercent)){
              ticker.changePercent = 0;
            }
          });
          _this.mostActive = updatedTickerDetails;
        })
        .catch(function () {
          // TODO: most active error
        });
      var newsUrl =
       `${process.env.VUE_APP_POLYGON_URL}reference/news?limit=10&apiKey=${process.env.VUE_APP_POLYGON_API_KEY}`
      this.http
        .get(newsUrl)
        .then(function (res) {
          _this.news = res.data.results;
          // _this.mostActive = res.data;
        })
        .catch(function () {
          // TODO: most active error
        });
      window.clearTimeout(_this.refreshTimer);
      _this.refreshTimer = window.setTimeout(function () {
        if (_this.mounted) {
          _this.updateData();
        }
      }, 300000);
    },
    async fetchSymbolDescription(symbols) {
      let descriptionList = []
      try {
        const response = await this.http.get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
          `marketdata/symbols/${symbols.join(",")}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        );

        descriptionList = response.data.Symbols.map(symbolInfo => ({
          [symbolInfo.Symbol]: symbolInfo.Description
        }));
        this.descriptionData = descriptionList;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    changeAccount(account) {
      this.$emit("changeAccount", account);
    },
    // TODO: change accounts
    updateAccount() {
      this.$emit("update-cv");
    },
    newLog(x) {
      this.$emit("new-log", x);
      this.sell = null;
      this.sellOptions = null;
      this.rollOptions = null;
    },
    isFilled(x) {
      if (x.StatusDescription == "Filled") {
        return true;
      } else return false;
    },
    shareOrder() {
      this.orderToShare = this.selectedOrder;
      this.selectedOrder = null;
      var orderLegs = "";
      this.orderToShare["Legs"].forEach((leg) => {
        orderLegs = orderLegs + leg["Symbol"] + ",";
      });
      this.$gtag.event("share_closed", {
        profit_percent: this.profits.find(
          (obj) => obj.OrderID == this.orderToShare.OrderID
        ).ProfitLossPercent,
        legs: orderLegs,
      });
    },
    sharePosition() {
      this.positionToShare = this.selectedPosition;
      this.selectedPosition = null;
      this.$gtag.event("share_position", {
        by_mark: false,
        profit_percent: this.positionToShare["UnrealizedProfitLossPercent"],
        ticker: this.positionToShare["Symbol"],
      });
    },
    sharePositionMark() {
      var positionCopy = JSON.parse(JSON.stringify(this.selectedPosition));
      if (positionCopy.AssetType.indexOf("OPTION") != -1) {
        positionCopy.UnrealizedProfitLoss =
          this.markProfitLossAll(positionCopy);
        positionCopy.UnrealizedProfitLossPercent =
          this.markProfitLossPercent(positionCopy);
      }
      this.positionToShare = positionCopy;
      this.selectedPosition = null;
      this.$gtag.event("share_position", {
        by_mark: true,
        profit_percent: this.positionToShare["UnrealizedProfitLossPercent"],
        ticker: this.positionToShare["Symbol"],
      });
    },
    closeShare() {
      this.positionToShare = null;
      this.orderToShare = null;
    },
  },
  mounted() {
    this.$gtag.pageview({
      page_title: "Home",
      page_location: location.href,
      page_path: this.$route.path,
    });
    if (this.$route.path.indexOf("/options/close") != -1) {
      var closeOptionsQuery = this.$route.query.o.split(",");
      var closeOptions = [];
      for (var a = 0; a < closeOptionsQuery.length; a++) {
        closeOptions.push({
          Symbol: closeOptionsQuery[a].split("!")[1].replaceAll("_", " "),
          Buy: closeOptionsQuery[a].split("!")[0] == "B",
          Bid: "0",
          Ask: "0",
          Quantity: closeOptionsQuery[a].split("!")[2]
            ? closeOptionsQuery[a].split("!")[2]
            : "1",
          AssetType: "OP",
        });
      }
      this.sellOptions = closeOptions;
    } else if (this.$route.path.indexOf("/options/order") != -1) {
      var orderOptionsQuery = this.$route.query.o.split(",");
      var orderOptions = [];
      for (var i = 0; i < orderOptionsQuery.length; i++) {
        orderOptions.push({
          Symbol: orderOptionsQuery[i].split("!")[1].replaceAll("_", " "),
          Buy: orderOptionsQuery[i].split("!")[0] == "B",
          Bid: "0",
          Ask: "0",
          AssetType: "OP",
        });
      }
      this.buyOptions = orderOptions;
    } else if (this.$route.path.indexOf("/order/") != -1) {
      this.buy = this.$route.params.ticker;
    } else if (this.$route.path.indexOf("/close/") != -1) {
      if (this.$route.query.sh == "true") {
        this.sellQuantity = "-1";
      } else {
        this.sellQuantity = "1";
      }
      this.sell = this.$route.params.ticker;
    }
    this.updateData();
  },
  watch: {
    positions: function (newPositions) {
      if (this.selectedPosition != null) {
        this.selectedPosition = newPositions.filter(
          (position) => position.PositionID == this.selectedPosition.PositionID
        )[0];
      }
    },
    orders: function (newOrders) {
      if (
        this.selectedOrder != null &&
        newOrders.filter((order) => order.OrderID == this.selectedOrder.OrderID)
          .length > 0
      ) {
        this.selectedOrder = newOrders.filter(
          (order) => order.OrderID == this.selectedOrder.OrderID
        )[0];
      }
    },
  },
};
</script>

<style scoped>
.cv {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  max-width: 1440px;
  margin: auto;
  padding-bottom: 129px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cv-main {
  margin-top: 29px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 33px;
}

.dark .cv-main {
  border: 1px solid #ffffff0d;
  background: #1f2324;
  box-shadow: 0px 2px 4px 0px #00000014;
}

/* .cv-main>* {
  flex: 1;
} */

.account-house {
  text-align: left;
  /* padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px; */
  box-sizing: border-box;
  /* background: #ffffff; */
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #00000014;
  border: 1px solid #F0F3F5;
  overflow: hidden;
  width: 78%;
}

.account-house .account-info {
  /* margin-top: 50px; */
  display: flex;
}

.account-detail{
  padding: 30px;
}

.account-house .account-info>* {
  flex: 1;
}

.account-house .graph {
  padding-top: 48px;
  max-width: 380px;
  margin-right: 0px;
}

.account-house .stats {
  padding-left: 30px;
  padding-top: 30px;
  border-left: 1px solid #EEF0F3;
}

.stat-house .key-value{
  padding-right: 30px;
}

.account-title{
  padding-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
}

.news-house {
  position: relative;
  /* max-width: 370px; */
  /* margin-left: 70px; */
  text-align: left;
  padding-top: 24px;
  padding-left: 24px;
  /* background: #ffffff; */
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #00000014;
  border: 1px solid #F0F3F5;
  overflow: hidden;
  width: 35%;
    /* flex:0500; */
}

.news-house .news-list {
  margin-top: 10px;
  overflow-y: scroll;
  max-height: 550px;
}
.news-article:last-child{
  border-bottom: none !important;
}

.news-house .news-list .news-article {
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #F0F3F5; */
  column-gap: 20px;
  justify-content: space-between;
}

.news-house .fade {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 60px;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
}

.dark .news-house .fade {
  color: rgb(31, 35, 36);
  background: linear-gradient(
    rgba(31, 35, 36, 0),
    rgba(31, 35, 36, 0.8),
    rgba(31, 35, 36, 1)
  );
}
.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #00000014;
  border: 1px solid #f0f3f5;
  padding: 24px;
}

.dark .bottom-row {
  border: 1px solid #ffffff0d;
  background: #1f2324;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.bottom-row .bottom-left {
  text-align: left;
  width: 59%;
}

.bottom-row .bottom-right {
  width: 41%;
  text-align: right;
  /* border-left: 1px solid #F0F3F5; */
}

.bottom-row .bottom-right .head-link:last-of-type {
  margin-right: 0;
}

.orders-house {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-end; */
  margin-left: 15px;
}

.orders-house .orders-table {
  
  padding-left: 10px;
}

.positions-house .positions-table,
.orders-house .orders-table {
  margin-top: 20px;
}

@media only screen and (max-width: 1280px) {
  .cv-main{
    gap:30px;
  }

  .account-house {
    width: 60%;
  }
  .account-house .account-info {
    flex-direction: column;
  }

  .account-house .graph {
    max-width: none;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .news-house .news-list {
    max-height: 830px;
  }

  .account-house .stats {
    margin-left: 0;
  }

  .news-house {
    max-width: none;
  }

  .bottom-row {
    flex-direction: column;
  }

  .bottom-row .bottom-left {
    width: 100%;
  }

  .bottom-row .bottom-right {
    margin-top: 20px;
    text-align: left;
    width: 100%;
    border: none;
  }

  .orders-house {
    justify-content: flex-start;
    margin: 0px;
  }

  .orders-house .orders-table {
    border-left: none;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1150px) {
  .account-house{
    width: 55%;
  }
  .news-house{
    width: 40%;
  }
}

@media only screen and (max-width: 1024px) {
  .news-house .news-list {
    min-height: 830px;
  }
}

@media only screen and (max-width: 768px) {
  .cv {
    padding: 20px;
  }

  .controls {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .controls-links {
    margin-top: 30px;
    align-self: flex-start;
  }

  .positions-house,
  .orders-house {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 725px) {
  .cv-main {
    flex-direction: column;
    gap: 15px;
  }

  .account-house{
    width: 100%;
  }

  .news-house {
    margin-left: 0;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 24px;
    height: 410px;
    width: 96%;
  }

  .news-house .news-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 360px;
  }

  .news-house .news-list .news-article {
    width: 100%;
  }

  .controls-split {
    width: 100%;
  }
}
</style>
