<template>
    <div id="tv_chart_container" ref="tvChartContainer"></div>
</template>

<script>
import { widget } from '../../../public/charting_library/';
import * as Datafeeds from '../../../public/datafeeds/udf/lib/udf-compatible-datafeed';
import * as Brokers from '../../../public/broker/lib/broker';

const { structuredClone } = require('structured-clone');
const storageKeys = {
    charts: "tv_charts",
    // Add other storage keys as needed
};

const EventEmitter = require("events");

let watchListAPI;
let eventEmitterLocal;

function getFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
}

function convertData(originData) {
    const sourceDataList = originData.map((item) => {
        return {
            title: item.title,
            published: new Date(item.published_utc).getTime(), // Convert UTC date to milliseconds
            source: item.publisher.name,
            shortDescription: item.description,
            link: item.article_url,
            fullDescription: item.description // You can update this with the full description if needed
        };
    });

    return sourceDataList;
}

function extractSymbols(data) {
    return data.map(item => item.symbol);
}

function saveToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

function generateRandomId() {
    return 'tv_' + Math.random().toString(36).substr(2, 9);
}

class ChartSaveLoadAdapter {
    constructor() {
        this.charts = getFromLocalStorage('charts') || [];
        this.studyTemplates = [];
        this.drawingTemplates = [];
        this.chartTemplates = [];
        this.drawings = {};
    }

    getAllCharts() {
        return Promise.resolve(getFromLocalStorage(storageKeys.charts) || []);
    }

    removeChart(id) {
        let charts = getFromLocalStorage(storageKeys.charts) || [];
        charts = charts.filter(chart => chart.id !== id);
        saveToLocalStorage(storageKeys.charts, charts);
        return Promise.resolve();
    }

    saveChart(chartData) {
        const charts = getFromLocalStorage(storageKeys.charts) || [];
        if (!chartData.id) {
            chartData.id = generateRandomId();
        }
        charts.push(chartData);
        saveToLocalStorage(storageKeys.charts, charts);
        return Promise.resolve(chartData.id);
    }

    getChartContent(id) {
        const charts = getFromLocalStorage(storageKeys.charts) || [];
        const chart = charts.find(chart => chart.id === id);
        return chart ? Promise.resolve(chart.content) : Promise.reject();
    }

    removeStudyTemplate(studyTemplateData) {
        for (var i = 0; i < this.studyTemplates.length; ++i) {
            if (this.studyTemplates[i].name === studyTemplateData.name) {
                this.studyTemplates.splice(i, 1);
                return Promise.resolve();
            }
        }

        return Promise.reject();
    }

    getStudyTemplateContent(studyTemplateData) {
        for (var i = 0; i < this.studyTemplates.length; ++i) {
            if (this.studyTemplates[i].name === studyTemplateData.name) {
                return Promise.resolve(this.studyTemplates[i].content);
            }
        }

        console.error("st: error");

        return Promise.reject();
    }

    saveStudyTemplate(studyTemplateData) {
        for (var i = 0; i < this.studyTemplates.length; ++i) {
            if (this.studyTemplates[i].name === studyTemplateData.name) {
                this.studyTemplates.splice(i, 1);
                break;
            }
        }

        this.studyTemplates.push(studyTemplateData);
        return Promise.resolve();
    }

    getAllStudyTemplates() {
        return Promise.resolve(this.studyTemplates);
    }

    removeDrawingTemplate(toolName, templateName) {
        for (var i = 0; i < this.drawingTemplates.length; ++i) {
            if (this.drawingTemplates[i].name === templateName) {
                this.drawingTemplates.splice(i, 1);
                return Promise.resolve();
            }
        }

        return Promise.reject();
    }

    loadDrawingTemplate(toolName, templateName) {
        for (var i = 0; i < this.drawingTemplates.length; ++i) {
            if (this.drawingTemplates[i].name === templateName) {
                return Promise.resolve(this.drawingTemplates[i].content);
            }
        }

        console.error("drawing: error");

        return Promise.reject();
    }

    saveDrawingTemplate(toolName, templateName, content) {
        for (var i = 0; i < this.drawingTemplates.length; ++i) {
            if (this.drawingTemplates[i].name === templateName) {
                this.drawingTemplates.splice(i, 1);
                break;
            }
        }

        this.drawingTemplates.push({ name: templateName, content: content });
        return Promise.resolve();
    }

    getDrawingTemplates() {
        return Promise.resolve(
            this.drawingTemplates.map(function (template) {
                return template.name;
            })
        );
    }

    async getAllChartTemplates() {
        return this.chartTemplates.map((x) => x.name);
    }

    async saveChartTemplate(templateName, content) {
        const theme = this.chartTemplates.find((x) => x.name === templateName);

        if (theme) {
            theme.content = content;
        } else {
            this.chartTemplates.push({ name: templateName, content });
        }
    }

    async getChartTemplateContent(templateName) {
        const theme = {};

        const content = this.chartTemplates.find((x) => x.name === templateName)
            ?.content;

        if (content) {
            theme.content = structuredClone(content);
        }

        return theme;
    }

    // saveLineToolsAndGroups is only required if the `saveload_separate_drawings_storage` featureset is enabled
    saveLineToolsAndGroups(layoutId, chartId, state) {
        const drawings = state.sources;

        if (!this.drawings[this._getDrawingKey(layoutId, chartId)]) {
            this.drawings[this._getDrawingKey(layoutId, chartId)] = {};
        }

        for (let [key, state] of drawings) {
            if (state === null) {
                delete this.drawings[this._getDrawingKey(layoutId, chartId)][key];
            } else {
                this.drawings[this._getDrawingKey(layoutId, chartId)][key] = state;
            }
        }
        return Promise.resolve();
    }

    // loadLineToolsAndGroups is only required if the `saveload_separate_drawings_storage` featureset is enabled
    loadLineToolsAndGroups(layoutId, chartId, requestType, requestContext) {
        console.log("requestType", requestType, requestContext);
        const rawSources = this.drawings[
            this._getDrawingKey(layoutId, chartId)
        ];
        if (!rawSources) return null;
        const sources = new Map();

        for (let [key, state] of Object.entries(rawSources)) {
            sources.set(key, state);
        }
        return Promise.resolve({
            sources
        });
    }

    _getDrawingKey(layoutId, chartId) {
        return `${layoutId}/${chartId}`;
    }

}


export default {
    name: 'TradingViewChart',
    inject: {
        http: { from: "http" },
        // stream: { from: "stream" },
    },
    props: {
        chartUrl: String,
        dark: Boolean,
        watchlistData: Array,
        positionsData: Array,
        ordersData: Array,
        account: Object,
        eventEmitter: EventEmitter,
  },
  data: function () {
    return {
      tvWidget: null,
      positionList: [],
      orderList: [],
      symbolist: [],
      newsList: [],
      chartSaveLoadAdapter: new ChartSaveLoadAdapter(),
      count: 0,
    };
  },
  mounted: async function () {
      eventEmitterLocal = this.eventEmitter;
    this.symbolist = extractSymbols(this.watchlistData ?? []);

    await Promise.allSettled([
      this.getPositions(),
      this.getChartData(),
      this.getOrders(),
    ]);
    this.initChart();
  },
  beforeUnmount: () => {
    if (watchListAPI) {
      watchListAPI.onListChanged().unsubscribe();
    }
  },
  watch: {
    dark: {
      async handler() {
        console.log("dark mode");
        await this.tvWidget.changeTheme(this.dark ? "dark" : "light");
        this.changeFullScreenButtonStyle();
      },
    },
    async watchlistData(newData) {
      this.symbolist = extractSymbols(newData ?? []);

            if (this.tvWidget) {
                const watchlists = (await this.tvWidget.watchList()).getAllLists();
                const nonEmptyWatchlistIds = Object.values(watchlists)
                    .filter(watchlist => watchlist.symbols.length > 0)
                    .map(watchlist => watchlist.id);

                if (nonEmptyWatchlistIds.length > 0) {
                    (await this.tvWidget.watchList()).updateList(nonEmptyWatchlistIds[0], this.symbolist);
                }
            }
        }
    },
    methods: {
        changeFullScreenButtonStyle() {
            var button = document.querySelector('.fullscreen-button');
            if (this.dark) {
                button.classList.add('dark-button');
            } else {
                button.classList.remove('dark-button');
            }
        },
        filterStocksBySymbol(symbol) {
            try {
                const chartsJson = localStorage.getItem('tv_charts');
                if (!chartsJson) {
                    throw new Error('No stocks data found in local storage.');
                }

                const charts = JSON.parse(chartsJson);

                const filteredStocks = charts.filter(stock => stock.symbol === symbol);

                return filteredStocks;
            } catch (error) {
                console.error('Error filtering stocks by symbol:', error);
                return []; // Return an empty array if an error occurs
            }
        },
        async initChart() {
            let posList, oList = [];

            if (Array.isArray(this.positionList)) {
                posList = this.positionList;
            } else {
                console.log('pos is not an array');
            }
            if (Array.isArray(this.orderList)) {
                oList = this.orderList;
            } else {
                console.log('order is not an array');
            }
            // const datafeedUrl = "https://chart.tradearies.com/chart";
            const datafeedUrl = `${process.env.VUE_APP_BACKEND_URL}/chart`;
            // const datafeedUrl = "https://demo_feed.tradingview.com";
            const datafeedSymbol = this.constructDatafeedUrl();
            const symbol = this.extractSymbolFromUrl(datafeedSymbol);
            const account = this.account;
            // const daysAgo = 7;
            // const timestampFor7DaysAgo = getTimestampForPastDate(daysAgo);
            const datafeed = new Datafeeds.UDFCompatibleDatafeed(datafeedUrl, {
                accessToken: localStorage.getItem('accessToken'),
                _id: localStorage.getItem('session'),
                token: localStorage.getItem('sessionKey'),
            }, {
                maxResponseLength: 1000,
                expectedOrder: 'latestFirst',
            });

      const widgetOptions = {
        symbol: symbol,
        container: this.$refs.tvChartContainer.id,
        datafeed: datafeed,
        library_path: "/charting_library/",
        locale: this.getParameterByName("lang") || "en",
        show_popup_button: true,
        interval: "1D",
        disabled_features: [
          "use_localstorage_for_settings",
          "open_account_manager",
        ],
        enabled_features: [
          "study_templates",
          "dom_widget",
          "supportDOM",
          "pre_post_market_sessions",
          "side_toolbar_in_fullscreen_mode",
          "header_in_fullscreen_mode",
        ],
        charts_storage_api_version: "1.1",
        theme: this.dark ? "dark" : "light",
        order_panel: true,
        charts_storage_url: "https://draw.tradearies.com",
        client_id: "ariesWeb",
        user_id: this.getParameterByName("u"),
        load_last_chart: true,
        save_load_adapter: this.chartSaveLoadAdapter,
        auto_save_delay: 5,
        overrides: {
          "mainSeriesProperties.sessionId": "regular",
        },
        widgetbar: {
          details: true,
          datawindow: true,
          news: true,
          watchlist: true,
          watchlist_settings: {
            default_symbols: this.symbolist,
            readonly: true,
          },
        },
        broker_factory: function (host) {            
          return new Brokers.BrokerSample(
            host,
            datafeed,
            Object.values(account),
            oList,
            posList,
            eventEmitterLocal,
          );
        },
        broker_config: {
          configFlags: {
            supportEditAmount: false,
            supportLeverage: true,
            supportLeverageButton: true,
            supportNativeReversePosition: true,
            supportClosePosition: true,
            supportPLUpdate: true,
            supportLevel2Data: true,
            supportDOM: true,
            showQuantityInsteadOfAmount: true,
            supportOrderBrackets: true,
            supportMarketOrders: true,
            supportMarketBrackets: true,
            supportPositionBrackets: true,
            supportIndividualPositionBrackets: true,
          },
          durations: [
            { name: "GTC", value: "GTC" },
            { name: "DAY", value: "DAY" },
          ],
        },
        news_provider: function getNews(symbol, callback) {
          symbol = symbol.includes(":") ? symbol.split(":")[1] : symbol;
          symbol = symbol.split(" ")[0];
          const newsUrl = `${process.env.VUE_APP_POLYGON_IO_URL}news?ticker=${symbol}&order=desc&limit=19&apiKey=jSwNQDQ687mVCtBJCMYOI8WLHEFXOchE`;
          fetch(newsUrl)
            .then((res) => res.json())
            .then((data) => {
              if (data && data.results && Array.isArray(data.results)) {
                let originData = convertData(data.results);

                                callback({
                                    title: "Top News",
                                    newsItems: originData
                                });
                            } else {
                                throw new Error('Invalid JSON data format - Missing or incorrect result field');
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching news data:', error);
                            callback({
                                newsItems: []
                            });
                        });
                }
            };

      this.tvWidget = new widget(widgetOptions);
      this.tvWidget.onChartReady(async () => {
        watchListAPI = await this.tvWidget.watchList();

        watchListAPI.onListChanged().subscribe(
          null,
          async () => {
            let data = await watchListAPI.getList();

            eventEmitterLocal.emit("chrtWatchLst",JSON.stringify(data));
            
            this.setWatchlist(data);
            // set watchlist here using a new API
          },
          false
        );

                // let savedStateJSON = localStorage.getItem('chartStatesList');
                // let savedState = savedStateJSON ? JSON.parse(savedStateJSON) : [];
                // // let savedStudyJSON = localStorage.getItem('studyList');
                // // let savedStudy = savedStudyJSON ? JSON.parse(savedStudyJSON) : [];
                // let savedResolutionJSON = localStorage.getItem('resolution');
                // let savedResolution = savedResolutionJSON ? savedResolutionJSON : [];
                // let activeRightWidgetBar = localStorage.getItem('widgetBar');
                // this.tvWidget.changeTheme(this.dark ? 'dark' : 'light');

                // if (savedState.length > 0)  {
                //     let symbolItems = savedState.filter(item => item.symbol === symbol);
                //     if (symbolItems.length > 0) {
                //         let lastItem = symbolItems[symbolItems.length - 1];
                //         this.tvWidget.load(lastItem.data);
                //     }
                // }

                // this.tvWidget.widgetbar().then(widgetbarApi => {
                //     widgetbarApi.hidePage('data_window');
                //     widgetbarApi.hidePage('object_tree');
                //     widgetbarApi.hidePage('watchlist_details_news');
                //     if(activeRightWidgetBar) { widgetbarApi.showPage(activeRightWidgetBar) }
                // });

                // savedStudy.forEach(item => {
                //     if(item.name.toLowerCase() != 'volume'){
                //         this.tvWidget.activeChart().createStudy(item.name);
                //         console.log("Created!", item.id);
                //     }
                // })

                // this.tvWidget.activeChart().setResolution(savedResolution);

                // this.tvWidget.subscribe('onAutoSaveNeeded', () => {
                //     if(this.count < 1) {this.count++; return;}
                //     localStorage.setItem('resolution', this.tvWidget.activeChart().resolution());
                //     localStorage.setItem('studyList', JSON.stringify(this.tvWidget.activeChart().getAllStudies()));

                //     this.tvWidget.save((state) => {
                //         let item = {
                //             symbol: symbol,
                //             data: state
                //         }
                //         const index = savedState.findIndex(item => item.symbol == symbol);
                //         if(index > -1)
                //             savedState[index] = item;
                //         else
                //             savedState.push(item);
                //         localStorage.setItem('chartStatesList', JSON.stringify(savedState));
                //     });

                //     this.tvWidget.widgetbar().then(widgetbarApi => {
                //         let name = widgetbarApi.isPageVisible('data_window') ? 'data_window' : '';
                //         if(name != '') {
                //             localStorage.setItem('widgetBar', name);
                //             return;
                //         }
                //         name = widgetbarApi.isPageVisible('object_tree') ? 'object_tree' : '';
                //         if(name != '') {
                //             localStorage.setItem('widgetBar', name);
                //             return;
                //         }
                //         name = widgetbarApi.isPageVisible('watchlist_details_news') ? 'watchlist_details_news' : '';
                //         if(name != '') {
                //             localStorage.setItem('widgetBar', name);
                //             return;
                //         }
                //         localStorage.setItem('widgetBar', '');
                //     })

                //     let userdata = {};
                //     userdata.chartStatesList = localStorage.getItem('chartStatesList');
                //     userdata.studyList = localStorage.getItem('studyList');
                //     userdata.resolution = localStorage.getItem('resolution');
                //     userdata.widgetBar = localStorage.getItem('widgetBar');
                //     const savedRequestUrl = `${process.env.VUE_APP_BACKEND_URL}/chartprocessor?action=/save`;
                //     const userId = localStorage.getItem('u');
                //     const chartData = JSON.stringify(userdata);
                //     const requestData = { userId, chartData };
                //     this.http
                //         .post(savedRequestUrl, requestData)
                //         .then(res => console.log("Saved successfully", res))
                //         .catch(err => console.log(err));
                // });

                // Adjust Fullscreen Button.
                var iframe = document.querySelector('iframe');
                var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

                var fullscreenButton = iframeDocument.querySelector("#header-toolbar-fullscreen");
                var classnames = fullscreenButton.classList.value;
                var parentFullscreen = fullscreenButton.parentNode;
                parentFullscreen.innerHTML = `
                    <button onclick="bodyFullScreen()" id="fullscreen-body-only" class="${classnames}">
                        <span class="icon-GwQQdU8S">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                            <path fill="currentColor" d="M8.5 6A2.5 2.5 0 0 0 6 8.5V11h1V8.5C7 7.67 7.67 7 8.5 7H11V6H8.5zM6 17v2.5A2.5 2.5 0 0 0 8.5 22H11v-1H8.5A1.5 1.5 0 0 1 7 19.5V17H6zM19.5 7H17V6h2.5A2.5 2.5 0 0 1 22 8.5V11h-1V8.5c0-.83-.67-1.5-1.5-1.5zM22 19.5V17h-1v2.5c0 .83-.67 1.5-1.5 1.5H17v1h2.5a2.5 2.5 0 0 0 2.5-2.5z">
                            </path>
                            </svg>
                        </span>
                    </button>
                `
                var script = iframeDocument.createElement('script');
                script.innerHTML = `
                    function enterFullScreen(element) {
                        if (element.requestFullscreen) {
                            element.requestFullscreen();
                        } else if (element.mozRequestFullScreen) {
                            element.mozRequestFullScreen();
                        } else if (element.webkitRequestFullscreen) {
                            element.webkitRequestFullscreen();
                        } else if (element.msRequestFullscreen) {
                            element.msRequestFullscreen();
                        }
                    }

                    function exitFullScreen() {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                        }
                    }

                    function bodyFullScreen() {
                        if (!document.fullscreenElement &&
                            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
                            enterFullScreen(document.querySelector('body'));
                            document.querySelector("#fullscreen-body-only").innerHTML = '<span class="icon-GwQQdU8S"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M17 6v2.5a2.5 2.5 0 0 0 2.5 2.5H22v-1h-2.5A1.5 1.5 0 0 1 18 8.5V6h-1zm2.5 11a2.5 2.5 0 0 0-2.5 2.5V22h1v-2.5c0-.83.67-1.5 1.5-1.5H22v-1h-2.5zm-11 1H6v-1h2.5a2.5 2.5 0 0 1 2.5 2.5V22h-1v-2.5c0-.83-.67-1.5-1.5-1.5zM11 8.5V6h-1v2.5c0 .83-.67 1.5-1.5 1.5H6v1h2.5A2.5 2.5 0 0 0 11 8.5z"></path></svg></span>'
                        } else {
                            exitFullScreen();
                            document.querySelector("#fullscreen-body-only").innerHTML = '<span class="icon-GwQQdU8S"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M8.5 6A2.5 2.5 0 0 0 6 8.5V11h1V8.5C7 7.67 7.67 7 8.5 7H11V6H8.5zM6 17v2.5A2.5 2.5 0 0 0 8.5 22H11v-1H8.5A1.5 1.5 0 0 1 7 19.5V17H6zM19.5 7H17V6h2.5A2.5 2.5 0 0 1 22 8.5V11h-1V8.5c0-.83-.67-1.5-1.5-1.5zM22 19.5V17h-1v2.5c0 .83-.67 1.5-1.5 1.5H17v1h2.5a2.5 2.5 0 0 0 2.5-2.5z"></path></svg></span>'
                        }
                    }
                `;
        iframeDocument.querySelector("body").append(script);
      });
    },
    async getChartData() {
      // const userId = localStorage.getItem('u');
      // const url = `${process.env.VUE_APP_BACKEND_URL}/chartprocessor?action=/load`;
      // const res = await this.http.post(url, { userId });
      // if(res.data == 'nothing') return;
      // const response = JSON.parse(res.data);
      // const data = JSON.parse(response);
      // localStorage.setItem("chartStatesList", data.chartStatesList);
      // localStorage.setItem("studyList", data.studyList);
      // localStorage.setItem("resolution", data.resolution);
      // localStorage.setItem("widgetBar", data.widgetBar);
    },
    async getOrders() {
      const url = `${
        process.env.VUE_APP_BACKEND_URL
      }/chart?access=${localStorage.getItem(
        "accessToken"
      )}&action=/orders?account=${localStorage.getItem("lastAccount")}`;
      try {
        const res = await this.http.get(url);
        this.orderList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async setWatchlist(list) {
     await this.http.post(
        process.env.VUE_APP_SERVER +
          `/web/setwatchlist?_id=${localStorage.session}&token=${localStorage.sessionKey}`,
        {
          list,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    async getPositions() {
      const url = `${
        process.env.VUE_APP_BACKEND_URL
      }/chart?access=${localStorage.getItem(
        "accessToken"
      )}&action=/positions?account=${localStorage.getItem("lastAccount")}`;

            try {
                const response = await this.http.get(url);
                const Positions = response.data;
                this.positionList = Positions;
            } catch (error) {
                console.log(error);
            }
        },

        async saveChartData(userId, chartData) {
            const url = `${process.env.VUE_APP_BACKEND_URL}/chartprocessor?action=/save?userId=${userId}&chartData=${chartData}&account=${localStorage.getItem('lastAccount')}`

            try {
                const response = await this.http.get(url);
                console.log("return save: ", response);
            } catch (error) {
                console.log(error);
            }
        },

        async loadChartData(userId) {
            const url = `${process.env.VUE_APP_BACKEND_URL}/chartprocessor?action=/save?userId=${userId}&account=${localStorage.getItem('lastAccount')}`

            try {
                const response = await this.http.get(url);
                console.log("return load: ", response);
            } catch (error) {
                console.log(error);
            }
        },

        saveChartState(state) {
            const savedStates = getFromLocalStorage('chartStatesList') || [];
            // Append the new state to the list
            savedStates.push(state);

            // Save the updated list back to local storage
            saveToLocalStorage('chartStatesList', savedStates);
        },

        constructDatafeedUrl() {
            return `${process.env.VUE_APP_CHART}/chart?access=${encodeURIComponent(localStorage.accessToken)}&_id=${encodeURIComponent(localStorage.session)}&token=${encodeURIComponent(localStorage.sessionKey)}&action=/symbols?symbol=${encodeURIComponent(this.$route.params.ticker.toUpperCase())}&resolution=1D`;
        },

        extractSymbolFromUrl(url) {
            const actionRegex = /[?&]action=([^&#]*)/;
            const actionMatch = actionRegex.exec(url);
            if (!actionMatch) return '';

            const actionValue = decodeURIComponent(actionMatch[1]);
            const symbolRegex = /[?&]symbol=([^&#]*)/;
            const symbolMatch = symbolRegex.exec(actionValue);
            if (!symbolMatch) return '';

            return decodeURIComponent(symbolMatch[1].replace(/\+/g, ' '));
        },

        getParameterByName(name) {
            name = name.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },
    },
};
</script>

<style>
#tv_chart_container {
    iframe {
        width: 100%;
        height: 535px;
    }
}
</style>
