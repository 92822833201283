<template>
  <div class="buy-form" :class="{ compact: compactMode }">
    <div v-if="!submitted && !editRatio" class="form-header">
      <p class="ticker-details" v-if="isClosing">Roll Position</p>
    </div>
    <div
      class="form-body"
      style="border-top: 1px solid #eef0f3"
      v-if="!preview && !editRatio"
    >
      <!-- {{tickerList}} -->
      <div
        class="input-line"
        v-for="(ticker, index) in tickerList"
        :key="'title' + index"
      >
        <div class="input-row">
          <div>
            <label for="type">
              <p class="ticker">
                <!-- {{ ticker.Buy ? "Buy" : "Sell" }} -->
                <span class="green" v-if="tickerList.length > 1"
                  >{{ ticker.Quantity }}x</span
                >
                {{
                  displaySymbolForRollOption(
                    ticker,
                    ticker.year,
                    ticker.month,
                    ticker.day
                  )
                }}
              </p>
            </label>
            <p class="description">
              {{ ticker.Quantity }}
              {{
                ticker.LongShort === "Short" ? "buy to open" : "sell to close"
              }}
            </p>
          </div>
          <div class="ticker-price">
            <span>
              {{ ticker.TradeAction?.includes("SELL") ? "+" : "-" }}${{
                ticker.TradeAction?.includes("SELL") ? ticker.Bid : ticker.Ask
              }}
            </span>

            <span
              v-if="ticker.day"
              class="aero"
              @click="handleSelectedOption()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M9 18l6-6-6-6" /></svg
            ></span>
          </div>
        </div>

        <Modal v-if="openOption" @close="openOption = false">
          <div class="form-header">
            <p class="ticker-details">Select contract</p>
          </div>
          <div class="input-line">
            <div class="input-row">
              <label for="type">Strike Price</label>
              <select
                name="type"
                id="type"
                :class="{ 'strike-error': !selectedStrikePrice }"
                v-model="selectedStrikePrice"
              >
                <option
                  v-for="(price, index) in strikePrices"
                  :key="index"
                  :value="price"
                >
                  {{ price }}
                </option>
              </select>
            </div>
            <p class="error" v-if="!selectedStrikePrice">
              Please select strike price
            </p>
          </div>
          <div class="input-line">
            <div class="input-row">
              <label for="type">Expiry Date</label>
              <select
                class="newTest"
                name="type"
                id="type"
                v-model="selectedExpiryDate"
              >
                <option
                  v-for="(date, index) in formatExpiryDates"
                  :key="index"
                  :value="date"
                >
                  {{ date }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-line last">
            <div class="input-row">
              <label for="type">Action</label>
              <select name="type" id="type" v-model="selectedAction">
                <option>Buy to Open</option>
                <option>Sell to Open</option>
              </select>
            </div>
          </div>
          <div class="buttons" v-if="!editRatio">
            <button class="secondary" @click="openOption = false">
              Cancel
            </button>
            <button @click="setTickerOption(ticker)">Done</button>
          </div>
        </Modal>
      </div>

      <div class="input-line">
        <div class="input-row">
          <label class="ticker" for="quantity">Quantity</label>
          <p>
            {{ quantity }}
          </p>
        </div>
      </div>
      <div
        class="input-line limit"
        v-if="
          (!isClosing &&
            ['Limit', 'Bracket', 'Bracket %', 'Stop Limit'].indexOf(
              orderType
            ) != -1) ||
          ['Limit', 'Stop Limit'].indexOf(orderType) != -1
        "
      >
        <div class="input-row">
          <label class="ticker" for="limitPrice">Limit Price</label>
          <input
            type="text"
            name="limitPrice"
            id="limitPrice"
            v-model="limitPrice"
            placeholder="0.00"
          />
        </div>
        <p class="error" v-if="errors.limitPrice">{{ errors.limitPrice }}</p>
      </div>
      <div class="bid-ask upper">
        <div style="display: flex; flex-direction: row">
          <p class="clickable">Bid:</p>
          <p class="clickable">Mid:</p>
          <p class="clickable">Ask:</p>
        </div>
        <div class="selection-bar">
          <div class="slider-container">
            <input
              type="range"
              id="priceRange"
              name="priceRange"
              min="1"
              max="3"
              step="1"
              v-model="sliderPosition"
              @input="updatePrice"
            />
            <div class="input-range-icon"></div>
          </div>
        </div>

        <div style="display: flex; flex-direction: row">
          <p class="clickable" @click="setSliderPosition(1)">
            ${{ this.roundFigure(totalBid) }}
          </p>

          <p class="clickable" @click="setSliderPosition(2)">
            ${{ this.roundFigure((totalAsk + totalBid) / 2) }}
          </p>

          <p class="clickable" @click="setSliderPosition(3)">
            ${{ this.roundFigure(totalAsk) }}
          </p>
        </div>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label class="ticker" for="quantity">Estimated Cost</label>
          <p class="value">${{ (limitPrice * quantity * 100).toFixed(2) }}</p>
        </div>
        <p class="description">
          {{ limitPrice }} x {{ quantity }} x 100 Shares
        </p>
      </div>
      <div class="keyValue-last">
        <span>
          <p class="keyValue-string">Buying Power</p>
          <p>{{ dollarWithCommas(account.BuyingPower) }}</p>
        </span>
        <span>
          <p class="keyValue-string">P/L Total</p>
          <p>
            {{
              sumUnrealizedProfitLoss != "-"
                ? `$ ${dollarWithCommas(sumUnrealizedProfitLoss)}`
                : `${dollarWithCommas(sumUnrealizedProfitLoss)}`
            }}
          </p>
        </span>
      </div>
    </div>
    <div class="form-body" v-if="editRatio">
      <div
        class="input-line"
        v-for="(ticker, index) in tickerList"
        :key="'ratio' + index"
      >
        <div class="input-row">
          <label :for="'quantity' + index">{{
            displaySymbolForRollOption(
              ticker,
              ticker,
              ticker.year,
              ticker.month,
              ticker.day
            )
          }}</label>
          <div class="ratio-input">
            <p class="ratio-action">{{ ticker.Buy ? "Buy" : "Sell" }}</p>
            <p>{{ quantity }}</p>
            <!-- <input type="text" :name="'quantity' + index" :id="'quantity' + index" v-model="ticker.Quantity"
              placeholder="1" /> -->
          </div>
        </div>
      </div>
      <p class="error" v-if="errors.ratio">{{ errors.ratio }}</p>
    </div>
    <div class="preview-body" v-if="preview && !submitted">
      <KeyValue key-string="Account" :value="preview[0].AccountID" />
      <KeyValue
        key-string="Estimated Commission"
        :value="estimatedCommission"
      />
      <KeyValue key-string="Estimated Cost" :value="estimatedCost" />
      <KeyValue
        key-string="Duration"
        :value="preview[0].TimeInForce.Duration"
      />
      <p class="summary-title">Summary:</p>
      <p
        class="summary-message"
        v-for="(msg, index) in preview"
        :key="`msg${index}`"
      >
        {{ msg.SummaryMessage }}
      </p>
    </div>
    <Modal
      style="z-index: 1000"
      v-if="isConfirmationModalOpen == true"
      @close="(isConfirmationModalOpen = false), (loading = false)"
    >
      <ConfirmationOrder
        @close="
          (isConfirmationModalOpen = false),
            (loading = false),
            (isConfirmationOrder = false)
        "
        @confirm="(isConfirmationOrder = true), reviewOrder()"
      />
    </Modal>
    <div class="success-body" v-if="submitted">
      <p>{{ submitted.Message }}</p>
    </div>
    <p class="error" v-if="errors.global">{{ errors.global }}</p>
    <div class="buttons" v-if="!editRatio">
      <!-- <button class="secondary" v-if="!preview" @click="close">Cancel</button> -->
      <button
        class="secondary"
        v-if="preview && !submitted"
        @click="(preview = null), (isConfirmationOrder = false)"
      >
        Back
      </button>
      <button class="secondary" v-if="!loading && submitted" @click="close">
        Close
      </button>

      <button class="blank" v-if="loading">
        <img class="loading" src="../assets/loading.gif" />
      </button>
      <button
        style="width: 100%"
        v-if="!loading && !preview"
        @click="reviewOrder"
      >
        Review
      </button>
      <button v-if="!loading && !submitted && preview" @click="submitOrder">
        Submit
      </button>
      <button v-if="!loading && submitted" @click="goHome">Home</button>
    </div>
    <div class="buttons" v-if="editRatio">
      <button style="width: 100%" @click="finishRatio">Done</button>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import KeyValue from "./KeyValue.vue";
import Toast from "./Toasts.vue";
import Modal from "./Modal.vue";
import ConfirmationOrder from "./ConfirmationOrder.vue";

export default {
  name: "RollSelect",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  mixins: [Mixins],
  props: {
    tickers: Array,
    account: Object,
    availableLegs: {
      type: Array,
      default() {
        return [];
      },
    },
    sound: Boolean,
    isClosing: {
      type: Boolean,
      default: false,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
    startPrice: {
      type: String,
      default: null,
    },
    refresh: {
      type: Number,
      default: null,
    },
    orderExecRoutes: Array,
    position: Object,
    orders: Array,
  },
  components: {
    KeyValue,
    ConfirmationOrder,
    Modal,
  },
  data() {
    return {
      tickerList: this.tickers,
      addLegs: false,
      editRatio: false,
      legList: JSON.parse(JSON.stringify(this.availableLegs)),
      quantity:
        this.tickers[0]["Quantity"] != null && this.tickers.length == 1
          ? this.tickers[0]["Quantity"].replace("-", "")
          : "1",
      preview: null,
      submitted: null,
      loading: true,
      priceIncrement: {
        style: null,
        increment: null,
        schedule: [],
      },
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      orderType: this.startPrice == "market" ? "Market" : "Limit",
      limitPrice:
        this.startPrice && this.startPrice != "market" ? this.startPrice : "",
      stopPrice: "",
      profitLimit: "",
      stopLoss: "",
      trailAmount: "",
      trailPercent: "",
      duration: "Until Cancel",
      typeDescription: {
        Limit: "Order only if price is equal to or better than my limit",
        Market: "Order as soon as possible, regardless of the price",
      },
      durationDescription: {
        "Until Cancel":
          "Valid until I cancel, trades during regular market hours",
        Day: "Valid until the end of the regular trading session",
        Extended:
          "Valid until during one extended trading session (pre or post market)",
        "All Hours":
          "Valid until I cancel, trades during regular market hours and extended trading hours",
        Immediate:
          "Only try to fill immediately, accept partial fills, then cancel",
        "Fill or Kill": "Only fill this order completely, reject partial fills",
      },
      queryTypeMap: [
        "Limit", //0
        "Market", //1
        "Bracket", //2
        "Stop Market", //3
        "Stop Limit", //4
        "Trailing Stop", //5
        "Trailing Stop %", //6
        "Bracket %", //7
      ],
      queryDurationMap: [
        "Until Cancel", //0
        "Day", //1
        "Extended", //2
        "All Hours", //3
        "Immediate", //4
        "Fill or Kill", //5
      ],
      errors: {},
      orderRoute: {
        Id: "Intelligent",
        Name: "Intelligent",
      },
      estimatedCost: 0,
      estimatedCommission: 0,
      isConfirmationModalOpen: false,
      isConfirmationOrder: false,
      sumTodaysProfitLoss: "-",
      sumUnrealizedProfitLoss: "-",
      openOption: false,
      selectedAction: "Buy to Open",
      selectedExpiryDate: null,
      formatExpiryDates: [],
      strikePrices: [],
      selectedStrikePrice: null,
      sameQuantity: true,
      sliderPosition: 1,
    };
  },
  watch: {
    sliderPosition(val) {
      this.sliderPosition = val;
      this.updatePrice();
    },
    isQuantitySame(newVal) {
      if (newVal) {
        this.applySameQuantity();
      } else {
        this.applyGcdQuantity();
      }
    },
    limitPrice(newVal) {
      this.limitPrice = newVal;
    },
    availableLegs() {
      this.calculateSums();
    },
    refresh(ping) {
      if (ping == 0) {
        return;
      } else {
        this.remount();
      }
    },
    tickers() {
      this.remount();
      this.calculateSums();
    },
    quantity() {
      if (this.quantity > 50 && this.orderType == "Bracket") {
        this.orderType = "Limit";
      }
    },
    async tickerList() {
      await this.getExpiryDate();
      await this.streamData();
      this.calculateSums();
    },
    selectedExpiryDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.fetchStrikePrices(newDate);
      }
    },
  },
  computed: {
    limitPriceDisplay() {
      // Computed property to show limit price based on slider
      return this.limitPrice.toFixed(2);
    },
    isQuantitySame() {
      if (this.tickerList.length === 0) return true;
      const firstQuantity = this.tickerList[0].Quantity;
      return this.tickerList.every(
        (ticker) => ticker.Quantity === firstQuantity
      );
    },
    totalBid() {
      let totalBid = 0;
      if (this.tickerList.length > 1) {
        this.tickerList.forEach((ticker) => {
          if (ticker.TradeAction?.includes("SELL")) {
            totalBid -= parseFloat(ticker.Bid) * parseFloat(ticker.Quantity);
          } else {
            totalBid += parseFloat(ticker.Bid) * parseFloat(ticker.Quantity);
          }
        });
      }
      return totalBid;
    },
    totalAsk() {
      let totalAsk = 0;
      if (this.tickerList.length > 1) {
        this.tickerList.forEach((ticker) => {
          if (ticker?.TradeAction?.includes("SELL")) {
            totalAsk -= parseFloat(ticker.Ask) * parseFloat(ticker.Quantity);
          } else {
            totalAsk += parseFloat(ticker.Ask) * parseFloat(ticker.Quantity);
          }
        });
        if (parseFloat(totalAsk) > 0) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sliderPosition = 3;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.limitPrice = this.roundFigure(totalAsk);
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sliderPosition = 1;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.limitPrice = this.roundFigure(this.totalBid);
        }
      }
      return totalAsk;
    },
  },
  beforeUnmount() {
    if (this.quoteStream.connection) {
      this.quoteStream.connection.end();
      this.quoteStream.connection.destroy();
    }
  },
  async mounted() {
    if (
      this.$route.path.indexOf("/options/order") != -1 ||
      this.$route.path.indexOf("/options/close") != -1
    ) {
      if (this.$route.query.t && parseInt(this.$route.query.t) < 8) {
        if (
          (this.$route.query.t != "2" && this.$route.query.t != "7") ||
          this.tickers.length == 1
        ) {
          //no bracket on multileg
          this.orderType = this.queryTypeMap[parseInt(this.$route.query.t)];
        }
      }
      if (this.$route.query.d && parseInt(this.$route.query.d) < 6) {
        this.duration = this.queryDurationMap[parseInt(this.$route.query.d)];
      }
      if (this.$route.query.l) {
        this.limitPrice = this.$route.query.l;
      }
      if (this.$route.query.pl) {
        this.profitLimit = this.$route.query.pl;
      }
      if (this.$route.query.sl) {
        this.stopLoss = this.$route.query.sl;
      }
      if (this.$route.query.s) {
        this.stopPrice = this.$route.query.s;
      }
      if (this.$route.query.ta) {
        this.trailAmount = this.$route.query.ta;
      }
      if (this.$route.query.tp) {
        this.trailPercent = this.$route.query.tp;
      }
    } else if (this.isClosing) {
      if (this.tickerList[0].Buy == undefined) {
        this.tickerList[0].Buy = this.tickerList[0].Quantity < 0;
      }
      var indexSplice = 0;
      for (var i = 0; i < this.legList.length; i++) {
        this.legList[i].Buy = this.legList[i].LongShort == "Short";
        this.legList[i].Bid = this.legList[i].BidPrice;
        this.legList[i].Ask = this.legList[i].AskPrice;
        if (this.legList[i].Symbol == this.tickerList[0].Symbol) {
          indexSplice = i;
        }
      }
      if (indexSplice > -1) {
        this.legList.splice(indexSplice, 1);
      }
    }
    if (this.tickers.length == 1) {
      this.fetchSymbolDescription();
    } else {
      this.loading = false;
    }
    await this.getExpiryDate();
    await this.streamData();
    this.calculateSums();
    if (this.isQuantitySame) {
      this.applySameQuantity();
    } else {
      this.applyGcdQuantity();
    }
  },

  methods: {
    setSliderPosition(position) {
      this.sliderPosition = position;
      this.updatePrice();
    },
    updatePrice() {
      if (this.sliderPosition == 1) {
        this.setLimitPrice(this.totalBid);
      } else if (this.sliderPosition == 2) {
        this.setLimitPrice((this.totalAsk + this.totalBid) / 2);
      } else if (this.sliderPosition == 3) {
        this.setLimitPrice(this.totalAsk);
      }
    },
    setLimitPrice(value) {
      if (this.preview != null) {
        return;
      }
      if (this.orderType == "Limit" || this.orderType == "Stop Limit") {
        this.limitPrice = this.roundFigure(value);
      } else if (this.orderType == "Bracket") {
        if (this.isClosing) {
          this.profitLimit = this.roundFigure(value);
        } else {
          this.limitPrice = this.roundFigure(value);
        }
      } else if (this.orderType == "Stop Market") {
        this.stopPrice = this.roundFigure(value);
      }
    },
    roundFigure(num) {
      return Math.floor(num * 100) / 100;
    },
    applySameQuantity() {
      // const firstQuantity = this.tickerList[0].Quantity;
      this.tickerList.forEach((ticker) => {
        ticker.Quantity = 1;
      });
      // this.quantity = 1;
    },
    applyGcdQuantity() {
      let gcdFound = null;
      this.tickerList.forEach((ticker) => {
        let qty = String(ticker.Quantity).replaceAll("-", "");
        if (gcdFound === null) {
          gcdFound = parseInt(qty);
        } else {
          gcdFound = this.getGcd(gcdFound, parseInt(qty));
        }
      });
      this.quantity = gcdFound.toFixed(0);
      this.tickerList.forEach((ticker) => {
        ticker.Quantity = parseFloat(ticker.Quantity) / gcdFound;
      });
    },
    getGcd(a, b) {
      // Add your actual GCD function logic here
      return b === 0 ? a : this.getGcd(b, a % b);
    },
    async setTickerOption(ticker) {
      if (
        !this.selectedStrikePrice ||
        !this.selectedExpiryDate ||
        !this.selectedAction
      ) {
        return;
      }
      const date = new Date(this.selectedExpiryDate);

      const year = date.getFullYear().toString().slice(-2);
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const index = this.tickerList.findIndex(
        (t) => t.Symbol === ticker.Symbol
      );
      let newPosition = { ...ticker };
      let strikeString = "";
      if (newPosition.Symbol.includes("C")) {
        strikeString = "C" + this.selectedStrikePrice;
      } else {
        strikeString = "P" + this.selectedStrikePrice;
      }
      newPosition.Symbol = `${
        ticker.Symbol.split(" ")[0]
      } ${year}${month}${day}${this.selectedStrikePrice}`;
      newPosition.Symbol = `${
        newPosition.Symbol.split(" ")[0]
      } ${year}${month}${day}${strikeString}`;
      let data = {
        ...ticker,
        Symbol: newPosition.Symbol,
        LongShort: this.selectedAction === "Buy to Open"? "Short":"Long",
        TradeAction: this.selectedAction === "Buy to Open"? "BUYTOOPEN" : "SELLTOOPEN",
        Buy: this.selectedAction === "Buy to Open" ? true : false,
        Last: ticker.Last,
        year: date.getFullYear().toString().slice(-2),
        month: (date.getMonth() + 1).toString().padStart(2, "0"),
        day: date.getDate().toString().padStart(2, "0"),
        date: this.formatDate(
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear()
        ),
      };
      if (index) {
        this.tickerList.splice(index, 1, data);
        await this.streamData();
      }
      this.openOption = false;
    },
    fetchStrikePrices(date) {
      const val = new Date(date);

      const day = val.getDate();
      const month = val.getMonth() + 1;
      const year = val.getFullYear();
      const baseUrl =
        localStorage.sim === "true"
          ? process.env.VUE_APP_TS_SIM
          : process.env.VUE_APP_TS;
      const symbol = this.tickerList[0].Symbol.split(" ")[0];
      const url = `${baseUrl}marketdata/options/strikes/${symbol}?expiration=${month}-${day}-${year}`;

      this.http
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.accessToken}` },
        })
        .then((res) => {
          this.strikePrices = res.data.Strikes.map((e) => e[0]);
          if (!this.strikePrices.includes(this.selectedStrikePrice)) {
            this.selectedStrikePrice = null;
          }
        })
        .catch((error) => {
          console.error("Error fetching strike prices:", error);
        });
    },
    formatDate(date, month, year) {
      const dateObject = new Date(year, month - 1, date);
      return dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    handleSelectedOption() {
      this.openOption = true;
    },
    async getExpiryDate() {
      try {
        const _this = this; // Preserve the context
        const promises = _this.tickerList.map((ticker) => {
          // Map each ticker to a promise
          const symbol = ticker.Symbol.split(" ")[0];
          if (!symbol) return;
          const url =
            (localStorage.sim == "true"
              ? process.env.VUE_APP_TS_SIM
              : process.env.VUE_APP_TS) +
            `marketdata/options/expirations/${symbol}`;

          return _this.http
            .get(url, {
              headers: { Authorization: `Bearer ${localStorage.accessToken}` },
            })
            .then((res) => {
              const symbolYear =
                "20" + ticker.Symbol.split(" ")[1].substring(0, 2);
              const symbolMonth = ticker.Symbol.split(" ")[1].substring(2, 4);
              const symbolDay = ticker.Symbol.split(" ")[1].substring(4, 6);
              const tickerExpiryDate = new Date(
                `${symbolYear}-${symbolMonth}-${symbolDay}`
              );
              const thresholdDate = new Date(
                tickerExpiryDate.getTime() + 6 * 24 * 60 * 60 * 1000
              ); // 6 days after
              const filteredDates = res.data.Expirations.filter(
                (expiry) => new Date(expiry.Date) > thresholdDate
              ).sort((a, b) => new Date(a.Date) - new Date(b.Date));

              const result = filteredDates.length > 0 ? filteredDates[0] : null;
              if (result) {
                const dateString = result.Date;
                const date = new Date(dateString);
                const expiryDateString = new Date(result.Date)
                  .toISOString()
                  .slice(2, 10)
                  .replace(/-/g, "");
                let strikeString = "";
                let newPosition = JSON.parse(JSON.stringify(ticker));

                if (newPosition.Symbol.includes("C")) {
                  strikeString =
                    "C" + newPosition.Symbol.split(" ")[1].split("C")[1];
                } else {
                  strikeString =
                    "P" + newPosition.Symbol.split(" ")[1].split("P")[1];
                }

                newPosition.Symbol = `${
                  newPosition.Symbol.split(" ")[0]
                } ${expiryDateString}${strikeString}`;
                let newTradeAction;
                let newLongShort;

                if (ticker.LongShort === "Long") {
                  ticker.TradeAction = "SELLTOCLOSE";
                  newTradeAction = "BUYTOOPEN";
                  newLongShort = "Short";
                } else {
                  ticker.TradeAction = "BUYTOCLOSE";
                  newTradeAction = "SELLTOOPEN";
                  newLongShort = "Long";
                }
                let data = {
                  ...ticker,
                  Symbol: newPosition.Symbol,
                  LongShort: newLongShort,
                  TradeAction: newTradeAction,
                  // Buy: false,
                  year: date.getFullYear().toString().slice(-2),
                  month: (date.getMonth() + 1).toString().padStart(2, "0"),
                  day: date.getDate().toString().padStart(2, "0"),
                  date: this.formatDate(
                    date.getDate(),
                    date.getMonth() + 1,
                    date.getFullYear()
                  ),
                };
                this.tickerList.push(data);

                _this.formatExpiryDates = res.data.Expirations.map((item) => {
                  _this.selectedExpiryDate = data.date;
                  const dateObject = new Date(item.Date);
                  return dateObject.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                });
                _this.selectedStrikePrice =
                  ticker.Symbol.split(" ")[1].split("C")[1] ||
                  ticker.Symbol.split(" ")[1].split("P")[1];

                _this.fetchStrikePrices(_this.selectedExpiryDate);
              } else {
                return null;
              }
            });
        });

        await Promise.all(promises);
      } catch (error) {
        console.error("Error fetching expiration dates:", error);
      }
    },
    displaySymbolForRollOption: function (
      x,
      symbolYear,
      symbolMonth,
      symbolDay,
      full = false
    ) {
      if (x.PositionType == "group") {
        return x.Symbol.split("\n")[0] + (full ? " Spread" : "");
      }
      if (x.Spread && x.Spread.length > 0) {
        var returnText = x.Spread;
        if (returnText.indexOf("Ratio") != -1) {
          returnText = "Custom";
        }
        return x.Legs[0].Symbol.split(" ")[0] + " " + returnText;
      }
      x = x.Legs ? x.Legs[0] : x;
      var symbolDisplay = x["Symbol"];
      if (
        (x["AssetType"] == "UNKNOWN" && x.Symbol.split(" ").length > 1) ||
        x["AssetType"] == "OP" ||
        x["AssetType"].indexOf("OPTION") != -1 ||
        (x["AssetType"].indexOf("Option") != -1 &&
          symbolDisplay.indexOf(" ") != -1)
      ) {
        var symbolTicker = symbolDisplay.split(" ")[0];
        if (!symbolYear)
          symbolYear = symbolDisplay.split(" ")[1].substring(0, 2);
        if (!symbolMonth)
          symbolMonth = symbolDisplay.split(" ")[1].substring(2, 4);
        if (!symbolDay) symbolDay = symbolDisplay.split(" ")[1].substring(4, 6);
        var symbolOptionType = symbolDisplay.split(" ")[1].substring(6, 7);
        var symbolOptionStrike = "$" + symbolDisplay.split(" ")[1].substring(7);
        if (symbolMonth == "01") {
          symbolMonth = "1";
        } else if (symbolMonth == "02") {
          symbolMonth = "2";
        } else if (symbolMonth == "03") {
          symbolMonth = "3";
        } else if (symbolMonth == "04") {
          symbolMonth = "4";
        } else if (symbolMonth == "05") {
          symbolMonth = "5";
        } else if (symbolMonth == "06") {
          symbolMonth = "6";
        } else if (symbolMonth == "07") {
          symbolMonth = "7";
        } else if (symbolMonth == "08") {
          symbolMonth = "8";
        } else if (symbolMonth == "09") {
          symbolMonth = "9";
        } else if (symbolMonth == "10") {
          symbolMonth = "10";
        } else if (symbolMonth == "11") {
          symbolMonth = "11";
        } else if (symbolMonth == "12") {
          symbolMonth = "12";
        }
        if (symbolOptionType == "C") {
          symbolOptionType = "Call";
        } else if (symbolOptionType == "P") {
          symbolOptionType = "PUT";
        }
        symbolDisplay =
          symbolOptionStrike +
          "\n" +
          symbolTicker +
          " " +
          symbolOptionType +
          "\n" +
          symbolMonth +
          "/" +
          symbolDay;
        if (x.PositionType == "child" && full == false) {
          symbolDisplay =
            symbolOptionStrike +
            " " +
            symbolOptionType +
            "\n" +
            symbolMonth +
            "/" +
            symbolDay;
        }
        return symbolDisplay;
      } else {
        return x.Symbol;
      }
    },
    calculateSums() {
      let sumUnrealizedProfitLossLocal = 0;
      let sumTodaysProfitLossLocal = 0;
      this.tickers.forEach((ticker) => {
        let found = this.availableLegs.find(
          (leg) => leg.Symbol === ticker.Symbol
        );
        if (found) {
          sumTodaysProfitLossLocal += parseFloat(found.TodaysProfitLoss);
          sumUnrealizedProfitLossLocal += parseFloat(
            found.UnrealizedProfitLoss
          );
        }
      });
      this.sumTodaysProfitLoss = sumTodaysProfitLossLocal;
      this.sumUnrealizedProfitLoss = sumUnrealizedProfitLossLocal;
    },
    remount() {
      this.loading = true;
      this.limitPrice = this.startPrice ? this.startPrice : "";
      this.quantity =
        this.tickers[0]["Quantity"] != null ? this.tickers[0]["Quantity"] : "1";
      this.preview = null;
      this.submitted = null;
      this.tickerList = this.tickers;
      if (this.tickers.length == 1) {
        this.fetchSymbolDescription();
      } else {
        this.loading = false;
      }

      if (this.isClosing) {
        this.tickerList.forEach((leg) => {
          if (!leg.Quantity) {
            leg.Quantity = "1";
          } else {
            leg.Quantity = String(leg.Quantity);
          }
        });
      } else {
        this.tickerList.forEach((leg) => {
          leg.Quantity = "1";
        });
      }
      if (this.isQuantitySame) {
        this.applySameQuantity();
      } else {
        this.applyGcdQuantity();
      }
      this.streamData();
    },
    fetchSymbolDescription() {
      var _this = this;
      if (!this.tickers[0].Symbol) return;
      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) +
            `marketdata/symbols/${encodeURIComponent(this.tickers[0].Symbol)}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          if (res.data.Symbols.length > 0) {
            if (
              res.data.Symbols[0].PriceFormat &&
              res.data.Symbols[0].PriceFormat.IncrementStyle
            ) {
              _this.priceIncrement.style =
                res.data.Symbols[0].PriceFormat.IncrementStyle;
              if (_this.priceIncrement.style == "Simple") {
                _this.priceIncrement.increment =
                  res.data.Symbols[0].PriceFormat.Increment;
              } else if (_this.priceIncrement.style == "Schedule") {
                _this.priceIncrement.schedule =
                  res.data.Symbols[0].PriceFormat.IncrementSchedule;
              }
            }
          }
          _this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
        });
    },
    streamData() {
      var _this = this;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      this.quoteStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var tickerString = "";
      for (var i = 0; i < this.tickerList.length; i++) {
        tickerString += this.tickerList[i].Symbol + ",";
      }
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${tickerString}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.quoteStream.connection = this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.quoteStream || !this.quoteStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.quoteStream.leftOver != null) {
                chunk = _this.quoteStream.leftOver + chunk;
                _this.quoteStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              var newData = this.tickerList;
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  if (snapShot.Ask) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Ask =
                      parseFloat(snapShot.Ask);
                  }
                  if (snapShot.Bid) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Bid =
                      parseFloat(snapShot.Bid);
                  }
                  if (snapShot.Mid) {
                    newData.find((obj) => obj.Symbol == snapShot.Symbol).Mid =
                      parseFloat(snapShot.Mid);
                  }
                } else {
                  _this.quoteStream.leftOver = quotes[a];
                }
              }
              _this.tickerList = newData;
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    checkIncrement(price) {
      if (this.tickerList.length != 1 || this.priceIncrement.style == null) {
        return false;
      }
      var incrementStyle = this.priceIncrement.style;
      if (incrementStyle == "Simple") {
        var simpleIncrement = this.priceIncrement.increment;
        var incrementMultiply =
          1 * Math.pow(10, simpleIncrement.split(".")[1].length);
        if (
          Math.round(price * incrementMultiply) %
            Math.round(simpleIncrement * incrementMultiply) !=
          0
        ) {
          return `At the current market price this ticker only accepts price increments of ${simpleIncrement}`;
        }
      } else if (incrementStyle == "Schedule") {
        var targetIncrement = null;
        var incrementMultiplySchedule = null;
        for (var i = 0; i < this.priceIncrement.schedule.length; i++) {
          if (
            parseFloat(price) >
            parseFloat(this.priceIncrement.schedule[i]["StartsAt"])
          ) {
            targetIncrement = this.priceIncrement.schedule[i]["Increment"];
            incrementMultiplySchedule =
              1 *
              Math.pow(
                10,
                this.priceIncrement.schedule[i]["Increment"].split(".")[1]
                  .length
              );
          }
        }
        if (
          Math.round(price * incrementMultiplySchedule) %
            Math.round(targetIncrement * incrementMultiplySchedule) !=
          0
        ) {
          return `At the current market price this ticker only accepts price increments of ${targetIncrement}`;
        }
      }
      return false;
    },
    addLeg(leg) {
      if (this.orderType == "Bracket") {
        this.orderType = "Limit";
      }
      const index = this.legList.indexOf(leg);
      if (index > -1) {
        this.legList.splice(index, 1);
      }
      if (!leg.Bid) {
        leg.Bid = 0;
      }
      if (!leg.Ask) {
        leg.Ask = 0;
      }
      if (this.tickerList.length > 1) {
        this.tickerList.forEach((leg) => {
          leg.Quantity = (
            parseFloat(leg.Quantity) * parseFloat(this.quantity)
          ).toFixed(0);
        });
      }
      this.tickerList.push(leg);

      var gcdFound = null;
      this.tickerList.forEach((leg) => {
        leg.Quantity = String(leg.Quantity).replaceAll("-", "");
        if (gcdFound == null) {
          gcdFound = parseInt(leg.Quantity);
        } else {
          gcdFound = this.getGcd(gcdFound, parseInt(leg.Quantity));
        }
      });
      this.tickerList.forEach((leg) => {
        leg.Quantity = parseFloat(leg.Quantity) / gcdFound;
        this.quantity = gcdFound.toFixed(0);
      });
      this.streamData();
      this.calculateSums();
    },
    reviewOrder() {
      this.errors = {};
      this.loading = true;
      if (
        this.quantity == 0 ||
        this.quantity.length == 0 ||
        isNaN(this.quantity)
      ) {
        this.errors = {
          quantity: "Quantity must be a valid number that is not zero",
        };
        this.loading = false;
        return;
      }
      if (this.orderType == "Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var limitIncrement = this.checkIncrement(this.limitPrice);
        if (limitIncrement) {
          this.errors = {
            limitPrice: limitIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket") {
        if (
          (this.limitPrice == 0 ||
            this.limitPrice.length == 0 ||
            isNaN(this.limitPrice)) &&
          !this.isClosing
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.profitLimit == 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit: "Profit Limit must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss == 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss: "Stop Loss must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var bracketLimitIncrement = this.isClosing
          ? null
          : this.checkIncrement(this.limitPrice);
        var bracketProfitIncrement = this.checkIncrement(this.profitLimit);
        var bracketStopIncrement = this.checkIncrement(this.stopLoss);
        if (
          bracketLimitIncrement ||
          bracketProfitIncrement ||
          bracketStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketLimitIncrement,
            profitLimit: bracketProfitIncrement,
            stopLoss: bracketStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket %") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        this.profitLimit = this.profitLimit.replace("%", "");
        this.stopLoss = this.stopLoss.replace("%", "");
        if (
          this.profitLimit <= 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit:
              "Profit Limit must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss <= 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss:
              "Stop Loss must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Market") {
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopMarketIncrement = this.checkIncrement(this.stopPrice);
        if (stopMarketIncrement) {
          this.errors = {
            stopPrice: stopMarketIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopLimitLimitIncrement = this.checkIncrement(this.limitPrice);
        if (stopLimitLimitIncrement) {
          this.errors = {
            limitPrice: stopLimitLimitIncrement,
          };
          this.loading = false;
          return;
        }
        var stopLimitStopIncrement = this.checkIncrement(this.stopPrice);
        if (stopLimitStopIncrement) {
          this.errors = {
            stopPrice: stopLimitStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop") {
        if (
          this.trailAmount == 0 ||
          this.trailAmount.length == 0 ||
          isNaN(this.trailAmount)
        ) {
          this.errors = {
            trailAmount: "Trail Amount must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop %") {
        if (
          this.trailPercent == 0 ||
          this.trailPercent.length == 0 ||
          isNaN(this.trailPercent)
        ) {
          this.errors = {
            trailPercent:
              "Trailing Stop % must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      }
      if (this.quantity > 50) {
        this.isConfirmationModalOpen = true;
        if (!this.isConfirmationOrder) return;
        this.isConfirmationModalOpen = false;
      }
      if (
        (this.orderType == "Bracket" ||
          this.orderType == "Bracket %" ||
          this.orderType == "Stop Market" ||
          this.orderType == "Trailing Stop" ||
          this.orderType == "Trailing Stop %" ||
          this.orderType == "Market") &&
        this.duration == "Extended"
      ) {
        this.errors.global =
          "Extended hours orders must be limit orders. Please change order type or duration.";
        this.loading = false;
        return;
      }
      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        try {
          calculatedProfit =
            (parseFloat(this.limitPrice) *
              (100 + parseFloat(this.profitLimit))) /
            100;
          calculatedProfit = calculatedProfit.toFixed(2);
        } catch (e) {
          this.errors.profitLimit = "Can not calculate profit limit";
          return;
        }
        try {
          calculatedLoss =
            (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
            100;
          calculatedLoss = calculatedLoss.toFixed(2);
        } catch (e) {
          this.errors.stopLoss = "Can not calculate profit limit";
          return;
        }
        var bracketPercentLimitIncrement = this.checkIncrement(this.limitPrice);
        var bracketPercentProfitIncrement =
          this.checkIncrement(calculatedProfit);
        var bracketPercentStopIncrement = this.checkIncrement(calculatedLoss);
        if (
          bracketPercentLimitIncrement ||
          bracketPercentProfitIncrement ||
          bracketPercentStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketPercentLimitIncrement,
            profitLimit: bracketPercentProfitIncrement,
            stopLoss: bracketPercentStopIncrement,
          };
          if (!bracketPercentLimitIncrement) {
            this.orderType = "Bracket";
            this.profitLimit = calculatedProfit;
            this.stopLoss = calculatedLoss;
          }
          this.loading = false;
          return;
        }
      }

      const splitOrders = this.quantity > 50 ? [] : null;
      const maxOrderSize = 50;
      let remainingQuantity = this.quantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);
      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = this.tickerList.map((leg) => {
          let tradeAction;

          if (leg.isClosing) {
            tradeAction = leg["Buy"] ? "BUYTOCLOSE" : "SELLTOCLOSE";
          } else {
            tradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          }

          const legData = {
            Symbol: leg["Symbol"],
            Quantity: orderSize.toString().replaceAll("-", ""),
            TradeAction: tradeAction,
          };

          return legData;
        });

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          bracketProfit:
            this.orderType === "Bracket %"
              ? calculatedProfit
              : this.profitLimit,
          bracketLoss:
            this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: "SELL",
          orderType: this.orderType,
          limitPrice: this.limitPrice,
          orderDuration: this.duration,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickerList.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );

        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      const orderPayload = {
        orderType: this.orderType,
        callOptionsOrders: splitOrders,
        close: this.isClosing,
        final: false,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: null,
        quantity: this.quantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.duration,
        legs: this.tickerList,
        tradeAction: "SELL",
        assetType: "OP",
        isRollOptions: true,
      };

      var _this = this;
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          const totals = res.data.Confirmations?.reduce(
            (acc, item) => {
              acc.totalCost += parseInt(item.EstimatedCost, 10);
              acc.totalCommission += parseInt(item.EstimatedCommission, 10);
              return acc;
            },
            { totalCost: 0, totalCommission: 0 }
          );

          _this.estimatedCommission = totals?.totalCommission.toString();
          _this.estimatedCost = totals?.totalCost.toString();
          _this.preview = res.data.Confirmations;
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            return;
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
            return;
          }
        });
    },
    postDataOrder(_this) {
      this.errors = {};
      this.loading = true;

      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        calculatedProfit =
          (parseFloat(this.limitPrice) * (100 + parseFloat(this.profitLimit))) /
          100;
        calculatedProfit = calculatedProfit.toFixed(2);
        calculatedLoss =
          (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
          100;
        calculatedLoss = calculatedLoss.toFixed(2);
      }
      const splitOrders = this.quantity > 50 ? [] : null;
      const maxOrderSize = 50;
      let remainingQuantity = this.quantity;
      const numberOfGroups = Math.ceil(remainingQuantity / maxOrderSize);
      for (let i = 0; i < numberOfGroups; i++) {
        const orderSize = Math.min(maxOrderSize, remainingQuantity);
        const mappedLegs = this.tickerList.map((leg) => {
          let tradeAction;
          if (leg.isClosing) {
            tradeAction = leg["Buy"] ? "BUYTOCLOSE" : "SELLTOCLOSE";
          } else {
            tradeAction = leg["Buy"] ? "BUYTOOPEN" : "SELLTOOPEN";
          }

          const legData = {
            Symbol: leg["Symbol"],
            Quantity: orderSize.toString(),
            TradeAction: tradeAction,
          };

          return legData;
        });

        const orderDetails = {
          symbol: null,
          quantity: orderSize,
          bracketProfit:
            this.orderType === "Bracket %"
              ? calculatedProfit
              : this.profitLimit,
          bracketLoss:
            this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
          stopPrice: this.stopInput,
          trailAmount: this.trailingStopInput,
          trailPercent: this.trailingStopPercentInput,
          tradeAction: "SELL",
          orderType: this.orderType,
          limitPrice: this.limitPrice,
          orderDuration: this.duration,
          legs: mappedLegs,
        };

        const anyLegExceeds = this.tickerList.some(
          (leg) => parseFloat(leg.Quantity) * parseFloat(orderSize) > 50
        );

        if (anyLegExceeds) {
          splitOrders?.push(orderDetails);
        } else if (splitOrders) {
          splitOrders?.push(orderDetails);
        }

        remainingQuantity -= orderSize;
      }

      const orderPayload = {
        orderType: this.orderType,
        callOptionsOrders: splitOrders,
        close: this.isClosing,
        final: true,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
        symbol: null,
        quantity: this.quantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType === "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType === "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderDuration: this.duration,
        legs: this.tickerList,
        tradeAction: "SELL",
        assetType: "OP",
        isRollOptions: true,
      };
      // var _this = this;
      this.postOrder(orderPayload)
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(res.data),
            });
          } else {
            res.data.Orders.forEach((order) => {
              var sendLegs = "";
              if (order["Error"] == null) {
                _this.tickerList.forEach((leg) => {
                  sendLegs += `${leg["Symbol"]},`;
                });
                _this.$gtag.event("order_closing", { legs: sendLegs });
              } else {
                _this.$gtag.event("web_error", {
                  action: "order_closing",
                  res_data: order["Error"],
                });
              }
            });
            if (res.data.Orders[0].Error) {
              if (
                !_this.sellShort &&
                _this.isClosing &&
                (res.data.Orders[0].Message.includes("EC703") ||
                  res.data.Orders[0].Message.includes("EC704"))
              ) {
                _this.deleteAndRepost(_this);
              } else {
                _this.submitted = res.data.Orders[0];
                Toast.error(_this.submitted.Message);
                _this.playSound(
                  "../../order-rejected-cancelled.mp3",
                  _this.sound
                );
                _this.submitted = false;
                return;
              }
            } else {
              _this.submitted = res.data.Orders[0];
              Toast.success(_this.submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.isClosing) {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var averageCost = 0.0;
                  _this.tickerList.forEach((orderTicker) => {
                    if (orderTicker.Buy == true) {
                      averageCost -=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    } else {
                      averageCost +=
                        parseFloat(orderTicker.AveragePrice) *
                        (_this.tickerList.length == 1
                          ? 1
                          : parseFloat(orderTicker.Quantity));
                    }
                  });
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: averageCost.toString(),
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                      _this.$emit("close");
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }
            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later0000";
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: "order_closing",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    async deleteAndRepost(_this) {
      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds = [];

        orderIds = _this.orders
          .filter(
            (element) =>
              element.Legs[0].Symbol === _this.tickers[0]?.Symbol &&
              element.Legs[0].BuyOrSell === "Sell" &&
              validStatuses.includes(element.Status)
          )
          .map((e) => e.OrderID);
        const deletePromises = orderIds.map((orderId) =>
          _this.deleteOrder(_this, orderId)
        );
        await Promise.all(deletePromises);
        await _this.postDataOrder(_this);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteOrder(_this, orderId) {
      _this.loading = true;

      const url = `${
        localStorage.sim === "true"
          ? process.env.VUE_APP_TS_SIM
          : process.env.VUE_APP_TS
      }orderexecution/orders/${orderId}`;
      const headers = { Authorization: `Bearer ${localStorage.accessToken}` };

      try {
        const response = await _this.http.delete(url, { headers });

        _this.loading = false;
        if (response.status === 200) {
          _this.$emit("close");
          let legSymbols = "";
          _this.order?.Legs.forEach((leg) => {
            legSymbols += `${leg.Symbol},`;
          });
          _this.$gtag.event("order_delete", { legs: legSymbols });
        } else if (response.data.Message) {
          _this.errors.global = response.data.Message;
          Toast.error(_this.errors.global);
          _this.playSound("order-rejected-cancelled.mp3", _this.sound);
          _this.$gtag.event("web_error", {
            action: "order_delete",
            res_data: JSON.stringify(response.data),
          });
        } else {
          throw new Error("Something went wrong... Please try again later");
        }
      } catch (error) {
        _this.loading = false;

        const errorMessage =
          error.response && error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "Something went wrong... Please try again later";
        _this.errors.global = errorMessage;
        Toast.error(_this.errors.global);
        _this.playSound("order-rejected-cancelled.mp3", _this.sound);
        _this.$gtag.event("web_error", {
          action: "order_delete",
          res_data: JSON.stringify(
            error.response ? error.response.data : error
          ),
        });
      }
      _this.$emit("update-cv");
    },
    submitOrder() {
      this.errors = {};
      this.loading = true;
      this.postDataOrder(this);
    },
    close() {
      this.$emit("close");
    },
    goHome() {
      this.$emit("home");
    },
    finishRatio() {
      this.errors = {};
      var gcdFound = null;
      var nanFail = false;
      this.tickerList.forEach((leg) => {
        if (
          leg.Quantity == 0 ||
          leg.Quantity.length == 0 ||
          isNaN(leg.Quantity) ||
          nanFail
        ) {
          nanFail = true;
          return;
        }
        if (gcdFound == null) {
          gcdFound = parseInt(leg.Quantity);
        } else {
          gcdFound = this.getGcd(gcdFound, parseInt(leg.Quantity));
        }
      });
      if (nanFail) {
        this.errors = {
          ratio: "Ratio quantities must be a valid number greater than 0",
        };
        return;
      }
      if (gcdFound != 1) {
        this.tickerList.forEach((leg) => {
          leg["Quantity"] = (leg["Quantity"] / gcdFound).toFixed(0);
        });
      }
      this.editRatio = false;
    },
  },
};
</script>

<style scoped>
.keyValue-last {
  display: flex;
  justify-content: center;
  gap: 8%;
  margin-top: 10px;
}

.keyValue-string {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #97999a;
}

.keyValue-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #151c26;
}

.buy-form {
  padding: 10px;
}

.compact.buy-form {
  padding: 20px;
}

p {
  margin: 0;
  font-family: "Roboto";
}

.form-header {
  text-align: left;
}

.ticker-details {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 26px;
  padding: 14px 0;
}

.ticker {
  font-size: 18px;
  font-weight: 500;
}

.compact .bid-ask {
  font-size: 12px;
}

.add-legs {
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.edit-ratio {
  font-size: 16px;
  text-align: right;
  cursor: pointer;
  font-weight: 500;
}

.leg-list {
  margin-top: 12px;
}

.leg-list .ticker {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
  color: #616164;
}

.leg-list .ticker:hover {
  color: #10bc74;
}

.bid-ask {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eef0f3;
  color: #97999a;
  padding: 15px 0px;
  font-size: 14px;
}

.dark .bid-ask {
  border-bottom: 1px solid #333333;
}

.limit ~ .bid-ask {
  margin-top: -15px;
}

.bid-ask p {
  width: 33%;
  text-align: center;
}

.bid-ask p:first-of-type {
  text-align: left;
}

.bid-ask p:last-of-type {
  text-align: right;
}

.compact .bid-ask {
  font-size: 14px;
}

.input-line {
  margin: auto;
  padding: 20px 0;
  border-bottom: 1px solid #eef0f3;
}

.dark .input-line {
  border-bottom: 1px solid #333333;
}

.input-line.limit {
  border-bottom: none;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  font-size: 18px;
}

.input-line:last-of-type {
  border-bottom: none;
}

.input-line label {
  font-weight: 600;
  font-size: 21px;
}

.compact .input-line label {
  font-size: 18px;
}

.input-line select,
.input-line input {
  background: none;
  border: none;
  font-size: 21px;
  color: #10bc74;
  text-align: right;
  font-family: "Roboto";
  font-weight: 600;
  max-width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.compact .input-line select,
.compact .input-line input {
  font-size: 18px;
}

.input-line select:focus,
.input-line input:focus {
  outline: none;
}

.input-line option {
  color: #10bc74;
  background: none;
}

.input-line .description {
  color: #616164;
  font-size: 16px;
  text-align: left;
}

.compact .input-line .description {
  font-size: 14px;
}

.input-line .price-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-line .price-select p {
  font-weight: 600;
  color: #616164;
  cursor: pointer;
}

.ratio-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ratio-input .ratio-action {
  color: #909194;
}

.ratio-input input {
  max-width: 100px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}

.compact button {
  font-size: 16px;
}

.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

.dark button.secondary {
  background: #000000;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

button.blank {
  background: #ffffff;
}

.dark button.blank {
  background: #000000;
}

.loading {
  width: 18px;
  height: 18px;
}

.preview-body {
  text-align: left;
}

.compact .summary-title {
  font-size: 18px;
}

.summary-title {
  margin-top: 21px;
  font-size: 20px;
}

.summary-message {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.summary-message:last-of-type {
  margin-bottom: 20px;
}

.success-body p {
  /* color: #10BC74; */
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.success-body p {
  font-size: 21px;
}

.error {
  color: #ce0606;
}

@media only screen and (max-width: 425px) {
  .ticker {
    font-size: 24px;
  }

  .input-line label {
    font-size: 18px;
  }

  .input-line select,
  .input-line input {
    font-size: 18px;
  }

  button {
    font-size: 16px;
  }
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 5px;
}

.input-range-icon {
  position: absolute;
  top: 1px;
  left: 3px;
  width: 12px;
  height: 12px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 20px;
  background: #04aa6d;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  background-position-y: center;
  background-position-x: center;
  background-image: url("../assets/pause.svg");
  background-size: 14px;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-image: url("../assets/settings.png");
  background-size: cover;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #04aa6d;
  cursor: pointer;
  border-radius: 50%;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.range-labels span {
  color: #333;
  font-size: 14px;
}

.upper {
  border-bottom: 0;
}

.selection-bar {
  padding: 8px 0px;
}

.ticker-price {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a6c6c;
}

.buy-form.compact {
  padding-top: 0px;
}
.estimate-division {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}
.aero {
  margin-top: 7px;
  margin-right: -20px;
}

.slider-container {
  position: relative;
}

.hi {
  position: absolute;
}
</style>