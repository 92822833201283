<template>
  <div class="buy-form" :class="{ compact: compactMode }">
    <div class="form-header">
      <div class="form-header-top-row">
        <p class="ticker" v-if="!submitted">{{ getTitle }}</p>
        <p class="short green clickable" v-if="!submitted && !isClosing && preview == null"
          @click.prevent="sellShort = !sellShort" href="#">{{
            sellShort ?
            "Buy Long" : "Sell Short"
          }}</p>
      </div>
    </div>
    <div class="form-body" v-if="!preview">
      <div class="input-line">
        <div class="input-row">
          <label for="quantity">Quantity</label>
          <input type="text" name="quantity" id="quantity" v-model="quantity" placeholder="0" />
        </div>
        <p class="error" v-if="errors.quantity">{{ errors.quantity }}</p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="type">Order Type</label>
          <select name="type" id="type" v-model="orderType">
            <option>Limit</option>
            <option>Market</option>
            <option>Bracket</option>
            <option v-if="!isClosing">Bracket %</option>
            <option>Stop Market</option>
            <option>Stop Limit</option>
            <option>Trailing Stop</option>
            <option>Trailing Stop %</option>
          </select>
        </div>
        <p class="description">
          {{ typeDescription[orderType] }}
        </p>
      </div>
      <div class="input-line limit" v-if="(['Limit', 'Bracket', 'Bracket %', 'Stop Limit'].indexOf(orderType) !=
        -1 && !isClosing) || ['Limit', 'Stop Limit'].indexOf(orderType) != -1
        ">
        <div class="input-row">
          <label for="limitPrice">Limit Price</label>
          <input type="text" name="limitPrice" id="limitPrice" v-model="limitPrice" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.limitPrice">{{ errors.limitPrice }}</p>
      </div>
      <div class="bid-ask">
        <p class="clickable" @click="() => {
          if (preview != null) {
            return;
          }
          if (orderType == 'Limit' || orderType == 'Stop Limit') {
            limitPrice = updatedTicker.Bid.toString();
          } else if (orderType == 'Bracket') {
            if (isClosing) {
              profitLimit = updatedTicker.Bid.toString();
            } else {
              limitPrice = updatedTicker.Bid.toString();
            }
          } else if (orderType == 'Stop Market') {
            stopPrice = updatedTicker.Bid.toString();
          }
        }">
          Bid: {{
            updatedTicker.Bid ? dollarWithCommas(updatedTicker.Bid) : "-"
          }} </p>

        <p class="clickable" @click="() => {
            if (preview != null) {
              return;
            }
            var midPrice = ((parseFloat(updatedTicker.Ask) +
              parseFloat(updatedTicker.Bid)) /
              2).toFixed(2);
            if (orderType == 'Limit' || orderType == 'Stop Limit') {
              limitPrice = midPrice;
            } else if (orderType == 'Bracket') {
              if (isClosing) {
                profitLimit = midPrice;
              } else {
                limitPrice = midPrice;
              }
            } else if (orderType == 'Stop Market') {
              stopPrice = midPrice;
            }
          }">
          Mid: ${{
            updatedTicker.Bid && updatedTicker.Ask
            ? dollarWithCommas(
              (parseFloat(updatedTicker.Ask) +
                parseFloat(updatedTicker.Bid)) /
              2
            )
            : "-"
          }}
        </p>
        <p class="clickable" @click="() => {
              if (preview != null) {
                return;
              }
              if (orderType == 'Limit' || orderType == 'Stop Limit') {
                limitPrice = updatedTicker.Ask.toString();
              } else if (orderType == 'Bracket') {
                if (isClosing) {
                  profitLimit = updatedTicker.Ask.toString();
                } else {
                  limitPrice = updatedTicker.Ask.toString();
                }
              } else if (orderType == 'Stop Market') {
                stopPrice = updatedTicker.Ask.toString();
              }
            }">
          Ask: ${{
            updatedTicker.Ask ? dollarWithCommas(updatedTicker.Ask) : "-"
          }}
        </p>
      </div>
      <div class="input-line" v-if="['Stop Market', 'Stop Limit'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopPrice">Stop Price</label>
          <input type="text" name="stopPrice" id="stopPrice" v-model="stopPrice" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.stopPrice">{{ errors.stopPrice }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="profitLimit">Profit Limit</label>
          <input type="text" name="profitLimit" id="profitLimit" v-model="profitLimit" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.profitLimit">{{ errors.profitLimit }}</p>
      </div>
      <div class="input-line" v-if="['Bracket %'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="profitLimit">Profit Limit %</label>
          <input type="text" name="profitLimit" id="profitLimit" v-model="profitLimit" placeholder="0" />
        </div>
        <p class="error" v-if="errors.profitLimit">{{ errors.profitLimit }}</p>
      </div>
      <div class="input-line" v-if="['Bracket'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopLoss">Stop Loss</label>
          <input type="text" name="stopLoss" id="stopLoss" v-model="stopLoss" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.stopLoss">{{ errors.stopLoss }}</p>
      </div>
      <div class="input-line" v-if="['Bracket %'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="stopLoss">Stop Loss %</label>
          <input type="text" name="stopLoss" id="stopLoss" v-model="stopLoss" placeholder="0" />
        </div>
        <p class="error" v-if="errors.stopLoss">{{ errors.stopLoss }}</p>
      </div>
      <div class="input-line" v-if="['Trailing Stop'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="trailAmount">Trail Amount</label>
          <input type="text" name="trailAmount" id="trailAmount" v-model="trailAmount" placeholder="0.00" />
        </div>
        <p class="error" v-if="errors.trailAmount">{{ errors.trailAmount }}</p>
      </div>
      <div class="input-line" v-if="['Trailing Stop %'].indexOf(orderType) != -1">
        <div class="input-row">
          <label for="trailPercent">Trailing Stop %</label>
          <input type="text" name="trailPercent" id="trailPercent" v-model="trailPercent" placeholder="0" />
        </div>
        <p class="error" v-if="errors.trailPercent">
          {{ errors.trailPercent }}
        </p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="duration">Duration</label>
          <select name="duration" id="duration" v-model="duration">
            <option>Day</option>
            <option>Extended</option>
            <option>Until Cancel</option>
            <option>All Hours</option>
            <option>Immediate</option>
            <option>Fill or Kill</option>
          </select>
        </div>
        <p class="description">
          {{ durationDescription[duration] }}
        </p>
      </div>
      <div class="input-line">
        <div class="input-row">
          <label for="execRoute">Route</label>
          <select name="execRoute" id="execRoute" v-model="orderRoute.Id">
            <option v-for="route in filteredExecRoutes" :key="route.Id" :value="route.Id">
              {{ route.Name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="isClosing">
      <KeyValue key-string="Buying Power" :value="'$' + dollarWithCommas(account.BuyingPower)" />
        <KeyValue  key-string="P/L All" :value="'$' + dollarWithCommas(position?.UnrealizedProfitLoss)" />
      <KeyValue last key-string="P/L Today" :value="'$' + dollarWithCommas(position?.TodaysProfitLoss)" />
      </div>
      <div v-else>
        <KeyValue last key-string="Buying Power" :value="'$' + dollarWithCommas(account.BuyingPower)" />
      </div>
    </div>
    <div class="preview-body" v-if="preview && !submitted">
      <KeyValue key-string="Account" :value="preview[0].AccountID" />
      <KeyValue key-string="Estimated Commission" :value="preview[0].EstimatedCommission" />
      <KeyValue key-string="Estimated Cost" :value="preview[0].EstimatedCost" />
      <KeyValue key-string="Duration" :value="preview[0].TimeInForce.Duration" />
      <p class="summary-title">Summary:</p>
      <p class="summary-message" v-for="(msg, index) in preview" :key="`msg${index}`">
        {{ msg.SummaryMessage }}
      </p>
    </div>
    <div class="success-body" v-if="submitted">
      <p>{{ submitted.Message }}</p>
    </div>
    <ShareLink :link="shareLink" />
    <p class="error" v-if="errors.global">{{ errors.global }}</p>
    <div class="buttons">
      <button class="secondary" v-if="!preview" @click="close">Cancel</button>
      <button class="secondary" v-if="preview && !submitted" @click="preview = null">
        Back
      </button>
      <button class="secondary" v-if="!loading && submitted" @click="chart">
        Chart
      </button>

      <button class="blank" v-if="loading">
        <img class="loading" src="../assets/loading.gif" />
      </button>
      <button v-if="!loading && !preview" @click="reviewOrder">Review</button>
      <button v-if="!loading && !submitted && preview" @click="submitOrder">
        Submit
      </button>
      <button v-if="!loading && submitted" @click="goHome">Home</button>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import KeyValue from "./KeyValue.vue";
import ShareLink from "./ShareLink.vue";
import Toast from "./Toasts.vue";
export default {
  name: "BuyPosition",
  inject: {
    http: { from: "http" },
    stream: { from: "stream" },
  },
  mixins: [Mixins],
  props: {
    account: Object,
    ticker: String,
    existingQuantity: {
      type: String,
      default: null,
    },
    startShort: {
      type: Boolean,
      default: false
    },
    startPrice: {
      type: String,
      default: null
    },
    averageCost: String,
    sound: Boolean,
    isClosing: {
      type: Boolean,
      default: false,
    },
    compactMode: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Number,
      default: null
    },
    orderExecRoutes: Array,
    position: Object,
    orders: Array
  },
  components: {
    KeyValue,
    ShareLink,
  },
  data() {
    return {
      quantity: this.existingQuantity ? this.existingQuantity.replace('-', '') : "1",
      preview: null,
      submitted: null,
      loading: true,
      priceIncrement: {
        style: null,
        increment: null,
        schedule: [],
      },
      updatedTicker: { Symbol: this.ticker },
      quoteStream: {
        connection: null,
        leftOver: null,
        data: null,
      },
      orderType: this.startPrice == "market" ? "Market" : "Limit",
      limitPrice: this.startPrice && this.startPrice != "market" ? this.startPrice : "",
      stopPrice: "",
      profitLimit: "",
      stopLoss: "",
      trailAmount: "",
      trailPercent: "",
      duration: "Until Cancel",
      typeDescription: {
        Limit: "Order only if price is equal to or better than my limit",
        Market: "Order as soon as possible, regardless of the price",
        Bracket:
          "Place a limit order and when it fills create two closing orders. One to limit my loss, the other to automatically take profit",
        "Bracket %":
          "Place a limit order and when it fills create two closing orders. One to limit my loss, the other to automatically take profit",
        "Stop Market":
          "Order as soon as possible once the price is equal to or worse than my stop price",
        "Stop Limit":
          "Order once the price is equal to or worse than my stop price, but only if the price is equal to or better than my limit",
        "Trailing Stop":
          "Create a stop market order with trail amount difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
        "Trailing Stop %":
          "Create a stop market order with trail % difference, then follow that price when it moves in my direction, automatically filling when the direction reverses and breaks through the trailed stop",
      },
      durationDescription: {
        "Until Cancel":
          "Valid until I cancel, trades during regular market hours",
        Day: "Valid until the end of the regular trading session",
        Extended:
          "Valid until during one extended trading session (pre or post market)",
        "All Hours":
          "Valid until I cancel, trades during regular market hours and extended trading hours",
        Immediate:
          "Only try to fill immediately, accept partial fills, then cancel",
        "Fill or Kill": "Only fill this order completely, reject partial fills",
      },
      errors: {},
      queryTypeMap: [
        "Limit", //0
        "Market", //1
        "Bracket", //2
        "Stop Market", //3
        "Stop Limit", //4
        "Trailing Stop", //5
        "Trailing Stop %", //6
        "Bracket %", //7
      ],
      queryDurationMap: [
        "Until Cancel", //0
        "Day", //1
        "Extended", //2
        "All Hours", //3
        "Immediate", //4
        "Fill or Kill", //5
      ],
      sellShort: this.startShort ? this.startShort : this.existingQuantity ? this.existingQuantity.indexOf("-") != -1 : false,
      orderRoute: {
        Id: "Intelligent",
        Name: "Intelligent",
      },
      sumTodaysProfitLoss:'-',
      sumUnrealizedProfitLoss:'-'
    };
  },
  watch: {
    refresh(ping) {
      if (ping == 0) {
        return;
      } else {
        this.limitPrice = this.startPrice ? this.startPrice : "";
        this.sellShort = this.existingQuantity ? this.existingQuantity.indexOf("-") != -1 : this.startShort;
        this.quantity = this.existingQuantity ? this.existingQuantity.replace('-', '') : "1";
        this.preview = null;
        this.submitted = null;
      }
    },
  },
  computed: {
    getTitle() {
      if (this.isClosing) {
        if (this.sellShort) {
          return `Cover ${this.ticker}`;
        } else {
          return `Sell ${this.ticker}`;
        }
      } else {
        if (this.sellShort) {
          return `Short ${this.ticker}`;
        } else {
          return `Buy ${this.ticker}`;
        }
      }
    },
    shareLink() {
      var returnUrl = location.origin;
      if (this.isClosing) {
        returnUrl += `/close/${this.ticker}`;
      } else {
        returnUrl += `/order/${this.ticker}`;
      }

      if (this.orderType == "Limit") {
        returnUrl += "?t=0";
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
      } else if (this.orderType == "Market") {
        returnUrl += "?t=1";
      } else if (this.orderType == "Bracket") {
        returnUrl += "?t=2";
        if (this.limitPrice.length > 0 && !this.isClosing) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
        if (this.profitLimit.length > 0) {
          returnUrl += `&pl=${encodeURIComponent(this.profitLimit)}`;
        }
        if (this.stopLoss.length > 0) {
          returnUrl += `&sl=${encodeURIComponent(this.stopLoss)}`;
        }
      } else if (this.orderType == "Bracket %") {
        returnUrl += "?t=7";
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
        if (this.profitLimit.length > 0) {
          returnUrl += `&pl=${encodeURIComponent(this.profitLimit)}`;
        }
        if (this.stopLoss.length > 0) {
          returnUrl += `&sl=${encodeURIComponent(this.stopLoss)}`;
        }
      } else if (this.orderType == "Stop Market") {
        returnUrl += "?t=3";
        if (this.stopPrice.length > 0) {
          returnUrl += `&s=${encodeURIComponent(this.stopPrice)}`;
        }
      } else if (this.orderType == "Stop Limit") {
        returnUrl += "?t=4";
        if (this.stopPrice.length > 0) {
          returnUrl += `&s=${encodeURIComponent(this.stopPrice)}`;
        }
        if (this.limitPrice.length > 0) {
          returnUrl += `&l=${encodeURIComponent(this.limitPrice)}`;
        }
      } else if (this.orderType == "Trailing Stop") {
        returnUrl += "?t=5";
        if (this.trailAmount.length > 0) {
          returnUrl += `&ta=${encodeURIComponent(this.trailAmount)}`;
        }
      } else if (this.orderType == "Trailing Stop %") {
        returnUrl += "?t=6";
        if (this.trailPercent.length > 0) {
          returnUrl += `&tp=${encodeURIComponent(this.trailPercent)}`;
        }
      }

      if (this.sellShort) {
        returnUrl += "&sh=true";
      }

      returnUrl += `&d=${this.queryDurationMap.indexOf(this.duration)}`;
      return returnUrl;
    },
    filteredExecRoutes() {
      return [
        { Id: "Intelligent", Name: "Intelligent" },
        ...this.orderExecRoutes.filter((route) =>
          route.AssetTypes.includes(this.updatedTicker.AssetType)
        ),
      ];
    },
  },
  beforeUnmount() {
    if (this.quoteStream.connection) {
      this.quoteStream.connection.end();
      this.quoteStream.connection.destroy();
    }
  },
  mounted() {
    if (this.$route.path.indexOf("/order/") != -1 || this.$route.path.indexOf("/close/") != -1) {
      if (this.$route.path.indexOf("/close/") != -1) {
        this.isClosing = true;
      }
      if (this.$route.query.t && parseInt(this.$route.query.t) < 8) {
        this.orderType = this.queryTypeMap[parseInt(this.$route.query.t)];
      }
      if (this.$route.query.d && parseInt(this.$route.query.d) < 6) {
        this.duration = this.queryDurationMap[parseInt(this.$route.query.d)];
      }
      if (this.$route.query.l) {
        this.limitPrice = this.$route.query.l;
      }
      if (this.$route.query.pl) {
        this.profitLimit = this.$route.query.pl;
      }
      if (this.$route.query.sl) {
        this.stopLoss = this.$route.query.sl;
      }
      if (this.$route.query.s) {
        this.stopPrice = this.$route.query.s;
      }
      if (this.$route.query.ta) {
        this.trailAmount = this.$route.query.ta;
      }
      if (this.$route.query.tp) {
        this.trailPercent = this.$route.query.tp;
      }
      if (this.$route.query.sh == "true") {
        this.sellShort = true;
      }
    }
    this.fetchSymbolDescription();
    this.streamData();
  },
  methods: {
    fetchSymbolDescription() {
      var _this = this;

      this.http
        .get(
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM
            : process.env.VUE_APP_TS) + `marketdata/symbols/${_this.ticker}`,
          { headers: { Authorization: `Bearer ${localStorage.accessToken}` } }
        )
        .then(function (res) {
          if (res.data.Symbols.length > 0) {
            _this.updatedTicker.AssetType = res.data.Symbols[0].AssetType;
            if (
              res.data.Symbols[0].PriceFormat &&
              res.data.Symbols[0].PriceFormat.IncrementStyle
            ) {
              _this.priceIncrement.style =
                res.data.Symbols[0].PriceFormat.IncrementStyle;
              if (_this.priceIncrement.style == "Simple") {
                _this.priceIncrement.increment =
                  res.data.Symbols[0].PriceFormat.Increment;
              } else if (_this.priceIncrement.style == "Schedule") {
                _this.priceIncrement.schedule =
                  res.data.Symbols[0].PriceFormat.IncrementSchedule;
              }
            }
          }
          _this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
        });
    },
    streamData() {
      var _this = this;
      if (this.quoteStream.connection) {
        this.quoteStream.connection.end();
        this.quoteStream.connection.destroy();
      }
      this.quoteStream = {
        connection: null,
        leftOver: null,
        data: null,
      };
      var quoteOptions = {
        hostname:
          localStorage.sim == "true"
            ? "sim-api.tradestation.com"
            : "api.tradestation.com",
        path: `/v3/marketdata/stream/quotes/${this.ticker}`,
        headers: { Authorization: `Bearer ${localStorage.accessToken}` },
      };
      this.quoteStream.connection = this.stream
        .get(quoteOptions, async (tsRes) => {
          tsRes.setEncoding("binary");
          await tsRes.on("data", (chunk) => {
            try {
              if (!_this.quoteStream || !this.quoteStream.connection) {
                tsRes.destroy();
                return;
              }
              // chunk = '{"newData":['+chunk+"]}";
              chunk = chunk.replace(/END/g, "");
              chunk = chunk.replace(/\r/g, "");
              if (_this.quoteStream.leftOver != null) {
                chunk = _this.quoteStream.leftOver + chunk;
                _this.quoteStream.leftOver = null;
              }
              var quotes = chunk.split("\n");
              for (var a = 0; a < quotes.length; a++) {
                if (quotes[a].charAt(quotes[a].length - 1) == "}") {
                  var snapShot = JSON.parse(quotes[a]);
                  for (const [key, value] of Object.entries(snapShot)) {
                    _this.updatedTicker[key] = value;
                  }
                } else {
                  _this.quoteStream.leftOver = quotes[a];
                }
              }
            } catch (e) {
              console.log(e);
            }
          });
        })
        .on("error", function (err) {
          console.log(err);
        });
    },
    checkIncrement(price) {
      if (this.priceIncrement.style == null) {
        return false;
      }
      var incrementStyle = this.priceIncrement.style;
      if (incrementStyle == "Simple") {
        var simpleIncrement =
          this.priceIncrement.increment;
        var incrementMultiply = 1 
        if (simpleIncrement.indexOf(".") != -1) {
          incrementMultiply = 1 *
          Math.pow(10, simpleIncrement.split('.')[1].length);
        } 
        if (Math.round(price * incrementMultiply) %
          Math.round(simpleIncrement * incrementMultiply) !=
          0) {
          return `At the current market price this ticker only accepts price increments of ${simpleIncrement}`;
        }
      } else if (incrementStyle == "Schedule") {
        var targetIncrement = null;
        var incrementMultiplySchedule = null;
        for (var i = 0; i < this.priceIncrement.schedule.length; i++) {
          if (parseFloat(price) > parseFloat(this.priceIncrement.schedule[i]["StartsAt"])) {
            targetIncrement = this.priceIncrement.schedule[i]["Increment"];
            incrementMultiplySchedule =
              1 * Math.pow(10, this.priceIncrement.schedule[i]["Increment"].split('.')[1].length);
          }
        }

        if (Math.round(price * incrementMultiplySchedule) %
          Math.round(targetIncrement * incrementMultiplySchedule) !=
          0) {
          return `At the current market price this ticker only accepts price increments of ${targetIncrement}`;
        }
      }
      return false;
    },
    reviewOrder() {
      this.errors = {};
      this.loading = true;
      if (
        this.quantity == 0 ||
        this.quantity.length == 0 ||
        isNaN(this.quantity)
      ) {
        this.errors = {
          quantity: "Quantity must be a valid number that is not zero",
        };
        this.loading = false;
        return;
      }
      var tradeAction = this.isClosing ? "SELL" : "BUY";
      var sendQuantity = String(this.quantity);
      if (this.sellShort) {
        tradeAction = this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
      }
      if (this.orderType == "Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var limitIncrement = this.checkIncrement(this.limitPrice);
        if (limitIncrement) {
          this.errors = {
            limitPrice: limitIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket") {
        if (
          (this.limitPrice == 0 ||
            this.limitPrice.length == 0 ||
            isNaN(this.limitPrice)) && !this.isClosing
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.profitLimit == 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit: "Profit Limit must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss == 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss: "Stop Loss must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var bracketLimitIncrement = !this.isClosing ? this.checkIncrement(this.limitPrice) : null;
        var bracketProfitIncrement = this.checkIncrement(this.profitLimit);
        var bracketStopIncrement = this.checkIncrement(this.stopLoss);
        if (
          bracketLimitIncrement ||
          bracketProfitIncrement ||
          bracketStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketLimitIncrement,
            profitLimit: bracketProfitIncrement,
            stopLoss: bracketStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Bracket %") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        this.profitLimit = this.profitLimit.replace("%", "");
        this.stopLoss = this.stopLoss.replace("%", "");
        if (
          this.profitLimit <= 0 ||
          this.profitLimit.length == 0 ||
          isNaN(this.profitLimit)
        ) {
          this.errors = {
            profitLimit:
              "Profit Limit must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopLoss <= 0 ||
          this.stopLoss.length == 0 ||
          isNaN(this.stopLoss)
        ) {
          this.errors = {
            stopLoss:
              "Stop Loss must be a valid number that is greater than zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Market") {
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopMarketIncrement = this.checkIncrement(this.stopPrice);
        if (stopMarketIncrement) {
          this.errors = {
            stopPrice: stopMarketIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Stop Limit") {
        if (
          this.limitPrice == 0 ||
          this.limitPrice.length == 0 ||
          isNaN(this.limitPrice)
        ) {
          this.errors = {
            limitPrice: "Limit Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        if (
          this.stopPrice == 0 ||
          this.stopPrice.length == 0 ||
          isNaN(this.stopPrice)
        ) {
          this.errors = {
            stopPrice: "Stop Price must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
        var stopLimitLimitIncrement = this.checkIncrement(this.limitPrice);
        if (stopLimitLimitIncrement) {
          this.errors = {
            limitPrice: stopLimitLimitIncrement,
          };
          this.loading = false;
          return;
        }
        var stopLimitStopIncrement = this.checkIncrement(this.stopPrice);
        if (stopLimitStopIncrement) {
          this.errors = {
            stopPrice: stopLimitStopIncrement,
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop") {
        if (
          this.trailAmount == 0 ||
          this.trailAmount.length == 0 ||
          isNaN(this.trailAmount)
        ) {
          this.errors = {
            trailAmount: "Trail Amount must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      } else if (this.orderType == "Trailing Stop %") {
        if (
          this.trailPercent == 0 ||
          this.trailPercent.length == 0 ||
          isNaN(this.trailPercent)
        ) {
          this.errors = {
            trailPercent:
              "Trailing Stop % must be a valid number that is not zero",
          };
          this.loading = false;
          return;
        }
      }
      if (
        (this.updatedTicker.AssetType == "EQ" ||
          this.updatedTicker.AssetType == "STOCK") &&
        sendQuantity > 10000
      ) {
        this.errors.global =
          "Commission-free equity orders must be maximum 10,000 quantity. Please split your order.";
        this.loading = false;
        return;
      }
      if (
        (this.orderType == "Bracket" ||
          this.orderType == "Bracket %" ||
          this.orderType == "Stop Market" ||
          this.orderType == "Trailing Stop" ||
          this.orderType == "Trailing Stop %" ||
          this.orderType == "Market") &&
        this.duration == "Extended"
      ) {
        this.errors.global =
          "Extended hours orders must be limit orders. Please change order type or duration.";
        this.loading = false;
        return;
      }
      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        try {
          calculatedProfit =
            (parseFloat(this.limitPrice) *
              (100 + parseFloat(this.profitLimit))) /
            100;
          calculatedProfit = calculatedProfit.toFixed(2);
        } catch (e) {
          this.errors.profitLimit = "Can not calculate profit limit";
          return;
        }
        try {
          calculatedLoss =
            (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
            100;
          calculatedLoss = calculatedLoss.toFixed(2);
        } catch (e) {
          this.errors.stopLoss = "Can not calculate profit limit";
          return;
        }
        var bracketPercentLimitIncrement = this.checkIncrement(this.limitPrice);
        var bracketPercentProfitIncrement =
          this.checkIncrement(calculatedProfit);
        var bracketPercentStopIncrement = this.checkIncrement(calculatedLoss);
        if (
          bracketPercentLimitIncrement ||
          bracketPercentProfitIncrement ||
          bracketPercentStopIncrement
        ) {
          this.errors = {
            limitPrice: bracketPercentLimitIncrement,
            profitLimit: bracketPercentProfitIncrement,
            stopLoss: bracketPercentStopIncrement,
          };
          if (!bracketPercentLimitIncrement) {
            this.orderType = "Bracket";
            this.profitLimit = calculatedProfit;
            this.stopLoss = calculatedLoss;
          }
          this.loading = false;
          return;
        }
      }
      var _this = this;
      this.postOrder({
        final: false,
        symbol: this.ticker,
        quantity: sendQuantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType == "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType == "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderType: this.orderType,
        orderDuration: this.duration,
        legs: null,
        close: this.isClosing,
        tradeAction: tradeAction,
        assetType: this.updatedTicker.AssetType,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
      })
        .then(function (res) {
          _this.loading = false;
          _this.preview = res.data.Confirmations;
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            Toast.error(_this.errors.global);
          }
        });
    },
    postDataOrder(_this) {
      this.errors = {};
      this.loading = true;
      var tradeAction = this.isClosing ? "SELL" : "BUY";
      var sendQuantity = String(this.quantity);
      if (this.sellShort) {
        tradeAction = this.isClosing ? "BUYTOCOVER" : "SELLSHORT";
      }
      var calculatedProfit = 0;
      var calculatedLoss = 0;
      if (this.orderType == "Bracket %") {
        calculatedProfit =
          (parseFloat(this.limitPrice) * (100 + parseFloat(this.profitLimit))) /
          100;
        calculatedProfit = calculatedProfit.toFixed(2);
        calculatedLoss =
          (parseFloat(this.limitPrice) * (100 - parseFloat(this.stopLoss))) /
          100;
        calculatedLoss = calculatedLoss.toFixed(2);
      }

      this.postOrder({
        final: true,
        symbol: this.ticker,
        quantity: sendQuantity,
        limitPrice: this.limitPrice,
        bracketProfit:
          this.orderType == "Bracket %" ? calculatedProfit : this.profitLimit,
        bracketLoss:
          this.orderType == "Bracket %" ? calculatedLoss : this.stopLoss,
        stopPrice: this.stopPrice,
        trailAmount: this.trailAmount,
        trailPercent: this.trailPercent,
        orderType: this.orderType,
        orderDuration: this.duration,
        legs: null,
        close: this.isClosing ? true : false,
        tradeAction: tradeAction,
        assetType: this.updatedTicker.AssetType,
        accountID: this.account.AccountID,
        orderRoute: this.orderRoute.Id,
      })
        .then(function (res) {
          _this.loading = false;
          if (res.data.Errors) {
            _this.errors.global = res.data.Errors[0].Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: _this.isClosing ? "order_closing" : "order_new",
              res_data: JSON.stringify(res.data),
            });
          } else {
            res.data.Orders.forEach((order) => {
              if (order["Error"] == null) {
                _this.$gtag.event(_this.isClosing ? "order_closing" : "order_new", { legs: _this.ticker });
              } else {
                _this.$gtag.event("web_error", {
                  action: _this.isClosing ? "order_closing" : "order_new",
                  res_data: order["Error"],
                });
              }
            });
            if (res.data.Orders[0].Error) {
              if(!_this.sellShort && _this.isClosing && (res.data.Orders[0].Message.includes("EC703")|| res.data.Orders[0].Message.includes("EC704"))) {
                _this.deleteAndRepost(_this);
              } else {
               _this.submitted = res.data.Orders[0];
                Toast.error(_this.submitted.Message);
                _this.playSound(
                  "../../order-rejected-cancelled.mp3",
                  _this.sound
                );
              }           
            } else {
              _this.submitted = res.data.Orders[0];
              Toast.success(_this.submitted.Message);
              _this.playSound("../../order-placed-updated.mp3", _this.sound);
              if (_this.isClosing) {
                var orderList = res.data.Orders;
                var sendOrders = [];
                orderList.forEach((order) => {
                  if (order.Error == null) {
                    sendOrders.push(order.OrderID);
                  }
                });
                if (sendOrders.length > 0) {
                  var requestBody = {
                    session: localStorage.session,
                    token: localStorage.sessionKey,
                    trades: sendOrders,
                    averageCost: _this.averageCost,
                  };
                  _this.tagPLstart(requestBody).then((res) => {
                    if (res.status == 200) {
                      var newProfit = res.data.profits ?? [];
                      _this.$emit("new-log", newProfit);
                      _this.$emit("close");
                    } else {
                      _this.$emit("close");
                    }
                  });
                }
              } else {
                _this.$emit("close");
              }

            }
          }
          _this.$emit("update-cv");
        })
        .catch((error) => {
          console.log(error);
          _this.loading = false;
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.Message
          ) {
            _this.errors.global = error.response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: _this.isClosing ? "order_closing" : "order_new",
              res_data: JSON.stringify(error.response.data),
            });
          } else {
            _this.errors.global =
              "Something went wrong... Please try again later";
            _this.playSound("../../order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
              action: _this.isClosing ? "order_closing" : "order_new",
              internal_error: JSON.stringify(error),
            });
          }
        });
    },
    async deleteAndRepost(_this) {
      try {
        const validStatuses = ["ACK", "FPR", "OPN", "DON", "UCN"];
        let orderIds =[]
    
        orderIds = _this.orders
        .filter(element =>
            element.Legs[0].Symbol === _this.updatedTicker.Symbol &&
            element.Legs[0].BuyOrSell === 'Sell' &&
            validStatuses.includes(element.Status))
        .map(e => e.OrderID);

      const deletePromises = orderIds.map(orderId => _this.deleteOrder(_this, orderId));
      await Promise.all(deletePromises);
        await _this.postDataOrder(_this)
      } catch (e) {
        console.log(e)
      }
    },
    async deleteOrder(_this,orderId) {
    _this.loading = true;
    console.log('delete order:', orderId);
    const url = `${localStorage.sim === "true" ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS}orderexecution/orders/${orderId}`;
    const headers = { Authorization: `Bearer ${localStorage.accessToken}` };

    try {
        const response = await _this.http.delete(url, { headers });

        _this.loading = false;
        if (response.status === 200) {
            _this.$emit("close"); 

            let legSymbols = "";
            _this.order?.Legs.forEach((leg) => {
                legSymbols += `${leg.Symbol},`;
            });
            _this.$gtag.event("order_delete", { legs: legSymbols });

        } else if (response.data.Message) {
            _this.errors.global = response.data.Message;
            Toast.error(_this.errors.global);
            _this.playSound("order-rejected-cancelled.mp3", _this.sound);
            _this.$gtag.event("web_error", {
                action: "order_delete",
                res_data: JSON.stringify(response.data),
            });
        } else {
            throw new Error("Something went wrong... Please try again later");
        }
    } catch (error) {
        _this.loading = false;
        const errorMessage = error.response && error.response.data && error.response.data.Message
            ? error.response.data.Message
            : "Something went wrong... Please try again later";
        _this.errors.global = errorMessage;
        Toast.error(_this.errors.global);
        _this.playSound("order-rejected-cancelled.mp3", _this.sound);
        _this.$gtag.event("web_error", {
            action: "order_delete",
            res_data: JSON.stringify(error.response ? error.response.data : error),
        });
    }

    _this.$emit("update-cv");
},
    submitOrder() {
      this.errors = {};
      this.loading = true;
 
     this.postDataOrder(this);
    },
    close() {
      this.$emit("close");
    },
    chart() {
      this.loading = true;
      this.$emit("chart", this.ticker);
    },
    goHome() {
      this.$emit("home");
    },
  },
};
</script>

<style scoped>
.buy-form {
  padding: 10px;
}

.compact.buy-form {
  padding: 20px;
}

p {
  margin: 0;
  font-family: "Roboto";
}

.share {
  padding-right: 0;
}

.form-header {
  text-align: left;
}

.form-header-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ticker {
  font-size: 36px;
  font-weight: 500;
}

.short {
  font-size: 21px;
  text-decoration: none;
  font-weight: 600;
}

.compact .ticker {
  font-size: 21px;
}

.compact .short {
  font-size: 18px;
}

.bid-ask {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eef0f3;
}

.dark .bid-ask {
  border-bottom: 1px solid #333333;
}

.limit~.bid-ask {
  margin-top: -15px;
}


.bid-ask p {
  width: 33%;
  text-align: center;
}

.bid-ask p:first-of-type {
  text-align: left;
}

.bid-ask p:last-of-type {
  text-align: right;
}

.compact .bid-ask {
  font-size: 14px;
}

.input-line {
  margin: auto;
  padding: 20px 0;
  border-bottom: 1px solid #eef0f3;
}

.dark .input-line {
  border-bottom: 1px solid #333333;
}

.input-line.limit {
  border-bottom: none;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.input-line:last-of-type {
  border-bottom: none;
}

.input-line label {
  font-weight: 600;
  font-size: 21px;
}

.compact .input-line label {
  font-size: 18px;
}

.input-line select,
.input-line input {
  background: none;
  border: none;
  font-size: 21px;
  color: #10bc74;
  text-align: right;
  font-family: "Roboto";
  font-weight: 600;
  max-width: 200px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.compact .input-line select,
.compact .input-line input {
  font-size: 18px;
}

.input-line select:focus,
.input-line input:focus {
  outline: none;
}

.input-line option {
  color: #10bc74;
  background: none;
}

.input-line .description {
  color: #616164;
  margin-top: 5px;
  font-size: 16px;
  text-align: left;
}

.compact .input-line .description {
  font-size: 14px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  width: 130px;
  padding: 10px 0;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  background: #10bc74;
  cursor: pointer;
}

.compact button {
  font-size: 16px;
}

.dark button {
  background: #000000;
  border: 1px solid #10bc74;
  color: #10bc74;
}

button.secondary {
  color: #000000;
  background: #f5f7fa;
  margin-right: 15px;
}

.dark button.secondary {
  background: #000000;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
}

button.blank {
  background: #ffffff;
}

.dark button.blank {
  background: #000000;
}

.loading {
  width: 18px;
  height: 18px;
}

.preview-body {
  text-align: left;
}

.summary-title {
  margin-top: 21px;
  font-size: 20px;
}

.compact .summary-title {
  font-size: 18px;
}

.summary-message {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.compact .summary-message {
  font-size: 16px;
}

.summary-message:last-of-type {
  margin-bottom: 20px;
}

.success-body p {
  /* color: #10BC74; */
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.compact .success-body p {
  font-size: 21px;
}

.error {
  color: #ce0606;
}

@media only screen and (max-width: 425px) {
  .ticker {
    font-size: 24px;
  }

  .input-line label {
    font-size: 18px;
  }

  .input-line select,
  .input-line input {
    font-size: 18px;
  }

  .input-line .description {
    font-size: 14px;
  }

  button {
    font-size: 16px;
  }
}
</style>
