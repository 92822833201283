<template>
    <div v-if="data.length == 0">
        Loading...
    </div>
    <GChart v-else :type="type" :data="data" :options="options" />
</template>

<script>
import { GChart } from "vue3-googl-chart";

export default {
    name: 'SparkChart',

    inject: {
        http: { from: "http" },
        stream: { from: "stream" },
    },

    components: {
        GChart,
    },

    props: {
        symbolName: String,
        dark: Boolean,
    },
    data() {
        return {
            type: 'LineChart',
            data: [],
            options: this.getChartOptions(this.dark),
        };
    },
    watch:{
        symbolName: function(newVal) {
            if(newVal) {
                this.fetchChartData(newVal);
            }
        },
        dark: function(newVal) {
            this.options = this.getChartOptions(newVal);
            if (newVal && this.symbolName) {
                this.fetchChartData(this.symbolName);
            }
        }
    },
    methods: {
        getChartOptions(isDark) {
            return {
                responsive: true,
                title: "",
                lineTension: 1,
                curveType: "function",
                legend: { position: "top" },
                hAxis: {
                    textPosition: "none",
                    gridlines: { color: "transparent" },
                    baselineColor: "transparent",
                },
                vAxis: {
                    textPosition: "none",
                    gridlines: { color: "transparent" },
                    baselineColor: "transparent",
                },
                width: 70,
                height: 66,
                chartArea: { width: "100%", height: "100%" },
                colors: isDark ? ["#FFFFFF"] : ["#6A6C6C"],
                backgroundColor: isDark ? "#1f2324" : "#FFFFFF",
                tooltip: {theme: isDark?'dark' : 'light'},
                pointSize: 0,
                lineWidth: 1,
                focusTarget: "none",
            };
        },
        async fetchChartData(symbol) {
            const timeNow = new Date();
            let cutOff = new Date(Date.UTC(timeNow.getUTCFullYear(), timeNow.getUTCMonth(), timeNow.getUTCDate(), 5, 0, 0)); // 1am summer, midnight winter

            if (timeNow.getUTCHours() < 13) { // 13 = 9am summer, 8am winter
                cutOff.setUTCDate(cutOff.getUTCDate() - 1);
            }

            // Adjust for weekends
            if (cutOff.getUTCDay() === 0) { // Sunday
                cutOff.setUTCDate(cutOff.getUTCDate() - 2);
            } else if (cutOff.getUTCDay() === 6) { // Saturday
                cutOff.setUTCDate(cutOff.getUTCDate() - 1);
            }
            const isoString = cutOff.toISOString();
            let formattedDate = isoString.slice(0, isoString.length - 5) + "Z";
            try {
                const url = `${localStorage.sim === "true" ? process.env.VUE_APP_TS_SIM : process.env.VUE_APP_TS}marketdata/barcharts/${symbol}?unit=Minute&interval=5&sessiontemplate=USEQPreAndPost&firstdate=${encodeURIComponent(formattedDate)}`;
                const response = await this.http.get(url, {
                    headers: { Authorization: `Bearer ${localStorage.accessToken}` }
                });

                const barsHistory = response.data.Bars;
                const chartData = [['Time', 'Close']];
                let gotFirst = false;
                let maxValue = -Infinity;
                let minValue = Infinity;
                let horizontalLine = null;

                barsHistory.forEach(bar => {
                    const barDate = new Date(bar.TimeStamp);
                    if (barDate >= cutOff) {
                        const closeValue = parseFloat(bar.Close);
                        if (closeValue > 0) {
                            chartData.push([barDate, closeValue]);

                            if (!gotFirst) {
                                horizontalLine = closeValue;
                                gotFirst = true;
                            }
                            if (closeValue > maxValue) {
                                maxValue = closeValue;
                            }
                            if (closeValue < minValue) {
                                minValue = closeValue;
                            }
                        }
                    }
                });

                if (symbol.previousClose) {
                    horizontalLine = parseFloat(symbol.previousClose);
                    if (horizontalLine > maxValue) {
                        maxValue = horizontalLine;
                    }
                    if (horizontalLine < minValue) {
                        minValue = horizontalLine;
                    }
                }
                if (chartData.length === 1) {
                    const now = new Date();
                    const fallbackValue = 100; // Set your desired fallback value here
                    chartData.push([now, fallbackValue], [new Date(now.getTime() + 1000), fallbackValue]);
                }

                this.data = chartData;

            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        }
    }
};
</script>