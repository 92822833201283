<template>
    <div class="permission-popup">
        <div class="popup-content">
            <div class="popup-header">
                <h2>Header Filter</h2>
                <div class="close-icon" @click="this.close()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="#909194" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </div>
            </div>
            <div v-for="(title, index) in permissions" :key="index" class="permission-item"
                :draggable="title.text !== 'Symbol'" @dragstart="dragStart(index)" @dragover.prevent
                @drop="drop(index)">
                <span style="cursor: pointer;">
                    <svg v-if="title.text !== 'Symbol'" viewBox="0 0 24 24" width="24" height="24"
                        xmlns="http://www.w3.org/2000/svg" :stroke="dark ? '#000000' : '#c7c7c7'">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M8.5 7C9.60457 7 10.5 6.10457 10.5 5C10.5 3.89543 9.60457 3 8.5 3C7.39543 3 6.5 3.89543 6.5 5C6.5 6.10457 7.39543 7 8.5 7Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                            <path
                                d="M15.5 7C16.6046 7 17.5 6.10457 17.5 5C17.5 3.89543 16.6046 3 15.5 3C14.3954 3 13.5 3.89543 13.5 5C13.5 6.10457 14.3954 7 15.5 7Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                            <path
                                d="M10.5 12C10.5 13.1046 9.60457 14 8.5 14C7.39543 14 6.5 13.1046 6.5 12C6.5 10.8954 7.39543 10 8.5 10C9.60457 10 10.5 10.8954 10.5 12Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                            <path
                                d="M15.5 14C16.6046 14 17.5 13.1046 17.5 12C17.5 10.8954 16.6046 10 15.5 10C14.3954 10 13.5 10.8954 13.5 12C13.5 13.1046 14.3954 14 15.5 14Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                            <path
                                d="M10.5 19C10.5 20.1046 9.60457 21 8.5 21C7.39543 21 6.5 20.1046 6.5 19C6.5 17.8954 7.39543 17 8.5 17C9.60457 17 10.5 17.8954 10.5 19Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                            <path
                                d="M15.5 21C16.6046 21 17.5 20.1046 17.5 19C17.5 17.8954 16.6046 17 15.5 17C14.3954 17 13.5 17.8954 13.5 19C13.5 20.1046 14.3954 21 15.5 21Z"
                                :fill="dark ? '#000000' : '#c7c7c7'"></path>
                        </g>
                    </svg>
                </span>

                <span v-if="title.text !== 'Symbol'">{{ title.text }}</span>
                <label v-if="title.text !== 'Symbol'" class="switch" :disabled="title.text === 'Symbol'">
                    <input :disabled="title.text === 'Symbol'" type="checkbox" v-model="title.isShow" />
                    <span class="slider"></span>
                </label>
            </div>

            <div class="setting-button">
                <button class="secondary" @click="resetChanges">Reset</button>
                <button class="secondary" @click="handleSave">Submit</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "SettingsPopup",
    props: {
        headerTitle: {
            type: Array,
            required: true,
                    },
        dark: Boolean,
        headerKey:{
            type:String,
        }
    },
    data() {
        return {
            permissions: JSON.parse(JSON.stringify(this.headerTitle)),
            draggedIndex: null,
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        dragStart(index) {
            this.draggedIndex = index;
        },
        drop(dropIndex) {
            if (this.draggedIndex !== null) {
                const permission = this.permissions[this.draggedIndex];
                this.permissions.splice(this.draggedIndex, 1);
                this.permissions.splice(dropIndex, 0, permission);
                this.draggedIndex = null;
            }
        },
        handleSave() {
            localStorage.setItem(this.headerKey, JSON.stringify(this.permissions));
            this.$emit("update-header", this.permissions);
            this.close();
        },
        resetChanges() {
            this.permissions = JSON.parse(JSON.stringify(this.headerTitle));
            this.permissions.forEach((permission) => {
                permission.isShow = true;
            });
        },
    },
};
</script>

<style scoped>
.permission-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.dark .popup-content {
    background: black;
    box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
    border: 1px solid #333333;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 400px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.permission-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 20px;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #10bc74;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

button {
    background-color: #10bc74;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}

.dark button {
    background: #000000;
    border: 1px solid #10bc74;
    color: #10bc74;
}

.setting-button {
    display: flex;
    justify-content: space-between;
}

.close-icon svg {
    cursor: pointer;
    padding: 3px;
}
</style>
