<template>
  <div class="key-value" :class="{ first: first, last: last, preview: preview }">
    <div class="key-col">
      <p class="key">{{ keyString }}</p>
      <p v-if="shareable" class="share" @click="triggerShare">share</p>
    </div>
    <div class="value-col">
      <p class="value">{{ value }}</p>
      <p v-if="sub" class="sub">{{ sub }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyValue",
  props: {
    keyString: String,
    value: String,
    sub: String,
    first: {
      type: Boolean,
      default: false,
    },
    shareable: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    preview:{
      type:Boolean,
      default:false,
    },
  },
  components: {},
  methods: {
    triggerShare() {
      this.$emit("share");
    },
  },
};
</script>

<style scoped>
.key-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: #eceff2 1px solid;
}
.dark .key-value {
  border-bottom: #333333 1px solid;
}
.key-value.first {
  padding-top: 0;
}
.key-value.last {
  border-bottom: none;
}
.preview {
  padding: 16px;
}
.green .key,
.red .key {
  color: #000000;
}
.dark .green .key,
.dark .red .key {
  color: #d9d9d9;
}

.key-value p {
  font-family: "Roboto";
  margin: 0;
  font-size: 18px;
  font-weight: 300;
}

.key-value .value {
  font-weight: 500;
}

.key-value .value-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.key-value .sub {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: -1em;
}
.key-value .share {
  font-weight: 500;
  font-size: 16px;
  color: #10bc74;
  cursor: pointer;
  margin-bottom: -1em;
}
.key-value .share:hover {
  text-decoration: underline;
}
</style>
