<template>
    <div class="sim-warn">
        <div class="exclaim red">!</div>
        <p class="heading main">
            Important Simulated Trading Information
        </p>
        <p>
            1. Order fills and fills prices can vary slightly from live markets.
        </p>
        <p>
            2. Trading Live on Aries, you will not be charged commission, however TS Simulated Trading will show a
            commission fee.
        </p>
        <p>
            3. If your Live account has yet to be funded with any amount, then some data may be delayed. A deposit of
            any amount is required to activate Options Data and Live Data.
        </p>
        <div class="buttons">
            <button @click="close">Ok</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimWarning",
    props: {},
    components: {},
    data() {
        return {};
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.sim-warn {
    padding: 10px;
    min-width: 280px;
}

.exclaim {
    font-size: 60px;
    text-align: center;
    vertical-align: center;
    width: 75px;
    height: 75px;
    border: #ce0606 2px solid;
    border-radius: 1000px;
    margin: auto;
    margin-bottom: 12px;
    line-height: 1.25em;
}

p {
    margin: 0;
    margin-top: 24px;
    margin-bottom: 24px;
}

.heading {
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
}

.heading.main {
    margin-top: 0;
}

a {
    color: #10bc74;
}

.position-calc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

button,
.buttons a {
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 500;
    width: 130px;
    padding: 10px 0;
    border-radius: 100px;
    border: none;
    color: #ffffff;
    background: #10bc74;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.dark button,
.dark .buttons a {
    background: #000000;
    border: 1px solid #10bc74;
    color: #10bc74;
}

button.secondary {
    color: #000000;
    background: #f5f7fa;
    margin-right: 15px;
}

.dark button.secondary {
    background: #000000;
    border: 1px solid #d9d9d9;
    color: #d9d9d9;
}

button.blank {
    background: #ffffff;
}

.dark button.blank {
    background: #000000;
}

.mid-last button {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
}

.mid-last {
    background: #eef0f3;
    border-radius: 100px;
    cursor: pointer;
}

@media only screen and (max-width: 425px) {}
</style>
