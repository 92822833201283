import Toast from "@brackets/vue-toastification";
import "@brackets/vue-toastification/dist/index.css";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import "./main.css";

import { createApp } from "vue";

const options = {
  // You can set your default options here 2
  position: "top-right",
};
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
import axios from "axios";
import https from "https";
import router from "./router";
import { BootstrapVue3 } from 'bootstrap-vue-3';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

const app = createApp(App)
  .use(PerfectScrollbar)
  .use(router)
  .use(BootstrapVue3)
  .use(Toast, options);
app.use(mdiVue, {
  icons: mdijs, //https://pictogrammers.com/library/mdi/
});
app.use(VueGtag, {
  config: { id: "G-9085BR0F0G", send_page_view: false },
});
app.provide("http", axios);
app.provide("stream", https);

app.mount("#app");
