<template>
  <div
    class="expiry-date"
    @click="emitClick"
    :class="{ active: active, bullish: bullish }"
  >
    <p class="day">{{ day }}</p>
    <p class="month">{{ month }}</p>
    <p class="year">{{ year }}</p>
  </div>
</template>

<script>
export default {
  name: "ExpiryDate",
  props: {
    day: String,
    month: String,
    year: String,
    active: Boolean,
    bullish: Boolean,
  },
  methods: {
    emitClick: function () {
      this.$emit("emitClick");
    },
  },
};
</script>

<style scoped>
.expiry-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  border: 1px solid #eceff2;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 5px 10px RGBA(0, 0, 0, 0.08);
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
.dark .expiry-date {
  box-shadow: 0px 5px 10px RGBA(255, 255, 255, 0.2);
  border: 1px solid #333333;
}
.expiry-date.active {
  background: #ce0606;
  color: white;
}
.dark .expiry-date.active {
  background: #000000;
  color: #ce0606;
  border: 1px solid #ce0606;
}
.bullish .expiry-date.active {
  background: #10bc74;
}
.dark .bullish .expiry-date.active {
  background: #000000;
  color: #10bc74;
  border: 1px solid #10bc74;
}

p {
  margin: 0;
  font-family: "Roboto";
}

p.day {
  font-weight: 500;
  font-size: 24px;
}
p.month {
  font-size: 18px;
}
p.year {
  font-size: 14px;
  color: #616164;
}
@media only screen and (max-width: 550px) {
  .expiry-date {
    width: 60px;
    height: 70px;
  }
  p.day {
    font-size: 21px;
  }
  p.month {
    font-size: 16px;
  }
}
</style>
