<template>
  <div class="account-menu">
    <p class="heading">Manage Accounts</p>
    <p>
      You can manage your account and security by logging in to
      <a href="https://www.tradestation.com/" @click="actionLog('manageAccounts')" target="_blank">TradeStation Client
        Center</a>.
    </p>
    <p class="heading">Manage Funds</p>
    <p>
      For deposits and transfers please use
      <a :href="fundLink" @click="actionLog('manageFunds')" target="_blank">TradeStation Funding & Transfers</a>.
    </p>
    <p class="heading">Discord Channel</p>
    <p>
      We welcome all users to join our Discord channel for updates, suggestions,
      and bug reports. You can join by
      <a href="https://discord.com/invite/zN3mZw7Jpa" @click="actionLog('discord')" target="_blank">clicking here</a>.
    </p>
    <p class="heading">TradeStation Support</p>
    <p>
      For trade execution, account and funding questions, as well as data access
      please
      <a href="https://www.tradestation.com/contact-us/" @click="actionLog('tradeDesk')" target="_blank">reach out to
        support here</a>.
    </p>
    <p class="heading">Aries Support</p>
    <p>
      For questions, suggestions, and questions related to the Aries trading
      platform e-mail
      <a href="mailto:dev@tradearies.com" @click="actionLog('contactDev')" target="_blank">dev@tradearies.com</a>.
    </p>
    <div class="heading">Calculate Unrealized Gains by:</div>
    <div class="toggle-house">
      <div class="toggle-button" @click="toggleCalculation">
        <button :class="{ active: !byMark }">Last Price</button>
        <button :class="{ active: byMark }">Mark</button>
      </div>
    </div>
    <div class="heading">Notification Sound:</div>
    <div class="toggle-house last">
      <div class="toggle-button" @click="toggleSound">
        <button :class="{ active: !sound }">Off</button>
        <button :class="{ active: sound }">On</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountMenu",
  props: {
    byMark: Boolean,
    sound: Boolean,
  },
  components: {},
  data() {
    return {
      fundLink: `https://clientcenter.tradestation.com/support/transfer_funds_cashiering.aspx?page=transfers`
    };
  },
  methods: {
    toggleCalculation: function () {
      this.$emit("toggleMark");
    },
    toggleSound: function () {
      this.$emit("toggleSound");
    },
    actionLog(action) {
      switch (action) {
        case "manageAccounts":
          this.$gtag.event("manage_accounts");
          break;
        case "manageFunds":
          this.$gtag.event("manage_funds");
          break;
        case "discord":
          this.$gtag.event("join_discord");
          break;
        case "tradeDesk":
          this.$gtag.event("contact_ts", { location: "account_menu" });
          break;
        case "contactDev":
          this.$gtag.event("contact_dev");
          break;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
}

.account-menu {
  padding: 10px;
  min-width: 280px;
}

p {
  margin: 0;
  margin-bottom: 20px;
}

.heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.heading.main {
  margin-top: 0;
}

a {
  color: #10bc74;
}

.toggle-house {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.last {
  margin-bottom: 0;
}

button {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  width: 80px;
  padding: 5px 0;
  border-radius: 100px;
  border: none;
  color: #000000;
  background: #eef0f3;
  cursor: pointer;
}

button.active {
  background: #10bc74;
  color: #ffffff;
}

.toggle-button button {
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
}

.toggle-button {
  background: #eef0f3;
  border-radius: 100px;
  cursor: pointer;
}

@media only screen and (max-width: 425px) {}
</style>
