<template>
    <div class="options-chain" :class="{ bullish: isGreen }">
        <!-- <p>Expiry Date:</p> -->
        <div class="horizontal-scroll">
            <perfect-scrollbar class="horizontal-scroll">
                <ExpiryDate v-for="(date, index) in dates" :key="'expdate' + index" @emitClick="changeExpiry(date)"
                    :bullish="isGreen" :day="date.Date.substring(8, 10)"
                    :month="months[parseInt(date.Date.substring(5, 7)) - 1]" :year="date.Date.substring(0, 4)"
                    :active="date.Date == selectedDate.Date" />
            </perfect-scrollbar>
        </div>
        <div class="view-switch">
            <div class="buy-sell" @click="buy = !buy">
                <button :class="{ active: buy }">Buy</button>
                <button :class="{ active: !buy }">Sell</button>
            </div>
            <div class="call-put" @click="calls = !calls">
                <button :class="{ active: calls }">Call</button>
                <button :class="{ active: !calls }">Put</button>
            </div>
        </div>
        <div class="options-list loading" v-if="loading">
            <img src="../assets/loading.gif" />
        </div>
        <div class="options-list" v-if="!loading">
            <perfect-scrollbar watchOptions="true" ref="chain">
                <p class="load-more green" v-if="loadLevel < 100" @click="loadMore('top')">
                    {{ loadingMore ? "" : "Load more" }}
                    <img v-if="loadingMore" src="../assets/loading.gif" />
                </p>
                <div v-for="(option, index) in visibleChain" :key="'option' + index">
                    <OptionRow :strike="dollarWithCommas(option.StrikePrice)"
                        :call="option.Symbol.split(' ')[1].indexOf('C') != -1" :active="selectedCart.filter(
                            (obj) => obj.Symbol == option.Symbol && obj.Buy == buy
                        ).length != 0
                            " :bullish="isGreen" :breakEven="calls
        ? dollarWithCommas(
            option.StrikePrice + (option.Ask + option.Bid) / 2
        )
        : dollarWithCommas(
            option.StrikePrice - (option.Ask + option.Bid) / 2
        )
        " :toBreak="(((calls
        ? option.StrikePrice + (option.Ask + option.Bid) / 2
        : option.StrikePrice - (option.Ask + option.Bid) / 2) -
        underlying) /
        underlying) *
        100
        " :price="dollarWithCommas((option.Ask + option.Bid) / 2)" :change="option.NetChangePct.toFixed(2)"
                        :delta="option.Delta" :theta="option.Theta" :gamma="option.Gamma" :rho="option.Rho"
                        :vega="option.Vega" :iv="option.ImpliedVolatility" :oi="option.DailyOpenInterest"
                        :volume="option.Volume" @rowSelect="selectOption(option)"
                        @activeTradeRow="activeTradeOption(option)" />
                    <div class="underlying" v-if="index == expIndex">
                        <p>Underlying: ${{ dollarWithCommas(underlying) }}</p>
                    </div>
                </div>
                <p class="load-more green" v-if="loadLevel < 100" @click="loadMore('bottom')">
                    {{ loadingMore ? "" : "Load more" }}
                    <img v-if="loadingMore" src="../assets/loading.gif" />
                </p>
            </perfect-scrollbar>
        </div>
        <div class="cart" v-if="selectedCart.length > 0">
            <div class="order-list">
                <p v-for="(option, index) in selectedCart" :key="'cart' + index" @click="selectOption(option)">
                    {{ option.Buy ? "Buy" : "Sell" }} {{ option.StrikePrice }}
                    {{
                        option.Symbol.split(" ")[1].indexOf("C") != -1 ? "Calls" : "Puts"
                    }}
                </p>
            </div>
            <button class="active" @click="startOrder">Order</button>
        </div>
    </div>
</template>

<script>
import Mixins from "../Mixins";
import ExpiryDate from "./ExpiryDate.vue";
import OptionRow from "./OptionRow.vue";

export default {
    name: "OptionsChainMobile",
    props: {
        symbol: String,
        underlying: Number,
        dates: Array,
        options: Array,
        selectedDate: Object,
        selectedCart: Array,
        loadedDate: Object,
        loadLevel: Number,
        lastLoad: Number,
    },
    mixins: [Mixins],
    components: {
        ExpiryDate,
        OptionRow,
    },
    data() {
        return {
            buy: true,
            calls: true,
            loading: false,
            loadingMore: false,
            loadFrom: "top",
            loadTarget: 10,
            expiryDates: this.dates,
            months: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            activeClickPrevent: false,
        };
    },
    computed: {
        isGreen() {
            if ((this.buy && this.calls) || (!this.buy && !this.calls)) {
                return true;
            } else {
                return false;
            }
        },
        visibleChain() {
            var results = [];
            if (this.options == null) {
                return results;
            }
            if (this.calls) {
                results = this.options.filter(
                    (contract) =>
                        contract.AssetType != "UNKNOWN" &&
                        contract.Symbol.split(" ")[1].indexOf("C") != -1
                );
            } else {
                results = this.options.filter(
                    (contract) =>
                        contract.AssetType != "UNKNOWN" &&
                        contract.Symbol.split(" ")[1].indexOf("P") != -1
                );
            }
            return results;
        },
        expIndex() {
            var indexResult = 0;
            for (var i = 0; i < this.visibleChain.length; i++) {
                if (this.visibleChain[i].StrikePrice < this.underlying) {
                    indexResult = i;
                }
            }
            return indexResult;
        },
    },
    methods: {
        activeTradeOption(option) {
            this.activeClickPrevent = true;
            this.$emit('activeOption', option);
        },
        selectOption(option) {
            if (this.activeClickPrevent) {
                this.activeClickPrevent = false;
                return;
            }
            var selectedOption = JSON.parse(JSON.stringify(option));
            if (selectedOption.Buy == undefined) {
                selectedOption.Buy = this.buy;
            }
            if (
                this.selectedCart.filter(
                    (obj) =>
                        obj.Symbol == selectedOption.Symbol && obj.Buy == selectedOption.Buy
                ).length != 0
            ) {
                var foundIndex = this.selectedCart.findIndex(
                    (obj) =>
                        obj.Symbol == selectedOption.Symbol && obj.Buy == selectedOption.Buy
                );
                this.selectedCart.splice(foundIndex, 1);
            } else {
                this.selectedCart.push(selectedOption);
            }
            this.startOrder();
        },
        close() {
            this.$emit("close");
        },
        changeExpiry(date) {
            this.loading = true;
            this.loadTarget = 10;
            this.$emit("changeExpiry", date);
        },
        startOrder(e) {
            //pass the event to check if order button was hit.
            this.$emit("orderOptions", { cart: this.selectedCart, event: e });
        },
        loadMore(from) {
            this.loadFrom = from;
            if (this.loadingMore) {
                return;
            } else {
                this.loadTarget += 10;
                this.loadingMore = true;
                this.$emit("changeLoad", this.loadTarget);
            }
        },
        scrollMid() {
            var _this = this;
            var curMid = 0;
            for (var i = 0; i < this.visibleChain.length; i++) {
                if (this.visibleChain[i].StrikePrice < this.underlying) {
                    curMid = i;
                }
            }
            if (this.$refs.chain) {
                this.$refs.chain.ps.scrollTop = 120 * curMid + 52;
                this.$refs.chain.ps.element.scrollTop = 120 * curMid + 52;
            } else {
                setTimeout(function () {
                    _this.scrollMid();
                }, 200);
            }
        },
    },
    watch: {
        visibleChain: function () {
            if (this.lastLoad == this.loadTarget && this.loadingMore) {
                this.loadingMore = false;
                if (this.loadFrom == "top") {
                    this.$nextTick(function () {
                        this.$refs.chain.scrollTop = 0;
                    });
                } else {
                    this.$nextTick(function () {
                        this.$refs.chain.scrollTop = 10000000;
                    });
                }
            }
            if (this.loading && this.selectedDate.Date == this.loadedDate.Date) {
                this.loading = false;
                setTimeout(this.scrollMid(), 500);
            }
        },
    },
    mounted() {
        setTimeout(this.scrollMid(), 500);
    },
    beforeUnMount() {
        //before you go save the selected cart within tickerDetails
        if (this.selectedCart.length > 0) {
            this.$emit('saveCart', this.selectedCart);
        }
    }
};
</script>

<style scoped>
.options-chain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

p {
    font-family: "Roboto";
}

.horizontal-scroll {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    height: 140px;
    overflow-y: hidden;
    /* min-height: 130px; */
}

.horizontal-scroll>.ps {
    /* width: 1000px; */
}

.horizontal-scroll .expiry-date {
    margin-right: 10px;
}

.view-switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

button {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    width: 80px;
    padding: 5px 0;
    border-radius: 100px;
    border: none;
    color: #000000;
    background: #eef0f3;
    cursor: pointer;
}

.dark button {
    color: #d9d9d9;
    background: #333333;
}

button.active {
    background: #ce0606;
    color: #ffffff;
}

.bullish button.active {
    background: #10bc74;
}

.buy-sell button:first-of-type,
.call-put button:first-of-type {
    margin-right: 10px;
}

.buy-sell,
.call-put {
    background: #eef0f3;
    border-radius: 100px;
    cursor: pointer;
}

.dark .buy-sell,
.dark .call-put {
    background: #333333;
}

.options-list {
    flex-grow: 10;
    height: 100%;
    margin-top: 10px;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
}

.options-list.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.options-list.loading img {
    width: 50px;
    height: 50px;
}

.underlying {
    border-bottom: 1px solid #eef0f3;
    padding: 10px 0;
}

.dark .underlying {
    border-bottom: 1px solid #333333;
}

.underlying p {
    text-align: center;
    margin: 0;
    font-family: "Roboto";
    font-size: 16px;
    color: #ce0606;
    font-weight: 600;
}

.bullish .underlying p {
    color: #10bc74;
}

.cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 10px;
}

.cart .order-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-right: 20px;
}

.cart .order-list p {
    /* display: inline-block; */
    margin: 0;
    margin-right: 10px;
    color: #ce0606;
    cursor: pointer;
}

.bullish .cart .order-list p {
    color: #10bc74;
}

.load-more {
    cursor: pointer;
    text-align: center;
}

.load-more img {
    height: 20px;
    width: 20px;
    position: relative;
    top: 5px;
}

@media only screen and (max-width: 550px) {
    .horizontal-scroll {
        height: 100px;
    }

    button {
        width: 60px;
        font-size: 14px;
    }

    .underlying p {
        font-size: 14px;
    }
}
</style>
