<template>
  <div class="flow-meters">
    <div class="bullish meter">
      <RadialProgressBar :diameter="130" :stroke-width="10" :inner-stroke-width="10" start-color="#10BC74"
        stop-color="#10BC74" :inner-stroke-color="dark ? '#575859' : '#EEF0F3'" :completed-steps="bullish"
        :total-steps="100">
        <p class="gauge-label">{{ bullish.toFixed(0) }}%</p>
      </RadialProgressBar>
      <p class="title">Bullish</p>
      <p class="description">Flow Sentiment</p>
    </div>
    <div class="put-to-call meter">
      <RadialProgressBar :diameter="130" :stroke-width="10" :inner-stroke-width="10" start-color="#5831C7"
        stop-color="#5831C7" :inner-stroke-color="dark ? '#575859' : '#EEF0F3'" :completed-steps="
          isNaN(
            volumeTotals.puts / volumeTotals.calls > 2
              ? 2
              : volumeTotals.puts / volumeTotals.calls
          )
            ? 0
            : volumeTotals.puts / volumeTotals.calls > 2
              ? 2
              : volumeTotals.puts / volumeTotals.calls
        " :total-steps="2">
        <p class="gauge-label">
          {{
          (isNaN(volumeTotals.puts / volumeTotals.calls)
  ? 0
  : volumeTotals.puts / volumeTotals.calls
).toFixed(2) == "Infinity"
  ? "Full"
  : (isNaN(volumeTotals.puts / volumeTotals.calls)
    ? 0
    : volumeTotals.puts / volumeTotals.calls
  ).toFixed(2)
          }}
        </p>
      </RadialProgressBar>
      <p class="title">Put to Call</p>
      <p class="description">
        Current:
        {{
        (isNaN(volumeTotals.puts / volumeTotals.calls)
  ? 0
  : volumeTotals.puts / volumeTotals.calls
).toFixed(3) == "Infinity"
  ? "Full"
  : (isNaN(volumeTotals.puts / volumeTotals.calls)
    ? 0
    : volumeTotals.puts / volumeTotals.calls
  ).toFixed(3)
        }}
      </p>
    </div>
    <div class="put-flow meter">
      <RadialProgressBar :diameter="130" :stroke-width="10" :inner-stroke-width="10" start-color="#FF6C4B"
        stop-color="#FF6C4B" :inner-stroke-color="dark ? '#575859' : '#EEF0F3'" :completed-steps="
          isNaN((volumeTotals.puts / volumeTotals.total) * 100)
            ? 0
            : (volumeTotals.puts / volumeTotals.total) * 100
        " :total-steps="100">
        <p class="gauge-label">
          {{
          (isNaN((volumeTotals.puts / volumeTotals.total) * 100)
  ? 0
  : (volumeTotals.puts / volumeTotals.total) * 100
).toFixed(0)
          }}%
        </p>
      </RadialProgressBar>
      <p class="title">Put Flow</p>
      <p class="description">
        Current: {{ numberWithCommas(volumeTotals.puts) }}
      </p>
    </div>
    <div class="call-flow meter">
      <RadialProgressBar :diameter="130" :stroke-width="10" :inner-stroke-width="10" start-color="#10BC74"
        stop-color="#10BC74" :inner-stroke-color="dark ? '#575859' : '#EEF0F3'" :completed-steps="
          isNaN((volumeTotals.calls / volumeTotals.total) * 100)
            ? 0
            : (volumeTotals.calls / volumeTotals.total) * 100
        " :total-steps="100">
        <p class="gauge-label">
          {{
          (isNaN((volumeTotals.calls / volumeTotals.total) * 100)
  ? 0
  : (volumeTotals.calls / volumeTotals.total) * 100
).toFixed(0)
          }}%
        </p>
      </RadialProgressBar>
      <p class="title">Call Flow</p>
      <p class="description">
        Current: {{ numberWithCommas(volumeTotals.calls) }}
      </p>
    </div>
  </div>
</template>

<script>
import Mixins from "../Mixins";
import RadialProgressBar from "../components/RadialProgressBar.vue";

export default {
  name: "FlowMeters",
  props: {
    flow: Array,
    dark: Boolean,
  },
  mixins: [Mixins],
  components: {
    RadialProgressBar,
  },
  data() {
    return {};
  },
  computed: {
    bullish: function () {
      var totalValue = 0;
      var totalBullish = 0;
      this.flow.forEach((row) => {
        totalValue += parseFloat(row.total_value);
        if (row.sentiment == "bullish") {
          totalBullish += parseFloat(row.total_value);
        }
      });
      return isNaN((totalBullish / totalValue) * 100)
        ? 0
        : (totalBullish / totalValue) * 100;
    },
    volumeTotals: function () {
      var totalVolume = 0;
      var totalCalls = 0;
      var totalPuts = 0;
      this.flow.forEach((row) => {
        totalVolume += parseInt(row.total_size);
        if (row.contract.charAt(12) == "C") {
          totalCalls += parseInt(row.total_size);
        } else if (row.contract.charAt(12) == "P") {
          totalPuts += parseInt(row.total_size);
        }
      });
      return { total: totalVolume, calls: totalCalls, puts: totalPuts };
    },
  },
  methods: {},
};
</script>

<style scoped>
.flow-meters {
  display: flex;
  flex-direction: row;
}

.meter {
  width: 25%;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 0px 15px 30px RGBA(0, 0, 0, 0.08);
  border: 1px solid #eceff2;
  border-radius: 8px;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dark .meter {
  background: #000000;
  box-shadow: 0px 15px 30px RGBA(255, 255, 255, 0.2);
  border: 1px solid #333333;
}

.meter:last-of-type {
  margin-right: 0;
}

p {
  margin: 0;
}

.gauge-label {
  position: relative;
  font-size: 34px;
  font-weight: 300;
  left: 0.25em;
}

.put-to-call .gauge-label {
  left: 0;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.description {
  font-size: 16px;
  color: #909194;
}

@media only screen and (max-width: 1100px) {
  .meter {
    margin-right: 10px;
    padding: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .flow-meters {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .meter {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .meter {
    width: 50%;
    margin: 0;
    box-sizing: border-box;
  }
}
</style>
