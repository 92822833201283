<template>
  <div class="flow-filters">
    <div class="filters">
      <div class="sliders-group">
        <DoubleRangeSlider
          v-for="drs in doubleRangeSliders"
          :key="drs.key"
          :class="drs.className"
          :filterProps="drs.filterProps"
          @filterUpdated="updateRangeFilter"
        ></DoubleRangeSlider>
      </div>

      <button
        class="filter"
        :class="{ active: filter['positions'] }"
        @click="setFilter('positions')"
      >
        My Positions
      </button>
      <button
        class="filter"
        :class="{ active: filter['unusual'] }"
        @click="setFilter('unusual')"
      >
        Unusual
      </button>
      <!-- <button class="filter" :class="{active:filter['weekly']}" @click="setFilter('weekly')">Weekly</button> -->
      <!-- <button class="filter" :class="{active:filter['3day']}" @click="setFilter('3day')">3 Day</button> -->
      <button
        class="filter"
        :class="{ active: filter['less30'] }"
        @click="setFilter('less30')"
      >
        &lt;0.30
      </button>
      <!-- <button class="filter" :class="{active:filter['million']}" @click="setFilter('million')">&gt;1M</button> -->
      <button
        class="filter"
        :class="{ active: filter['ask'] }"
        @click="setFilter('ask')"
      >
        &gt;=Ask
      </button>
      <button
        class="filter"
        :class="{ active: filter['bid'] }"
        @click="setFilter('bid')"
      >
        &lt;=Bid
      </button>
      <button
        class="filter"
        :class="{ active: filter['large'] }"
        @click="setFilter('large')"
      >
        Large
      </button>
      <button
        class="filter"
        :class="{ active: filter['sweep'] }"
        @click="setFilter('sweep')"
      >
        Sweep
      </button>
      <!-- <button class="filter" :class="{active:filter=='golden'}" @click="setFilter('golden')">Golden</button> -->
      <!-- <button class="filter" :class="{active:filter=='sigs'}" @click="setFilter('sigs')">Sigs</button> -->
      <button
        class="filter"
        :class="{ active: filter['block'] }"
        @click="setFilter('block')"
      >
        Block
      </button>
      <button
        class="filter"
        :class="{ active: filter['watchlist'] }"
        @click="setFilter('watchlist')"
      >
        Watchlist
      </button>
      <button
        class="filter"
        :class="{ active: filter['bullish'] }"
        @click="setFilter('bullish')"
      >
        Bullish
      </button>
      <button
        class="filter"
        :class="{ active: filter['bearish'] }"
        @click="setFilter('bearish')"
      >
        Bearish
      </button>
      <button
        class="filter"
        :class="{ active: filter['neutral'] }"
        @click="setFilter('neutral')"
      >
        Neutral
      </button>
      <button
        class="filter"
        :class="{ clear: needClear }"
        @click="setFilter(null)"
      >
        Reset
      </button>
    </div>
  </div>
</template>

<script>
import DoubleRangeSlider from "./DoubleRangeSlider.vue";

export const RANGE_FILTER_MAP = {
  premium: { from: 0, to: 1000000 },
  size: { from: 0, to: 10000 },
  daysToExpire: { from: 0, to: 180 },
};
export default {
  name: "FlowFilters",
  props: {
    ticker: String,
  },
  components: {
    DoubleRangeSlider,
  },
  data() {
    return {
      filter: {
        positions: false,
        size: false,
        million: false,
        unusual: false,
        weekly: false,
        "3day": false,
        bid: false,
        ask: false,
        sweep: false,
        less30: false,
        block: false,
        watchlist: false,
      },
      needClear: false,
      doubleRangeSliders: [
        {
          key: 0,
          className: "slider-1",
          filterProps: {
            name: "premium",
            displayName: "Premium",
            minValue: RANGE_FILTER_MAP.premium.from,
            maxValue: RANGE_FILTER_MAP.premium.to,
            leftValue: 0,
            rightValue: 100,
            prependString: "$",
          },
        },
        {
          key: 1,
          className: "slider-1",
          filterProps: {
            name: "size",
            displayName: "Size",
            minValue: RANGE_FILTER_MAP.size.from,
            maxValue: RANGE_FILTER_MAP.size.to,
            leftValue: 0,
            rightValue: 100,
            prependString: "",
          },
        },
        {
          key: 2,
          className: "slider-3",
          filterProps: {
            name: "daysToExpire",
            displayName: "Days to Expire",
            minValue: RANGE_FILTER_MAP.daysToExpire.from,
            maxValue: RANGE_FILTER_MAP.daysToExpire.to,
            leftValue: 0,
            rightValue: 100,
            prependString: "",
          },
        },
      ],
    };
  },
  methods: {
    setFilter(newFilter) {
      if (newFilter == null) {
        this.filter = {
          positions: false,
          large: false,
          million: false,
          unusual: false,
          weekly: false,
          "3day": false,
          bid: false,
          ask: false,
          sweep: false,
          less30: false,
          block: false,
          watchlist: false,
          premium: false,
          size: false,
          daysToExpire: false,
        };
        this.needClear = false;
        for (let slider of this.doubleRangeSliders) {
          //trigger a rerender by changing their key
          slider.key = Math.floor(Math.random() * 1001);
          //set range sliders to original positions.
          slider.filterProps.leftValue = 0;
          slider.filterProps.rightValue = 100;
          localStorage.setItem(slider.filterProps.name, JSON.stringify({}));
        }
      } else {
        this.filter[newFilter] = !this.filter[newFilter];
        this.needClear = this.isFilterClear();
      }
      localStorage.setItem("flowFilter", JSON.stringify(this.filter));
      this.$emit("newFilters", this.filter);
    },
    updateRangeFilter(event) {
      this.$emit("rangeFilter", event);
      if (
        RANGE_FILTER_MAP[event.filterName].from != event.from ||
        RANGE_FILTER_MAP[event.filterName].to != event.to
      ) {
        this.filter[event.filterName] = true;
        this.needClear = true;
        this.$emit("newFilters", this.filter);
        for (let slider in this.doubleRangeSliders) {
          if (
            this.doubleRangeSliders[slider].filterProps.name == event.filterName
          ) {
            localStorage.setItem(event.filterName, JSON.stringify(event));
          }
        }
      } else {
        this.filter[event.filterName] = false;
        this.needClear = this.isFilterClear();
        this.$emit("newFilters", this.filter);
      }
    },
    isFilterClear() {
      let clear = false;

      for (var filter in this.filter) {
        if (this.filter[filter] == true) {
          clear = true;
        }
      }
      if (
        !clear &&
        (this.filter.premium || this.filter.size || this.daysToExpire)
      ) {
        clear = true;
      }
      return clear;
    },
  },
  beforeMount: function () {
    try {
      var sliders = [];
      if (localStorage.premium != null) {
        sliders.push(JSON.parse(localStorage.premium));
      }
      if (localStorage.size != null) {
        sliders.push(JSON.parse(localStorage.size));
      }
      if (localStorage.daysToExpire != null) {
        sliders.push(JSON.parse(localStorage.daysToExpire));
      }
      for (let slider of sliders) {
        for (let s of this.doubleRangeSliders) {
          if (s.filterProps.name == slider.filterName) {
            s.filterProps.leftValue = parseInt(slider.sliderFrom);
            s.filterProps.rightValue = parseInt(slider.sliderTo);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  mounted: function () {
    if (localStorage.flowFilter != null) {
      let filters = JSON.parse(localStorage.flowFilter);
      if (filters) {
        for (let filter in filters) {
          if (filters[filter] == true) {
            this.setFilter(filter);
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.flow-filters {
  display: flex;
  flex-direction: column;
}
.search-house {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
  margin-bottom: 10px;
}

.filters {
  /* flex-grow: 2; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.sliders-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.slider-1,
.slider-2,
.slider-3 {
  width: 30%;
}

.filter {
  font-size: 16px;
  border: 1px solid #d8dbde;
  color: #6a6c6c;
  padding: 9px 16px;
  background: #ffffff;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 100px;
  cursor: pointer;
}

.dark .filter {
  color: #d9d9d9;
  background: #000000;
  box-shadow: 0px 10px 50px RGBA(255, 255, 255, 0.2);
  border: 1px solid #333333;
}

.filter.active {
  background: #10bc74;
  color: #ffffff;
  border: 1px solid #10bc74;
}

.filter.clear {
  background: #151c26;
  color: #ffffff;
  border: 1px solid #2f1e5f;
}

@media only screen and (max-width: 520px) {
  .sliders-group {
    flex-direction: column;
  }

  .slider-1,
  .slider-2,
  .slider-3 {
    width: 100%;
  }

  .flow-filters {
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
