<template>
  <!-- <div v-if="!openOrderAccordian" class="accordian-bar" @click="expandAccordian">
    <div></div>
    <p class="text-white">Trade {{ displaySymbol(ticker) }}</p>
    <mdicon name="chevronUp" size="32" />
  </div> -->
  <div class="accordian">
    <!-- <div class="accordian-bar-open clickable" @click="expandAccordian">
      <div></div>
      <p class="text-black">
        Details & Ratings
      </p>
      <mdicon name="chevronUp" size="32" />
    </div> -->
    <div class="accordian-contents">
      <div class="positions-house">
        <div class="position-window">
          <div class="headlink-house">
            <HeadLink
              :active="!viewRecent"
              title="Positions"
              @on-click="switchView(false)"
            />
            <HeadLink
              :active="viewRecent"
              title="Recent Orders"
              @on-click="switchView(true)"
            />
          </div>

          <Positions
            v-if="!viewRecent"
            :byMark="byMark"
            :positions="positions"
            @openPosition="openPosition"
            :dark="dark"
            @sellOptions="sellTickerOptions"
            @rollOptions="rollTickerOptions"
            @sell="sellTicker"
            :globalPositionsQuotesPrice="globalPositionsQuotesPrice"
            @cancel="buyTicker(ticker.Symbol)"
          />
          <div class="orders-house">
            <OrdersTable
              v-if="viewRecent"
              :sound="sound"
              :orders="orders"
              :orderHistory="orderHistory"
              @openOrder="openOrder"
              :dark="dark"
              :recent="true"
            />
          </div>
        </div>

        <!-- <div class="fade"></div> -->
      </div>

      <div
        v-if="selectedOrder && !selectedPosition"
        class="order-house"
        ref="rightSide1"
      >
        <OrderCard :sound="sound" :order="selectedOrder" @close="closeOrder" />
      </div>
      <div
        class="position-display"
        v-if="!selectedOrder && selectedPosition"
        ref="rightSide2"
      >
        <PositionCard
          :position="selectedPosition"
          :byMark="byMark"
          @buy="buyTicker"
          @sellOptions="sellTickerOptions"
          @rollOptions="rollTickerOptions"
          @buyOptions="buyTickerOptions"
          @sell="sellTicker"
          @cancel="buyTicker(ticker.Symbol)"
        />
      </div>
      <div
        class="position-display"
        v-if="!selectedOrder && !selectedPosition"
        ref="rightSide3"
      >
        <BuyPosition
          v-if="
            !activeTrader &&
            !buy &&
            !sell &&
            !buyOptions &&
            !sellOptions &&
            tickers.length == 0 &&
            currentTab != 'options'
          "
          :sound="sound"
          :account="account"
          :ticker="ticker.Symbol"
          :orderExecRoutes="orderExecRoutes"
          :position="positions.find((elem) => elem.Symbol == ticker.Symbol)"
          @close="expandAccordian"
          :orders="orders"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
        />
        <BuyOptions
          v-if="!buy && !sell && !buyOptions && !sellOptions && tickers.length"
          :sound="sound"
          :position="
            positions.find(
              (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
            )
          "
          :account="account"
          :tickers="tickers"
          :orderExecRoutes="orderExecRoutes"
          @close="$emit('closeOptions')"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
          :orders="orders"
        />
        <BuyPosition
          v-if="buy"
          :sound="sound"
          :account="account"
          :ticker="buy"
          :orderExecRoutes="orderExecRoutes"
          :position="positions.find((elem) => elem.Symbol == ticker.Symbol)"
          @close="buy = null"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
          :orders="orders"
        />
        <BuyOptions
          v-if="buyOptions"
          :sound="sound"
          :account="account"
          :tickers="buyOptions"
          :position="
            positions.find(
              (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
            )
          "
          :orderExecRoutes="orderExecRoutes"
          @close="buyOptions = null"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
          :orders="orders"
        />
        <BuyPosition
          v-if="sell"
          :isClosing="true"
          :sound="sound"
          :account="account"
          :ticker="sell"
          :position="positions.find((elem) => elem.Symbol == ticker.Symbol)"
          :existingQuantity="sellQuantity"
          :startPrice="activeTraderPrice"
          :orderExecRoutes="orderExecRoutes"
          @close="sell = null"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
          :orders="orders"
        />
        <BuyOptions
          :isClosing="true"
          v-if="sellOptions && !rollOptions"
          :orderExecRoutes="orderExecRoutes"
          :orders="orders"
          :position="
            positions.find(
              (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
            )
          "
          @sellOptions="sellTickerOptions"
          @close="sellOptions = null"
          @update-cv="$emit('update-cv')"
          :sound="sound"
          :account="account"
          :tickers="sellOptions"
          :compactMode="true"
          :availableLegs="
            positions.filter(
              (position) =>
                position.Symbol.indexOf(' ') != -1 &&
                position.Symbol.split(' ')[0] ==
                  sellOptions[0].Symbol.split(' ')[0]
            )
          "
        />
        <RollSelect
          :isClosing="true"
          v-if="sellOptions && rollOptions"
          :orderExecRoutes="orderExecRoutes"
          :orders="orders"
          :position="
            positions.find(
              (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
            )
          "
          @rollOptions="rollTickerOptions"
          @close="sellOptions = null"
          @update-cv="$emit('update-cv')"
          :sound="sound"
          :account="account"
          @openOptions="openOptions"
          :tickers="sellOptions"
          :compactMode="true"
          :availableLegs="
            positions.filter(
              (position) =>
                position.Symbol.indexOf(' ') != -1 &&
                position.Symbol.split(' ')[0] ==
                  sellOptions[0].Symbol.split(' ')[0]
            )
          "
        />
        <BuyPosition
          v-if="activeTrader && !activeTraderOption"
          :orders="orders"
          :sound="sound"
          :position="positions.find((elem) => elem.Symbol == ticker.Symbol)"
          :isClosing="activeTraderDirection == 'close'"
          :ticker="ticker.Symbol"
          :startPrice="activeTraderPrice"
          :refresh="activeTaps"
          :account="account"
          :existingQuantity="activeQuantity"
          :orderExecRoutes="orderExecRoutes"
          :startShort="
            (activeTraderIntent == 'buy' && activeTraderDirection == 'close') ||
            (activeTraderIntent == 'sell' && activeTraderDirection == 'open')
          "
          @close="activeTrader = false"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
        />

        <BuyOptions
          v-if="activeTrader && activeTraderOption"
          :orders="orders"
          :sound="sound"
          :isClosing="activeTraderDirection == 'close'"
          :orderExecRoutes="orderExecRoutes"
          :position="
            positions.find(
              (elem) => sellOptions && elem.Symbol === sellOptions[0].Symbol
            )
          "
          :tickers="[
            {
              Symbol: activeTraderOption.Symbol,
              bid: activeTraderOption.Bid,
              ask: activeTraderOption.Ask,
              AssetType: activeTraderOption.AssetType,
              Buy: activeTraderIntent == 'buy',
            },
          ]"
          :startPrice="activeTraderPrice"
          :refresh="activeTaps"
          :account="account"
          :existingQuantity="activeQuantity"
          @close="activeTrader = false"
          @update-cv="$emit('update-cv')"
          :compactMode="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "../../Mixins";
import BuyPosition from "../BuyPosition.vue";
import BuyOptions from "../BuyOptions.vue";
import HeadLink from "../HeadLink.vue";
import Positions from "./Positions.vue";
import OrdersTable from "../OrdersTable.vue";
import OrderCard from "./OrderCard.vue";
import PositionCard from "./PositionCard.vue";
import Toast from "../Toasts.vue";
import RollSelect from "../RollSelect.vue";

export default {
  props: {
    sound: Boolean,
    byMark: Boolean,
    ticker: Object,
    tickers: Array,
    positions: Array,
    globalPositionsQuotesPrice: Object,
    account: Object,
    orders: Array,
    orderHistory: Array,
    position: Object,
    openOrderAccordian: Boolean,
    activeTaps: Number,
    activeTraderOption: Object,
    activeTraderPrice: Number,
    activeTraderDirection: String,
    activeTraderIntent: String,
    orderExecRoutes: Array,
    currentTab: String,
    dark: Boolean,
  },
  mixins: [Mixins],
  components: {
    BuyPosition,
    BuyOptions,
    HeadLink,
    Positions,
    OrdersTable,
    OrderCard,
    PositionCard,
    RollSelect,
  },
  data: function () {
    return {
      viewRecent: false,
      selectedOrder: null,
      selectedPosition: this.position ? this.position : null,
      currentLeft: 400,
      buy: null,
      buyOptions: null,
      sell: null,
      sellOptions: null,
      rollOptions: null,
      sellQuantity: null,
      activeTrader: false,
      activeQuantity: null,
    };
  },

  watch: {
    openOrderAccordian(newValue) {
      if (newValue == false) {
        this.viewRecent = false;
        this.selectedOrder = null;
        this.selectedPosition = null;
        this.currentLeft = 400;
        this.buy = null;
        this.buyOptions = null;
        this.sell = null;
        this.sellOptions = null;
        this.rollOptions = null;
        this.sellQuantity = null;
      }
    },
    activeTaps(newTaps) {
      if (newTaps == 0) {
        return;
      } else {
        this.selectedOrder = null;
        this.selectedPosition = null;
        this.buy = null;
        this.buyOptions = null;
        this.sell = null;
        this.sellOptions = null;
        this.rollOptions = null;
        this.sellQuantity = null;
        this.activeTrader = true;
        if (localStorage.activeQuantity == "Set Size") {
          var setSize = "1";
          if (localStorage.activeSize) {
            setSize = localStorage.activeSize;
          }
          this.activeQuantity = setSize;
        } else if (
          localStorage.activeQuantity == "Set Lot" &&
          localStorage.activeLot
        ) {
          if (this.activeTraderPrice == "market") {
            return;
          }
          var lotSize = 1;
          try {
            var parseTotal = parseFloat(localStorage.activeLot);
            var parsePrice = parseFloat(this.activeTraderPrice);
            if (this.activeTraderOption) {
              parsePrice = parsePrice * 100;
            }
            var quantityCalc = Math.floor(parseTotal / parsePrice);
            lotSize = quantityCalc;
            if (lotSize == 0) {
              lotSize = 1;
              Toast.error(
                `Lot size of ${parseTotal.toFixed(
                  2
                )} is too large for price of ${parsePrice.toString()}`
              );
            }
          } catch (e) {
            console.log(e);
          }
          this.activeQuantity = String(lotSize);
        }
        if (this.activeTraderDirection == "close") {
          var checkTicker = this.ticker.Symbol;
          if (this.activeTraderOption) {
            checkTicker = this.activeTraderOption.Symbol;
          }
          var checkExisting = this.positions.find(
            (elem) => elem.Symbol == checkTicker
          );
          if (checkExisting != null) {
            if (
              parseFloat(this.activeQuantity) <
              parseFloat(checkExisting.Quantity)
            ) {
              this.activeQuantity = checkExisting.Quantity;
            }
          }
        }
      }
    },
    position(newValue) {
      this.selectedPosition = newValue;
    },
  },

  methods: {
    openOptions() {
      this.$emit("openOption", "options");
    },
    expandAccordian: function () {
      if (!this.openOrderAccordian == true) {
        this.$gtag.event("expand_sticky_trader");
      } else {
        this.$gtag.event("hide_sticky_trader");
      }
      this.$emit("openBuy", !this.openOrderAccordian);
    },
    switchView(viewRecent) {
      this.viewRecent = viewRecent;
      if (viewRecent) {
        this.$gtag.event("sticky_view_orders");
      } else {
        this.$gtag.event("sticky_view_positions");
      }
    },
    openOrder: function (order) {
      this.selectedPosition = null;
      this.selectedOrder = order;
      var orderLegs = "";
      order["Legs"].forEach((leg) => {
        orderLegs = orderLegs + leg["Symbol"] + ",";
      });
      this.$gtag.event("order_view", {
        legs: orderLegs,
      });
    },
    closeOrder: function () {
      this.selectedOrder = null;
    },
    openPosition: function (position) {
      this.selectedOrder = null;
      this.buy = null;
      this.sell = null;
      this.sellOptions = null;
      this.rollOptions = null;
      this.buyOptions = null;
      this.selectedPosition = position;

      this.$gtag.event("position_view", { ticker: position["Symbol"] });
    },
    closePosition: function () {
      this.selectedPosition = null;
    },
    openTicker: function (ticker) {
      this.selectedPosition = null;
      this.selectedOrder = null;
      this.$emit("chart", ticker);
    },
    buyTicker: function (ticker) {
      this.$gtag.event("position_add", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.sell = null;
      this.selectedPosition = null;
      this.buy = ticker;
    },
    buyTickerOptions: function (orderArray) {
      this.$gtag.event("position_add", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      this.buyOptions = orderArray;
    },
    sellTicker: function (ticker) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.buy = null;
      this.sellQuantity = this.selectedPosition.Quantity;
      this.selectedPosition = null;
      this.sell = ticker;
    },
    sellTickerOptions: function (orderArray) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      orderArray.forEach((order) => {
        if(order.Quantity.indexOf("-") != -1){
          order.Buy = true;
        } else {
          order.Buy = false;
        }
        order.Quantity = order.Quantity.replaceAll("-", "");
      });
      this.sellOptions = [...orderArray];
      this.rollOptions = null;
    },
    rollTickerOptions: function (orderArray) {
      this.$gtag.event("position_close", {
        ticker: this.selectedPosition["Symbol"],
      });
      this.selectedPosition = null;
      this.sellOptions = [...orderArray];
      this.rollOptions = [...orderArray];
    },
    sortDate: function (a, b) {
      if (a.Timestamp > b.Timestamp) {
        return -1;
      } else return 1;
    },
  },
};
</script>

<style scoped>
.orders-house {
  display: flex;
  flex-direction: row;
  margin-right: 3%;
}

p {
  font-size: 1.1rem;
  font-family: "Roboto";
  font-weight: 500;
}

.position-display {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
  margin-right: 0.5rem;
}

.dark .text-black {
  color: #ffffff;
}

.accordian {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
  border: 1px solid #f0f3f5;
  box-shadow: 0px 2px 4px 0px #00000014;
  border-radius: 12px;
  padding: 30px;
  background: #ffffff;
}

.dark .accordian {
  border: 1px solid #ffffff0d;
  background: #1f2324;
  box-shadow: 0px 2px 4px 0px #00000014;
}

.accordian-contents {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 100%;
  width: inherit;
}

.accordian-bar {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0rem 1rem;
  background-color: #10bc74;
  border-radius: 0.65rem;
  color: white;
  box-sizing: border-box;
}

.accordian-bar-open {
  margin-top: 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0rem 1rem;
  background-color: white;
  border-radius: 0.65rem;
  color: #10bc74;
}

.dark .accordian-bar-open {
  background-color: #000000;
}

.headlink-house {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}

.positions-house {
  border-right: solid 1px #ccc;
  width: 50%;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  /* height: 600px; */
}

.dark .positions-house {
  border-right: 1px solid #ffffff0d !important;
}

.position-window .ps {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 60px;
  box-sizing: border-box;
}

.order-house {
  width: 50%;
  margin-top: 1rem;
}

.fade {
  position: absolute;
  bottom: -1px;
  right: 0;
  left: 0;
  height: 60px;
  background: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
}

.dark .fade {
  background: linear-gradient(
    rgba(31, 35, 36, 0),
    rgba(31, 35, 36, 0.8),
    rgba(31, 35, 36, 1)
  );
}

/* .dark .fade.hidden {
    display: none;
} */

@media only screen and (min-width: 1240px) {
  .accordian {
    width: 100%;
  }
}

@media only screen and (max-width: 920px) {
  .positions-house {
    width: 100%;

    border: none;
    border-bottom: 1px solid #ccc;
  }

  .position-display {
    width: 100%;
    padding: 0 4.5rem;
  }

  .accordian-contents {
    flex-wrap: wrap;
  }

  .order-house {
    width: 100%;
  }
  .accordian {
    width: 100% !important;
  }
}

@media only screen and (max-width: 720px) {
  .position-display {
    padding: 0;
  }
}
</style>
