<template>
  <div class="search-box" :class="{ noShadow: noShadow }">
    
    <input :class="{ onTickerPage: onTickerPage }" ref="searchRef" type="text" v-model="search" placeholder="" v-on:keyup.enter="openTicker(search)" />
    

    <img v-if="!loading" src="../assets/search-normal.png" />
    <img v-if="loading" src="../assets/loading.gif" />
    <div class="search-results" v-if="error || results.length > 0">
      <div class="error" v-if="error">
        <p>{{ error }}</p>
      </div>
      <div v-for="(result, index) in results" :key="'result' + index" @click.stop="openTicker(result.Name)"
        class="result">
        <div class="ticker-container" v-if="!ticker && !result.watched || (ticker != null && result.Name != ticker.Symbol)">
          <p class="ticker">{{ result.Name }}</p>
          <img class="star-icon" v-if="!result.watched" @click.stop="setWatchlist(result, true)" src="../assets/watch.png"
            alt="Add to watchlist" />
          <img class="star-icon" v-else @click.stop="setWatchlist(result, false)" src="../assets/watched.png"
            alt="Add to watchlist" />
        </div>

        <div class="ticker-container" v-else>
          <p class="ticker">{{ result.Name }}</p>
          <img class="star-icon" v-if="!result.watched" @click.stop="setWatchlist(result, true)" src="../assets/watch.png"
            alt="Add to watchlist" />
          <img class="star-icon" v-else @click.stop="setWatchlist(result, false)" src="../assets/watched.png"
            alt="Add to watchlist" />

        </div>
        <p class="name">{{ result.Description }}</p>
      </div>
    </div>
    <div class="search-results recent-tickers" v-else-if="recent.length > 0">
      <div v-for="(result, index) in recent" :key="'result' + index" @click.stop="openTicker(result.Symbol)"
        class="result">
        <div class="ticker-container" v-if="!ticker && !result.watched || (ticker != null && result.Symbol != ticker.Symbol)">
          <p class="ticker">{{ result.Symbol }}</p>
          <img class="star-icon" v-if="!watched" @click.stop="setWatchlist(result, true)" src="../assets/watch.png"
            alt="Add to watchlist" />
          <img class="star-icon" v-else @click.stop="setWatchlist(result, false)" src="../assets/watched.png"
            alt="Add to watchlist" />
        </div>

        <div class="ticker-container" v-else>
          <p class="ticker">{{ result.Symbol }}</p>
          <img class="star-icon" v-if="!result.watched" @click.stop="setWatchlist(result, true)" src="../assets/watch.png"
            alt="Add to watchlist" />
          <img class="star-icon" v-else @click.stop="setWatchlist(result, false)" src="../assets/watched.png"
            alt="Add to watchlist" />

        </div>
        <p class="name">{{ result.Description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBox",
  inject: {
    http: { from: "http" },
  },
  props: {
    watchlist: Array,
    ticker: Object,
    watched: Boolean,
    noShadow: {
      type: Boolean,
      default: false,
    },
    onTickerPage:{
      type:Boolean,
      default:false,
    }
  },
  data: function () {
    return {
      search: "",
      results: [],
      recent: [],
      timer: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    openTicker: function (ticker) {
      this.search = "";
      this.loading = false;
      this.$gtag.event("tap_search", {
        ticker: ticker,
      });
      this.$emit("open-ticker", ticker);
    },
    inWatchList: function (x) {
      if (this.watchlist.find(element => element.symbol == x.Name) || this.watchlist.find(element => element.symbol == x.Symbol)) {
        return true;
      }
      else return false;
    },
    setWatchlist: function (result, watch) {
      result.watched = watch;
      var _this = this;
      // this.$emit('update-ticker-details', watch);
      this.http
        .get(
          process.env.VUE_APP_SERVER +
          `/web/setwatchlist?_id=${localStorage.session}&token=${localStorage.sessionKey}&ticker=${(result.Name || result.Symbol)}&add=${watch}`
        )
        .then(function () { })
        .catch(function () {
          _this.watched = !watch;
        });
    },
  },
  mounted() {
    this.recent = JSON.parse(localStorage.getItem('recentTickers')) || [];
    this.recent.forEach((el) => {
      el.watched = this.inWatchList(el);
    });
  },
  watch: {
    search: function (val) {
      this.error = null;
      if (val == "") {
        this.results = [];
        return;
      } else if(val.toLowerCase() == "spx"){
        this.results = [
          {"Description": "S&P 500 Index","Name": "$SPX.X"},
          {"Description": "S&P 500 PM Settled Index","Name": "$SPXW.X"}
        ];
        return this.results;
      }
      if (this.timer != null) {
        clearTimeout(this.timer);
      }
      this.loading = true;
      var _this = this;
      this.timer = setTimeout(function () {
        var searchUrl =
          (localStorage.sim == "true"
            ? process.env.VUE_APP_TS_SIM_V2
            : process.env.VUE_APP_TS_V2) +
          `data/symbols/suggest/${val}?access_token=${encodeURIComponent(
            localStorage.accessToken
          )}`;
        _this.http
          .get(searchUrl)
          .then(function (res) {
            _this.loading = false;
            if (val == _this.search) {
              _this.results = res.data.filter((el) => {
                const name = el.Name;
                return !name.startsWith('@') && !name.endsWith('.D');
              });
              _this.$gtag.event("search_ticker", {
                ticker: _this.search,
              });
              _this.results.forEach((el) => {
                el.watched = _this.inWatchList(el);
              });
            }
          })
          .catch(function (error) {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.Message
            ) {
              _this.error = error.response.data.Message;
              _this.$gtag.event("web_error", {
                action: "search_ticker",
                res_data: JSON.stringify(error.response.data),
              });
            } else {
              console.log(error);
              _this.error = "Something went wrong... Please try again later";
              _this.$gtag.event("web_error", {
                action: "search_ticker",
                res_data: JSON.stringify(error),
              });
            }
          });
      }, 500);
    },
  },
};
</script>

<style scoped>
.search-box {
  position: relative;
}

input {
  position: relative;
  height: 45px;
  border-radius: 12px;
  border: 1px solid #eef0f3;
  box-shadow: 0px 2px 4px 0px #00000014;
  padding: 12px 20px;
  padding-right: 40px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  z-index: 30;
}
.noShadow input {
  box-shadow: none;
}
.onTickerPage {
  max-width: 10rem;
}

.dark input {
  background: #1F2324;
  color: #d9d9d9;
  box-shadow: none;
  border: 0.5px solid #FFFFFF4D
}

input:focus {
  outline: #10bc74 solid 2px;
}

.recent-tickers {
  display: none;
}

input:focus~.recent-tickers {
  display: block;
}

.recent-tickers:hover {
  display: block;
}

img {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 30;
  width: 16px;
  height: 16px;
}

.star-icon {
  position: unset;
  margin-left: .25rem;
  z-index: 30;
  width: 16px;
  height: 16px;
}

.search-results {
  position: absolute;
  top: 45px;
  left: 20px;
  right: 20px;
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 10px 50px RGBA(0, 0, 0, 0.16);
  z-index: 20;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-height: 375px;
  overflow-y: scroll;
}

.dark .search-results {
  background: #191919;
}

.result {
  margin-top: 20px;
  cursor: pointer;
}

.result p {
  font-family: "Roboto";
  margin: 0;
  text-align: left;
  font-size: 16px;
}

.result .ticker {
  font-weight: 500;
}

.ticker-container {
  display: flex;
  align-items: center;
}

.error p {
  margin: 0;
  font-family: "Roboto";
  font-size: 14px;
  color: #ce0606;
  margin-top: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 725px) {

.search-box,
  input {
    width: 100%;
  }
}
@media only screen and (max-width: 568px) {
  input {
    max-width: unset!important;
  }
}
</style>