<template>
  <div class="header-house">
    <div class="app-header">
      <div class="left">
        <img
          src="../assets/logo.png"
          class="logo"
          alt="Aries Logo"
          @click="goHome"
        />
        <div class="large" v-if="login">
          <a
            href="#"
            @click="openTab('trade')"
            :class="{ active: tab == 'trade' }"
            >Trade</a
          >
          <a
            href="#"
            @click="openTab('flow')"
            :class="{ active: tab == 'flow' }"
            >Flow</a
          >
        </div>
        <!-- <a href="#">Reviews</a>
        <a href="#">Tools</a> -->
      </div>
      <div class="right">
        <div class="dark-toggle" @click="toggleDarkMode()">
          <img v-if="!dark" src="../assets/sun.png" aria-hidden="true" />
          <div class="toggle-circle" />
          <img v-if="dark" src="../assets/moon.png" aria-hidden="true" />
        </div>
        <div class="large button account" v-if="login" @click="account">
          <img src="../assets/user.png" aria-hidden="true" />
          <a href="#">Account</a>
        </div>
        <div class="large button logout" v-if="login" @click="signOut">
          <a href="#">Sign Out</a>
        </div>
        <div
          class="large button logout"
          v-if="!login && tab == 'flow'"
          @click="loginFlow"
        >
          <a href="#">Login</a>
        </div>
        <div class="small" @click="openMenu">
          <img
            v-if="!menu"
            src="../assets/menu.png"
            class="menu"
            alt="Open Menu"
          />
          <img
            v-if="menu"
            src="../assets/close.png"
            class="menu"
            alt="Close Menu"
          />
        </div>
      </div>
    </div>
    <div class="small mobile-menu" v-if="menu">
      <div class="button" v-if="login">
        <a
          href="#"
          @click="openTab('trade')"
          :class="{ active: tab == 'trade' }"
          >Trade</a
        >
      </div>
      <div class="button" v-if="login">
        <a href="#" @click="openTab('flow')" :class="{ active: tab == 'flow' }"
          >Flow</a
        >
      </div>
      <div class="small-right">
        <div class="button account" v-if="login" @click="account">
          <img src="../assets/user.png" aria-hidden="true" />
          <a href="#">Account</a>
        </div>
        <div class="button logout" v-if="login" @click="signOut">
          <a href="#">Sign Out</a>
        </div>
        <div
          class="button logout"
          v-if="!login && tab == 'flow'"
          @click="loginFlow"
        >
          <a href="#">Login</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    login: Boolean,
    tab: String,
    dark: Boolean,
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    signOut() {
      this.menu = false;
      this.$emit("signOut");
      this.$gtag.event("logout");
    },
    loginFlow() {
      this.$emit("loginFlow");
    },
    account() {
      this.menu = false;
      this.$emit("account");
      this.$gtag.event("open_account_menu");
    },
    goHome() {
      this.menu = false;
      this.$emit("home");
    },
    openTab(newTab) {
      this.menu = false;
      if (
        newTab == "trade" &&
        this.$router.options.history.state.back != null &&
        this.$router.options.history.state.back.includes("trade")
      ) {
        this.$router.push(this.$router.options.history.state.back);
        this.$emit("openTab", this.$router.options.history.state.back);
      } else {
        this.$emit("openTab", newTab);
      }
    },
    toggleDarkMode() {
      this.$gtag.event("toggle_dark_mode", { dark_mode_active: !this.dark });
      this.$emit("toggleDark", !this.dark);
    },
    openMenu() {
      this.menu = !this.menu;
    },
  },
};
</script>

<style scoped>
.header-house {
  display: flex;
  flex-direction: column;
}
.app-header {
  height: 80px;
  background: #2f1e5f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
}

.logo {
  margin-right: 32.5px;
}

a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.left {
  display: flex;
  align-items: center;
}

.left a {
  font-weight: 500;
  margin-right: 32px;
}

.left a:hover,
a.active {
  color: #6be58b;
}

.right {
  display: flex;
  align-items: flex-end;
}
.button {
  padding: 10px 20px;
  border-radius: 100px;
  cursor: pointer;
}
.button.account img {
  position: relative;
  top: 0.25em;
  margin-right: 8px;
}
.button.account:hover a {
  color: #6be58b;
}
.button.logout {
  background: #5831c7;
}
.button.logout:hover {
  background: #ffffff;
}
.button.logout:hover a {
  color: #5831c7;
}
.right .small img {
  height: 24px;
}
.small.mobile-menu {
  background: #6a628c;
  box-shadow: inset 0px 0px 30px RGBA(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-right: 20px;
}
.mobile-menu .small-right {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  /* margin-right: -20px; */
  margin-top: 20px;
}
.small {
  display: none;
}
.dark-toggle {
  background: #937bd5;
  height: 20px;
  width: 43px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;
}
.dark-toggle img {
  width: 20px;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  /* display: inline-block; */
}
.dark-toggle .toggle-circle {
  width: 20px;
  height: 20px;
  background: #ffffff;
  /* display: inline-block; */
  border-radius: 100px;
}
@media only screen and (max-width: 868px) {
  /* .left a{
    display: none;
  } */
  .app-header {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 700px) {
  .large {
    display: none;
  }
  .small {
    display: block;
  }
  .dark-toggle {
    margin-right: 20px;
    margin-bottom: 5px;
  }
}
</style>
